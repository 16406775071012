import React from 'react';

export const RequestsIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.7135 5.35212C16.7135 5.23426 16.617 5.13784 16.4992 5.13784H6.21345C6.09559 5.13784 5.99916 5.23426 5.99916 5.35212V6.63784C5.99916 6.75569 6.09559 6.85212 6.21345 6.85212H16.4992C16.617 6.85212 16.7135 6.75569 16.7135 6.63784V5.35212ZM16.4992 8.99498H6.21345C6.09559 8.99498 5.99916 9.09141 5.99916 9.20926V10.495C5.99916 10.6128 6.09559 10.7093 6.21345 10.7093H16.4992C16.617 10.7093 16.7135 10.6128 16.7135 10.495V9.20926C16.7135 9.09141 16.617 8.99498 16.4992 8.99498ZM11.142 12.8521H6.21345C6.09559 12.8521 5.99916 12.9485 5.99916 13.0664V14.3521C5.99916 14.47 6.09559 14.5664 6.21345 14.5664H11.142C11.2599 14.5664 11.3563 14.47 11.3563 14.3521V13.0664C11.3563 12.9485 11.2599 12.8521 11.142 12.8521ZM9.85631 21.1021H3.85631V2.24498H18.8563V11.4593C18.8563 11.5771 18.9527 11.6735 19.0706 11.6735H20.5706C20.6885 11.6735 20.7849 11.5771 20.7849 11.4593V1.17355C20.7849 0.699442 20.4018 0.316406 19.9277 0.316406H2.78488C2.31077 0.316406 1.92773 0.699442 1.92773 1.17355V22.1735C1.92773 22.6477 2.31077 23.0307 2.78488 23.0307H9.85631C9.97416 23.0307 10.0706 22.9343 10.0706 22.8164V21.3164C10.0706 21.1985 9.97416 21.1021 9.85631 21.1021Z"
      fill="currentColor"
    />
    <g clipPath="url(#clip0_104014_564146)">
      <path
        d="M17.9995 12.75C15.1003 12.75 12.7495 15.1008 12.7495 18C12.7495 20.8992 15.1003 23.25 17.9995 23.25C20.8987 23.25 23.2495 20.8992 23.2495 18C23.2495 15.1008 20.8987 12.75 17.9995 12.75ZM17.9995 22.3594C15.5925 22.3594 13.6401 20.407 13.6401 18C13.6401 15.593 15.5925 13.6406 17.9995 13.6406C20.4065 13.6406 22.3589 15.593 22.3589 18C22.3589 20.407 20.4065 22.3594 17.9995 22.3594Z"
        fill="currentColor"
      />
      <path
        d="M19.3071 15.711C18.9556 15.4028 18.4915 15.234 17.9993 15.234C17.5071 15.234 17.0431 15.4039 16.6915 15.711C16.3259 16.0309 16.1243 16.461 16.1243 16.9215V17.0106C16.1243 17.0621 16.1665 17.1043 16.2181 17.1043H16.7806C16.8321 17.1043 16.8743 17.0621 16.8743 17.0106V16.9215C16.8743 16.4047 17.3794 15.984 17.9993 15.984C18.6193 15.984 19.1243 16.4047 19.1243 16.9215C19.1243 17.286 18.8665 17.6199 18.4669 17.7735C18.2185 17.8684 18.0075 18.0348 17.8564 18.2528C17.7028 18.4754 17.6232 18.7426 17.6232 19.0133V19.2653C17.6232 19.3168 17.6653 19.359 17.7169 19.359H18.2794C18.331 19.359 18.3732 19.3168 18.3732 19.2653V18.9992C18.3738 18.8855 18.4086 18.7745 18.4732 18.6809C18.5378 18.5872 18.6291 18.5152 18.7353 18.4742C19.4267 18.2082 19.8732 17.5989 19.8732 16.9215C19.8743 16.461 19.6728 16.0309 19.3071 15.711ZM17.5306 20.5778C17.5306 20.7021 17.58 20.8213 17.6679 20.9092C17.7558 20.9971 17.875 21.0465 17.9993 21.0465C18.1236 21.0465 18.2429 20.9971 18.3308 20.9092C18.4187 20.8213 18.4681 20.7021 18.4681 20.5778C18.4681 20.4534 18.4187 20.3342 18.3308 20.2463C18.2429 20.1584 18.1236 20.109 17.9993 20.109C17.875 20.109 17.7558 20.1584 17.6679 20.2463C17.58 20.3342 17.5306 20.4534 17.5306 20.5778Z"
        fill="currentColor"
      />
      <path
        d="M17.9995 12.75C15.1003 12.75 12.7495 15.1008 12.7495 18C12.7495 20.8992 15.1003 23.25 17.9995 23.25C20.8987 23.25 23.2495 20.8992 23.2495 18C23.2495 15.1008 20.8987 12.75 17.9995 12.75ZM17.9995 22.3594C15.5925 22.3594 13.6401 20.407 13.6401 18C13.6401 15.593 15.5925 13.6406 17.9995 13.6406C20.4065 13.6406 22.3589 15.593 22.3589 18C22.3589 20.407 20.4065 22.3594 17.9995 22.3594Z"
        stroke="currentColor"
        strokeWidth="0.6"
      />
      <path
        d="M19.3071 15.711C18.9556 15.4028 18.4915 15.234 17.9993 15.234C17.5071 15.234 17.0431 15.4039 16.6915 15.711C16.3259 16.0309 16.1243 16.461 16.1243 16.9215V17.0106C16.1243 17.0621 16.1665 17.1043 16.2181 17.1043H16.7806C16.8321 17.1043 16.8743 17.0621 16.8743 17.0106V16.9215C16.8743 16.4047 17.3794 15.984 17.9993 15.984C18.6193 15.984 19.1243 16.4047 19.1243 16.9215C19.1243 17.286 18.8665 17.6199 18.4669 17.7735C18.2185 17.8684 18.0075 18.0348 17.8564 18.2528C17.7028 18.4754 17.6232 18.7426 17.6232 19.0133V19.2653C17.6232 19.3168 17.6653 19.359 17.7169 19.359H18.2794C18.331 19.359 18.3732 19.3168 18.3732 19.2653V18.9992C18.3738 18.8855 18.4086 18.7745 18.4732 18.6809C18.5378 18.5872 18.6291 18.5152 18.7353 18.4742C19.4267 18.2082 19.8732 17.5989 19.8732 16.9215C19.8743 16.461 19.6728 16.0309 19.3071 15.711ZM17.5306 20.5778C17.5306 20.7021 17.58 20.8213 17.6679 20.9092C17.7558 20.9971 17.875 21.0465 17.9993 21.0465C18.1236 21.0465 18.2429 20.9971 18.3308 20.9092C18.4187 20.8213 18.4681 20.7021 18.4681 20.5778C18.4681 20.4534 18.4187 20.3342 18.3308 20.2463C18.2429 20.1584 18.1236 20.109 17.9993 20.109C17.875 20.109 17.7558 20.1584 17.6679 20.2463C17.58 20.3342 17.5306 20.4534 17.5306 20.5778Z"
        stroke="currentColor"
        strokeWidth="0.6"
      />
    </g>
    <defs>
      <clipPath id="clip0_104014_564146">
        <rect
          width="12"
          height="12"
          fill="currentColor"
          transform="translate(12 12)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default RequestsIcon;
