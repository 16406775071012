import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { isEqual } from 'lodash';
import { Translation } from 'react-i18next';

import styles from './event-status.module.scss';

export const EventStatus = ({
  bordered,
  className,
  statuses,
  activeStatus,
  size,
  style,
  id,
  children
}) => {
  const _status = statuses.get(activeStatus) || {};

  const [status, setStatus] = useState(_status);
  const [isStatusChange, setIsStatusChange] = useState(false);
  const [activeId, setActiveId] = useState(id);

  const isCondition = id ? activeId === id : true;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (!isEqual(status, _status) && isCondition) {
      setIsStatusChange(true);

      const timer = setTimeout(() => {
        setStatus(_status);
        setIsStatusChange(false);
      }, 300);

      return () => clearTimeout(timer);
    }

    setActiveId(id);
    setStatus(_status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_status, id]);

  return (
    <Tag
      className={classnames(
        styles.root,
        className,
        styles[size],
        styles[activeStatus],
        {
          [styles.flip]: isStatusChange
        }
      )}
      style={{
        color: status.color,
        backgroundColor: status.backgroundColor,
        border: bordered
          ? `1px solid ${status.borderColor || status.color}`
          : null,
        ...style
      }}
    >
      {children || (
        <Translation ns={status.ns}>{t => t(status.label)}</Translation>
      )}
    </Tag>
  );
};

EventStatus.propTypes = {
  bordered: PropTypes.bool,
  className: PropTypes.string,
  statuses: PropTypes.instanceOf(Map).isRequired,
  activeStatus: PropTypes.string,
  size: PropTypes.oneOf(['default', 'large', 'small']),
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

EventStatus.defaultProps = {
  bordered: false,
  className: undefined,
  activeStatus: undefined,
  size: 'default',
  id: null,
  children: undefined
};

export default EventStatus;
