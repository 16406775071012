import React from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Typography from 'components/common/typography';

import { getFullName } from 'utils/get-fio';

import FormReplaceEmployee from './form';

const ReplaceEmployeeDrawer = ({ data, visible, onClose }) => {
  const { t } = useTranslation(['ReplaceAnEmployee']);
  const { employee = {} } = data;

  return (
    <Drawer
      title={<Drawer.Title>{t('ReplaceAnEmployee')}</Drawer.Title>}
      width={480}
      open={!!visible}
      destroyOnClose
      onClose={onClose}
      maskClosable={false}
    >
      <Typography.Paragraph>
        <Trans
          i18nKey="ReplaceAnEmployeeDesc1"
          ns="ReplaceAnEmployee"
          components={{
            bold: <Typography.Text strong />
          }}
          values={{ value: getFullName({ allName: true, ...employee }) }}
        />
      </Typography.Paragraph>
      <Typography.Paragraph>{t('ReplaceAnEmployeeDesc2')}</Typography.Paragraph>

      <FormReplaceEmployee data={data} onClose={onClose} />
    </Drawer>
  );
};

ReplaceEmployeeDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    employee: PropTypes.object
  })
};

ReplaceEmployeeDrawer.defaultProps = {
  data: {},
  visible: undefined
};

export default ReplaceEmployeeDrawer;
