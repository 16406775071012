import React from 'react';

export const GoogleDriveNotConnectedIcon = ({
  style = { width: 150, height: 150 }
}) => (
  <svg
    style={style}
    width="150"
    height="151"
    viewBox="0 0 150 151"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M91.5223 128.966C89.9508 129.109 88.3794 129.109 86.808 129.109C79.2365 128.966 71.2366 129.251 63.6651 129.251C63.2366 129.251 62.9508 129.251 62.5223 129.251C57.3794 129.394 52.5223 129.394 47.808 129.109C46.0937 128.966 44.6651 128.966 43.0937 128.823C27.5223 127.394 17.2366 122.109 21.2366 105.823C21.5223 104.394 21.6651 103.109 21.5223 101.537C21.0937 95.3943 17.3794 88.2514 14.5223 80.9657C13.808 79.2514 13.2366 77.5371 12.6651 75.68C12.0937 73.5371 11.5223 71.5371 11.2366 69.5371C10.6651 66.1086 10.9508 62.5371 11.9508 59.1086C11.9508 59.1086 13.0937 55.68 15.6651 52.3943C18.808 48.3943 55.0937 10.3943 97.808 24.9657C99.5223 25.5371 141.522 40.5371 142.237 75.2514C142.808 109.109 103.808 128.394 91.5223 128.966Z"
      fill="#EBF8F8"
    />
    <path
      d="M121.96 35.6674L132.604 61.2148C133.781 64.051 132.439 67.3091 129.603 68.4866L91.161 84.5032C88.3248 85.6807 85.0667 84.3383 83.8913 81.5122L73.2474 55.9648C72.0699 53.1286 73.4123 49.8705 76.2384 48.6951L114.68 32.6784C117.516 31.5009 120.772 32.8333 121.96 35.6674Z"
      fill="#57D478"
    />
    <path
      d="M93.5637 65.6062L86.3323 68.62C85.3862 69.0143 84.9388 70.101 85.3331 71.0472L86.5718 74.0192C86.9661 74.9654 88.0528 75.4128 88.999 75.0184L96.2303 72.0046C97.1765 71.6103 97.6239 70.5236 97.2295 69.5774L95.9909 66.6054C95.5966 65.6592 94.5099 65.2119 93.5637 65.6062Z"
      fill="white"
    />
    <path
      d="M108.376 59.4226L101.145 62.4364C100.199 62.8307 99.7513 63.9174 100.146 64.8636L101.384 67.8356C101.779 68.7818 102.865 69.2292 103.811 68.8348L111.043 65.821C111.989 65.4267 112.436 64.34 112.042 63.3939L110.803 60.4218C110.409 59.4756 109.322 59.0283 108.376 59.4226Z"
      fill="white"
    />
    <path
      d="M123.189 53.2507L115.957 56.2645C115.011 56.6588 114.564 57.7455 114.958 58.6917L116.197 61.6638C116.591 62.6099 117.678 63.0573 118.624 62.663L125.855 59.6492C126.802 59.2548 127.249 58.1681 126.855 57.222L125.616 54.2499C125.222 53.3038 124.135 52.8564 123.189 53.2507Z"
      fill="white"
    />
    <path
      d="M88.9934 54.657L81.762 57.6708C80.8158 58.0651 80.3685 59.1518 80.7628 60.098L82.0015 63.07C82.3958 64.0162 83.4825 64.4635 84.4287 64.0692L91.66 61.0554C92.6062 60.6611 93.0536 59.5744 92.6592 58.6282L91.4206 55.6562C91.0262 54.71 89.9396 54.2626 88.9934 54.657Z"
      fill="white"
    />
    <path
      d="M103.808 48.4851L96.5765 51.4989C95.6303 51.8932 95.1829 52.9799 95.5773 53.9261L96.8159 56.8981C97.2103 57.8443 98.297 58.2917 99.2431 57.8973L106.474 54.8835C107.421 54.4892 107.868 53.4025 107.474 52.4564L106.235 49.4843C105.841 48.5381 104.754 48.0908 103.808 48.4851Z"
      fill="white"
    />
    <path
      d="M118.62 42.3132L111.389 45.327C110.443 45.7213 109.995 46.808 110.39 47.7542L111.628 50.7263C112.023 51.6724 113.109 52.1198 114.056 51.7255L121.287 48.7117C122.233 48.3173 122.681 47.2306 122.286 46.2845L121.048 43.3124C120.653 42.3663 119.567 41.9189 118.62 42.3132Z"
      fill="white"
    />
    <rect
      x="27.0039"
      y="31.6035"
      width="45.7029"
      height="52.537"
      rx="6"
      transform="rotate(-10.4057 27.0039 31.6035)"
      fill="#AAC8FA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.8382 25.7575L32.9052 30.5198C29.646 31.1183 27.4891 34.2456 28.0876 37.5048L35.4093 77.3751C36.0078 80.6343 39.1351 82.7912 42.3943 82.1927L75.5429 76.1054C78.8021 75.5069 80.959 72.3796 80.3605 69.1204L74.3646 36.4697L63.2148 38.5172C62.1284 38.7167 61.086 37.9977 60.8865 36.9113L58.8382 25.7575Z"
      fill="#5491F5"
    />
    <path
      d="M37.8364 57.3188C37.6371 56.1435 38.3622 55.0145 39.4559 54.7971L69.0454 48.9165C70.1391 48.6991 71.1874 49.4757 71.3867 50.651C71.586 51.8264 70.8609 52.9554 69.7672 53.1727L40.1777 59.0534C39.0839 59.2707 38.0357 58.4942 37.8364 57.3188Z"
      fill="white"
    />
    <path
      d="M38.9701 64.0044C38.7708 62.829 39.4959 61.7 40.5896 61.4827L70.1792 55.602C71.2729 55.3847 72.3211 56.1612 72.5204 57.3366C72.7198 58.5119 71.9947 59.6409 70.9009 59.8583L41.3114 65.7389C40.2177 65.9563 39.1695 65.1797 38.9701 64.0044Z"
      fill="white"
    />
    <path
      d="M40.1039 70.6899C39.9046 69.5146 40.6297 68.3856 41.7234 68.1682L56.3837 65.2546C57.4774 65.0372 58.5256 65.8138 58.725 66.9891C58.9243 68.1645 58.1992 69.2935 57.1054 69.5109L42.4452 72.4244C41.3514 72.6418 40.3032 71.8652 40.1039 70.6899Z"
      fill="white"
    />
    <rect
      x="78.4043"
      y="65.5488"
      width="58.5603"
      height="41.0423"
      rx="6"
      transform="rotate(12.2336 78.4043 65.5488)"
      fill="#F5BB19"
    />
    <rect
      x="81.2539"
      y="74.4023"
      width="49.2372"
      height="24.946"
      rx="3"
      transform="rotate(12.2336 81.2539 74.4023)"
      fill="#F2F2F2"
    />
    <rect
      x="84.4258"
      y="80"
      width="40.668"
      height="15.3484"
      rx="1"
      transform="rotate(12.2336 84.4258 80)"
      fill="#F5BB19"
    />
    <rect
      x="18.8047"
      y="57.5195"
      width="78.0771"
      height="57.8851"
      rx="10"
      transform="rotate(11.0026 18.8047 57.5195)"
      fill="#BAF7F7"
    />
    <path
      d="M16.8962 67.3357C17.9502 61.9144 23.1995 58.374 28.6209 59.4281L85.6305 70.5123C91.0518 71.5663 94.5922 76.8156 93.5381 82.237L93.0447 84.7747L16.4028 69.8734L16.8962 67.3357Z"
      fill="#32C9C9"
    />
    <rect
      x="20.7344"
      y="98.8672"
      width="59.0488"
      height="11.0237"
      rx="5.51186"
      transform="rotate(11.0026 20.7344 98.8672)"
      fill="white"
    />
    <path
      d="M34.1326 110.802C34.8483 110.928 35.5849 110.858 36.2638 110.599C36.9426 110.339 37.5381 109.9 37.9863 109.328C38.4346 108.756 38.7188 108.072 38.8086 107.351C38.8983 106.63 38.7902 105.898 38.4958 105.233C38.2013 104.569 37.7317 103.997 37.1372 103.579C36.5427 103.161 35.8456 102.912 35.1207 102.86C34.3958 102.808 33.6703 102.954 33.0219 103.282C32.3736 103.61 31.8267 104.109 31.44 104.724L25.0222 103.569L22.071 105.289L25.3875 105.886L25.2609 106.584L26.7744 106.855L26.9009 106.156L27.8578 106.331L27.571 107.971L29.0918 108.244L29.3837 106.624L30.8323 106.885C30.8372 107.821 31.1702 108.726 31.7732 109.441C32.3763 110.157 33.2112 110.638 34.1326 110.802ZM32.9566 106.52C33.0233 106.131 33.204 105.772 33.4757 105.486C33.7473 105.201 34.0977 105.003 34.4822 104.918C34.8668 104.832 35.268 104.863 35.635 105.006C36.002 105.149 36.3181 105.398 36.5431 105.722C36.7682 106.045 36.8919 106.428 36.8987 106.822C36.9055 107.216 36.7949 107.603 36.5812 107.933C36.3674 108.264 36.06 108.524 35.6982 108.68C35.3363 108.835 34.9363 108.88 34.5491 108.808C34.0363 108.712 33.5818 108.418 33.2838 107.99C32.9858 107.562 32.8683 107.034 32.9566 106.52Z"
      fill="#FEC458"
    />
    <circle
      cx="45.4054"
      cy="109.279"
      r="1.69079"
      transform="rotate(11.0026 45.4054 109.279)"
      fill="#C4C4C4"
    />
    <circle
      cx="51.5577"
      cy="110.475"
      r="1.69079"
      transform="rotate(11.0026 51.5577 110.475)"
      fill="#C4C4C4"
    />
    <circle
      cx="57.712"
      cy="111.672"
      r="1.69079"
      transform="rotate(11.0026 57.712 111.672)"
      fill="#C4C4C4"
    />
    <circle
      cx="63.8663"
      cy="112.869"
      r="1.69079"
      transform="rotate(11.0026 63.8663 112.869)"
      fill="#C4C4C4"
    />
    <circle
      cx="70.0206"
      cy="114.064"
      r="1.69079"
      transform="rotate(11.0026 70.0206 114.064)"
      fill="#C4C4C4"
    />
    <path
      d="M25.8434 67.6381C27.0319 68.0944 28.3654 67.5008 28.8217 66.3123C29.278 65.1237 28.6844 63.7903 27.4958 63.334C26.3073 62.8777 24.9738 63.4713 24.5175 64.6599C24.0612 65.8484 24.6548 67.1818 25.8434 67.6381Z"
      fill="white"
    />
    <path
      d="M34.4996 69.2924C35.6882 69.7487 37.0216 69.1551 37.4779 67.9666C37.9342 66.778 37.3406 65.4446 36.1521 64.9883C34.9635 64.532 33.6301 65.1256 33.1738 66.3142C32.7175 67.5027 33.3111 68.8361 34.4996 69.2924Z"
      fill="white"
    />
    <path
      d="M43.1657 70.9526C44.3542 71.4089 45.6876 70.8153 46.1439 69.6267C46.6002 68.4382 46.0066 67.1048 44.8181 66.6485C43.6295 66.1922 42.2961 66.7858 41.8398 67.9743C41.3835 69.1629 41.9771 70.4963 43.1657 70.9526Z"
      fill="white"
    />
    <path
      d="M60.3871 88.6737L51.4185 86.9304L51.8594 84.662C52.0019 83.9292 52.7104 83.4513 53.4433 83.5937L59.7597 84.8215C60.4925 84.9639 60.9705 85.6725 60.828 86.4054L60.3871 88.6737Z"
      fill="#1DC1C9"
    />
    <path
      d="M62.9808 89.8657C63.1775 88.8537 62.5028 87.8534 61.4907 87.6566L50.7424 85.5674C50.4283 85.5063 50.2034 85.1729 50.2644 84.8588L50.4883 83.7072C50.685 82.6952 50.0102 81.6948 48.9982 81.4981L43.7636 80.4806C42.7516 80.2839 41.7513 80.9586 41.5545 81.9707L38.7259 96.5228C38.5292 97.5349 39.2039 98.5352 40.2159 98.7319L58.6417 102.314C59.6537 102.51 60.6541 101.836 60.8508 100.823L62.9808 89.8657Z"
      fill="#FFE181"
    />
    <path
      d="M46.5653 93.0844L43.3199 92.4536C42.4125 92.2772 41.5372 92.8676 41.3609 93.7749C41.1845 94.6823 41.7749 95.5576 42.6822 95.7339L45.9277 96.3648C46.835 96.5412 47.7103 95.9508 47.8867 95.0434C48.0281 94.1293 47.4377 93.254 46.5653 93.0844Z"
      fill="white"
    />
  </svg>
);

export default GoogleDriveNotConnectedIcon;
