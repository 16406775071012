import { getFileIds } from 'hooks/common/use-file-upload/get-file-ids';

import {
  EMIT_READ_CHAT_MESSAGE,
  EMIT_SEND_CHAT_MESSAGE,
  EMIT_JOIN_USER_UPDATES,
  EMIT_LEAVE_USER_UPDATES,
  EMIT_CHAT_TYPING
} from '../constants/socket';

// tag - поле которое мы отправляем по сокетам и оно нам же возвращается
export default emit => ({
  sendMessage: (chatId, message, workspaceId) =>
    emit(EMIT_SEND_CHAT_MESSAGE, {
      chatId,
      ...message,
      fileList: getFileIds(message.fileList),
      // employee используем для reduce
      employee: undefined,
      workspaceId
    }),

  readMessage: (chatId, workspaceId, messageId) => {
    emit(EMIT_READ_CHAT_MESSAGE, {
      chatId,
      workspaceId,
      messageId
    });
  },

  chatTyping: chatId => emit(EMIT_CHAT_TYPING, { chatId }),

  joinUserUpdates: userIds => emit(EMIT_JOIN_USER_UPDATES, { userIds }),

  leaveUserUpdates: userIds => emit(EMIT_LEAVE_USER_UPDATES, { userIds })
});
