import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import { getFile } from 'components/attachments-view/drawers/add-file';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import { useFileUpload } from 'hooks';
import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';

import Form from './from';

export const SaveToDiskDrawer = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('SaveReportToMyDrive');

  const {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    hasWorkspaceGoogleDriveIntegration,
    hasWorkspaceGoogleDriveWorkflow
  } = useWorkspaceGoogleDriveSupport();

  const [isLoading, setIsLoading] = useState(false);

  const { onUploadAttachment } = useFileUpload({
    hasWorkspaceGoogleDriveWorkflow,
    storage:
      isWorkspaceOwnGoogleDriveIntegrationEnabled &&
      !hasWorkspaceGoogleDriveIntegration
        ? 's3'
        : undefined
  });

  const { fileName, fileData, fileType, fileFormat } = data;

  const onSubmit = useCallback(
    async ({ name, relation }) => {
      setIsLoading(true);

      const file = new File([fileData], `${name}.${fileFormat}`, {
        type: fileType
      });

      await onUploadAttachment({
        file,
        onError: () => {
          setIsLoading(false);
        },
        onSuccess: async ({ id }, uploadedFile) => {
          await getFile({
            ...relation[0],
            fileList: [
              { id, name: uploadedFile.name, type: uploadedFile.type }
            ],
            dispatch
          });

          setIsLoading(false);

          onClose();
          showNoticeMessage({ number: NOTICE_NUMBER.fileSavedToDisk });
        }
      });
    },
    [dispatch, fileData, fileFormat, fileType, onClose, onUploadAttachment]
  );

  return (
    <Drawer
      title={<Drawer.Title>{t('SaveToMyDiskHeading')}</Drawer.Title>}
      open={visible}
      width={600}
      destroyOnClose
      onClose={onClose}
    >
      <Form
        isLoading={isLoading}
        defaultValues={{ name: fileName }}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

SaveToDiskDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    fileName: PropTypes.string,
    fileData: PropTypes.any,
    fileType: PropTypes.string,
    fileFormat: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired
};

SaveToDiskDrawer.defaultProps = {
  data: {}
};

export default SaveToDiskDrawer;
