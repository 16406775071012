import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';

import Drawer from 'components/common/drawer';

import mapValue from 'utils/map-value';
import { showNoticeMessage } from 'services/notice';

import { Form } from './form';

export const AccessManagementDrawer = ({ visible, data = {}, onClose }) => {
  const [afterVisible, setAfterVisible] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [defaultEmployees, setDefaultEmployees] = useState(null);
  const [deletedEmployees, setDeletedEmployees] = useState([]);

  const { title, fetch, submit, ...formData } = data;

  const onFetch = async () => {
    try {
      setIsFetchLoading(true);

      const results = await fetch();
      setDefaultEmployees(results);
    } finally {
      setIsFetchLoading(false);
    }
  };

  const onDelete = employee => {
    setDefaultEmployees(prev => prev.filter(e => e.id !== employee.id));
    setDeletedEmployees(prev => [...prev, employee]);
  };

  const onSubmit = async ({ employees }) => {
    try {
      setIsSubmitLoading(true);

      await submit({
        added: (employees || []).map(mapValue),
        deleted: deletedEmployees.map(e => e.id)
      });

      onClose();

      showNoticeMessage();
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (afterVisible) {
      onFetch();
    } else {
      setDeletedEmployees([]);
      setDefaultEmployees(null);
    }
  }, [afterVisible]);

  return (
    <Drawer
      title={
        <>
          <Drawer.Back onClick={onClose} />
          <Drawer.Title>{title}</Drawer.Title>
        </>
      }
      open={visible}
      closable={false}
      width={480}
      afterOpenChange={setAfterVisible}
      onClose={onClose}
    >
      {isFetchLoading || !defaultEmployees ? (
        <Spin />
      ) : (
        <Form
          isLoading={isSubmitLoading}
          onSubmit={onSubmit}
          onDelete={onDelete}
          data={{ ...formData, employees: defaultEmployees }}
        />
      )}
    </Drawer>
  );
};
