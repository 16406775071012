import React from 'react';

export const GoogleDriveIcon = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.5719 23.9622C20.0302 24.3209 19.4072 24.5002 18.757 24.5002H6.21529C5.56518 24.5002 4.94215 24.3209 4.40039 23.9622C6.37782 20.1963 8.30107 16.4902 8.30107 16.4902H16.6442C16.6713 16.5201 19.055 21.0631 20.5719 23.9622Z"
      fill="#1E88E5"
    />
    <path
      d="M16.4007 1.06836C16.9424 1.42701 17.4029 1.90522 17.728 2.53287L24.0124 14.5179C24.3375 15.1456 24.5 15.833 24.5 16.5204C21.6558 16.5503 16.6986 16.5204 16.6986 16.5204L12.5 8.51045C12.5 8.51045 14.667 4.38592 16.4007 1.06836Z"
      fill="#FBC02D"
    />
    <path
      d="M24.5006 16.5195C24.5006 17.207 24.338 17.8944 24.013 18.522L21.7918 22.7661C21.4667 23.2742 21.0604 23.6926 20.5999 23.9915L16.6992 16.5195H24.5006Z"
      fill="#E53935"
    />
    <path
      d="M0.5 16.5206C0.5 15.8332 0.662528 15.1458 0.987584 14.5182L7.27201 2.5331C7.59706 1.90546 8.05756 1.42725 8.59932 1.0686C9.14108 0.70994 12.5 8.54057 12.5 8.54057L8.32844 16.5206C8.32844 16.5206 2.85666 16.5206 0.5 16.5206Z"
      fill="#4CAF50"
    />
    <path
      d="M8.3287 16.5195L4.42802 23.9915C3.96752 23.6926 3.53412 23.2742 3.23615 22.7661L1.01493 18.522C0.689872 17.8944 0.527344 17.207 0.527344 16.5195H8.3287Z"
      fill="#1565C0"
    />
    <path
      d="M16.401 1.06787L12.5003 8.50996L8.59961 1.03798C9.06011 0.739103 9.60187 0.559776 10.1707 0.5H14.5861C15.2362 0.529888 15.8592 0.709216 16.401 1.06787Z"
      fill="#2E7D32"
    />
  </svg>
);

export default GoogleDriveIcon;
