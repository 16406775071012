import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import isEqual from 'lodash/isEqual';
import { useTranslation } from 'react-i18next';

import {
  CONTACTS_IMPORT_DRAWER,
  STATUS_IMPORT_CONTACTS_ACCEPTED,
  STATUS_IMPORT_CONTACTS_COMPLETED,
  STATUS_IMPORT_CONTACTS_COMPLETED_WITH_ERRORS
} from 'constants/index';

import Modal from 'components/common/modal';
import SkeletonCard from 'components/common/skeleton-card';

import { setVisibleDrawer } from 'store/drawers';
import {
  fetchResultsImportContacts,
  fetchStatusImportContacts
} from 'store/contacts';

import { ERROR_NOTICE_TYPE, showNoticeMessage } from 'services/notice';

import { CONTACT_IMPORT_TEMPLATE_COLUMN_KEYS, checkFormatFile } from '../utils';
import {
  FileUploadImportContacts,
  ProcessImportContacts,
  SuccessImportContacts
} from './components';

import styles from './modals.module.scss';

const ImportContactsModal = ({ visible, onClose, setVisible }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState([]);
  const [columns, setColumns] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [isFileBeingChecked, setIsFileBeingChecked] = useState(true);

  const [statusImportContacts, setStatusImportContacts] = useState(null);
  const [isLoadingStatus, setIsLoadingStatus] = useState(true);
  const [countSuccessRows, setCountSuccessRows] = useState(null);

  const isLoadedStatus = !isLoadingStatus && statusImportContacts;

  const { t } = useTranslation(['ContactImport', 'Toast']);

  const handleOnSubmit = () => {
    const tableColumns = columns.map(columnName => ({
      title: columnName,
      dataIndex: columnName,
      key: columnName
    }));

    const isEmptyDataSource = dataSource.every(item =>
      Object.values(item).every(value => value === '')
    );

    const tableDataSource = dataSource.map(row => {
      const rowData = {};

      columns.forEach((columnName, index) => {
        const cellValue = row[index] || '-';

        rowData[columnName] = cellValue;
      });

      return {
        key: row[0],
        ...rowData
      };
    });

    const templateColumns = Object.values(CONTACT_IMPORT_TEMPLATE_COLUMN_KEYS)
      .map(k => t(k))
      .map(k => k.toLowerCase());

    if (
      !isEqual(
        templateColumns,
        columns.map(c => c.toLowerCase())
      ) ||
      isEmptyDataSource
    ) {
      return showNoticeMessage({
        customContent: t('UploadedFileFormatIncorrectTemplate', {
          ns: 'Toast'
        }),
        type: ERROR_NOTICE_TYPE
      });
    }

    dispatch(
      setVisibleDrawer({
        drawer: CONTACTS_IMPORT_DRAWER,
        data: {
          dataSource: tableDataSource,
          columns: tableColumns,
          fileId: file[0].response.id,
          fileType: checkFormatFile(file[0]),
          setVisibleContactsImportModal: setVisible,
          setIsFileBeingChecked
        }
      })
    );

    return onClose();
  };

  const fetchStatus = useCallback(async () => {
    try {
      setIsLoadingStatus(true);
      const status = await dispatch(fetchStatusImportContacts());

      if (status.contactImportProcessing) {
        const { successRow } = await dispatch(
          fetchResultsImportContacts({
            id: status.contactImportProcessing
          })
        );

        setCountSuccessRows(successRow);
      }

      setStatusImportContacts(status);
    } finally {
      setIsLoadingStatus(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (visible) {
      fetchStatus();
    }

    return () => {
      if (!isFileBeingChecked) {
        setFile([]);
        setIsFileBeingChecked(true);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, isFileBeingChecked]);

  return (
    <Modal
      title={
        isLoadedStatus &&
        (statusImportContacts.contactImportProcessing ||
          statusImportContacts.status === STATUS_IMPORT_CONTACTS_ACCEPTED)
          ? undefined
          : t('ContactImportHeading')
      }
      contentStyle={{ padding: 24 }}
      centered
      open={visible}
      width={
        isLoadedStatus &&
        statusImportContacts.status === STATUS_IMPORT_CONTACTS_COMPLETED
          ? 440
          : 600
      }
      onClose={() => {
        setIsFileBeingChecked(false);

        onClose();
      }}
      destroyOnClose
    >
      {isLoadingStatus && (
        <SkeletonCard
          count={3}
          paragraph={{ rows: 2 }}
          className={styles.skeleton}
        />
      )}

      {isLoadedStatus && !statusImportContacts.contactImportProcessing && (
        <FileUploadImportContacts
          file={file}
          setFile={setFile}
          setColumns={setColumns}
          setDataSource={setDataSource}
          handleOnSubmit={handleOnSubmit}
        />
      )}

      {isLoadedStatus &&
        statusImportContacts.contactImportProcessing &&
        (statusImportContacts.status ===
          STATUS_IMPORT_CONTACTS_COMPLETED_WITH_ERRORS ||
          statusImportContacts.status === STATUS_IMPORT_CONTACTS_ACCEPTED) && (
          <ProcessImportContacts
            data={statusImportContacts}
            onClose={onClose}
          />
        )}

      {isLoadedStatus &&
        statusImportContacts.status === STATUS_IMPORT_CONTACTS_COMPLETED && (
          <SuccessImportContacts
            onClose={onClose}
            contactImportProcessing={
              statusImportContacts.contactImportProcessing
            }
            data={{
              countSuccessRows,
              contactImportProcessing:
                statusImportContacts.contactImportProcessing
            }}
          />
        )}
    </Modal>
  );
};

ImportContactsModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  setVisible: PropTypes.func
};

ImportContactsModal.defaultProps = {
  visible: false,
  onClose: () => {},
  setVisible: () => {}
};

export default ImportContactsModal;
