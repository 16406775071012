import React from 'react';
import { Tooltip as AntTooltip } from 'antd';
import PropTypes from 'prop-types';

const Tooltip = ({ title, children, ...props }) => (
  <AntTooltip title={title} mouseEnterDelay={0.5} {...props}>
    {children}
  </AntTooltip>
);

Tooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.func, PropTypes.string])
};

Tooltip.defaultProps = {
  title: undefined
};

export default Tooltip;
