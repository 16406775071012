import qs from 'qs';

import { makeCancelableRequest } from 'utils/make-cancalable-request';

export default api => ({
  fetch: makeCancelableRequest(
    api,
    (
      fetch,
      workspaceId,
      offset,
      limit,
      search = null,
      kind,
      statusFilter,
      author,
      responsible,
      controller,
      subordinates,
      expired,
      createdDateRange = [],
      startedDateRange = [],
      endedDateRange = [],
      completedDateRange = [],
      project,
      orderStatus,
      contact,
      sprint,
      sprintIsNull,
      access,
      ordering,
      isFavorite,
      myTasks,
      isAutoCreated,
      hideSubtasks,
      asset,
      tagsCondition,
      tagsIds,
      members,
      tasks,
      requests,
      channelChats,
      exclude
    ) =>
      fetch('get', `/v1/${workspaceId}/tasks/`, {
        params: {
          limit,
          offset,
          search,
          kind,
          status: statusFilter,
          author,
          responsible,
          controller,
          subordinates,
          expired,
          created_at_gte: createdDateRange[0],
          created_at_lte: createdDateRange[1],
          completed_at_gte: completedDateRange[0],
          completed_at_lte: completedDateRange[1],
          date_end_gte: endedDateRange[0],
          date_end_lte: endedDateRange[1],
          date_start_gte: startedDateRange[0],
          date_start_lte: startedDateRange[1],
          project,
          order_status: orderStatus,
          contact,
          sprint,
          sprint_isnull: sprintIsNull,
          access,
          ordering,
          is_favorite: isFavorite,
          my_tasks: myTasks,
          is_auto_created: isAutoCreated,
          hide_subtasks: hideSubtasks,
          asset,
          tags_condition: tagsCondition,
          tags_ids: tagsIds,
          members,
          task: tasks,
          request: requests,
          channel_chat: channelChats,
          exclude
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchWithoutCancelling: (
    workspaceId,
    offset,
    limit,
    search = null,
    kind,
    statusFilter,
    author,
    responsible,
    controller,
    subordinates,
    expired,
    createdDateRange = [],
    startedDateRange = [],
    endedDateRange = [],
    completedDateRange = [],
    project,
    orderStatus,
    contact,
    sprint,
    sprintIsNull,
    access,
    ordering,
    isFavorite,
    myTasks,
    isAutoCreated,
    hideSubtasks,
    asset,
    tagsCondition,
    tagsIds,
    members,
    tasks,
    requests,
    channelChats,
    axiosCancelTokenSource
  ) =>
    api.get(`/v1/${workspaceId}/tasks/`, {
      params: {
        limit,
        offset,
        search,
        kind,
        status: statusFilter,
        author,
        responsible,
        controller,
        subordinates,
        expired,
        created_at_gte: createdDateRange[0],
        created_at_lte: createdDateRange[1],
        completed_at_gte: completedDateRange[0],
        completed_at_lte: completedDateRange[1],
        date_end_gte: endedDateRange[0],
        date_end_lte: endedDateRange[1],
        date_start_gte: startedDateRange[0],
        date_start_lte: startedDateRange[1],
        project,
        order_status: orderStatus,
        contact,
        sprint,
        sprint_isnull: sprintIsNull,
        access,
        ordering,
        is_favorite: isFavorite,
        my_tasks: myTasks,
        is_auto_created: isAutoCreated,
        hide_subtasks: hideSubtasks,
        asset,
        tags_condition: tagsCondition,
        tags_ids: tagsIds,
        members,
        task: tasks,
        request: requests,
        channel_chat: channelChats
      },
      cancelToken: axiosCancelTokenSource && axiosCancelTokenSource.token,
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  create: (workspaceId, task) => api.post(`/v1/${workspaceId}/tasks/`, task),

  backgroundTasks: (workspaceId, task) =>
    api.post(`/v1/${workspaceId}/background-tasks/`, task),

  fetchOne: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/`, { show400: false }),

  update: (workspaceId, id, task) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/`, task),

  partialUpdate: ({ workspaceId, id, values }) =>
    api.patch(`/v1/${workspaceId}/tasks/${id}/`, values),

  updateDates: ({ workspaceId, id, dates }) =>
    api.patch(`/v1/${workspaceId}/tasks/${id}/update-dates/`, {
      dateStart: dates.dateStart,
      dateEnd: dates.dateEnd
    }),

  delete: (workspaceId, id) => api.delete(`/v1/${workspaceId}/tasks/${id}/`),

  setFavorite: (workspaceId, id, isFavorite) =>
    api.post(`/v1/${workspaceId}/tasks/${id}/favorite-set/`, {
      isFavorite
    }),

  fetchAttachments: ({
    workspaceId,
    id,
    source = 'all',
    offset,
    limit = 10000
  }) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/attachments/`, {
      params: {
        limit,
        offset,
        source
      }
    }),

  fetchContacts: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/contacts/`, {
      params: {
        limit,
        offset
      }
    }),

  fetchContact: (workspaceId, id, contactId) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/contacts/${contactId}`, {}),

  deleteContact: (workspaceId, id, contactId) =>
    api.delete(`/v1/${workspaceId}/tasks/${id}/contacts/${contactId}/`),

  createContact: (workspaceId, id, data) =>
    api.post(`/v1/${workspaceId}/tasks/${id}/contacts/`, data),

  fetchAssignments: (
    workspaceId,
    id,
    directMembersOnly = false,
    offset = 0,
    limit = 10000
  ) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/assignments/`, {
      params: {
        direct_members_only: directMembersOnly,
        limit,
        offset
      }
    }),

  assignments: ({ workspaceId, id, employees, isDelete }) =>
    api.post(`/v1/${workspaceId}/tasks/${id}/assignments/`, {
      employees,
      delete: isDelete
    }),

  changeRelations: ({ workspaceId, id, relations }) =>
    api.post(`/v1/${workspaceId}/tasks/${id}/relations/bulk_update/`, {
      relations
    }),

  fetchRelations: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/relations/`, {
      params: {
        limit,
        offset
      }
    }),

  fetchSubtaskList: (workspaceId, id, limit = 1000, offset = 0) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/sub-tasks/`, {
      params: { limit, offset }
    }),

  createWorklog: (workspaceId, id, value) =>
    api.post(`/v1/${workspaceId}/tasks/${id}/worklog/`, value, {
      hide428: true
    }),

  updateWorklog: (workspaceId, id, worklogId, value) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/worklog/${worklogId}/`, value, {
      hide428: true
    }),

  deleteWorklog: (workspaceId, id, taskId) =>
    api.delete(`/v1/${workspaceId}/tasks/${id}/worklog/${taskId}/`),

  checkWorklogIsFull: ({ workspaceId, taskId }) =>
    api.get(`/v1/${workspaceId}/tasks/${taskId}/worklog/is-full/`),

  fetchWorklogList: (workspaceId, id, employeeId, offset, limit) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/worklog/`, {
      params: { employee: employeeId, offset, limit }
    }),

  fetchEventList: (workspaceId, id, offset, limit) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/log/`, {
      params: { limit, offset }
    }),

  fetchWorklog: (workspaceId, id, taskId) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/worklog/${taskId}/`),

  updateStatus: (workspaceId, id, status, reason, reasonFileList, dateEnd) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/transitions/`, {
      status,
      reason,
      reasonFileList,
      dateEnd
    }),

  fetchHierarchy: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/hierarchy/`),

  estimate: (workspaceId, id, estimation) =>
    api.put(
      `/v1/${workspaceId}/tasks/${id}/estimate/`,
      { estimation },
      {
        hide428: true
      }
    ),

  pointEstimate: (workspaceId, id, estimation) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/estimate-story-point/`, estimation),

  agreementAction: ({ workspaceId, id, action, rejectionReason }) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/agreement-action/`, {
      action,
      rejectionReason
    }),

  fetchAgreementSteps: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/agreement-steps/`),

  fetchTemplateAgreementSteps: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/tasks/templates/${id}/agreement-steps/`),

  view: (workspaceId, id) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/log-view/`),

  updateSubtasksDates: (workspaceId, id) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/subtasks-dates/`),

  fetchAcquaintenceSheet: (workspaceId, id) =>
    api.post(`/v1/${workspaceId}/tasks/${id}/acquaintance-sheet/`),

  uploadAcquaintenceSheetFile: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/acquaintance-sheet/`),

  fetchCoResponsibles: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/co-responsibles/`),

  changeController: (workspaceId, id, employeeId) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/controller/`, {
      employee: employeeId
    }),

  changeResponsible: (workspaceId, id, employeeId) =>
    api.put(`/v1/${workspaceId}/tasks/${id}/responsible/`, {
      employee: employeeId
    }),

  convertToSubtask: ({ workspaceId, taskId, parentId }) =>
    api.patch(
      `/v1/${workspaceId}/tasks/${taskId}/subtask/`,
      {
        parent: parentId
      },
      {
        hide428: true
      }
    ),

  convertSubtaskToTask: ({ workspaceId, taskId, parentId }) =>
    api.patch(`/v1/${workspaceId}/tasks/${taskId}/subtask-to-task/`, {
      parent: parentId
    }),

  fetchIssue: ({ issueUuid }) => api.get(`/v1/tasks/issues/${issueUuid}/`),

  rejectedIssue: ({ issueUuid, data }) =>
    api.put(`/v1/tasks/issues/${issueUuid}/reject/`, data),

  acceptedIssue: ({ issueUuid }) =>
    api.put(`/v1/tasks/issues/${issueUuid}/accept/`),

  fetchUsersWithAccess: (workspaceId, id, offset = 0, limit = 10000) =>
    api.get(`/v1/${workspaceId}/tasks/${id}/access-management/`, {
      params: {
        limit,
        offset
      }
    }),

  modifyUsersAccess: ({ workspaceId, id, employees, isDelete }) =>
    api.post(`/v1/${workspaceId}/tasks/${id}/access-management/`, {
      employees,
      delete: isDelete
    })
});
