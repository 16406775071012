import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Typography from 'components/common/typography';

import MovingDepartmentForm from './form';

export const MovingDepartmentDrawer = ({ visible, data, onClose }) => {
  const { t } = useTranslation(['MoveDepartment', 'Team']);
  const { department } = data;

  const departmentName = department && department.name;

  return (
    <Drawer
      destroyOnClose
      maskClosable={false}
      open={visible}
      title={<Drawer.Title>{t('MoveDepartment', { ns: 'Team' })}</Drawer.Title>}
      onClose={onClose}
    >
      <Typography.Paragraph>
        <Trans
          i18nKey="ChangeAdminDesc"
          ns="MoveDepartment"
          components={{
            bold: <Typography.Text strong />
          }}
          values={{ value: departmentName }}
        />
      </Typography.Paragraph>

      <Alert type="warning" message={t('RequestDeclinedWarning')} />

      <MovingDepartmentForm department={department} onClose={onClose} />
    </Drawer>
  );
};

MovingDepartmentDrawer.propTypes = {
  data: PropTypes.shape({
    department: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      permissions: PropTypes.object,
      isMainDepartment: PropTypes.bool,
      isBenchDepartment: PropTypes.bool,
      isJoinsDepartment: PropTypes.bool
    })
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

MovingDepartmentDrawer.defaultProps = {
  data: {},
  visible: false,
  onClose: () => {}
};

export default MovingDepartmentDrawer;
