import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Modal from 'components/common/modal';
import { updateAttachmentSubscription } from 'components/common/subscriptions/utils/update-attachment-subscription';
import { updateAttachmentSubscribers } from 'components/common/subscriptions/utils/update-attachment-subscribers';
import { updateAttachmentValidityDate } from 'components/common/validity-date/utils/update-attachment-validity-date';
import AttachmentsTable from 'components/attachments-view/views/all/components/table';

import { fetchAttachmentLocal } from 'store/attachments';

import styles from './attachment-overview-modal.module.scss';

const AttachmentOverviewModal = ({ data, visible, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const tableDataRef = useRef(tableData);

  const { fileId } = data;

  const fetchAttachment = async () => {
    setIsLoading(true);

    try {
      const attachment = await dispatch(fetchAttachmentLocal({ fileId }));
      setTableData([attachment]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      fetchAttachment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    tableDataRef.current = tableData;
  }, [tableData]);

  const handleSubscription = ({ data: file, isSubscribed }) => {
    updateAttachmentSubscription({
      attachments: tableDataRef.current,
      setAttachments: setTableData,
      data: file,
      isSubscribed
    });
  };

  const handleAfterChangeManageSubscription = ({
    added,
    deleted,
    userId,
    entityId
  }) => {
    updateAttachmentSubscribers({
      attachments: tableDataRef.current,
      setAttachments: setTableData,
      data: { added, deleted, entityId, userId }
    });
  };

  const handleAfterChangeValidityDateCallback = ({ attachment }) => {
    updateAttachmentValidityDate({
      attachments: tableDataRef.current,
      setAttachments: setTableData,
      data: attachment
    });
  };

  return (
    <Modal
      width={1052}
      destroyOnClose
      open={visible}
      onClose={onClose}
      maskClosable={false}
      contentClassName={styles.modalContent}
    >
      <AttachmentsTable
        data={tableData}
        pagination={{
          hideOnSinglePage: true
        }}
        hideSorting
        scroll={{ x: 1052 }}
        isLoading={isLoading}
        fileLinkTarget="_blank"
        subscribeCallback={handleSubscription}
        unsubscribeCallback={handleSubscription}
        changeManageSubscribersCallback={handleAfterChangeManageSubscription}
        changeValidityDateCallback={handleAfterChangeValidityDateCallback}
      />
    </Modal>
  );
};

AttachmentOverviewModal.propTypes = {
  data: PropTypes.shape({
    fileId: PropTypes.string
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

AttachmentOverviewModal.defaultProps = {
  data: {},
  visible: false,
  onClose: () => {}
};

export default AttachmentOverviewModal;
