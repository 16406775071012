import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  MESSAGE_DURATION,
  MANUAL_COLLEAGUE_INVITATION_EVENT,
  REG_BOSS_INVITATION_EVENT,
  REG_SUBORDINATE_INVITATION_EVENT,
  MAIN
} from 'constants/index';

import Drawer from 'components/common/drawer';
import Typography from 'components/common/typography';

import { inviteEmployee, checkEmail } from 'store/team/employees/actions';
import { getUserEmployee } from 'store/workspace';
import { createDepartmentWithInvite } from 'store/team/departments';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import InviteEmployeeForm from './form';

export const InviteEmployeeDrawer = ({ visible, onClose, data }) => {
  const { t } = useTranslation(['InviteCollegue', 'Toast']);

  const dispatch = useDispatch();

  const currentEmployee = useSelector(getUserEmployee);

  const [isLoading, setIsLoading] = useState(false);
  const [afterVisible, setAfterVisible] = useState(visible);

  const amplitude = useAmplitude();

  const { callback } = data;

  const onCheckEmail = email => dispatch(checkEmail({ email }));

  const checkIssetEmails = async emails => {
    const newEmails = [];
    const issetEmails = [];

    await Promise.all(
      emails.map(email =>
        onCheckEmail(email).then(({ present }) => {
          if (present) {
            issetEmails.push(email);
          } else {
            newEmails.push(email);
          }
        })
      )
    );

    return { newEmails, issetEmails };
  };

  const showWarning = issetEmails => {
    const emails = issetEmails.join(', ');
    const text = t('EmailExistsErrorMessage', { ns: 'Toast' });

    message.warn(`${emails}: ${text}`, 10);
  };

  const onInvite = async values => {
    const { employees = [], manager, subordinates = [], department } = values;

    const resultEmails = manager ? [manager] : [...employees, ...subordinates];
    const resultDepartment = manager
      ? undefined
      : department || currentEmployee.department.name;

    amplitude.colleagueInvitationEvent([MANUAL_COLLEAGUE_INVITATION_EVENT]);

    try {
      setIsLoading(true);

      const { newEmails, issetEmails } = await checkIssetEmails(resultEmails);

      if (issetEmails.length) {
        showWarning(issetEmails);
      }

      if (!newEmails.length) {
        return;
      }

      if (employees.length) {
        await dispatch(inviteEmployee({ employeeEmails: newEmails }));
      }

      if (manager || subordinates.length) {
        const amplitudeData = [REG_BOSS_INVITATION_EVENT];

        if ((subordinates || []).length) {
          amplitudeData.push(REG_SUBORDINATE_INVITATION_EVENT);
        }

        amplitude.colleagueInvitationEvent(amplitudeData);

        await dispatch(
          createDepartmentWithInvite({
            id:
              !!subordinates.length && currentEmployee.department.type !== MAIN
                ? currentEmployee.department.id
                : null,
            name: resultDepartment,
            managerEmail: newEmails.find(e => e === manager),
            employeeEmails: newEmails.filter(e => e !== manager)
          })
        );
      }

      if (callback) {
        await callback();
      }

      message.success(t('InvitationSent', { ns: 'Toast' }), MESSAGE_DURATION);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={<Drawer.Title>{t('InviteCollegueHeading')}</Drawer.Title>}
      width={480}
      open={visible}
      destroyOnClose
      onClose={onClose}
      afterOpenChange={setAfterVisible}
    >
      <Typography.Paragraph>{t('InviteCollegueDesc')}</Typography.Paragraph>

      <InviteEmployeeForm
        isLoading={isLoading}
        afterVisible={afterVisible}
        currentEmployee={currentEmployee}
        onSubmit={onInvite}
      />
    </Drawer>
  );
};

InviteEmployeeDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  data: PropTypes.shape({
    callback: PropTypes.func
  })
};

InviteEmployeeDrawer.defaultProps = {
  data: {}
};

export default InviteEmployeeDrawer;
