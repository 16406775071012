import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DETAILS_REQUEST, MODAL, SELECTED_REQUEST } from '../../constants';
import { getRouterUrlQuery } from '../../store/router';

export const useRequestDetailModal = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const query = useSelector(getRouterUrlQuery);
  const modal = query[MODAL];
  const statusId = query[SELECTED_REQUEST];

  useEffect(() => {
    setVisible(!!(modal === DETAILS_REQUEST && statusId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return [visible, setVisible];
};

export default useRequestDetailModal;
