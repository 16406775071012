import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import { renderDescriptions } from 'components/common/comments/converters';

import styles from './history-log-view.module.scss';

export const HistoryLogView = ({ text, showHistory, setShowHistory }) => {
  const { t } = useTranslation(['Common', 'Tasks']);

  const fromTextRef = useRef(null);

  const [fromTextElWidth, setFromTextElWidth] = useState(0);

  useEffect(() => {
    if (fromTextRef.current && showHistory) {
      setFromTextElWidth(fromTextRef.current.offsetWidth);
    }
  }, [fromTextRef.current, showHistory]);

  const textIndent =
    showHistory && fromTextElWidth > 0 ? fromTextElWidth : undefined;

  const transformText = text
    .filter(item => item.previousValue)
    .map(({ previousValue }) => ({ text: previousValue }));

  return (
    <div>
      <div className={styles.history}>
        <div className={styles.infoWrap}>
          <span ref={fromTextRef} className={styles.fromText}>
            {t('FromDate', { ns: 'Tasks' })}:&nbsp;
          </span>

          <div style={{ textIndent }} className={styles.textWrap}>
            {renderDescriptions(transformText)}
          </div>
        </div>
      </div>

      <Button
        type="link"
        size="small"
        className={styles.collapseBtn}
        onClick={() => setShowHistory(false)}
        style={{ fontWeight: 400 }}
      >
        {t('CollapseBtn')}
      </Button>
    </div>
  );
};
