import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';

import Drawer from 'components/common/drawer';

import mapValue from 'utils/map-value';
import { showNoticeMessage } from 'services/notice';

import Form from './from';

export const AssignmentsDrawer = ({ visible, data, onClose }) => {
  const [afterVisible, setAfterVisible] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [defaultEmployees, setDefaultEmployees] = useState(null);
  const [deletedEmployees, setDeletedEmployees] = useState([]);

  const { title, fetch, submit, noticeNumber, ...formData } = data;

  const onFetch = useCallback(async () => {
    try {
      setIsFetchLoading(true);

      const results = await fetch();
      setDefaultEmployees(results);
    } finally {
      setIsFetchLoading(false);
    }
  }, [fetch]);

  const onDelete = useCallback(employee => {
    setDefaultEmployees(prev => prev.filter(e => e.id !== employee.id));
    setDeletedEmployees(prev => [...prev, employee]);
  }, []);

  const onSubmit = useCallback(
    async ({ employees }) => {
      try {
        setIsSubmitLoading(true);

        await submit({
          added: (employees || []).map(mapValue),
          deleted: deletedEmployees.map(e => e.id)
        });

        onClose();

        showNoticeMessage({ number: noticeNumber });
      } finally {
        setIsSubmitLoading(false);
      }
    },
    [deletedEmployees, noticeNumber, onClose, submit]
  );

  useEffect(() => {
    if (afterVisible) {
      onFetch();
    } else {
      setDeletedEmployees([]);
      setDefaultEmployees(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterVisible]);

  return (
    <Drawer
      title={
        <>
          <Drawer.Back onClick={onClose} />
          <Drawer.Title>{title}</Drawer.Title>
        </>
      }
      open={visible}
      closable={false}
      width={480}
      afterOpenChange={setAfterVisible}
      onClose={onClose}
    >
      {isFetchLoading || !defaultEmployees ? (
        <Spin />
      ) : (
        <Form
          isLoading={isSubmitLoading}
          onSubmit={onSubmit}
          onDelete={onDelete}
          data={{ ...formData, employees: defaultEmployees }}
        />
      )}
    </Drawer>
  );
};

AssignmentsDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    title: PropTypes.string,
    videoSlug: PropTypes.string,
    employeeListTitle: PropTypes.string,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    selectProps: PropTypes.object,
    permissions: PropTypes.shape({
      allowAdd: PropTypes.bool,
      allowDelete: PropTypes.bool
    }),
    submitText: PropTypes.string,
    fetch: PropTypes.func,
    submit: PropTypes.func
  }),
  onClose: PropTypes.func.isRequired
};

AssignmentsDrawer.defaultProps = {
  data: {}
};

export default AssignmentsDrawer;
