import React from 'react';
import { Link } from 'react-router-dom';

import {
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_ORDER,
  TYPE_REQUEST,
  TYPE_TASK
} from 'constants/index';

import styles from './gpt-widget-component.module.scss';

export const renderEntityLink = ({ routes, modals, entityInfo = {} }) => {
  let link;
  let linkText;

  const { entityType, entityId, entity } = entityInfo;

  if (entityType === TYPE_TASK) {
    linkText = `ID ${entityId} ${entity.title}`;

    link = modals.tasks.showDetails({
      id: entityId
    });
  }

  if (entityType === TYPE_REQUEST) {
    linkText = `ID ${entityId} ${entity.title}`;

    link = modals.requests.showDetails({
      id: entityId
    });
  }

  if (entityType === TYPE_ORDER) {
    linkText = `ID ${entity.orderId || entity.id} ${entity.title}`;

    link = routes.toOrderStatusFromLog({
      orderStatusId: entityId,
      isReplace: true
    });
  }

  if (entityType === TYPE_CONTACT) {
    linkText = `ID ${entityId} ${entity.firstName || entity.full_name}`;

    link = routes.toContact({ id: entityId });
  }

  if (entityType === TYPE_ASSET) {
    linkText = `ID ${entityId} ${entity.title}`;

    link = modals.assets.showDetails({
      id: entityId
    });
  }

  return (
    <Link className={styles.linkHeader} to={link}>
      {linkText}
    </Link>
  );
};

export default renderEntityLink;
