import React, { Suspense, useEffect } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

import {
  CONTACTS,
  SETTINGS,
  TEAM,
  ATTACHMENTS,
  REPORTS,
  ASSETS,
  TASKS,
  DASHBOARD,
  SUPPORT,
  REQUESTS
} from 'constants/index';

import BenchProvider from 'components/common/bench-provider';
import UrlProvider from 'components/common/url-provider';
import PageNotFound from 'components/common/page-not-found';
import GptWidgetComponent from 'components/common/gpt-widget-component';
import InviteeWorkspaceDataProvider from 'components/auth-view/register-steps-view/invitee-workspace-data-provider';
import FeatureFlagGuard from 'components/app/feature-flag/feature-flag-guard';

import { getIsOnlyUserProfile, getUser } from 'store/user';

import {
  GptWidgetProvider,
  UPSERVICE_WIDGET_QUERY,
  WebsocketOperatorProvider
} from 'providers';
import useFirebase from 'hooks/firebase/use-firebase';
import useRoutesService from 'services/routes';
import lazyWithRetry from 'utils/lazy-with-retry';

import NotificationsProvider from '../notifications-provider';
import DrawersProvider from '../drawers-provider';
import AmplitudeLoginProvider from '../amplitude-login-provider';
import ChangeWorkspaceProvider from '../change-workspace-provider';
import OpenFileProvider from '../open-file-provider';
import { ModalsProvider } from '../modals-provider';
import PaymentAccountStatusProvider from '../payment-account-status-provider';
// import AgreementProvider from '../agreement-provider';

// TODO: вынести в crm-view
const TasksView = lazyWithRetry(() => import('components/tasks-view'));
const RequestsView = lazyWithRetry(() => import('components/requests-view'));
const ContactsView = lazyWithRetry(() => import('components/contacts-view'));
const TeamView = lazyWithRetry(() => import('components/team-view'));
const SettingsView = lazyWithRetry(() => import('components/settings-view'));
const AttachmentsView = lazyWithRetry(() =>
  import('components/attachments-view')
);
const ReportsView = lazyWithRetry(() => import('components/reports-view'));
const AssetsView = lazyWithRetry(() => import('components/assets-view'));
const DashboardView = lazyWithRetry(() => import('components/dashboard-view'));

export const Routes = () => {
  const user = useSelector(getUser);
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);

  const { path, params } = useRouteMatch();

  useFirebase();

  const routes = useRoutesService({ returnUrl: true });
  const routesWithoutUrl = useRoutesService();

  useEffect(() => {
    if (isOnlyUserProfile && +params.worspace !== user.personalWorkspaceId) {
      routesWithoutUrl.toUserProfile({ workspaceId: user.personalWorkspaceId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnlyUserProfile]);

  return (
    <>
      <InviteeWorkspaceDataProvider>
        <WebsocketOperatorProvider>
          <ModalsProvider>
            <GptWidgetProvider>
              <DrawersProvider />
              <NotificationsProvider />
              <AmplitudeLoginProvider />
              <ChangeWorkspaceProvider />
              <OpenFileProvider />
              {/* 85482 - показываем модалку о принятии соглашения */}
              {/* пока закомментил, к след релизу раскомментить нужно */}
              {/* <AgreementProvider /> */}

              <Suspense fallback={<Spin />}>
                <UrlProvider>
                  <BenchProvider>
                    <PaymentAccountStatusProvider>
                      <GptWidgetComponent />

                      <FeatureFlagGuard>
                        <Switch>
                          <Redirect
                            exact
                            from={path}
                            to={routes.toDefaultPage()}
                          />

                          <Route
                            path={`${path}/${DASHBOARD}`}
                            component={DashboardView}
                          />

                          <Route
                            path={`${path}/${TASKS}`}
                            component={TasksView}
                          />

                          <Route
                            path={`${path}/${REQUESTS}`}
                            component={RequestsView}
                          />

                          <Route
                            path={`${path}/${ASSETS}`}
                            component={AssetsView}
                          />

                          <Route
                            path={`${path}/${TEAM}`}
                            component={TeamView}
                          />

                          <Route
                            path={`${path}/${CONTACTS}`}
                            component={ContactsView}
                          />

                          <Route
                            path={`${path}/${REPORTS}`}
                            component={ReportsView}
                          />

                          <Route
                            path={`${path}/${ATTACHMENTS}`}
                            component={AttachmentsView}
                          />

                          <Route
                            path={`${path}/${SETTINGS}`}
                            component={SettingsView}
                          />

                          <Redirect
                            from={`${path}/${SUPPORT}`}
                            to={`${routes.toDefaultPage()}?${UPSERVICE_WIDGET_QUERY}=true`}
                          />

                          <Route
                            render={() => (
                              <PageNotFound
                                style={{
                                  position: 'unset'
                                }}
                              />
                            )}
                          />
                        </Switch>
                      </FeatureFlagGuard>
                    </PaymentAccountStatusProvider>
                  </BenchProvider>
                </UrlProvider>
              </Suspense>
            </GptWidgetProvider>
          </ModalsProvider>
        </WebsocketOperatorProvider>
      </InviteeWorkspaceDataProvider>
    </>
  );
};

export default Routes;
