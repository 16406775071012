import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { notification } from 'antd';

import { CHANNELS_EDITOR_DRAWER } from 'constants/index';

import Modal from 'components/common/modal';

import { updateEmailChannelPassword } from 'store/channels';
import { setVisibleDrawer } from 'store/drawers';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import FormUpdateEmailPassword from './form';

const UpdateEmailPasswordModal = ({ channelId, visible, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const { t } = useTranslation(['ConnectEmail', 'Errors']);

  const onSubmit = async values => {
    try {
      setIsLoading(true);

      await dispatch(
        updateEmailChannelPassword({ id: channelId, credentials: values })
      );

      showNoticeMessage({ number: NOTICE_NUMBER.accessSettingsSaved });
      dispatch(setVisibleDrawer({ drawer: CHANNELS_EDITOR_DRAWER }));

      onClose();
    } catch ({ response }) {
      if (response.status === 428) {
        notification.warn({
          message: t('SomethingWentWrong', { ns: 'Errors' }),
          description: (
            <div>
              {t('ImapErrorConnectedDesc')}{' '}
              <a
                href="https://help.upservice.com/articles/98499-%D0%BA%D0%B0%D0%BA-%D0%BF%D0%BE%D0%B4%D0%BA%D0%BB%D1%8E%D1%87%D0%B8%D1%82%D1%8C-%D0%B2%D0%B0%D1%88%D1%83-%D0%BF%D0%BE%D1%87%D1%82%D1%83-%D0%BA-upservice"
                target="_blank"
                rel="noreferrer"
              >
                {t('ImapErrorConnectedLink')}
              </a>
            </div>
          )
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={508}
      open={visible}
      onClose={onClose}
      title={t('UpdateSourceDataHeading')}
      contentStyle={{ padding: 24 }}
      destroyOnClose
      centered
    >
      <FormUpdateEmailPassword onSubmit={onSubmit} isLoading={isLoading} />
    </Modal>
  );
};

export default UpdateEmailPasswordModal;

UpdateEmailPasswordModal.propTypes = {
  channelId: PropTypes.number.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

UpdateEmailPasswordModal.defaultProps = {
  visible: false,
  onClose: () => {}
};
