import { createAction } from 'redux-actions';

// Функци подписки на изменение ulr
// и очистки filter.search
export const makeSubscribeLocationChange = (
  view,
  subView,
  key,
  initialFilter
) => (state, { payload }) => {
  const { pathname } = payload.location;
  const urls = pathname.split('/').slice(1);
  // первый элемент - activeWorkspaceId
  // второй элемент - activeView
  const [, activeView, subActiveView] = urls;

  if (activeView !== view || (subView && subView !== subActiveView)) {
    const filter = initialFilter || {
      ...state.filter,
      search: ''
    };

    if (key) {
      return {
        ...state,
        [key]: {
          ...state[key],
          filter
        }
      };
    }

    return {
      ...state,
      filter
    };
  }

  return state;
};

export const setTabKey = createAction('router/set-tab-key');
export const setIsTabVisible = createAction('router/set-is-tab-visible');

export const setWithoutDefaultFilter = createAction(
  'router/set-without-default-filter'
);
