import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TASK_STATUS, VIDEOS } from 'constants/index';

import Actions from 'components/requests-view/view/actions';
import { IconId } from 'components/requests-view/components';
import EventStatus from 'components/common/event-status';
import VideoBtn from 'components/common/video-btn';
import FavoriteSwitch from 'components/common/favorite-switch';

import { setRequestFavorite } from 'store/requests';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import styles from './header.module.scss';

const Header = ({
  task,
  parentTask,
  isBacklog,
  className,
  setVisibleSprintSelect
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('Requests');

  const { isFavorite } = task;

  const handleFavorite = async () => {
    await dispatch(
      setRequestFavorite({ taskId: task.id, isFavorite: !isFavorite })
    );

    showNoticeMessage({
      number: isFavorite
        ? NOTICE_NUMBER.requestFavoriteDeleted
        : NOTICE_NUMBER.requestFavoriteAdded
    });
  };

  return (
    <div
      className={classnames(styles.root, className)}
      data-qa="qa-zjk3yg039xiomoq"
    >
      <div className={styles.block} data-qa="qa-8hbjhtcdrsxfg71">
        <IconId
          object={task}
          copyable={{ label: t('CopyRequestLinkTip') }}
          className={styles.iconId}
        />

        <EventStatus
          id={task.id}
          statuses={TASK_STATUS}
          activeStatus={task.status}
          bordered
          data-qa="qa-teo971pu3x9ok7x"
        />

        <VideoBtn
          slug={VIDEOS.whatIsTaskStatus}
          isEllipsis
          style={{ overflow: 'hidden' }}
        />
      </div>

      <div className={styles.block}>
        <FavoriteSwitch isFavorite={isFavorite} onChange={handleFavorite} />

        <Actions
          isBacklog={isBacklog}
          task={task}
          parentTask={parentTask}
          setVisibleSprintSelect={setVisibleSprintSelect}
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  task: PropTypes.object.isRequired,
  isBacklog: PropTypes.bool,
  className: PropTypes.string,
  setVisibleSprintSelect: PropTypes.func
};

Header.defaultProps = {
  isBacklog: false,
  className: undefined,
  setVisibleSprintSelect: () => {}
};

export default Header;
