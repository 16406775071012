import { useSelector } from 'react-redux';
import { useFeatureIsOn } from '@growthbook/growthbook-react';

import {
  WORKSPACE_OWN_GOOGLE_DRIVE_INTEGRATION_FLAG,
  WORKSPACE_COMMON_GOOGLE_DRIVE_INTEGRATION_LEGACY_FLAG
} from 'constants/index';

import { getActiveWorkspace } from 'store/workspace';

export const useWorkspaceGoogleDriveSupport = () => {
  const workspace = useSelector(getActiveWorkspace);

  const isWorkspaceOwnGoogleDriveIntegrationEnabled = useFeatureIsOn(
    WORKSPACE_OWN_GOOGLE_DRIVE_INTEGRATION_FLAG
  );
  const isWorkspaceCommonGoogleDriveIntegrationLegacyEnabled = useFeatureIsOn(
    WORKSPACE_COMMON_GOOGLE_DRIVE_INTEGRATION_LEGACY_FLAG
  );

  const isOwnAndCommonGoogleDriveIntegrationEnabled =
    isWorkspaceOwnGoogleDriveIntegrationEnabled &&
    isWorkspaceCommonGoogleDriveIntegrationLegacyEnabled;

  const hasWorkspaceGoogleDriveIntegration = !!workspace?.googleDrive;

  const hasWorkspaceGoogleDriveWorkflow =
    isWorkspaceOwnGoogleDriveIntegrationEnabled &&
    hasWorkspaceGoogleDriveIntegration;

  const getFileHasWorkspaceGoogleDriveSupport = file =>
    !!file?.hasWorkspaceGoogleDriveSupport;

  return {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    isWorkspaceCommonGoogleDriveIntegrationLegacyEnabled,
    isOwnAndCommonGoogleDriveIntegrationEnabled,
    hasWorkspaceGoogleDriveIntegration,
    hasWorkspaceGoogleDriveWorkflow,
    getFileHasWorkspaceGoogleDriveSupport
  };
};
