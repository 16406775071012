import React from 'react';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Icon from 'components/common/icon';

import { useDiscussionCreateTask } from 'hooks';

import styles from './discussion-create-task.module.scss';

const DiscussionCreateTask = ({ discussionId, sender, fileList }) => {
  const { t } = useTranslation('AddTask');

  const showTaskCreator = useDiscussionCreateTask({
    discussionId,
    sender,
    fileList
  });

  return (
    <div className={styles.root} onClick={e => e.stopPropagation()}>
      <Tooltip title={t('AddTaskHeading')} mouseEnterDelay={0.5}>
        <Button
          size="small"
          type="text"
          className={styles.iconBtn}
          onClick={showTaskCreator}
        >
          <Icon type="snippets" size={20} color="black-55" />
        </Button>
      </Tooltip>
    </div>
  );
};

DiscussionCreateTask.propTypes = {
  discussionId: PropTypes.number.isRequired,
  fileList: PropTypes.array.isRequired,
  sender: PropTypes.shape({
    fullName: PropTypes.string,
    avatarUrl: PropTypes.string,
    contactId: PropTypes.string,
    employee: PropTypes.string,
    username: PropTypes.string
  }).isRequired
};

export default DiscussionCreateTask;
