import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { push, goBack, replace } from 'connected-react-router';

import {
  MODAL,
  COPY_ORDER,
  SELECTED_STATUS,
  CREATE_ORDER,
  CREATE_REVIEW,
  CREATE_COMPANY,
  EDIT_WORKSPACE_REQUISITES,
  EDIT_USER_EMAIL,
  EDIT_WORKSPACE_PROFILE,
  EMPLOYEES,
  SELECTED_EMPLOYEE,
  REMOVE_EMPLOYEE,
  EDIT_EMPLOYEE,
  MOVE_EMPLOYEE,
  REMOVE_OWNER,
  APPOINTMENT,
  MOVE_OWNER,
  MOVE_MANAGER,
  TEAM,
  GUIDE,
  CHANGE_ADMIN,
  CREATE_PROJECT,
  EDIT_PROJECT,
  SELECTED_PROJECT,
  CREATE_IDEA,
  IDEA,
  EDIT_IDEA,
  DETAILS_IDEA,
  PUBLIC_IDEA,
  SELECTED_IDEA,
  CONVERT_IDEA_TO_PROJECT,
  COPY_PUBLIC_IDEA,
  CREATE_TASK,
  EDIT_TASK,
  SELECTED_TASK,
  DETAILS_TASK,
  CONVERT_IDEA_TO_TASK,
  CREATE_SPRINT,
  CREATE_CONTACT,
  DETAILS_ASSET,
  SELECTED_ASSET,
  ORGSTRUCTURE,
  ASSIGNING_SIGNATORY,
  INVITE_EMPLOYEE,
  DELETED_ASSET,
  CREATE_REQUEST,
  DETAILS_REQUEST,
  SELECTED_REQUEST,
  EDIT_REQUEST
} from 'constants/index';

import { getRouterUrlActivePath } from '../store/router';

export const useModalsService = ({ defaultUrl, returnUrl = false } = {}) => {
  const activePath = useSelector(getRouterUrlActivePath, shallowEqual);

  const dispatch = useDispatch();
  const baseUrl = useMemo(() => defaultUrl || `?${MODAL}=`, [defaultUrl]);

  // isReplace - добалено, чтобы драйверы в профиле сотрудника открывались не как новый урл
  // а заменяли текущий, и когда жмем "Вернуться назад", чтобы пушило на страницу, с которой перешли
  // а не на какой-нибудь драйвер, типа редактирования или удаления
  const goTo = useCallback(
    ({ modal, isReplace, ...location }) => {
      const modalQueryString = modal ? [baseUrl, modal].join('') : undefined;
      const returnQuery = modalQueryString || location.search || '';

      return returnUrl
        ? returnQuery
        : dispatch(
            isReplace
              ? replace({
                  search: modalQueryString,
                  ...location
                })
              : push({
                  search: modalQueryString,
                  ...location
                })
          );
    },
    [baseUrl, dispatch, returnUrl]
  );

  const memoOrders = useMemo(
    () => ({
      create: ({ state, isReplace } = {}) =>
        goTo({ modal: CREATE_ORDER, isReplace, state }),
      copy: statusId =>
        goTo({ modal: `${COPY_ORDER}&${SELECTED_STATUS}=${statusId}` }),
      assingSignatory: () =>
        goTo({ modal: ASSIGNING_SIGNATORY, isReplace: true })
    }),
    [goTo]
  );

  const memoProjects = useMemo(
    () => ({
      showProjectCreator: () => goTo({ modal: CREATE_PROJECT }),
      showProjectEditor: id =>
        goTo({ modal: `${EDIT_PROJECT}&${SELECTED_PROJECT}=${id}` })
    }),
    [goTo]
  );

  const memoIdeas = useMemo(
    () => ({
      showIdeaCreator: () => goTo({ modal: CREATE_IDEA }),
      showCopyPublicIdea: id =>
        goTo({ modal: `${COPY_PUBLIC_IDEA}&${SELECTED_IDEA}=${id}` }),
      showIdea: () => goTo({ modal: IDEA }),
      showIdeaDetails: (id, commentId) =>
        goTo({
          modal: `${DETAILS_IDEA}&${SELECTED_IDEA}=${id}`,
          state: { commentId }
        }),
      showIdeaEditor: id =>
        goTo({ modal: `${EDIT_IDEA}&${SELECTED_IDEA}=${id}` }),
      showPublicIdea: id =>
        goTo({ modal: `${PUBLIC_IDEA}&${SELECTED_IDEA}=${id}` }),
      showProjectCreator: id =>
        goTo({ modal: `${CONVERT_IDEA_TO_PROJECT}&${SELECTED_IDEA}=${id}` }),
      showCreator: id =>
        goTo({ modal: `${CREATE_TASK}&${SELECTED_IDEA}=${id}` }),
      showConvertToTask: id =>
        goTo({ modal: `${CONVERT_IDEA_TO_TASK}&${SELECTED_IDEA}=${id}` })
    }),
    [goTo]
  );

  const memoContacts = useMemo(
    () => ({
      create: isContactPageSubmit =>
        goTo({ modal: CREATE_CONTACT, state: { isContactPageSubmit } })
    }),
    [goTo]
  );

  const memoTasks = useMemo(
    () => ({
      showCreator: ({ initialData = {} }) =>
        goTo({ modal: CREATE_TASK, state: { initialData } }),
      showDetails: ({
        id,
        isReplace,
        commentId,
        isFromNotification,
        showCommentsTab,
        messageUuid,
        pathname
      }) =>
        goTo({
          modal: `${DETAILS_TASK}&${SELECTED_TASK}=${id}`,
          state: {
            commentId,
            isFromNotification,
            showCommentsTab,
            messageUuid
          },
          isReplace,
          pathname
        }),
      showEditor: id => goTo({ modal: `${EDIT_TASK}&${SELECTED_TASK}=${id}` }),
      showSprintCreator: id =>
        goTo({ modal: `${CREATE_SPRINT}&${SELECTED_TASK}=${id}` })
    }),
    [goTo]
  );

  const memoRequests = useMemo(
    () => ({
      showCreator: ({ initialData = {} }) =>
        goTo({ modal: CREATE_REQUEST, state: { initialData } }),
      showDetails: ({
        id,
        isReplace,
        commentId,
        isFromNotification,
        showCommentsTab,
        messageUuid,
        pathname
      }) =>
        goTo({
          modal: `${DETAILS_REQUEST}&${SELECTED_REQUEST}=${id}`,
          state: {
            commentId,
            isFromNotification,
            showCommentsTab,
            messageUuid
          },
          isReplace,
          pathname
        }),
      showEditor: id =>
        goTo({ modal: `${EDIT_REQUEST}&${SELECTED_REQUEST}=${id}` }),
      showSprintCreator: id =>
        goTo({ modal: `${CREATE_SPRINT}&${SELECTED_REQUEST}=${id}` })
    }),
    [goTo]
  );

  const memoAssets = useMemo(
    () => ({
      showDetails: ({ id, state }) =>
        goTo({
          modal: `${DETAILS_ASSET}&${SELECTED_ASSET}=${id}`,
          state
        }),

      showDeleted: ({ id, state }) =>
        goTo({
          modal: `${DELETED_ASSET}&${SELECTED_ASSET}=${id}`,
          state
        })
    }),
    [goTo]
  );

  const memoTeam = useMemo(
    () => ({
      showEmployeeCreator: () => goTo({ modal: INVITE_EMPLOYEE }),
      showEmployeeDeletion: ({ departmentId, employeeId, isFromDepartment }) =>
        goTo({
          state: {
            modal: REMOVE_EMPLOYEE,
            departmentId,
            employeeId,
            isFromDepartment
          },
          isReplace: true
        }),
      showEmployeeDeletionOwner: ({
        departmentId,
        employeeId,
        isFromDepartment
      }) =>
        goTo({
          state: {
            modal: REMOVE_OWNER,
            departmentId,
            employeeId,
            isFromDepartment
          },
          isReplace: true
        }),
      showEmployeeEditor: ({ departmentId, employeeId, isFromDepartment }) =>
        goTo({
          modal: `${EDIT_EMPLOYEE}&${SELECTED_EMPLOYEE}=${employeeId}`,
          state: {
            departmentId,
            employeeId,
            isFromDepartment
          },
          isReplace: true
        }),
      showEmployeeMoving: ({ departmentId, employeeId, isFromDepartment }) =>
        goTo({
          state: {
            modal: MOVE_EMPLOYEE,
            departmentId,
            employeeId,
            isFromDepartment
          },
          isReplace: true
        }),
      showManagerMoving: ({ departmentId, employeeId, isFromDepartment }) =>
        goTo({
          state: {
            modal: MOVE_MANAGER,
            departmentId,
            employeeId,
            isFromDepartment
          },
          isReplace: true
        }),
      showOwnerMoving: ({ departmentId, employeeId, isFromDepartment }) =>
        goTo({
          state: {
            modal: MOVE_OWNER,
            departmentId,
            employeeId,
            isFromDepartment
          },
          isReplace: true
        }),
      showAdminChange: () => goTo({ modal: CHANGE_ADMIN, isReplace: true }),
      goToEmployee: id =>
        goTo({ pathname: [activePath, TEAM, ORGSTRUCTURE, id].join('/') }),
      goToEmployees: () =>
        goTo({ pathname: [activePath, TEAM, EMPLOYEES].join('/') }),
      showAppointment: () =>
        goTo({
          state: {
            modal: APPOINTMENT
          }
        })
    }),
    [activePath, goTo]
  );

  const memoSettings = useMemo(
    () => ({
      showEditWorkspaceProfile: ({ isReplace } = {}) =>
        goTo({ modal: EDIT_WORKSPACE_PROFILE, isReplace }),

      showEditWorkspaceRequisites: ({ isVerification } = {}) =>
        goTo({ modal: EDIT_WORKSPACE_REQUISITES, state: { isVerification } }),
      showComfirmPasswordForEmail: email =>
        goTo({ modal: EDIT_USER_EMAIL, state: email })
    }),
    [goTo]
  );

  const memoWorkspace = useMemo(
    () => ({
      create: state => goTo({ modal: CREATE_COMPANY, state })
    }),
    [goTo]
  );

  const memoGuides = useMemo(
    () => ({
      show: () => goTo({ modal: GUIDE }),
      showBySlug: slug => goTo({ modal: `${GUIDE}&slug=${slug}` })
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const memoReviews = useMemo(
    () => ({
      create: () =>
        goTo({
          modal: CREATE_REVIEW
        })
    }),
    [goTo]
  );

  const onClose = useCallback(
    state => dispatch(replace({ query: null, state })),
    [dispatch]
  );

  const onBack = useCallback(() => dispatch(goBack()), [dispatch]);

  return {
    orders: memoOrders,
    projects: memoProjects,
    ideas: memoIdeas,
    contacts: memoContacts,
    tasks: memoTasks,
    team: memoTeam,
    settings: memoSettings,
    workspace: memoWorkspace,
    guides: memoGuides,
    reviews: memoReviews,
    assets: memoAssets,
    requests: memoRequests,

    close: onClose,
    back: onBack
  };
};

export default useModalsService;
