/* eslint-disable no-shadow */
import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Button from 'components/common/button';
import Typography from 'components/common/typography';
import { useTheme } from 'components/common/theme';
import { ReleaseFeatureTag } from 'components/common/release';

import { DOCX, PPTX, XLSX } from 'hooks/common/use-file-upload/types';

import styles from './select-type.module.scss';

const { Title, Text } = Typography;

export const SelectType = ({ isLoading, onClick }) => {
  const { variables } = useTheme();

  const { t } = useTranslation('AddFile');

  const types = [
    {
      iconType: 'google-file',
      backgroundColor: variables.color['danube-30'],
      borderColor: variables.color['danube-40'],
      title: t('Document'),
      titleColor: 'indigo',
      description: t('DocumentDesc'),
      type: DOCX
    },
    {
      iconType: 'google-sheets',
      backgroundColor: variables.color['green-40'],
      borderColor: variables.color['green-50'],
      title: t('Table'),
      titleColor: 'brand-20',
      description: t('TableDesc'),
      type: XLSX
    },
    {
      iconType: 'google-presentation',
      backgroundColor: variables.color['yellow-50'],
      borderColor: variables.color['yellow-60'],
      title: t('Presentation'),
      titleColor: 'yellow-10',
      description: t('PresentationDesc'),
      type: PPTX
    }
  ];

  return (
    <Spin wrapperClassName={styles.spin} spinning={isLoading}>
      <div className={styles.root}>
        {types.map(t => (
          <Button
            key={t.type}
            className={styles.button}
            style={{
              backgroundColor: t.backgroundColor,
              border: `1px solid ${t.borderColor}`
            }}
            onClick={() => onClick(t.type)}
          >
            <Icon type={t.iconType} size={16} className={styles.icon} />

            <ReleaseFeatureTag
              featureName={t.featureName}
              wrapperClassName={styles.titleWrap}
            >
              <Title color={t.titleColor} level={3} className={styles.title}>
                {t.title}
              </Title>
            </ReleaseFeatureTag>

            <Text color="black-25" size="small">
              {t.description}
            </Text>
          </Button>
        ))}
      </div>
    </Spin>
  );
};

SelectType.propTypes = {
  isLoading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

SelectType.defaultProps = {
  isLoading: false
};

export default SelectType;
