import React, { forwardRef } from 'react';
import { Upload, message } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { DONE, MESSAGE_DURATION } from 'constants/index';

import Attachments from 'components/common/attachments';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';

import { useFileUpload } from 'hooks';
import { EXE_TYPE, MSI } from 'hooks/common/use-file-upload/types';
import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';
import getFileListSize from 'utils/get-file-list-size';
import { ERROR_NOTICE_TYPE, showNoticeMessage } from 'services/notice';

import styles from './upload-files.module.scss';

const { Dragger } = Upload;
const { Text } = Typography;

const checkExtentionFile = file => {
  const fileName = file.name.toLowerCase();

  return fileName.split('.').pop().toLowerCase();
};

export const UploadFiles = forwardRef(
  (
    {
      className,
      multiple,
      children,
      fileList,
      labels,
      onChange,
      isInline,
      attachmetsProps,
      showAttachments,
      fullWidth,
      maxSizeFile,
      maxSizeFileList,
      attachmentProps,
      hasWorkspaceGoogleDriveSupport,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('Errors', 'Common');
    const { hasWorkspaceGoogleDriveIntegration } =
      useWorkspaceGoogleDriveSupport();

    const hasWorkspaceGoogleDriveWorkflow =
      hasWorkspaceGoogleDriveSupport && hasWorkspaceGoogleDriveIntegration;

    const { onUpload } = useFileUpload({ hasWorkspaceGoogleDriveWorkflow });

    const getLabels = () => ({
      addFiles: t('AddFiles', { ns: 'Common' }),
      fileTypes: 'PNG, JPEG, PDF, DOC',
      ...labels
    });

    const localLabels = getLabels();

    const onChangeFileList = ({ file, fileList: _fileList }) => {
      const files = multiple
        ? _fileList
        : (_fileList.length > 0 && [_fileList[_fileList.length - 1]]) || [];

      const formattedFiles = files
        .filter(
          item => item.type !== EXE_TYPE && checkExtentionFile(item) !== MSI
        )
        .map(item => ({ ...item, hasWorkspaceGoogleDriveSupport }));

      if (file.status === DONE && attachmentProps) {
        if (attachmentProps.sub) {
          attachmentProps.sub.append({
            allowSubscriberSelection: false,
            fileId: file.response.id,
            mimeType: file.type,
            subscribers: [],
            parentName: attachmentProps.sub.parentName
          });
        }

        if (attachmentProps.validityDates) {
          attachmentProps.validityDates.append({
            validityDate: '',
            fileId: file.response.id,
            parentName: attachmentProps.validityDates.parentName
          });
        }
      }

      onChange(formattedFiles);
    };

    const onDelete = file => {
      const key = file.id ? 'id' : 'uid';

      const newFiles = fileList.filter(f => f[key] !== file[key]);

      if (attachmentProps) {
        if (attachmentProps.sub) {
          const indexFileDelete = fileList.findIndex(f => f[key] === file[key]);
          attachmentProps.sub.remove(indexFileDelete);
        }

        if (attachmentProps.validityDates) {
          const indexFileDelete = fileList.findIndex(f => f[key] === file[key]);
          attachmentProps.validityDates.remove(indexFileDelete);
        }
      }

      onChange(newFiles);
    };

    const onRenameFile = ({ id, name }) => {
      const newFileList = fileList.reduce((acc, curr) => {
        if (curr.response && curr.response.id === id) {
          return [...acc, { ...curr, name }];
        }

        return [...acc, curr];
      }, []);

      onChange(newFileList);
    };

    const beforeUpload = (file, uploadedFileList) => {
      const uploadedFileListSize = getFileListSize(uploadedFileList);

      if (
        maxSizeFileList &&
        getFileListSize(fileList) + uploadedFileListSize > maxSizeFileList
      ) {
        return false;
      }

      if (maxSizeFile && file.size > maxSizeFile) {
        message.error(
          t('FileSizeMustNotExceed', { mb: maxSizeFile / 1024 / 1024 }),
          MESSAGE_DURATION
        );

        return false;
      }

      if (file.type === EXE_TYPE || checkExtentionFile(file) === MSI) {
        showNoticeMessage({
          customContent: t('MsiExeNotAllowed'),
          type: ERROR_NOTICE_TYPE
        });

        return false;
      }

      return true;
    };

    return (
      <>
        <Dragger
          ref={ref}
          multiple={multiple}
          showUploadList={false}
          name="file"
          className={classnames(className, styles.root, {
            [styles.inline]: isInline,
            [styles.fullWidth]: fullWidth
          })}
          onChange={onChangeFileList}
          customRequest={onUpload}
          beforeUpload={beforeUpload}
          // TODO: the onChange method stops executing and displaying download progress, but deleting files does not work correctly without this
          // fileList={fileList}
          {...props}
        >
          {children ? (
            <>{children}</>
          ) : (
            <div className={styles.content}>
              <Icon type="plus" size={16} className={styles.icon} />
              <Text className={styles.title} strong>
                {localLabels.addFiles}
              </Text>
              <Text className={styles.description}>
                ({localLabels.fileTypes})
              </Text>
            </div>
          )}
        </Dragger>

        {showAttachments && (
          <Attachments
            fileList={fileList}
            hasWorkspaceGoogleDriveSupport={hasWorkspaceGoogleDriveSupport}
            attachmentProps={{
              onDelete,
              onRename: onRenameFile,
              size: 'small',
              ...attachmentProps
            }}
            {...attachmetsProps}
          />
        )}
      </>
    );
  }
);

UploadFiles.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  // eslint-disable-next-line react/require-default-props
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  labels: PropTypes.shape({
    addFiles: PropTypes.string,
    fileTypes: PropTypes.string,
    file: PropTypes.string,
    exceedsSize: PropTypes.string,
    descriptionWarn: PropTypes.string
  }),
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      contentType: PropTypes.string,
      url: PropTypes.string,
      size: PropTypes.number.isRequired
    })
  ),
  isInline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showAttachments: PropTypes.bool,
  onChange: PropTypes.func,
  attachmetsProps: PropTypes.shape({
    allowDelete: PropTypes.bool,
    size: PropTypes.string,
    className: PropTypes.string,
    fileClassName: PropTypes.string
  }),
  maxSizeFile: PropTypes.number,
  hasWorkspaceGoogleDriveSupport: PropTypes.bool
};

UploadFiles.defaultProps = {
  className: undefined,
  multiple: true,
  showAttachments: true,
  isInline: false,
  fullWidth: false,
  children: undefined,
  fileList: [],
  labels: {},
  onChange: () => {},
  attachmetsProps: {},
  maxSizeFile: undefined,
  hasWorkspaceGoogleDriveSupport: false
};
