import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { fetchChannelsLocal } from 'store/channels';

const useTaskInfo = () => {
  const dispatch = useDispatch();

  const fetchChannelData = useCallback(
    async channelUuid => {
      const { results } = await dispatch(fetchChannelsLocal({ channelUuid }));
      return results[0];
    },
    [dispatch]
  );

  const getTaskInfo = useCallback(
    async ({ channelUuid, kind, contactId }) => {
      const generatedUuid = crypto.randomUUID();

      if (!channelUuid && !contactId) {
        return createDefaultTaskInfo(generatedUuid);
      }

      const channel = await fetchChannelData(channelUuid);
      const isSlaActive = channel?.config?.slaConfig?.isActive;

      return createTaskInfo(
        channelUuid,
        kind,
        contactId,
        isSlaActive,
        channel,
        generatedUuid
      );
    },
    [fetchChannelData]
  );

  const createDefaultTaskInfo = generatedUuid => ({
    taskInfo: {
      uuid: generatedUuid,
      messageUuid: null,
      messageText: null,
      operatorSla: null,
      executorSla: null,
      roomUuid: null,
      roomId: null,
      issueLink: `${window.location.origin}/issues/${generatedUuid}`,
      channelKind: null,
      channelUuid: null,
      contactId: null
    }
  });

  const createTaskInfo = (
    channelUuid,
    kind,
    contactId,
    isSlaActive,
    channel,
    generatedUuid
  ) => ({
    taskInfo: {
      uuid: generatedUuid,
      messageUuid: null,
      messageText: null,
      operatorSla: isSlaActive ? channel.config.slaConfig.operatorSla : null,
      executorSla: isSlaActive ? channel.config.slaConfig.executorSla : null,
      roomUuid: null,
      roomId: null,
      issueLink: `${window.location.origin}/issues/${generatedUuid}`,
      channelKind: kind,
      channelUuid,
      contactId: contactId || null
    }
  });

  return { getTaskInfo };
};

export default useTaskInfo;
