import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import BottomBanner from '../../../common/bottom-banner';
import AppointmentDrawer from '../../../team-view/employees-view/drawers/appointment';

import styles from './styles.module.scss';

export const JoiningMessage = ({
  visible,
  createdAt,
  assignee,
  dateFormat
}) => {
  const [appointmentVisible, setAppointmentVisible] = useState(false);

  const { t } = useTranslation('AllocationToTheDepartment', {
    useSuspense: false
  });

  return (
    <>
      <BottomBanner visible={visible} className={styles.joining}>
        <strong>{moment(createdAt).format(dateFormat)}</strong>{' '}
        {t('AllocationRequestDescription', { assignee })}{' '}
        <span
          className={styles.link}
          onClick={() => setAppointmentVisible(true)}
        >
          {t('ResendRequestBtn')}
        </span>
      </BottomBanner>

      <AppointmentDrawer
        open={appointmentVisible}
        onClose={() => setAppointmentVisible(false)}
        closable
      />
    </>
  );
};

JoiningMessage.propTypes = {
  visible: PropTypes.bool,
  createdAt: PropTypes.string,
  assignee: PropTypes.string,
  dateFormat: PropTypes.string
};

JoiningMessage.defaultProps = {
  visible: false,
  createdAt: '',
  assignee: '',
  dateFormat: 'DD/MM/YYYY HH:mm'
};

export default JoiningMessage;
