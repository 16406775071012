import themeVariables from 'theme';

// CLEAN UP
export const CRM = 'crm';
export const ORDERS = 'orders';

export const SELECT_ORDER_TYPE_STEP = 'select-order-type-step';
export const CREATE_ORDER_STEP = 'create-order-step';

export const CONTRACTOR_ORDER = 'executor-order';
export const CUSTOMER_ORDER = 'customer-order';
export const ORDER_TO_MARKET = 'order-to-market';

export const STATUS_NEW = 'new';
export const STATUS_RECENT = 'recent';
export const STATUS_DECLINED = 'declined';
export const STATUS_DECLINED_REJECTED = 'declined-order-rejected';
export const STATUS_DECLINED_RESPONSE_REJECTED = 'declined-response-rejected';
export const STATUS_ACTIVE = 'active';
export const STATUS_CLOSING = 'closing';
export const STATUS_COMPLETED_ACCEPTED = 'completed-accepted';
export const STATUS_COMPLETED_REJECTED = 'completed-rejected';
export const STATUS_COMPLETED = 'completed';
export const STATUS_NEGOTIATION = 'negotiation';
export const STATUS_LEAD = 'lead';
export const STATUS_IRRELEVANT = 'irrelevant';

export const ONLINE = 'online';
export const OFFLINE = 'offline';
export const EXTENDED = 'extended';

export const SPECIFICATION = 'SPECIFICATION';
export const ACT = 'ACT';

export const SCHEDULER_ORDER_WEEKLY = 'WEEKLY';
export const SCHEDULER_ORDER_MONTHLY = 'MONTHLY';
export const SCHEDULER_ORDER_QUARTERLY = 'QUARTERLY';

export const ORDER_TYPES = {
  // [CONTRACTOR_ORDER]: {
  //   icon: UserExtrudedIcon,
  //   title: 'Я заказчик',
  //   description: 'Заказ будет отправлен выбранному исполнителю',
  //   drawerTitle: 'Добавить заказ',
  //   iconColor: themeVariables.color.brand,
  //   color: themeVariables.color['brand-20'],
  //   backgroundColor: themeVariables.color['brand-90']
  // },
  // [CUSTOMER_ORDER]: {
  //   icon: UserExtrudedIcon,
  //   title: 'Я исполнитель',
  //   description: 'Внесите заказ или лид в свою базу данных',
  //   drawerTitle: 'Добавить заказ',
  //   iconColor: themeVariables.color.yellow,
  //   color: themeVariables.color['yellow-10'],
  //   backgroundColor: themeVariables.color['yellow-30']
  // }
};

// V2

export const LEADS = 'LEADS';
export const AGREEMENT = 'AGREEMENT';
export const IN_PROGRESS = 'IN_PROGRESS';
export const REVIEW = 'REVIEW';
export const COMPLETED = 'COMPLETED';
export const CANCELLED = 'CANCELLED';

export const OUTGOING = 'OUTGOING';
export const INCOMING = 'INCOMING';
export const HIRING = 'HIRING';

export const SPEC_KIND_CUSTOM = 'CUSTOM';
export const SPEC_KIND_SYSTEM = 'SYSTEM';

export const ORDER_STATUS_RESPONSIBLE = 'RESPONSIBLE';

export const ACCEPTED_STATE_ACTUAL = 'ACTUAL';
export const ACCEPTED_STATE_AGREEMENT = 'AGREEMENT';
export const ACCEPTED_STATE_DECLINED = 'DECLINED';
export const ACCEPTED_STATE_IRRELEVANT = 'IRRELEVANT';

export const REQUEST_KIND_APPROVING_CANCELING_ORDER =
  'APPROVING_CANCELING_ORDER';
export const REQUEST_KIND_APPROVING_SPECIFICATION = 'APPROVING_SPECIFICATION';
export const REQUEST_KIND_APPROVING_CHANGING_SPECIFICATION =
  'APPROVING_CHANGING_SPECIFICATION';
export const REQUEST_KIND_APPROVING_ACT = 'APPROVING_ACT';

export const REQUEST_KIND_APPROVING_SCHEDULING_SPECIFICATION =
  'APPROVING_SCHEDULING_SPECIFICATION';

export const REQUEST_KIND_APPROVING_CHANGING_SCHEDULING_SPECIFICATION =
  'APPROVING_CHANGING_SCHEDULING_SPECIFICATION';

export const REQUEST_KIND_APPROVING_SCHEDULING_ACT = 'APPROVING_SCHEDULING_ACT';

export const REQUEST_STATE_ACCEPTED = 'ACCEPTED';

export const ORDER_STATUS_STATES = new Map([
  [
    LEADS,
    {
      label: 'NegotiationsOrderStatus',
      ns: 'Statuses',
      color: themeVariables.color['green-10'],
      backgroundColor: themeVariables.color['green-30']
    }
  ],
  [
    AGREEMENT,
    {
      label: 'ApprovalOrderStatus',
      ns: 'Statuses',
      color: themeVariables.color['indigo-dark'],
      backgroundColor: themeVariables.color['indigo-light']
    }
  ],
  [
    IN_PROGRESS,
    {
      label: 'InProgressOrderStatus',
      ns: 'Statuses',
      color: themeVariables.color['yellow-10'],
      backgroundColor: themeVariables.color['yellow-30']
    }
  ],
  [
    REVIEW,
    {
      label: 'CheckOrderStatus',
      ns: 'Statuses',
      color: themeVariables.color['pink-dark'],
      backgroundColor: themeVariables.color['pink-light']
    }
  ],
  [
    COMPLETED,
    {
      label: 'CompletedOrderStatus',
      ns: 'Statuses',
      color: themeVariables.color['danube-10'],
      backgroundColor: themeVariables.color['danube-20']
    }
  ],
  [
    CANCELLED,
    {
      label: 'RejectedOrderStatus',
      ns: 'Statuses',
      color: themeVariables.color['red-30'],
      backgroundColor: themeVariables.color['red-90']
    }
  ]
]);

export const ORDER_STATUS_KINDS = [
  {
    label: 'SalesTab',
    ns: 'Orders',
    value: OUTGOING,
    iconName: 'shopping-card'
  },
  {
    label: 'PurchasesTab',
    ns: 'Orders',
    value: INCOMING,
    iconName: 'box'
  },
  {
    label: 'HiringSpecialists',
    ns: 'Contacts',
    value: HIRING,
    iconName: 'solution'
  }
];

export const TITLE_BY_KIND = {
  [OUTGOING]: {
    label: 'AddOrderHeading',
    ns: 'AddOrder'
  },
  [INCOMING]: {
    label: 'AddPurchaseHeading',
    ns: 'AddOrder'
  },
  [HIRING]: {
    label: 'AddOrderHeading',
    ns: 'AddOrder'
  }
};

export const CREATED_AT = 'CREATED_AT';
export const DEADLINE_AT = 'DEADLINE_AT';

export const ORDER_STATUS_ORDERING_OPTIONS = [
  {
    title: 'CreationDateSorting',
    ns: 'Orders',
    allow: true,
    key: CREATED_AT
  },
  {
    title: 'DeadlineDateSorting',
    ns: 'Orders',
    allow: true,
    key: DEADLINE_AT
  }
];

export const SCHEDULER_OPTIONS = [
  { value: SCHEDULER_ORDER_WEEKLY, label: 'Week', ns: 'OrderConditions' },
  { value: SCHEDULER_ORDER_MONTHLY, label: 'Month', ns: 'OrderConditions' },
  { value: SCHEDULER_ORDER_QUARTERLY, label: 'Quarter', ns: 'OrderConditions' }
];

export const DOCUMENT_TYPES = [
  { value: SPECIFICATION, label: 'ProformaInvoice', ns: 'OrderConditions' },
  { value: ACT, label: 'Act', ns: 'OrderConditions' }
];

export const FUNNEL_TYPE_OPTIONS = [
  { value: 'all', label: 'All', ns: 'Common' },
  { value: OUTGOING, label: 'Order', ns: 'Common' },
  { value: INCOMING, label: 'Purchase', ns: 'Common' }
];
