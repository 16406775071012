import React, { useEffect } from 'react';
import { Page as ReactPdfPage } from 'react-pdf';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useIsInViewport } from 'hooks';

const Page = ({
  key,
  pageNumber,
  inputRef,
  width,
  className,
  setPageNumber
}) => {
  const isInViewport = useIsInViewport(inputRef);

  useEffect(() => {
    if (isInViewport && inputRef.current) {
      setPageNumber(+inputRef.current.dataset.pageNumber);
    }
  }, [inputRef, isInViewport, setPageNumber]);

  return (
    <ReactPdfPage
      renderTextLayer={false}
      className={className}
      key={key}
      pageNumber={pageNumber}
      inputRef={inputRef}
      width={width}
    />
  );
};

export default Page;
