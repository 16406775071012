import React from 'react';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import { ModalsProvider } from 'components/app/modals-provider';

import MainForm from './main-form';
import { useCreatorDrawerContext } from './creator-drawer-context';

import styles from './styles.module.scss';

const RequestCreatorDrawer = ({
  data,
  onClose,
  onSubmit,
  visible,
  initialPage,
  onTasksCreated,
  withShowDetails,
  ...props
}) => {
  const { t } = useTranslation('Requests');

  const {
    handleSubmit,
    currentTaskType,
    values,
    isBacklog,
    isSprints,
    isLoading
  } = useCreatorDrawerContext({
    value: data,
    onClose,
    onSubmit,
    visible,
    initialPage,
    onTasksCreated,
    withShowDetails,
    ...props
  });

  return (
    <ModalsProvider>
      <Drawer
        title={<Drawer.Title>{t('AddRequest')}</Drawer.Title>}
        bodyStyle={{ padding: 0 }}
        className={styles.root}
        open={visible}
        maskClosable={false}
        onClose={onClose}
        width={620}
        destroyOnClose
      >
        <MainForm
          onSubmit={handleSubmit}
          currentTaskType={currentTaskType}
          defaultValues={values}
          isLoading={isLoading}
          isBacklog={isBacklog}
          isSprints={isSprints}
        />
      </Drawer>
    </ModalsProvider>
  );
};

export default RequestCreatorDrawer;
