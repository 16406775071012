import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';

import {
  STATUS_CANCELLED,
  STATUS_DONE,
  TASK_FIELD_DESCRIPTION,
  TYPE_ACQUAINTANCE,
  TYPE_AGREEMENT,
  TYPE_MEETING,
  TYPE_TASK
} from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';
import { renderDescriptions } from 'components/common/comments/converters';
import {
  checkAllowEditTask,
  getTaskDescriptionText
} from 'components/tasks-view/utils';

import { fetchTaskLocal } from 'store/tasks';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { FieldEditorItem } from 'providers/field-editor';

import styles from './description-info.module.scss';

const DescriptionInfo = ({ task, transformedTask }) => {
  const { t } = useTranslation('Task');
  const amplitude = useAmplitude();

  const dispatch = useDispatch();
  const [parentTask, setParentTask] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isCompleted =
    task.status === STATUS_DONE || task.status === STATUS_CANCELLED;

  const hasDescription =
    task.description && task.description[0] && task.description[0].text;

  const isTypeAcquaintence = task.kind === TYPE_ACQUAINTANCE;
  const isTypeAgreement = task.kind === TYPE_AGREEMENT;
  const isTypeMeeting = task.kind === TYPE_MEETING;

  const isTopTask = !(task || {}).parent;

  const isChildMeetingTask =
    isTypeMeeting && !isTopTask && parentTask && parentTask.kind !== TYPE_TASK;

  const allowEditTask = checkAllowEditTask({ task, parentTask });

  const isExcludedType =
    isTypeAcquaintence ||
    isTypeAgreement ||
    isChildMeetingTask ||
    isCompleted ||
    !allowEditTask;

  const fetchParentTask = async () => {
    setIsLoading(true);
    const fetchedParentTask = await dispatch(
      fetchTaskLocal({ id: task.parent })
    );
    setParentTask(fetchedParentTask);
    setIsLoading(false);
  };

  useEffect(() => {
    if (task.parent) {
      fetchParentTask();
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.id]);

  const handleDoubleClick = () => {
    if (!hasDescription) {
      amplitude.logAddTaskDescriptionEvent('double_click_on_details');
    }
  };

  if (isLoading) {
    return <Spin size="small" />;
  }

  if (!hasDescription && isExcludedType) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div onDoubleClick={handleDoubleClick}>
        <FieldEditorItem
          field={TASK_FIELD_DESCRIPTION}
          className={styles.item}
          defaultValues={{
            [TASK_FIELD_DESCRIPTION]: {
              description: getTaskDescriptionText(task.description),
              fileList: transformedTask.fileList
            }
          }}
        >
          {hasDescription ? (
            <DetailsField
              className={styles.detailsField}
              iconType="description"
              title={t('Description')}
            >
              <Typography.Paragraph style={{ margin: 0 }}>
                {renderDescriptions(task.description)}
              </Typography.Paragraph>
            </DetailsField>
          ) : (
            !isTypeAcquaintence &&
            !isTypeAgreement && (
              <DetailsField iconType="description" title={t('Description')}>
                <Typography.Text color="black-55">
                  {t('DescriptionPlaceholder')}
                </Typography.Text>
              </DetailsField>
            )
          )}
        </FieldEditorItem>
      </div>
    </div>
  );
};

DescriptionInfo.propTypes = {
  task: PropTypes.shape({
    description: PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string
      })
    )
  }),
  transformedTask: PropTypes.shape({
    fileList: PropTypes.array
  })
};

DescriptionInfo.defaultProps = {
  task: {},
  transformedTask: {}
};

export default DescriptionInfo;
