import React from 'react';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import MeetingCalendar from 'components/tasks-view/components/meeting-calendar';
import { ModalsProvider } from 'components/app/modals-provider';

import {
  CreatorDrawerProvider,
  useCreatorDrawerContext
} from './creator-drawer-context';
import StepsProvider from './steps-provider';

import styles from './styles.module.scss';

const TaskCreatorDrawer = ({ onClose, visible, ...props }) => {
  const { isFromTemplate, resetForm, isFirstPage, value, visibleCalender } =
    useCreatorDrawerContext();

  const { t } = useTranslation(['AddTask', 'AddSubtask']);

  const getTitle = () => {
    if (value.parent)
      return `${t('AddSubtaskHeading', { ns: 'AddSubtask' })} ${value.parent}`;
    if (isFromTemplate) return t('AddTaskTemplateHeading');

    return t('AddTaskHeading');
  };

  const drawerTitle = (
    <>
      {!isFirstPage && (
        <Drawer.Back onClick={resetForm} data-qa="qa-x2jzmkjyc3bzwbn" />
      )}

      <Drawer.Title>{getTitle()}</Drawer.Title>
    </>
  );

  return (
    <ModalsProvider>
      <Drawer
        title={drawerTitle}
        bodyStyle={{ padding: 0 }}
        className={styles.root}
        data-qa="qa-799665845033601"
        open={visible}
        maskClosable={false}
        onClose={onClose}
        width={620}
        destroyOnClose
      >
        <MeetingCalendar visible={visibleCalender}>
          <StepsProvider visible={visible} {...props} />
        </MeetingCalendar>
      </Drawer>
    </ModalsProvider>
  );
};

export default ({
  value,
  ideaId,
  onClose,
  onSubmit,
  visible,
  initialPage,
  onTasksCreated,
  withShowDetails,
  ...props
}) => (
  <CreatorDrawerProvider
    visible={visible}
    value={value}
    ideaId={ideaId}
    onSubmit={onSubmit}
    onClose={onClose}
    initialPage={initialPage}
    onTasksCreated={onTasksCreated}
    withShowDetails={withShowDetails}
  >
    <TaskCreatorDrawer onClose={onClose} visible={visible} {...props} />
  </CreatorDrawerProvider>
);
