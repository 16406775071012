import { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import { SELECTED_REQUEST, REQUESTS } from 'constants/index';

import {
  getIsTasksLoading,
  getIsTasksLoaded,
  getTaskFactory,
  fetchRequest
} from 'store/requests';
import { getRouterUrlView, getRouterUrlSubmenu } from 'store/router';
import { getWorkspaceId } from 'store/workspace';

import useQueryParam from '../common/use-query-param';

export const useRequestDataProvider = (isActive = false, taskId) => {
  const dispatch = useDispatch();

  const [isCurrentLoading, setIsCurrentLoading] = useState(false);
  const [errorStatus, setErrorStatus] = useState(null);

  const isAllLoading = useSelector(getIsTasksLoading, shallowEqual);
  let isAllLoaded = useSelector(getIsTasksLoaded, shallowEqual);
  const view = useSelector(getRouterUrlView);
  const subView = useSelector(getRouterUrlSubmenu);

  const workspaceId = useSelector(getWorkspaceId);

  const isTasksPage =
    view === REQUESTS &&
    (subView.includes('all') ||
      subView.includes('progress') ||
      subView.includes('backlog'));

  if (!isTasksPage) isAllLoaded = true;

  const statusId = Number(useQueryParam(SELECTED_REQUEST)) || taskId;

  const task = useSelector(
    state => getTaskFactory(state)(statusId),
    shallowEqual
  );

  const isLoading = isAllLoading || isCurrentLoading || !(task || {}).isLoaded;

  const onFetchTask = async () => {
    if (!workspaceId) {
      return;
    }

    try {
      setIsCurrentLoading(true);
      setErrorStatus(null);

      await dispatch(fetchRequest({ id: statusId }));
    } catch ({ response }) {
      setErrorStatus(response.status);
    } finally {
      setIsCurrentLoading(false);
    }
  };

  useEffect(() => {
    if (
      !isAllLoading &&
      !(task || {}).isLoaded &&
      isAllLoaded &&
      isActive &&
      !errorStatus &&
      !Number.isNaN(statusId) &&
      statusId
    ) {
      onFetchTask();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusId, isAllLoading, errorStatus, workspaceId, task, isActive]);

  useEffect(() => {
    setErrorStatus(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statusId]);

  return [task, isLoading, errorStatus];
};

export default useRequestDataProvider;
