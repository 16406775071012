import React, { useEffect, useState, useCallback } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Button, Typography } from 'antd';
import { Form } from '@ant-design/compatible';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SPRINT, SPRINT_PLANNED, SPRINT_ACTIVE } from 'constants/index';

import SprintSelect from 'components/common/controls/custom-select/sprint-select';
import Modal from 'components/common/modal';
import { useTheme } from 'components/common/theme';

import {
  selectSprint,
  fetchSprintsLocal,
  getProjectFactory
} from 'store/projects';
import {
  setBacklogFilterSprintIsNull,
  getBacklogFilterSprintIsNull,
  getBacklogFilterProject,
  addTasksToSprint
} from 'store/requests';

import useRoutesService from 'services/routes';

import styles from './styles.module.scss';

const { Title, Text } = Typography;

const SprintSelectModal = Form.create({})(({
  visible,
  data,
  onClose,
  setCreatingSprint,
  form
}) => {
  const { variables } = useTheme();

  const { t } = useTranslation(['BringTasksToWork', 'Sprints', 'Common']);

  const dispatch = useDispatch();

  const routes = useRoutesService();

  const [hasActiveSprints, setHasActiveSprints] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  const project = useSelector(getBacklogFilterProject, shallowEqual);

  const currentProject =
    useSelector(
      state =>
        getProjectFactory(state)(data.projectId || (project || {}).value),
      shallowEqual
    ) || {};

  const sprintIsNull = useSelector(getBacklogFilterSprintIsNull, shallowEqual);

  const { getFieldDecorator, getFieldValue } = form;

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const { count } = await dispatch(
        fetchSprintsLocal({
          status: [SPRINT_ACTIVE, SPRINT_PLANNED],
          project: data.projectId || (project || {}).value
        })
      );
      setHasActiveSprints(!!count);
    } finally {
      setIsLoading(false);
    }
  }, [data.projectId, dispatch, project]);

  useEffect(() => {
    if (visible && !isLoading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentProject.sprintsCount, fetchData, visible]);

  const onCreatingSprint = () => {
    onClose();
    setCreatingSprint(true);
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (sprintIsNull) dispatch(setBacklogFilterSprintIsNull());

    const sprint = (getFieldValue(SPRINT) || {}).label;

    if (data.taskId) {
      try {
        setIsSubmitLoading(true);

        await dispatch(
          addTasksToSprint({
            id: sprint.id,
            tasks: [data.taskId]
          })
        );

        dispatch(setBacklogFilterSprintIsNull(true));
      } finally {
        setIsSubmitLoading(false);
      }

      return onClose();
    }

    dispatch(
      selectSprint({ project: data.projectId || project.value, sprint })
    );
    dispatch(setBacklogFilterSprintIsNull(true));

    onClose();

    return routes.toBacklog(data.projectId || project.value);
  };

  const onCancel = () => {
    if (sprintIsNull) dispatch(setBacklogFilterSprintIsNull());
    onClose();
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      destroyOnClose
      onCancel={onCancel}
      className={styles.root}
      footer={null}
      title={
        <Title className={styles.title}>{t('BringTasksToWorkHeading')}</Title>
      }
      width={538}
    >
      <Form
        className={styles.root}
        onSubmit={onSubmit}
        hideRequiredMark
        data-qa="qa-rt8g0vzbz6ip8wj"
      >
        <div className={styles.fieldsWrap} data-qa="qa-27p15lw50it40sf">
          <Text className={styles.description} data-qa="qa-3x2820fu0buzkvy">
            {t('BringTasksToWorkDesc')}
          </Text>
          <div className={styles.field} data-qa="qa-weo90ll3uhyvz66">
            <Form.Item label={t('SprintName')} autoComplete="off" colon={false}>
              {getFieldDecorator(SPRINT)(
                <SprintSelect
                  isSearchable={false}
                  valueText={t('ChooseSprintPlchldr')}
                  rootClassName={styles.filterRoot}
                  popoverOverlayClassName={styles.popover}
                  data-qa="qa-uz0idhuvnczlkgl"
                  notFoundContent={
                    <Text
                      style={{
                        color: variables.color['red-60'],
                        display: 'flex'
                      }}
                    >
                      {t('NoSprintsAdded')}
                    </Text>
                  }
                  params={{
                    project: data.projectId || (project || {}).value,
                    status: [SPRINT_ACTIVE, SPRINT_PLANNED],
                    isLag: false
                  }}
                />
              )}
            </Form.Item>
          </div>
        </div>

        <div className={styles.btns} data-qa="qa-91cej1imbp47lvs">
          {!hasActiveSprints && (
            <Button
              className={styles.addSprint}
              data-qa="qa-h230ofyin1v3ivs"
              htmlType="button"
              type="link"
              loading={isLoading}
              onClick={onCreatingSprint}
            >
              {t('AddSprintBtn', { ns: 'Sprints' })}
            </Button>
          )}

          <Button
            className={classnames(styles.btnSubmit)}
            data-qa="qa-tb9yu91u478880y"
            htmlType="submit"
            type="primary"
            loading={isSubmitLoading}
            disabled={!getFieldValue(SPRINT)}
          >
            {t('Send', { ns: 'Common' })}
          </Button>
        </div>
      </Form>
    </Modal>
  );
});

SprintSelectModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({
    taskId: PropTypes.number
  }),
  onClose: PropTypes.func,
  setCreatingSprint: PropTypes.func
};

SprintSelectModal.defaultProps = {
  visible: false,
  data: {},
  onClose: () => {},
  setCreatingSprint: () => {}
};

export default SprintSelectModal;
