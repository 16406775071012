export const mergeRefs =
  (...refs) =>
  node => {
    for (const ref of refs) {
      if (typeof ref === 'function') {
        ref(node);
      } else if (ref && typeof ref === 'object' && 'current' in ref) {
        ref.current = node;
      }
    }
  };
