import React, { Fragment, useEffect } from 'react';
import moment from 'moment';

import useAmplitude from 'hooks/amplitude/use-amplitude';
import { useLocalStorage } from 'hooks';

const MINUTES_TO_CHECK = 30;

export const AmplitudeLoginProvider = ({ children }) => {
  const amplitude = useAmplitude();

  const [lastActivityDate, setLastActivityDate] = useLocalStorage(
    'lastActivityDate',
    undefined
  );

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      setLastActivityDate(new Date());
    });

    const nowDate = moment(new Date());

    if (
      !lastActivityDate ||
      moment.duration(nowDate.diff(lastActivityDate)).asMinutes() >
        MINUTES_TO_CHECK
    ) {
      amplitude.loginEvent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default AmplitudeLoginProvider;
