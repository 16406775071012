import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Typography from 'components/common/typography';

import { useUpserviceWidgetContext } from 'providers';

import Modal from '../modal';
import Markdown from '../markdown';
import VideoCollapse from './video-collapse';

import styles from './video-modal.module.scss';

const { Title, Text } = Typography;

export const VideoModal = ({
  data,
  visible,
  onClose,
  withoutComment,
  ...props
}) => {
  const { t } = useTranslation(['Support', 'Onboarding']);

  const { openWidget } = useUpserviceWidgetContext();

  const onShowSupport = () => {
    openWidget();

    onClose();
  };

  return (
    <>
      <Modal
        open={visible}
        width={640}
        destroyOnClose
        contentStyle={{ padding: '39px 24px 32px' }}
        onClose={onClose}
        {...props}
      >
        {data.videos ? (
          <>
            <Title level={1} weight="bold" className={styles.title}>
              {data.title}
            </Title>

            <VideoCollapse onShowSupport={onShowSupport} data={data.videos} />
          </>
        ) : (
          <>
            <Title
              level={1}
              weight="bold"
              className={classnames(styles.title, styles.cardTitle)}
            >
              {data.title}
            </Title>

            <Title
              level={3}
              weight="regular"
              color="black-35"
              className={classnames(styles.title, styles.subtitle)}
            >
              {t('VideoModalDesc')}
            </Title>

            <Markdown
              santizeProps={{ ADD_TAGS: ['iframe'] }}
              className={styles.content}
            >
              {data.content || ''}
            </Markdown>

            {!withoutComment && (
              <div className={styles.comment}>
                <Text size="large" color="black-35">
                  {t('HaveQuestion', { ns: 'Onboarding' })}{' '}
                </Text>

                <Button
                  type="link"
                  className={styles.button}
                  onClick={onShowSupport}
                >
                  {t('AskHere', { ns: 'Onboarding' })}
                </Button>
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  );
};

VideoModal.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.string
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  withoutComment: PropTypes.bool
};

VideoModal.defaultProps = {
  data: {},
  visible: false,
  onClose: () => {},
  withoutComment: false
};

export default VideoModal;
