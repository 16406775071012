import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import ActionsDropdown from 'components/common/actions';

import { archiveDiscussion } from 'store/discussions';

import { useDiscussionCreateTask } from 'hooks';

import styles from './discussion-actions.module.scss';

const DiscussionActions = ({ discussionId, sender, fileList }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const showTaskCreator = useDiscussionCreateTask({
    discussionId,
    sender,
    fileList
  });

  const onArchiveDiscussion = async () => {
    setIsLoading(true);

    try {
      await dispatch(
        archiveDiscussion({
          id: discussionId
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  const controlActions = useMemo(
    () => [
      {
        title: 'AddTaskAction',
        ns: 'Dashboard',
        onClick: showTaskCreator,
        allow: true
      },
      {
        title: 'ArchiveChat',
        ns: 'Dashboard',
        onClick: onArchiveDiscussion,
        allow: true,
        className: styles.archiveAction
      }
    ],
    [onArchiveDiscussion, showTaskCreator]
  );

  return (
    <div className={styles.root} onClick={e => e.stopPropagation()}>
      <ActionsDropdown actions={controlActions} isLoading={isLoading} />
    </div>
  );
};

DiscussionActions.propTypes = {
  discussionId: PropTypes.number.isRequired,
  fileList: PropTypes.array.isRequired,
  sender: PropTypes.shape({
    fullName: PropTypes.string,
    avatarUrl: PropTypes.string,
    contactId: PropTypes.string,
    employee: PropTypes.string,
    username: PropTypes.string
  }).isRequired
};

export default DiscussionActions;
