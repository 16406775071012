import React from 'react';

export const GooglePresentation = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_104334_34038)">
      <mask
        id="mask0_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_104334_34038)">
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L22.4547 5.84247L18.8688 0.822266Z"
          fill="#F4B400"
        />
      </g>
      <mask
        id="mask1_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_104334_34038)">
        <path
          d="M20.6627 15.166H11.3394C10.7478 15.166 10.2637 15.6501 10.2637 16.2418V25.565C10.2637 26.1567 10.7478 26.6408 11.3394 26.6408H20.6627C21.2543 26.6408 21.7384 26.1567 21.7384 25.565V16.2418C21.7384 15.6501 21.2543 15.166 20.6627 15.166ZM20.3041 23.4135H11.698V18.3933H20.3041V23.4135Z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="mask2_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_104334_34038)">
        <path
          d="M19.498 8.79883L27.4748 16.7738V9.42815L19.498 8.79883Z"
          fill="url(#paint0_linear_104334_34038)"
        />
      </g>
      <mask
        id="mask3_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_104334_34038)">
        <path
          d="M18.8691 0.822266V7.27681C18.8691 8.46552 19.8319 9.42833 21.0207 9.42833H27.4752L18.8691 0.822266Z"
          fill="#FADA80"
        />
      </g>
      <mask
        id="mask4_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_104334_34038)">
        <path
          d="M18.8691 0.822266V1.00156L27.2959 9.42833H27.4752L18.8691 0.822266Z"
          fill="white"
          fillOpacity="0.1"
        />
      </g>
      <mask
        id="mask5_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_104334_34038)">
        <path
          d="M6.67691 0.822266C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V3.15307C4.52539 1.96974 5.49357 1.00156 6.67691 1.00156H18.8688V0.822266H6.67691Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask6_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_104334_34038)">
        <path
          d="M25.3234 32.1984H6.67691C5.49357 32.1984 4.52539 31.2302 4.52539 30.0469V30.2262C4.52539 31.4095 5.49357 32.3777 6.67691 32.3777H25.3234C26.5067 32.3777 27.4749 31.4095 27.4749 30.2262V30.0469C27.4749 31.2302 26.5067 32.1984 25.3234 32.1984Z"
          fill="#BF360C"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask7_104334_34038"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask7_104334_34038)">
        <path
          d="M21.0207 9.42886C19.8319 9.42886 18.8691 8.46606 18.8691 7.27734V7.45664C18.8691 8.64535 19.8319 9.60815 21.0207 9.60815H27.4752V9.42886H21.0207Z"
          fill="#BF360C"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M18.8688 0.822266H6.67691C5.49357 0.822266 4.52539 1.79045 4.52539 2.97378V30.2263C4.52539 31.4096 5.49357 32.3778 6.67691 32.3778H25.3234C26.5067 32.3778 27.4749 31.4096 27.4749 30.2263V9.42833L18.8688 0.822266Z"
        fill="url(#paint1_radial_104334_34038)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_104334_34038"
        x1="418.378"
        y1="77.2726"
        x2="418.378"
        y2="806.405"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#BF360C" stopOpacity="0.2" />
        <stop offset="1" stopColor="#BF360C" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_104334_34038"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(77.2305 63.1899) scale(3700.57 3700.57)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_104334_34038">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0 0.822266)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GooglePresentation;
