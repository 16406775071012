import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import ImportProcessModal from 'components/contacts-view/import-contacts/modals/import-process';

import { fetchStatusDuplicateContacts, importContacts } from 'store/contacts';

import { socket } from 'socket';

import MainForm from './form';

export const ContactsImportDrawer = ({ onClose, data, ...props }) => {
  const dispatch = useDispatch();

  const { setVisibleContactsImportModal, setIsFileBeingChecked } = data;

  const [isLoading, setIsLoading] = useState(false);
  const [visibleImportProcessModal, setVisibleImportProcessModal] =
    useState(false);

  const { t } = useTranslation('ContactImport');

  const onSubmit = async values => {
    setIsLoading(true);

    try {
      await dispatch(
        importContacts({
          values: {
            responsiblePerson: values.employee.value,
            fileId: data.fileId,
            fileType: data.fileType
          }
        })
      );

      onClose();

      setVisibleImportProcessModal(true);
      setIsFileBeingChecked(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    socket.on('new-user-notification', ({ templateUid }) => {
      if (+templateUid === 442) {
        setVisibleImportProcessModal(false);

        dispatch(fetchStatusDuplicateContacts());
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { visible, ...restProps } = props;

  return (
    <>
      <Drawer
        title={
          <>
            <Drawer.Back
              onClick={() => {
                setVisibleContactsImportModal(true);
                onClose();
              }}
            />
            <Drawer.Title>{t('ContactImportHeading')}</Drawer.Title>
          </>
        }
        width={960}
        onClose={() => {
          setIsFileBeingChecked(false);

          onClose();
        }}
        destroyOnClose
        open={visible}
        {...restProps}
      >
        <MainForm data={data} onSubmit={onSubmit} isLoading={isLoading} />
      </Drawer>

      <ImportProcessModal
        visible={visibleImportProcessModal}
        onClose={() => setVisibleImportProcessModal(false)}
      />
    </>
  );
};

ContactsImportDrawer.propTypes = {
  onClose: PropTypes.func,
  data: PropTypes.shape({
    dataSource: PropTypes.array,
    columns: PropTypes.array,
    setVisibleContactsImportModal: PropTypes.func,
    fileId: PropTypes.string,
    fileType: PropTypes.string,
    setIsFileBeingChecked: PropTypes.func
  })
};

ContactsImportDrawer.defaultProps = {
  onClose: () => {},
  data: {
    dataSource: [],
    columns: [],
    setVisibleContactsImportModal: () => {},
    fileId: null,
    fileType: null,
    setIsFileBeingChecked: () => {}
  }
};

export default ContactsImportDrawer;
