import React from 'react';
import { Button } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';

import styles from './relations-bar.module.scss';

const RelationsBar = ({ buttons, className }) => {
  const { t } = useTranslation(['Task', 'TaskLinks']);

  const { relations, members, uploads } = buttons;

  return (
    <div
      className={classnames(styles.root, className)}
      data-qa="qa-sf9beijg34qjf7t"
    >
      {members.allow && (
        <Button
          onClick={members.click}
          className={styles.btn}
          data-qa="qa-zscjyemed4v5uza"
        >
          <Icon type="big-team" size={16} />
          <span>{t('AccessManagement', { ns: 'TaskLinks' })}</span>
        </Button>
      )}

      {uploads.allow && (
        <Button
          onClick={uploads.click}
          className={styles.btn}
          data-qa="qa-mdrp8juvq75comm"
        >
          <Icon size={16} type="paper-clip" data-qa="qa-6oths3nzdeddlt5" />
          <span>{t('AttachmentsBtn')}</span>
        </Button>
      )}

      {relations.allow && (
        <Button
          onClick={relations.click}
          className={styles.btn}
          data-qa="qa-lc7285ro83emg4g"
        >
          <Icon type="link" size={16} data-qa="qa-xd9uc1sj12t7w71" />
          <span>{t('LinksBtn')}</span>
        </Button>
      )}
    </div>
  );
};

RelationsBar.propTypes = {
  buttons: PropTypes.shape({
    relations: PropTypes.shape({
      allow: PropTypes.bool,
      click: PropTypes.func
    }),
    members: PropTypes.shape({
      allow: PropTypes.bool,
      click: PropTypes.func
    }),
    uploads: PropTypes.shape({
      allow: PropTypes.bool,
      click: PropTypes.func
    })
  }).isRequired,
  className: PropTypes.string
};

RelationsBar.defaultProps = {
  className: undefined
};

export default RelationsBar;
