import qs from 'qs';

import { makeCancelableRequest } from 'utils/make-cancalable-request';

export default api => ({
  fetch: makeCancelableRequest(
    api,
    (
      fetch,
      {
        workspaceId,
        ordering,
        search,
        limit,
        offset,
        isTrash,
        createdAt = [],
        creators,
        departments,
        relatedProjects,
        relatedContacts,
        relatedIdeas,
        relatedTasks,
        relatedRequests,
        relatedAsset,
        tagsCondition,
        tagsIds,
        relatedOrderStatuses,
        fileIds,
        fileExtension,
        isMySubscriptions,
        ignoreCache,
        validityDate = []
      }
    ) =>
      fetch('get', `/v1/${workspaceId}/attachments/`, {
        params: {
          search,
          ordering,
          limit,
          offset,
          is_trash: isTrash,
          created_at_gte: createdAt[0],
          created_at_lte: createdAt[1],
          creators,
          departments,
          related_projects: relatedProjects,
          related_contacts: relatedContacts,
          related_ideas: relatedIdeas,
          related_tasks: relatedTasks,
          related_requests: relatedRequests,
          related_assets: relatedAsset,
          tags_condition: tagsCondition,
          tags_ids: tagsIds,
          related_order_statuses: relatedOrderStatuses,
          file_ids: fileIds,
          file_extension: fileExtension,
          is_subscribed: isMySubscriptions,
          ignore_cache: ignoreCache,
          validity_date_gte: validityDate[0],
          validity_date_lte: validityDate[1]
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchOne: ({ workspaceId, id }) =>
    api.get(`/v1/${workspaceId}/attachments/${id}/`).then(({ data }) => data),

  password: workspaceId =>
    api
      .get(`/v1/${workspaceId}/attachments/password/`)
      .then(({ data }) => data),

  getUrl: ({ workspaceId, fileId, entityType, entityId }) =>
    api
      .get(`/v1/${workspaceId}/attachments/${fileId}/url/`, {
        params: { entity_type: entityType, entity_id: entityId }
      })
      .then(({ data }) => data),

  getUrls: ({ workspaceId, filesIds, entityType, entityId }) =>
    api
      .post(`/v1/${workspaceId}/attachments/urls/`, {
        filesIds,
        entityType,
        entityId
      })
      .then(({ data }) => data),

  saveToDisk: ({ workspaceId, fileId, entityType, entityId }) =>
    api
      .post(`/v1/${workspaceId}/attachments/${fileId}/save/`, null, {
        params: { entity_type: entityType, entity_id: entityId }
      })
      .then(({ data }) => data),

  copy: ({
    workspaceId,
    fileId,
    entityType,
    entityId,
    isComment,
    isPrivate,
    fileName
  }) =>
    api
      .post(`/v1/${workspaceId}/attachments/${fileId}/copy/`, {
        entityType,
        entityId,
        inComment: isComment,
        isPrivate,
        filename: fileName
      })
      .then(({ data }) => data),

  toTrash: ({ workspaceId, fileId }) =>
    api.put(`/v1/${workspaceId}/attachments/${fileId}/to-trash/`),

  view: ({ workspaceId, fileId, entityType, entityId }) =>
    api
      .get(`/v1/${workspaceId}/attachments/${fileId}/view/`, {
        params: { entity_type: entityType, entity_id: entityId }
      })
      .then(({ data }) => data),

  checkSingleRelation: ({ workspaceId, fileId }) =>
    api
      .get(`/v1/${workspaceId}/attachments/${fileId}/check-modal/`)
      .then(({ data }) => data),

  deleteFromEntity: ({
    workspaceId,
    fileId,
    entityType,
    entityId,
    messageUuid
  }) =>
    api.put(`/v1/${workspaceId}/attachments/${fileId}/delete-from/`, {
      entityType,
      entityId,
      messageId: messageUuid
    }),

  delete: ({ workspaceId, fileId }) =>
    api.delete(`/v1/${workspaceId}/attachments/${fileId}/`),

  restore: ({ workspaceId, fileId }) =>
    api.put(`/v1/${workspaceId}/attachments/${fileId}/restore/`),

  rename: ({ workspaceId, fileId, name }) =>
    api.put(`/v1/${workspaceId}/attachments/${fileId}/rename/`, {
      name
    }),

  // Versions

  fetchVersions: ({ workspaceId, fileId }) =>
    api.get(`/v1/${workspaceId}/attachments/${fileId}/versions/`),

  viewVersion: ({ workspaceId, fileId, versionId }) =>
    api
      .get(
        `/v1/${workspaceId}/attachments/${fileId}/versions/${versionId}/view/`
      )
      .then(({ data }) => data),

  getUrlVersion: ({ workspaceId, fileId, versionId }) =>
    api
      .get(
        `/v1/${workspaceId}/attachments/${fileId}/versions/${versionId}/url/`
      )
      .then(({ data }) => data),

  deleteVersion: ({ workspaceId, fileId, versionId }) =>
    api.delete(
      `/v1/${workspaceId}/attachments/${fileId}/versions/${versionId}/`
    ),

  restoreVersion: ({ workspaceId, fileId, versionId }) =>
    api
      .put(
        `/v1/${workspaceId}/attachments/${fileId}/versions/${versionId}/set-actual/`
      )
      .then(({ data }) => data),

  fetchAccessEmployees: ({ workspaceId, fileId, params }) =>
    api.get(`/v1/${workspaceId}/attachments/${fileId}/attachments-access/`, {
      params
    }),

  shareAccess: ({ workspaceId, fileId, employee }) =>
    api.post(`/v1/${workspaceId}/attachments/${fileId}/attachments-access/`, {
      role: employee.role,
      employee: employee.id
    }),

  editAccess: ({ workspaceId, fileId, employeeId, role }) =>
    api.patch(
      `/v1/${workspaceId}/attachments/${fileId}/attachments-access/${employeeId}/`,
      {
        oldRole: role.old,
        newRole: role.new
      }
    ),

  deleteAccess: ({ workspaceId, fileId, employeeId }) =>
    api.delete(
      `/v1/${workspaceId}/attachments/${fileId}/attachments-access/${employeeId}/`
    ),

  fetchAccessContacts: ({ workspaceId, fileId, params }) =>
    api.get(
      `/v1/${workspaceId}/attachments/${fileId}/contact-attachments-access/`,
      {
        params
      }
    ),

  shareAccessContact: ({ workspaceId, fileId, contact }) =>
    api.post(
      `/v1/${workspaceId}/attachments/${fileId}/contact-attachments-access/`,
      {
        role: contact.role,
        contact: contact.id,
        to: contact.to
      }
    ),

  editAccessContact: ({ workspaceId, fileId, contactId, role, to }) =>
    api.patch(
      `/v1/${workspaceId}/attachments/${fileId}/contact-attachments-access/${contactId}/`,
      {
        role: role.new,
        to
      }
    ),

  deleteAccessContact: ({ workspaceId, fileId, contactId }) =>
    api.delete(
      `/v1/${workspaceId}/attachments/${fileId}/contact-attachments-access/${contactId}/`
    ),

  changeValidityDate: ({ workspaceId, attachmentId, validityDate }) =>
    api.post(`v1/${workspaceId}/attachments/${attachmentId}/validity-date/`, {
      validityDate
    }),

  fetchChatFilesInfo: ({ workspaceId, entityIds }) =>
    api.post(`v1/${workspaceId}/attachments/chat-files-info/`, {
      entity_ids: entityIds
    }),

  changeBulkValidityDates: ({ workspaceId, data }) =>
    api.post(`v1/${workspaceId}/attachments/validity-date-bulk/`, data)
});
