import { useReducer } from 'react';

import { initialFilter } from 'store/attachments/reducer';

const actionTypes = {
  setSearch: 'SEARCH',
  setCompany: 'COMPANY',
  setOrders: 'ORDERS',
  setProjects: 'PROJECTS',
  setTasks: 'TASKS',
  setRequests: 'REQUESTS',
  setIdeas: 'IDEAS',
  setContacts: 'CONTACTS',
  clear: 'CLEAR',
  setSorting: 'SORTING',
  setCurrentPage: 'PAGE',
  setTag: 'TAG',
  setDepartments: 'DEPARTMENTS',
  setCreators: 'CREATORS',
  setCreatedAt: 'CREATED',
  setAsset: 'ASSET',
  setChannelChat: 'CHANNEL_CHAT',
  setFileType: 'FILE_TYPE',
  setMySubscriptions: 'MY_SUBSCRIPTIONS',
  setValidityDate: 'VALIDITY_DATE'
};

const initialState = {
  ...initialFilter,

  currentPage: 1,
  departments: [],
  creators: [],
  createdAt: {},
  validityDate: {}
};

const filtersReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.setSearch: {
      return { ...state, currentPage: 1, search: payload };
    }

    case actionTypes.setCompany: {
      return { ...state, currentPage: 1, contacts: [], company: payload };
    }

    case actionTypes.setOrders: {
      return { ...state, currentPage: 1, orders: payload };
    }

    case actionTypes.setProjects: {
      return { ...state, currentPage: 1, projects: payload };
    }

    case actionTypes.setTasks: {
      return { ...state, currentPage: 1, tasks: payload };
    }

    case actionTypes.setRequests: {
      return { ...state, currentPage: 1, requests: payload };
    }

    case actionTypes.setAsset: {
      return { ...state, currentPage: 1, asset: payload };
    }

    case actionTypes.setIdeas: {
      return { ...state, currentPage: 1, ideas: payload };
    }

    case actionTypes.setContacts: {
      return { ...state, currentPage: 1, contacts: payload };
    }

    case actionTypes.setDepartments: {
      return { ...state, currentPage: 1, departments: payload };
    }

    case actionTypes.setCreators: {
      return { ...state, currentPage: 1, creators: payload };
    }

    case actionTypes.setCreatedAt: {
      return { ...state, currentPage: 1, createdAt: payload };
    }

    case actionTypes.setTag: {
      return { ...state, currentPage: 1, tag: payload };
    }

    case actionTypes.setChannelChat: {
      return { ...state, currentPage: 1, channelChat: payload };
    }

    case actionTypes.clear: {
      return { ...state, ...initialFilter };
    }

    case actionTypes.setSorting: {
      return { ...state, sorting: payload };
    }

    case actionTypes.setCurrentPage: {
      return { ...state, currentPage: payload };
    }

    case actionTypes.setFileType: {
      return { ...state, currentPage: 1, fileType: payload };
    }

    case actionTypes.setMySubscriptions: {
      return { ...state, currentPage: 1, isMySubscriptions: payload };
    }

    case actionTypes.setValidityDate: {
      return { ...state, currentPage: 1, validityDate: payload };
    }

    default: {
      throw new Error(type);
    }
  }
};

export const useFilters = ({ reducer = filtersReducer } = {}) => {
  const [filters, dispatch] = useReducer(reducer, initialState);

  const setSearch = payload =>
    dispatch({ type: actionTypes.setSearch, payload });
  const setOrders = payload =>
    dispatch({ type: actionTypes.setOrders, payload });
  const setProjects = payload =>
    dispatch({ type: actionTypes.setProjects, payload });
  const setTasks = payload => dispatch({ type: actionTypes.setTasks, payload });
  const setRequests = payload =>
    dispatch({ type: actionTypes.setRequests, payload });
  const setAsset = payload => dispatch({ type: actionTypes.setAsset, payload });
  const setContacts = payload =>
    dispatch({ type: actionTypes.setContacts, payload });
  const setCreators = payload =>
    dispatch({ type: actionTypes.setCreators, payload });
  const setCreatedAt = payload =>
    dispatch({ type: actionTypes.setCreatedAt, payload });
  const setTag = payload => dispatch({ type: actionTypes.setTag, payload });
  const setChannelChat = payload =>
    dispatch({ type: actionTypes.setChannelChat, payload });
  const setSorting = payload =>
    dispatch({ type: actionTypes.setSorting, payload });
  const setCurrentPage = payload =>
    dispatch({ type: actionTypes.setCurrentPage, payload });
  const setFileType = payload =>
    dispatch({ type: actionTypes.setFileType, payload });
  const setMySubscriptions = payload =>
    dispatch({ type: actionTypes.setMySubscriptions, payload });
  const setValidityDate = payload =>
    dispatch({ type: actionTypes.setValidityDate, payload });

  const onClear = () => dispatch({ type: actionTypes.clear });

  return {
    filters,
    setSearch,
    setOrders,
    setProjects,
    setTasks,
    setRequests,
    setContacts,
    onClear,
    setCreators,
    setCreatedAt,
    setAsset,
    setTag,
    setChannelChat,
    setSorting,
    setCurrentPage,
    setFileType,
    setMySubscriptions,
    setValidityDate
  };
};

export default useFilters;
