import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import classnames from 'classnames';

import { DATE_PICKER_TIME_FORMAT } from 'constants/calendar';
import { DONE } from 'constants/index';

import DatePicker from 'components/common/date-picker';
import Typography from 'components/common/typography';

import styles from './manage-validity-date.module.scss';

const ManageValidityDate = ({ file, index, validityDateDeps }) => {
  const { t } = useTranslation(['AddFile', 'Errors']);

  if (!file.validityDateState) {
    return null;
  }

  const { changeValidityDateStateValue } = validityDateDeps;

  const isFileLoaded = file.fileId;
  const isValid = file.validityDateState.isValidDate;
  const isDisabled = !isFileLoaded && file.status !== DONE;

  const handleValidityDate = value => {
    const getDateWithDefaultTime = e => {
      const date = moment(e).toDate().setHours(23, 45);

      return moment(date).toDate();
    };

    const adjustedValue =
      !file.validityDateState.validityDateValue &&
      !moment(value).toDate().getHours()
        ? getDateWithDefaultTime(value)
        : value;

    changeValidityDateStateValue({ file, index, value: adjustedValue });
  };

  return (
    <div
      className={classnames(styles.root, {
        [styles.hasError]: !isValid
      })}
    >
      <span className={styles.label}>
        {t('ValidityExpiration', { ns: 'AddFile' })}
      </span>

      <div data-error>
        <DatePicker
          selected={
            file.validityDateState && file.validityDateState.validityDateValue
              ? moment(file.validityDateState.validityDateValue).toDate()
              : null
          }
          onChange={handleValidityDate}
          timeIntervals={15}
          placeholderText={t('Choose theExpirationDate', { ns: 'AddFile' })}
          showTimeSelect
          minDate={new Date()}
          wrapperClassname={styles.datePickerWrapper}
          itemProps={{ className: styles.formItem }}
          className={styles.datePicker}
          disabled={isDisabled}
          dateFormat={DATE_PICKER_TIME_FORMAT}
        />
      </div>

      {!isValid && (
        <Typography.Text color="red" style={{ fontSize: 12, display: 'block' }}>
          {t('PeriodGreaterThan24Hours', {
            ns: 'Errors'
          })}
        </Typography.Text>
      )}
    </div>
  );
};

ManageValidityDate.propTypes = {
  file: PropTypes.shape({
    fileId: PropTypes.string,
    id: PropTypes.string,
    uid: PropTypes.string,
    mimeType: PropTypes.string,
    type: PropTypes.string,
    status: PropTypes.string,
    allowSubscriberSelection: PropTypes.bool,
    subscribers: PropTypes.array
  }).isRequired,
  index: PropTypes.number.isRequired,
  validityDateDeps: PropTypes.shape({
    changeValidityDateStateValue: PropTypes.func
  }).isRequired
};

export default ManageValidityDate;
