import React, { useEffect, useContext, Fragment } from 'react';
import { useSelector } from 'react-redux';

import { DeviceContext } from 'components/common/device-detection-provider';

import { getIsUserLoaded, getIsUserAuthorized, getUser } from 'store/user';

import useRoutesService from 'services/routes';

import WebStub from '../web-stub';

const AuthRequiredProvider = ({ children }) => {
  const user = useSelector(getUser);
  const isUserAuthorized = useSelector(getIsUserAuthorized);
  const isUserLoaded = useSelector(getIsUserLoaded);

  const routes = useRoutesService();

  const { isSmallWidth, isMobile } = useContext(DeviceContext);
  const isNotDesktopView = isSmallWidth || isMobile;

  useEffect(() => {
    if (!isUserAuthorized) {
      routes.toLogin({ search: window.location.href });
    }

    if (isUserAuthorized && isUserLoaded && !user.firstName) {
      routes.toRegisterFinish();
    }
  }, [isUserAuthorized, isUserLoaded, routes, user]);

  if (isNotDesktopView) {
    return <WebStub />;
  }

  return <>{children}</>;
};

export default AuthRequiredProvider;
