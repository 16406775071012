import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Modal from 'components/common/modal';
import ValidityDateForm from 'components/common/validity-date/validity-date-form';

import { changeValidityDate } from 'store/attachments';

import { showNoticeMessage } from 'services/notice';

import styles from './attachment-change-validity-date-modal.module.scss';

const AttachmentChangeValidityDateModal = ({
  visible,
  onClose,
  data,
  actionDeps,
  changeValidityDateCallback
}) => {
  const { t } = useTranslation(['Task', 'ValidityDate']);

  const dispatch = useDispatch();

  const isNewAttachment = data.response && data.response.id;

  const onSubmit = async ({ validityDate }) => {
    if (isNewAttachment) {
      changeValidityDateCallback({
        attachment: {
          ...data,
          validityDate
        }
      });
    } else {
      const changedAttachmentData = await dispatch(
        changeValidityDate({
          validityDate,
          attachmentId: data.fileId,
          actionDeps
        })
      );

      changeValidityDateCallback(changedAttachmentData);

      showNoticeMessage();
    }

    onClose();
  };

  if (!visible) {
    return null;
  }

  return (
    <Modal
      width={383}
      title={t('ChangeValidityExpirationAction')}
      destroyOnClose
      open={visible}
      onClose={onClose}
      maskClosable={false}
      contentClassName={styles.modalContent}
      dataTestId="change-validity-date-modal"
    >
      <ValidityDateForm
        onSubmit={onSubmit}
        label={t('ValidityExpiration', { ns: 'ValidityDate' })}
        placeholder={t('Choose theExpirationDate', { ns: 'ValidityDate' })}
        data={data}
      />
    </Modal>
  );
};

AttachmentChangeValidityDateModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    canManageSubscribers: PropTypes.bool,
    canSubscribe: PropTypes.bool,
    createdAt: PropTypes.string,
    creator: PropTypes.shape({
      id: PropTypes.number,
      lastName: PropTypes.string,
      firstName: PropTypes.string,
      middleName: PropTypes.string,
      avatarFile: PropTypes.object
    }),
    entities: PropTypes.arrayOf(PropTypes.object),
    fileId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    fileSize: PropTypes.number,
    highlight: PropTypes.any,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    isSubscribed: PropTypes.bool,
    isSystem: PropTypes.bool,
    isTrash: PropTypes.bool,
    mimeType: PropTypes.string,
    permissions: PropTypes.object,
    title: PropTypes.string,
    updatedAt: PropTypes.string,
    workspace: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  changeValidityDateCallback: PropTypes.func,
  actionDeps: PropTypes.shape({
    entityId: PropTypes.number,
    entityType: PropTypes.string,
    messageUuid: PropTypes.string
  })
};

AttachmentChangeValidityDateModal.defaultProps = {
  visible: false,
  onClose: () => {},
  data: {},
  changeValidityDateCallback: () => {},
  actionDeps: {}
};

export default AttachmentChangeValidityDateModal;
