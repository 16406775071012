export const getAssetPathCategories = ({
  allCategories = [],
  currentCategoryId
}) => {
  if (!allCategories.length) {
    return [];
  }

  const currentCategory = allCategories.find(c => c.id === currentCategoryId);

  if (!currentCategory.parentId) {
    return [currentCategory];
  }

  return [
    ...getAssetPathCategories({
      allCategories,
      currentCategoryId: currentCategory.parentId
    }),
    currentCategory
  ];
};
