import React from 'react';

import Typography from 'components/common/typography';

import themeVariables from 'theme';

const { Text } = Typography;

export const WarningMessageBlock = ({ content = '' }) => (
  <div
    style={{
      backgroundColor: '#FFEDCC',
      border: `1px solid ${themeVariables.color.yellow}`,
      borderRadius: 4,
      marginBottom: 16,
      padding: '8px 16px'
    }}
  >
    <Text>{content}</Text>
  </div>
);
