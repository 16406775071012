import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  STATUS_FOR_EXECUTION,
  TYPE_AGREEMENT,
  TYPE_MEETING,
  TYPE_ACQUAINTANCE,
  ATTACHMENT_TYPE_ENTITY,
  TASK_FIELD_DATE_START,
  TASK_FIELD_FULL_LOCATION,
  TASK_FIELD_PROJECT,
  TASK_FIELD_DESCRIPTION,
  INCOMING,
  CREATE_ORDER_STATUS_MODAL,
  TYPE_REQUEST
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import RequestCreatorDrawer from 'components/requests-view/view/drawers/creator';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { DeleteTask } from 'components/common/icons';
import {
  transformRelationsToSelectValue,
  transformValueToRelation
} from 'components/common/controls/custom-select/relations-select/utils';
import {
  checkAllowEditTask,
  checkIsTaskKindRequest,
  checkIsTaskStatusCancelled,
  checkIsTaskStatusInWork,
  checkIsTopTask
} from 'components/requests-view/utils';
import { useModalContext } from 'components/app/modals-provider';
import { addValidityDateState } from 'components/common/validity-date/utils/add-validity-date-state';
import RequestListModal from 'components/requests-view/components/task-list-modal';

import {
  deleteTask,
  updateStatus,
  fetchAssignments,
  fetchRelations,
  fetchCoResponsibles,
  deleteTaskLocal,
  fetchAgreementSteps,
  fetchRequestLocal,
  fetchRequest,
  fetchAttachments
} from 'store/requests';
import { getUserEmployee } from 'store/workspace';
import { fetchTagsByEntity } from 'store/tags';

import { useModalsService } from 'services/modals';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { showNoticeMessage } from 'services/notice';
import { useFieldEditorContext } from 'providers';

import ReasonModal from '../drawers/details/reason-modal';
import RequestEditorDrawer from '../drawers/editor';

export const Actions = ({
  task,
  parentTask,
  isBacklog,
  isPreviewCard,
  setVisibleSprintSelect,
  isCalendarRecord,
  isSprintView,
  ...actionsProps
}) => {
  const modals = useModalsService();

  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const { id: employeeId } = useSelector(getUserEmployee) || {};

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [isLoadingDeleteTask, setIsLoadingDeleteTask] = useState(false);
  const [visibleReasonModal, setVisibleReasonModal] = useState(undefined);
  const [visibleCreatorDrawer, setVisibleCreatorDrawer] = useState(undefined);
  const [visibleEditorDrawer, setVisibleEditorDrawer] = useState(false);
  const [visibleActionsDropdown, setVisibleActionsDropdown] = useState(false);
  const [visibleTaskListModal, setVisibleTaskListModal] = useState(false);

  const { showModal } = useModalContext();

  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);

  const { t } = useTranslation([
    'Tasks',
    'Task',
    'Toast',
    'DeleteTask',
    'Common',
    'AddTask',
    'Contacts',
    'Requests'
  ]);

  const { editableFields, edit, checkField } = useFieldEditorContext();

  const {
    id: taskId,
    status,
    workLog,
    workLogTotal,
    author,
    responsible,
    kind,
    taskInfo,
    dateStart,
    location,
    project,
    description
  } = task;

  const isTypeRequest = checkIsTaskKindRequest(kind);

  const isTopTask = checkIsTopTask(task);

  const isUserAuthor = (author || {}).id === employeeId;
  const isUserResponsible = (responsible || {}).id === employeeId;

  const isStatusCancelled = checkIsTaskStatusCancelled(status);
  const isStatusInWork = checkIsTaskStatusInWork(status);

  const permissions = (task && task.permissions) || {};

  const allowDelete =
    permissions.deleteTask &&
    !(workLogTotal || workLog) &&
    !isStatusCancelled &&
    !taskInfo;

  const allowEdit = checkAllowEditTask({ task, parentTask });

  const isAllowSendToSprint =
    isTopTask && (isUserAuthor || permissions.changeTaskStatus);

  const allowDefer = isUserResponsible && isStatusInWork;

  // const allowConvertTask =
  //   isTypeRequest &&
  //   (permissions.changeSubtaskToTask || permissions.changeTaskToSubtask);

  const onCopy = async () => {
    const { template, ...fetchedTask } = await dispatch(
      fetchRequestLocal({ id: task.id })
    );

    const _responsible = Array.isArray(responsible)
      ? responsible
      : [responsible].filter(Boolean);

    const { results: members } = await dispatch(
      fetchAssignments({ directMembersOnly: true, id: task.id })
    );

    const { agreementSteps } = await dispatch(
      fetchAgreementSteps({ id: task.id })
    );

    const fetchedRelations = await dispatch(fetchRelations({ id: task.id }));

    const transformSteps = (agreementSteps || []).map(step => ({
      isEdit: true,
      employees: step.employees.map(e => ({ value: e.id, label: e }))
    }));

    const { coResponsibles } = await dispatch(
      fetchCoResponsibles({ id: task.id })
    );

    const { [task.id]: tags } = await dispatch(
      fetchTagsByEntity({ entityType: TYPE_REQUEST, entityIds: [task.id] })
    );

    const fileList = await dispatch(
      fetchAttachments({ id: task.id, source: ATTACHMENT_TYPE_ENTITY })
    );

    const fileListWithValidityState = (fileList || []).map(
      addValidityDateState
    );

    setVisibleCreatorDrawer({
      ...fetchedTask,
      fileList: fileListWithValidityState,
      tags,
      relations: transformRelationsToSelectValue(fetchedRelations),
      description,
      members,
      agreementSteps: transformSteps,
      dateEnd: undefined,
      dateStart: undefined,
      parent: null,
      responsible: _responsible,
      coResponsibles,
      isCopy: true,
      taskInfo: {},
      withShowDetails: true
    });
  };

  // const onSendWork = () => {
  //   const isSet = selectedTasks.includes(taskId);

  //   if (isSet) {
  //     return routes.toBacklog((task.project || {}).id);
  //   }

  //   return setVisibleSprintSelect({
  //     projectId: (task.project || {}).id,
  //     taskId
  //   });
  // };

  const onConfirmDelete = async () => {
    try {
      setIsLoadingDeleteTask(true);

      await dispatch(
        deleteTaskLocal({
          id: taskId,
          projectId: (task.project || {}).id
        })
      );

      if (!isPreviewCard) {
        if (
          task.parent &&
          ![TYPE_MEETING, TYPE_AGREEMENT, TYPE_ACQUAINTANCE].includes(task.kind)
        ) {
          modals.requests.showDetails({ id: task.parent });
        } else {
          modals.close();
        }
      }

      amplitude.deleteTaskEvent({
        ...task,
        author: task.author.id,
        responsible: (task.responsible || {}).id,
        controller: (task.controller || {}).id,
        approvingManager: (task.approvingManager || {}).id
      });

      dispatch(deleteTask({ id: taskId }));

      showNoticeMessage({
        customContent: t('RequestDeleted', { ns: 'Toast' })
      });
    } finally {
      setIsLoadingDeleteTask(false);
      setVisibleDeleteModal(false);
    }
  };

  const onUpdateStatus = async newStatus => {
    await dispatch(updateStatus(newStatus));

    modals.close();

    showNoticeMessage({
      customContent: t('RequestCancelled', { ns: 'Toast' })
    });
  };

  // const dispatchSubtaskToBacklog = () =>
  //   Promise.all(
  //     task.children.map(subtask =>
  //       dispatch(updateStatus({ id: +subtask.id, status: STATUS_BACKLOG }))
  //     )
  //   );

  // const onToBacklog = async () => {
  //   if (status === STATUS_BACKLOG) {
  //     await dispatch(
  //       partialTaskUpdate({ id: +taskId, values: { sprint: null } })
  //     );
  //   }

  //   await dispatch(updateStatus({ id: +taskId, status: STATUS_BACKLOG }));

  //   if ((task.children || []).length) {
  //     dispatchSubtaskToBacklog();
  //   }
  // };

  const onDefer = () =>
    dispatch(updateStatus({ id: +taskId, status: STATUS_FOR_EXECUTION }));

  // const onConvertToParentTask = async () => {
  //   await dispatch(convertSubtaskToTask({ taskId, parentId: task.parent }));

  //   await dispatch(fetchRequest({ id: taskId }));

  //   showNoticeMessage();
  // };

  const handleVisibleActionsDropdown = async visible => {
    if (!visible || !isEmpty(permissions)) {
      setVisibleActionsDropdown(visible);
      return;
    }

    try {
      setIsLoadingPermissions(true);

      await dispatch(fetchRequest({ id: task.id }));
    } finally {
      setIsLoadingPermissions(false);
      setVisibleActionsDropdown(visible);
    }
  };

  const onCreateOrderStatus = () =>
    showModal(CREATE_ORDER_STATUS_MODAL, {
      data: {
        defaultKind: INCOMING,
        defaultValues: {
          relations: transformValueToRelation(
            [
              {
                value: taskId,
                label: task
              }
            ],
            TYPE_REQUEST
          )
        },
        withContractor: true,
        withShowDetails: true
      }
    });

  const getActions = useCallback(
    () => [
      {
        title: t('AddFieldsBtn', { ns: 'Common' }),
        allow: allowEdit,
        children: [
          {
            title: t('Description', { ns: 'Common' }),
            icon: 'file-text',
            allow:
              !(description && description[0] && description[0].text) &&
              !checkField(TASK_FIELD_DESCRIPTION),
            onClick: () => {
              amplitude.logAddTaskDescriptionEvent('actions_menu');
              edit({ field: TASK_FIELD_DESCRIPTION });
            }
          },
          {
            title: t('AddressHeading', { ns: 'AddTask' }),
            icon: 'environment',
            allow:
              isTypeRequest &&
              !location &&
              !checkField(TASK_FIELD_FULL_LOCATION),
            onClick: () => edit({ field: TASK_FIELD_FULL_LOCATION })
          },
          {
            title: t('StartDate', {
              ns: 'AddTask'
            }),
            icon: 'calendar',
            allow: !dateStart && !checkField(TASK_FIELD_DATE_START),
            onClick: () => edit({ field: TASK_FIELD_DATE_START })
          },
          {
            title: t('Project', { ns: 'AddTask' }),
            icon: 'project',
            allow: !project && !checkField(TASK_FIELD_PROJECT),
            onClick: () => edit({ field: TASK_FIELD_PROJECT })
          }
          // {
          //   title: t('TaskResult', { ns: 'AddTask' }),
          //   icon: 'diff',
          //   allow:
          //     isTypeRequest &&
          //     !completeRequire &&
          //     !checkField(TASK_FIELD_COMPLETE_REQUIRE),
          //   onClick: () => edit({ field: TASK_FIELD_COMPLETE_REQUIRE })
          // }
        ]
      },
      {
        title: t('EditTaskAction'),
        onClick: () => setVisibleEditorDrawer(true),
        allow: allowEdit
      },
      // {
      //   title: t('MakeSubtaskAction'),
      //   onClick: () => setVisibleTaskListModal(true),
      //   allow: allowConvertTask && !task.parent
      // },
      // {
      //   title: t('DetachSubtaskAction'),
      //   onClick: onConvertToParentTask,
      //   allow: allowConvertTask && task.parent
      // },
      {
        title: t('DeleteTaskAction', { ns: 'Task' }),
        onClick: () => setVisibleDeleteModal(true),
        allow: allowDelete
      },
      {
        title: t('CopyRequestAction', { ns: 'Requests' }),
        onClick: onCopy,
        allow: true
      },
      {
        title: t('AddContactToFunnel', { ns: 'Contacts' }),
        onClick: onCreateOrderStatus,
        allow: true
      },
      {
        title: t('PostponeTaskAction'),
        onClick: onDefer,
        allow: allowDefer
      }
      // {
      //   title: t('BringTaskToWorkAction', { ns: 'Task' }),
      //   onClick: onSendWork,
      //   allow: isAllowSendToSprint
      // }
      // {
      //   title: t('RemoveTaskFromSprint'),
      //   onClick: onToBacklog,
      //   allow: isSprintView
      // }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      task,
      editableFields.length,
      status,
      allowDelete,
      allowEdit,
      isAllowSendToSprint,
      isStatusCancelled,
      workLogTotal
    ]
  );

  return (
    <>
      <ActionsDropdown
        actions={getActions()}
        dropdownProps={{
          open: visibleActionsDropdown,
          onOpenChange: handleVisibleActionsDropdown
        }}
        isLoading={isLoadingPermissions}
        afterSelectCallback={() => handleVisibleActionsDropdown(false)}
        {...actionsProps}
      />

      <ModalDeleteConfirm
        open={visibleDeleteModal}
        isLoading={isLoadingDeleteTask}
        title={t('DeleteTaskHeading', { ns: 'DeleteTask' })}
        description={t('DeleteTaskDesc', { ns: 'DeleteTask' })}
        deleteBtnText={t('DeleteBtn', { ns: 'DeleteTask' })}
        cancelBtnText={t('CancelBtn', { ns: 'DeleteTask' })}
        data-qa="qa-003694170708539"
        onConfirm={onConfirmDelete}
        iconModal={DeleteTask}
        onClose={() => setVisibleDeleteModal(false)}
      />

      <ReasonModal
        visible={visibleReasonModal !== undefined}
        data={visibleReasonModal}
        onUpdateStatus={onUpdateStatus}
        data-qa="qa-041874318889906"
        onClose={() => setVisibleReasonModal(undefined)}
      />

      <RequestCreatorDrawer
        visible={visibleCreatorDrawer !== undefined}
        data={visibleCreatorDrawer}
        onClose={() => setVisibleCreatorDrawer(undefined)}
      />

      <RequestEditorDrawer
        visible={visibleEditorDrawer}
        taskId={taskId}
        onClose={() => setVisibleEditorDrawer(false)}
      />

      <RequestListModal
        visible={visibleTaskListModal}
        onClose={() => setVisibleTaskListModal(false)}
        data={task}
      />
    </>
  );
};

Actions.propTypes = {
  task: PropTypes.object.isRequired,
  isBacklog: PropTypes.bool,
  isPreviewCard: PropTypes.bool,
  setVisibleSprintSelect: PropTypes.func
};

Actions.defaultProps = {
  isBacklog: false,
  isPreviewCard: false,
  setVisibleSprintSelect: () => {}
};

export default Actions;
