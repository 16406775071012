import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Alert } from 'antd';

import Typography from 'components/common/typography';
import { DeleteNumberIcon } from 'components/common/icons/multicolor';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';

import { deletePaymentAddon } from 'store/billing';

import { showNoticeMessage } from 'services/notice';

import styles from './delete-whatsapp-number.module.scss';

const DeleteWhatsAppNumberModal = ({ data, visible, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['ConnectWhatsApp', 'Common']);

  const { nextPaymentDate, addonId } = data;

  const onConfirm = async () => {
    try {
      setIsLoading(true);

      await dispatch(deletePaymentAddon({ addonId }));

      showNoticeMessage();
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  const description = (
    <>
      <Typography.Paragraph color="black-45">
        {t('WhatsAppTurnOffDesc', { date: nextPaymentDate })}
      </Typography.Paragraph>

      <Alert
        type="warning"
        message={
          <Typography.Paragraph className={styles.alertMessage}>
            <Trans
              i18nKey="WhatsAppTurnOffWarning"
              ns="ConnectWhatsApp"
              components={{
                br: <br />,
                ul: <ul />,
                li: <li />
              }}
            />
          </Typography.Paragraph>
        }
      />
    </>
  );

  return (
    <ModalDeleteConfirm
      open={visible}
      onClose={onClose}
      iconModal={DeleteNumberIcon}
      title={t('WhatsAppTurnOffHeading')}
      isLoading={isLoading}
      description={description}
      cancelBtnText={t('NoBtn', { ns: 'Common' })}
      deleteBtnText={t('YesBtn', { ns: 'Common' })}
      onConfirm={onConfirm}
    />
  );
};

DeleteWhatsAppNumberModal.propTypes = {
  data: PropTypes.shape({
    nextPaymentDate: PropTypes.string,
    addonId: PropTypes.number
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

DeleteWhatsAppNumberModal.defaultProps = {
  data: {
    nextPaymentDate: '',
    addonId: null
  }
};

export default DeleteWhatsAppNumberModal;
