import { $isAtNodeEnd } from '@lexical/selection';
import { $createParagraphNode, $getRoot, $insertNodes } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { $convertFromMarkdownString, TRANSFORMERS } from '@lexical/markdown';

import { MENTION_MARKDOWN } from './nodes/mention-node';

export const getSelectedNode = selection => {
  if (!selection) return null;

  const { anchor } = selection;
  const { focus } = selection;

  const anchorNode = selection.anchor.getNode();
  const focusNode = selection.focus.getNode();

  if (anchorNode === focusNode) {
    return anchorNode;
  }

  const isBackward = selection.isBackward();

  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  }

  return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
};

const isHTMLString = str => {
  const htmlRegex = /<[a-z][\s\S]*>/i;

  return htmlRegex.test(str);
};

export const updateEditorState = ({ value, editor, isHtml }) => {
  if (!value.description) {
    const root = $getRoot();
    const paragraph = $createParagraphNode();
    root.append(paragraph);

    return;
  }

  if (isHtml && isHTMLString(value.description)) {
    const parser = new DOMParser();
    const dom = parser.parseFromString(value.description, 'text/html');
    const nodes = $generateNodesFromDOM(editor, dom);

    $getRoot().select();

    $insertNodes(nodes);

    return;
  }

  $convertFromMarkdownString(value.description, [
    MENTION_MARKDOWN,
    ...TRANSFORMERS
  ]);
};

export default {
  getSelectedNode,
  updateEditorState
};
