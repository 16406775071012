import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Divider, Spin, Empty } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { VIDEOS, ITEMS_PER_PAGE, TYPE_REQUEST } from 'constants/index';

import Button from 'components/common/button';
import Avatar from 'components/common/avatar';
import { EmptyTimeLogIcon } from 'components/common/icons';
import DateList from 'components/common/date-list';
import VideoBtn from 'components/common/video-btn';
import {
  EstimateForm,
  getHours,
  getEstimateTitle
} from 'components/common/estimate';
import PopConfirm from 'components/common/pop-confirm';
import InfiniteScroll from 'components/common/infinite-scroll';
import Icon from 'components/common/icon';
import Tooltip from 'components/common/tooltip';
import Typography from 'components/common/typography';

import { getUserEmployee } from 'store/workspace';
import {
  fetchWorklogList,
  deleteWorklog,
  updateWorklog,
  getTasksHasMoreWorklog
} from 'store/requests';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { useModalsService } from 'services/modals';
import { getFIO, getFullName } from 'utils/get-fio';
import { showNoticeMessage } from 'services/notice';

import { IconId } from '../../components';

import styles from './time-log.module.scss';

const TimeLog = React.memo(({ task }) => {
  const dispatch = useDispatch();
  const modals = useModalsService();
  const amplitude = useAmplitude();

  const user = useSelector(getUserEmployee);

  const [isLoading, setIsLoadig] = useState(true);
  const [currentPage, setCurrentPage] = useState(0);

  const hasMore = useSelector(getTasksHasMoreWorklog);

  const { t } = useTranslation(['Task', 'Toast']);

  const isSubtask = id => (id !== undefined ? task.id !== id : false);

  const {
    updateTaskWorklog,
    deleteTaskWorklog,
    updateTaskSelfWorklog,
    deleteTaskSelfWorklog
  } = task.permissions;
  const showDivider = updateTaskWorklog && deleteTaskWorklog;

  const fetchWorklog = useCallback(async () => {
    try {
      await dispatch(
        fetchWorklogList({
          id: task.id,
          limit: ITEMS_PER_PAGE,
          offset: currentPage * ITEMS_PER_PAGE
        })
      ).then(() => {
        setCurrentPage(currentPage + 1);
      });
    } finally {
      setIsLoadig(false);
    }
  }, [currentPage, dispatch, task.id]);

  useEffect(() => {
    if (task) {
      fetchWorklog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.id]);

  const onClickTask = taskId => modals.requests.showDetails({ id: taskId });

  const WorklogItem = ({ item }) => {
    const [isLoadingDelete, setIsLoadingDelete] = useState(false);
    const [visiblePopover, setVisiblePopover] = useState(false);

    const handleUpdate = async ({ id, value, description, diff }) => {
      await dispatch(
        updateWorklog({
          id: task.id,
          worklogId: id,
          value,
          description: description || '',
          parent: task.parent,
          diff
        })
      );
    };

    const handleDelete = async ({ id, value }) => {
      try {
        setIsLoadingDelete(true);

        await dispatch(
          deleteWorklog({
            id: task.id,
            worklogId: id,
            value,
            parent: task.parent
          })
        );

        amplitude.changeWorklog({ value, task, isDelete: true });

        showNoticeMessage({
          customContent: t('WorklogDeleted', { ns: 'Toast' })
        });
      } finally {
        setIsLoadingDelete(false);
      }
    };

    const canUpdateOnlySelf =
      item.employee.id === user.id && updateTaskSelfWorklog;

    const canDeleteOnlySelf =
      item.employee.id === user.id && deleteTaskSelfWorklog;

    const showBtns =
      canUpdateOnlySelf ||
      canDeleteOnlySelf ||
      updateTaskWorklog ||
      deleteTaskWorklog;

    return (
      <div className={styles.item} data-qa="qa-lxmc7lmprpydswq">
        <div className={styles.itemInfo} data-qa="qa-7aultd9ivi0dkzv">
          <Avatar
            size={44}
            className={styles.avatar}
            data-qa="qa-jcql5btwi56790c"
            src={((item.employee || {}).avatarFile || {}).url}
            isActive={item.employee.isActive}
          >
            {getFIO(item.employee)}
          </Avatar>

          <div className={styles.info} data-qa="qa-yd8r6cfpphfr4zd">
            <div className={styles.user} data-qa="qa-lm8a638eaugg63k">
              {getFullName(item.employee)}
            </div>

            <Tooltip
              title={getEstimateTitle(item.value)}
              placement="right"
              getPopupContainer={trigger => trigger.parentNode}
            >
              <span
                className={styles.estimateText}
                data-qa="qa-k56u130nle9egh8"
              >
                <span>{t('Tracked')}</span>{' '}
                <span>{`${getHours(item.value)} ${t('HourShort')}`} </span>
              </span>
            </Tooltip>

            {isSubtask(item.objectId) && (
              <IconId
                object={{ id: item.objectId, kind: TYPE_REQUEST }}
                onClick={() => onClickTask(item.objectId)}
              />
            )}

            {item.description && (
              <div className={styles.description} data-qa="qa-yr3pzkzvjzfh72q">
                <Typography.Paragraph
                  ellipsis={{ rows: 2 }}
                  className={styles.paragraph}
                  data-qa="qa-u4zn8dwweniae97"
                >
                  {item.description}
                </Typography.Paragraph>
              </div>
            )}
          </div>
        </div>

        {showBtns && (
          <div className={styles.btnContainer} data-qa="qa-omengxgelc2yqko">
            <Popover
              className={styles.popover}
              data-qa="qa-uy1v4mwbau2cl7w"
              content={
                <EstimateForm
                  defaultValues={{
                    estimation: item.value,
                    description: item.description
                  }}
                  onSubmit={async ({ value, description, diff }) =>
                    handleUpdate({ id: item.id, value, description, diff })
                  }
                  saveMessage={t('TrackTimeSaved', { ns: 'Toast' })}
                  onCancel={() => setVisiblePopover(false)}
                  showComments
                />
              }
              getPopupContainer={trigger => trigger.parentNode}
              trigger="click"
              placement="bottom"
              open={visiblePopover}
              overlayClassName={styles.estimate}
              onOpenChange={setVisiblePopover}
            >
              {(updateTaskWorklog || canUpdateOnlySelf) && (
                <Button type="link" style={{ fontWeight: 'normal' }}>
                  {t('EditTimeTrackBtn')}
                </Button>
              )}
            </Popover>

            {showDivider && <Divider type="vertical" />}

            {(deleteTaskWorklog || canDeleteOnlySelf) && (
              <PopConfirm
                title={t('DeleteTrackTimeHeading')}
                onConfirm={() =>
                  handleDelete({ id: item.id, value: item.value })
                }
                style={{ maxWidth: 202 }}
              >
                <Button
                  type="link"
                  data-qa="qa-4lmokpxuk41lljd"
                  mood="negative"
                  style={{ fontWeight: 'normal' }}
                  loading={isLoadingDelete}
                >
                  {t('DeleteTimeTrackBtn')}
                </Button>
              </PopConfirm>
            )}
          </div>
        )}
      </div>
    );
  };

  const isEmpty = !(task.worklogList || []).length && !isLoading;

  return (
    <Spin
      spinning={isLoading}
      wrapperClassName={styles.root}
      data-qa="qa-y8ct8c67g754buz"
    >
      {isEmpty && (
        <Empty
          image={<Icon component={EmptyTimeLogIcon} />}
          style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}
          imageStyle={{ height: 'auto', marginBottom: 15 }}
          description={
            <>
              <Typography.Paragraph>{t('NoTrackedTime')}</Typography.Paragraph>
              <VideoBtn
                slug={VIDEOS.workWithTimeLog}
                style={{ margin: '20px auto 0' }}
              />
            </>
          }
        />
      )}

      <InfiniteScroll
        initialLoad={false}
        loadMore={fetchWorklog}
        hasMore={!isLoading && hasMore}
        findScrollParent
        loader={<Spin key="spin-worklog" />}
        className={styles.container}
      >
        <DateList>
          {(task.worklogList || []).map((item, index) => (
            <WorklogItem
              key={`${item.id}-${index}`}
              item={item}
              createdAt={item.startedAt}
            />
          ))}
        </DateList>
      </InfiniteScroll>
    </Spin>
  );
});

TimeLog.propTypes = {
  task: PropTypes.object.isRequired // TODO: расписать поля
};

export default TimeLog;
