import React from 'react';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

export const IconCustom = ({
  component,
  style,
  className,
  onClick,
  ...props
}) => (
  <Icon
    component={component}
    className={className}
    style={style}
    onClick={onClick}
    {...props}
  />
);

IconCustom.propTypes = {
  component: PropTypes.elementType.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  onClick: PropTypes.func
};

IconCustom.defaultProps = {
  style: {},
  className: undefined,
  onClick: undefined
};

export default IconCustom;
