import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Divider } from 'antd';

import { ENTITY_TYPE_FILTER_OPTIONS, DASHBOARD } from 'constants/index';

import CustomSelect, {
  EmployeeSelect
} from 'components/common/controls/custom-select';

import {
  getFilterNotifications,
  setNotificationsFilterCreatedDateRange,
  setNotificationsFilterSender,
  setNotificationsFilterEntityType,
  clearFilter
} from 'store/operator';
import { getRouterUrlView } from 'store/router';
import { getUserEmployee } from 'store/workspace';

import Dates from './dates';

import styles from './filters.module.scss';

export const Filters = ({ isNotificationInBellVisible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Dashboard');

  const filter = useSelector(getFilterNotifications);
  const urlView = useSelector(getRouterUrlView);
  const employee = useSelector(getUserEmployee);

  const { sender, createdDateRange, entityType } = filter;

  const entityTypeOptions = Object.values(ENTITY_TYPE_FILTER_OPTIONS);
  const setSender = value =>
    dispatch(setNotificationsFilterSender({ value, entityId: employee.id }));

  const setCreatedDateRange = date =>
    dispatch(
      setNotificationsFilterCreatedDateRange({
        value: date,
        entityId: employee.id
      })
    );

  const setEntityType = type =>
    dispatch(
      setNotificationsFilterEntityType({ value: type, entityId: employee.id })
    );

  useEffect(
    () => () => {
      if (isNotificationInBellVisible && urlView === DASHBOARD) {
        return;
      }

      dispatch(clearFilter({ entityId: employee.id }));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <div className={styles.root}>
      <EmployeeSelect
        label={t('Employee')}
        isMulti
        params={{
          excludeBench: false,
          isActive: null
        }}
        value={sender}
        onChange={setSender}
        rootClassName={styles.filter}
        optionProps={{ isFilters: true }}
      />

      <CustomSelect
        label={t('Chapter')}
        isMulti
        closeMenuOnSelect={false}
        rootClassName={styles.filter}
        options={entityTypeOptions}
        value={entityType}
        onChange={setEntityType}
      />

      <Divider className={styles.divider} />

      <Dates
        rootClassName={styles.filter}
        label={t('PeriodFilters')}
        value={createdDateRange}
        onChange={setCreatedDateRange}
      />
    </div>
  );
};

export default Filters;
