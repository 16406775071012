/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */

export const initGA = id => {
  try {
    if (window.gtag) {
      return;
    }

    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
    script.async = true;
    document.head.appendChild(script);

    if (!Array.isArray(window.dataLayer)) {
      window.dataLayer = [];
    }
    // eslint-disable-next-line no-inner-declarations
    function gtag() {
      // eslint-disable-next-line prefer-rest-params
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', id);

    window.gtag = gtag;
  } catch (error) {
    console.log('Error in initGA', error);
  }
};

export const initYM = id => {
  try {
    if (window.ym) {
      return;
    }

    (function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          // eslint-disable-next-line prefer-rest-params
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      k = e.createElement(t);
      // eslint-disable-next-line prefer-destructuring
      a = e.getElementsByTagName(t)[0];
      k.async = 1;
      k.src = r;
      a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    window.ym(id, 'init', {
      defer: true,
      clickmap: true,
      trackLinks: true,
      accurateTrackBounce: true,
      webvisor: false
    });
  } catch (error) {
    console.log('Error in initYM', error);
  }
};

export const initFB = id => {
  try {
    if (window.fbq) {
      return;
    }

    const f = window;
    const b = document;
    const e = 'script';
    const v = 'https://connect.facebook.net/en_US/fbevents.js';

    if (f.fbq) return;
    // eslint-disable-next-line no-multi-assign
    const n = (f.fbq = function (...args) {
      if (n.callMethod) {
        n.callMethod(...args);
      } else {
        n.queue.push(args);
      }
    });
    if (!f._fbq) f._fbq = n;
    n.push = n;
    n.loaded = !0;
    n.version = '2.0';
    n.queue = [];
    const t = b.createElement(e);
    t.async = !0;
    t.src = v;
    const s = b.getElementsByTagName(e)[0];
    s.parentNode.insertBefore(t, s);

    window.fbq('init', id);
    window.fbq('track', 'PageView');
  } catch (error) {
    console.log('Error in initFB', error);
  }
};

export const initVK = id => {
  try {
    if (window.VK) {
      return;
    }

    const t = document.createElement('script');
    t.type = 'text/javascript';
    t.async = !0;
    t.src = 'https://vk.com/js/api/openapi.js?168';
    t.onload = function () {
      window.VK.Retargeting.Init(id);
      window.VK.Retargeting.Hit();
    };
    document.head.appendChild(t);
  } catch (error) {
    console.log('Error in initVK', error);
  }
};

export const initGTM = environment => {
  try {
    if (window.google_tag_manager) {
      return;
    }

    ((w, d, s, l, i) => {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      const f = d.getElementsByTagName(s)[0];

      const j = d.createElement(s);
      const dl = l != 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(
      window,
      document,
      'script',
      'dataLayer',
      environment === 'production' ? 'GTM-5WLSN6K' : 'GTM-K9CQKGL'
    );
  } catch (error) {
    console.log('Error in initGTM', error);
  }
};
