import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Drawer from 'components/common/drawer';
import VideoBtn from 'components/common/video-btn';
import Typography from 'components/common/typography';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import Form from './form';
import { getDiffRelations, transformDefaultRelations } from './utils';
import SkeletonRelations from './skeleton';

import styles from './relations.module.scss';

export const RelationsDrawer = ({ visible, data, onClose }) => {
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [relations, setRelations] = useState();
  const [afterVisible, setAfterVisible] = useState(false);

  const {
    title,
    description,
    videoSlug,
    fetch,
    submit,
    allowEdit = true
  } = data;

  const onFetch = async () => {
    try {
      setIsFetchLoading(true);

      const results = await fetch();
      setRelations(transformDefaultRelations(results));
    } finally {
      setIsFetchLoading(false);
    }
  };

  const onSubmit = async values => {
    try {
      setIsSubmitLoading(true);

      const { added, deleted } = getDiffRelations(relations, values);

      await submit({ added, deleted });

      showNoticeMessage({ number: NOTICE_NUMBER.relationsSaved });
      onClose();
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (afterVisible) {
      onFetch();
    } else {
      setRelations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [afterVisible]);

  return (
    <Drawer
      title={
        <>
          <Drawer.Back onClick={onClose} />
          <Drawer.Title>{title}</Drawer.Title>
        </>
      }
      width={480}
      open={visible}
      closable={false}
      afterOpenChange={setAfterVisible}
      onClose={onClose}
    >
      {isFetchLoading || !relations ? (
        <SkeletonRelations />
      ) : (
        <>
          {videoSlug && (
            <VideoBtn slug={videoSlug} className={styles.videoBtn} />
          )}

          <Typography.Paragraph className={styles.description}>
            {description}
          </Typography.Paragraph>

          <Form
            defaultValues={relations}
            isLoading={isSubmitLoading}
            allowEdit={allowEdit}
            onSubmit={onSubmit}
          />
        </>
      )}
    </Drawer>
  );
};

RelationsDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    videoSlug: PropTypes.string,
    fetch: PropTypes.func,
    submit: PropTypes.func,
    allowEdit: PropTypes.bool
  }),
  onClose: PropTypes.func.isRequired
};

RelationsDrawer.defaultProps = {
  data: {}
};

export default RelationsDrawer;
