import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Typography from 'components/common/typography';

import CopyForm from './form';

const CopyDrawer = ({ file, visible, onClose, onSubmit }) => {
  const { t } = useTranslation('CreateDocumentCopy');

  return (
    <Drawer
      width={600}
      open={visible}
      title={<Drawer.Title>{t('CreateDocumentCopyHeading')}</Drawer.Title>}
      onClose={onClose}
    >
      <Typography.Paragraph>{t('CreateDocumentCopyDesc')}</Typography.Paragraph>

      <CopyForm
        defaultValues={{
          name: file.title
            ? `${file.title.substr(0, file.title.lastIndexOf('.'))} (${t(
                'CopyValue'
              )})`
            : undefined
        }}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

CopyDrawer.propTypes = {
  file: PropTypes.shape({
    title: PropTypes.string
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

CopyDrawer.defaultProps = {
  file: {}
};

export default CopyDrawer;
