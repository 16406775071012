import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';

import DocumentList from './list';

const DocumentsDrawer = ({ asset, visible, onClose }) => {
  const [isAfterVisible, setIsAfterVisible] = useState(false);

  const { t } = useTranslation('AssetDocuments');

  return (
    <Drawer
      title={<Drawer.Title>{t('AssetDocumentsHeading')}</Drawer.Title>}
      bodyStyle={{ padding: 0 }}
      open={visible}
      destroyOnClose
      onClose={onClose}
      afterOpenChange={setIsAfterVisible}
    >
      <DocumentList asset={asset} isAfterVisible={isAfterVisible} />
    </Drawer>
  );
};

DocumentsDrawer.propTypes = {
  visible: PropTypes.bool,
  asset: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired
};

DocumentsDrawer.defaultProps = {
  visible: false,
  asset: {}
};

export default DocumentsDrawer;
