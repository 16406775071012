import React, { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { createPortal } from 'react-dom';
import {
  $getSelection,
  $isRangeSelection,
  SELECTION_CHANGE_COMMAND
} from 'lexical';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import { Input, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Button from 'components/common/button';
import { getSelectedNode } from 'components/common/new-editor/utils';

import { LOW_PRIORITY } from '../../constants';
import { EMAIL_MATCHER, URL_MATCHER } from '../../auto-link';

import styles from '../../toolbar.module.scss';

const positionLinkEditorElement = (editor, rect) => {
  if (rect === null) {
    editor.style.opacity = '0';
    editor.style.top = '-1000px';
    editor.style.left = '-1000px';
  } else {
    editor.style.opacity = '1';
    editor.style.top = `${rect.top + rect.height + window.pageYOffset + 10}px`;
    editor.style.left = `${
      rect.left + window.pageXOffset - editor.offsetWidth / 1.2 + rect.width / 2
    }px`;
  }
};

const FloatingLinkEditor = ({ editor }) => {
  const editorRef = useRef(null);
  const inputRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] = useState(null);

  const { t } = useTranslation('Common');

  const saveLink = () => {
    if (lastSelection !== null) {
      if (linkUrl !== '' && URL_MATCHER.test(linkUrl)) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, linkUrl);
      } else {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }

      setEditMode(false);
    }
  };

  const updateLinkEditor = useCallback(() => {
    const selection = $getSelection();
    const node = getSelectedNode(selection);
    const parent = node?.getParent();

    if ($isRangeSelection(selection)) {
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }

    const editorElem = editorRef.current;
    const nativeSelection = window.getSelection();
    const { activeElement } = document;

    if (editorElem === null) {
      return;
    }

    const rootElement = editor.getRootElement();
    if (
      selection !== null &&
      !nativeSelection.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection.anchorNode)
    ) {
      const domRange = nativeSelection.getRangeAt(0);
      let rect;
      if (nativeSelection.anchorNode === rootElement) {
        let inner = rootElement;
        while (inner.firstElementChild != null) {
          inner = inner.firstElementChild;
        }

        rect = inner.getBoundingClientRect();
      } else {
        rect = domRange.getBoundingClientRect();
      }

      if (!mouseDownRef.current) {
        positionLinkEditorElement(editorElem, rect);
      }
      setLastSelection(selection);
    } else if (!activeElement || activeElement.className !== 'link-input') {
      positionLinkEditorElement(editorElem, null);

      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');

      if (
        parent &&
        typeof parent.getURL === 'function' &&
        !URL_MATCHER.test(parent.getURL()) &&
        !EMAIL_MATCHER.test(parent.getURL())
      ) {
        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      }
    }

    // eslint-disable-next-line consistent-return
    return true;
  }, [editor]);

  useEffect(
    () =>
      mergeRegister(
        editor.registerUpdateListener(({ editorState }) => {
          editorState.read(() => {
            updateLinkEditor();
          });
        }),

        editor.registerCommand(
          SELECTION_CHANGE_COMMAND,
          () => {
            updateLinkEditor();
            return true;
          },
          LOW_PRIORITY
        )
      ),
    [editor, updateLinkEditor]
  );

  useEffect(() => {
    editor.getEditorState().read(() => {
      updateLinkEditor();
    });
  }, [editor, updateLinkEditor]);

  useEffect(() => {
    if (isEditMode && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditMode]);

  return (
    <div ref={editorRef} className={styles.linkEditor}>
      {isEditMode ? (
        <div className={styles.linkWrap}>
          <Input
            ref={inputRef}
            className={styles.linkInput}
            value={linkUrl}
            onChange={event => {
              setLinkUrl(event.target.value);
            }}
            onKeyDown={event => {
              if (event.key === 'Enter') {
                event.preventDefault();

                saveLink();
              } else if (event.key === 'Escape') {
                event.preventDefault();
                setEditMode(false);
              }
            }}
          />

          <Tooltip title={t('Save')} mouseEnterDelay={0.5}>
            <Icon type="check-circle" color="brand" onClick={saveLink} />
          </Tooltip>
        </div>
      ) : (
        <>
          <div className={styles.linkInput}>
            <Tooltip
              title={linkUrl}
              mouseEnterDelay={0.5}
              style={{ zIndex: 10000 }}
            >
              <a href={linkUrl} target="_blank" rel="noopener noreferrer">
                {linkUrl}
              </a>
            </Tooltip>

            <Button
              type="link"
              className={styles.linkEdit}
              onMouseDown={event => event.preventDefault()}
              onClick={() => {
                setEditMode(true);
              }}
            >
              <Icon size={24} type="edit" />
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const LinkItem = ({ editor, isLink }) => {
  const { t } = useTranslation('CommonChat');

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, 'https://');
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
    }
  }, [editor, isLink]);

  return (
    <>
      <Tooltip title={t('LinkTip')} mouseEnterDelay={0.5}>
        <Button
          onClick={insertLink}
          className={classNames(styles.toolbarItem, styles.toolbarSpaced, {
            [styles.toolbarActive]: isLink
          })}
        >
          <Icon type="link" size={16} className={styles.toolbarFormat} />
        </Button>
      </Tooltip>

      {isLink &&
        createPortal(<FloatingLinkEditor editor={editor} />, document.body)}
    </>
  );
};

export default LinkItem;
