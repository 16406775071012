import { $applyNodeReplacement, TextNode } from 'lexical';

import { MENTION_REGEX } from 'components/common/markdown/helpers';

function convertMentionElement(domNode) {
  const { textContent } = domNode;

  if (textContent !== null) {
    const node = $createMentionNode({ mentionText: textContent });

    return {
      node
    };
  }

  return null;
}

const mentionStyle =
  'display: inline-block; padding: 2px 8px; margin: 0 5px 0 1px; border-radius: 23px; background: #D8D9DA; vertical-align: baseline; line-height: 1.1875; color: #57595C;';

export class MentionNode extends TextNode {
  constructor(mentionType, mentionText, id, text = `@${mentionText}`, key) {
    super(text, key);

    this.mention = mentionText;
    this.mentionType = mentionType;
    this.id = id;
  }

  static getType() {
    return 'mention';
  }

  static clone(node) {
    return new MentionNode(
      node.mentionType,
      node.mention,
      node.id,
      node.__text,
      node.__key
    );
  }

  static importJSON(serializedNode) {
    const node = $createMentionNode(serializedNode.mentionName);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  exportJSON() {
    return {
      ...super.exportJSON(),
      mentionName: this.__text,
      type: 'mention',
      version: 1
    };
  }

  createDOM(config) {
    const dom = super.createDOM(config);
    dom.style.cssText = mentionStyle;

    return dom;
  }

  // eslint-disable-next-line class-methods-use-this
  exportDOM() {
    const element = document.createElement('span');
    element.setAttribute('mention', 'true');

    return { element };
  }

  static importDOM() {
    return {
      span: domNode => {
        if (!domNode.hasAttribute('mention')) {
          return null;
        }

        return {
          conversion: convertMentionElement,
          priority: 1
        };
      }
    };
  }

  // eslint-disable-next-line class-methods-use-this
  isTextEntity() {
    return true;
  }

  // eslint-disable-next-line class-methods-use-this
  canInsertTextBefore() {
    return false;
  }

  // eslint-disable-next-line class-methods-use-this
  canInsertTextAfter() {
    return false;
  }
}

export const $createMentionNode = ({ mentionText, id, mentionType }) => {
  const mentionNode = new MentionNode(mentionType, mentionText, id);
  mentionNode.setMode('segmented').toggleDirectionless();

  return $applyNodeReplacement(mentionNode);
};

export const $isMentionNode = node => node instanceof MentionNode;

export const MENTION_MARKDOWN = {
  dependencies: [MentionNode],
  export: node => {
    if (!$isMentionNode(node)) {
      return null;
    }

    return `@[${node.mention}](${node.id}${
      node.mentionType ? `&${node.mentionType}` : ''
    }) `;
  },
  importRegExp: MENTION_REGEX,
  regExp: new RegExp(MENTION_REGEX + /$/),
  replace: (textNode, match) => {
    const [, mentionText, id, mentionType] = match;

    const mentionNode = $createMentionNode({ mentionType, mentionText, id });

    textNode.replace(mentionNode);
  },
  trigger: ')',
  type: 'text-match'
};
