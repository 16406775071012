import React from 'react';

export const GoogleDriveUnsyncIcon = ({
  style = { width: 150, height: 150 }
}) => (
  <svg
    style={style}
    width="151"
    height="150"
    viewBox="0 0 151 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_104064_371145)">
      <path
        d="M146.296 78.8562C146.192 78.5305 144.994 75.5852 142.448 69.5612C142.448 69.5612 140.627 65.2393 138.644 61.5835C138.511 61.3467 138.437 61.2283 138.304 60.9915C137.904 60.281 137.504 59.5706 137.075 58.9489C136.661 58.2385 136.172 57.4984 135.743 56.8768C135.58 56.7288 135.536 56.5216 135.373 56.3736C134.737 55.3967 134.07 54.5086 133.36 53.5022C133.153 53.147 132.827 52.8509 132.635 52.4957C131.762 51.2524 130.785 50.0832 129.719 48.8843C129.645 48.7659 129.556 48.7363 129.586 48.6475C129.423 48.4995 129.349 48.3811 129.186 48.2331C128.446 47.3302 127.588 46.3829 126.848 45.4801L126.774 45.3617C126.714 45.2433 126.611 45.2137 126.64 45.1249C126.478 44.9768 126.404 44.8584 126.241 44.7104C125.412 43.778 124.465 42.8159 123.591 41.9723C119.506 37.9316 114.962 34.0686 110.093 30.6051C103.965 26.2981 97.2458 22.6274 90.0969 19.8449L89.7269 19.7413C88.8092 19.386 87.9064 19.0308 86.9887 18.7792L86.6187 18.6756L86.5151 18.646C81.0535 16.6331 75.5624 15.4194 69.938 14.7533C68.5912 14.5757 67.1999 14.5017 65.7198 14.3833C64.3433 14.3093 63.0556 14.2501 61.8419 14.3093C60.5247 14.3537 59.1778 14.4721 57.9345 14.6349C40.869 17.2399 33.9422 32.4701 26.8525 48.2627C25.5057 51.2968 24.144 54.4346 22.6935 57.4392C19.6001 63.9072 16.1515 70.168 11.5484 75.2151C4.07391 86.7007 2.1942 98.127 5.95363 107.407C6.11644 107.555 7.1081 109.035 7.61134 110.871C11.7704 117.945 19.4669 123.481 30.612 126.367C39.4777 128.72 69.0204 138.711 96.7277 137.261C96.7277 137.261 100.783 137.083 106.23 136.284C125.915 133.309 132.487 126.604 134.13 123.94C134.885 122.637 135.195 121.527 135.195 121.527C135.743 118.463 133.434 115.918 134.677 108.147C137.267 93.0059 148.783 88.3732 146.311 78.8562H146.296Z"
        fill="#EBF8F8"
      />
      <path
        d="M133.139 100.213L106.643 95.9826L107.712 89.2811C108.058 87.116 110.091 85.6431 112.256 85.9888L130.917 88.968C133.082 89.3136 134.555 91.3462 134.209 93.5113L133.139 100.213Z"
        fill="#138186"
      />
      <path
        d="M140.983 102.734C141.411 100.053 139.592 97.5425 136.911 97.1145L103.816 91.831C103.197 91.7322 102.867 91.1508 102.949 90.6353L103.69 85.9958C104.118 83.3152 102.299 80.8044 99.6179 80.3765L82.8125 77.6935C80.1319 77.2656 77.6211 79.085 77.1932 81.7657L70.099 126.202C69.6711 128.883 71.4905 131.393 74.1711 131.821L129.949 140.726C132.629 141.154 135.14 139.335 135.568 136.654L140.983 102.734Z"
        fill="#FDA402"
      />
      <path
        d="M126.249 104.293L99.7519 100.063L100.822 93.3612C101.167 91.1961 103.2 89.7232 105.365 90.0688L124.026 93.0481C126.191 93.3937 127.664 95.4262 127.319 97.5913L126.249 104.293Z"
        fill="#1DC1C9"
      />
      <path
        d="M133.978 107.537C134.455 104.547 132.376 101.677 129.386 101.2L97.6308 96.1305C96.7029 95.9823 96.0098 95.0258 96.1579 94.0979L96.7011 90.6956C97.1784 87.7057 95.099 84.8362 92.1091 84.3589L76.644 81.8899C73.6541 81.4126 70.7847 83.492 70.3073 86.4819L63.4436 129.475C62.9663 132.465 65.0456 135.334 68.0356 135.812L122.473 144.502C125.463 144.98 128.332 142.9 128.809 139.91L133.978 107.537Z"
        fill="#FFE181"
      />
      <path
        d="M86.1138 118.609L76.5254 117.078C73.8448 116.65 71.334 118.47 70.9061 121.15C70.4781 123.831 72.2976 126.342 74.9782 126.77L84.5666 128.301C87.2472 128.728 89.7579 126.909 90.1859 124.228C90.5108 121.531 88.6913 119.021 86.1138 118.609Z"
        fill="white"
      />
      <path
        d="M116.603 118.628L75.5896 112.08C73.3152 111.717 71.1559 113.282 70.7927 115.556L69.2222 125.394C68.859 127.669 70.4228 129.834 72.6973 130.197L113.71 136.745C115.985 137.108 118.144 135.543 118.508 133.263L120.079 123.425C120.442 121.15 118.877 118.991 116.603 118.628Z"
        fill="white"
      />
      <path
        d="M100.657 128.048L90.061 126.356C89.6425 126.289 89.3571 125.895 89.4239 125.477C89.4907 125.058 89.8846 124.773 90.3031 124.84L100.899 126.531C101.318 126.598 101.603 126.992 101.536 127.411C101.469 127.829 101.075 128.115 100.657 128.048Z"
        fill="#2D9EA3"
      />
      <path
        d="M111.588 125.999L90.6508 122.657C90.2323 122.59 89.9469 122.196 90.0137 121.778C90.0805 121.359 90.4744 121.074 90.8929 121.141L111.83 124.483C112.249 124.55 112.534 124.944 112.467 125.362C112.4 125.781 112.007 126.066 111.588 125.999Z"
        fill="#2D9EA3"
      />
      <path
        d="M108.856 129.357L104.938 128.731C104.519 128.664 104.234 128.27 104.301 127.852C104.368 127.433 104.762 127.148 105.18 127.215L109.098 127.84C109.517 127.907 109.802 128.301 109.735 128.72C109.668 129.138 109.275 129.423 108.856 129.357Z"
        fill="#2D9EA3"
      />
      <path
        d="M84.206 122.748C83.7548 125.574 81.0961 127.501 78.2697 127.05C75.4433 126.598 73.5167 123.94 73.9679 121.113C74.4191 118.287 77.0778 116.36 79.9042 116.811C82.7306 117.263 84.6573 119.921 84.206 122.748Z"
        fill="url(#paint0_linear_104064_371145)"
      />
      <path
        d="M78.1813 124.366C77.9812 124.334 77.7997 124.225 77.6773 124.056L76.4356 122.327C76.1859 121.983 76.2694 121.499 76.607 121.254C76.9446 121.009 77.4359 121.088 77.6806 121.425L78.4543 122.5L80.7453 120.695C81.0797 120.432 81.5618 120.49 81.8256 120.824C82.0894 121.159 82.0311 121.641 81.6967 121.905L78.772 124.212C78.6083 124.341 78.3936 124.4 78.1874 124.367L78.1753 124.365L78.1813 124.366Z"
        fill="white"
      />
      <path
        d="M63.6037 26.1455C62.7451 23.8162 60.1702 22.6282 57.8409 23.4868L29.0821 34.087C28.5445 34.2851 28.0211 33.9692 27.856 33.5213L26.37 29.4897C25.5114 27.1603 22.9365 25.9724 20.6071 26.8309L6.00376 32.2136C3.67438 33.0722 2.48644 35.647 3.34502 37.9764L17.5776 76.5902C18.4362 78.9196 21.0111 80.1075 23.3405 79.249L71.8093 61.3839C74.1387 60.5253 75.3266 57.9504 74.468 55.621L63.6037 26.1455Z"
        fill="#FDA402"
      />
      <path
        d="M43.5642 10.0599L55.9558 20.9163C57.3292 22.1227 57.4671 24.2157 56.2607 25.5892L39.925 44.2354C38.7187 45.6088 36.6256 45.7466 35.2559 44.5451L22.8643 33.6886C21.4908 32.4823 21.353 30.3893 22.5545 29.0195L38.8902 10.3734C40.0966 8.99991 42.1859 8.85727 43.5642 10.0599Z"
        fill="#57D478"
      />
      <path
        d="M36.2499 33.5085L33.1772 37.0166C32.7751 37.4756 32.8213 38.1736 33.2803 38.5756L34.7221 39.8385C35.1811 40.2405 35.8791 40.1944 36.2812 39.7354L39.3539 36.2273C39.7559 35.7683 39.7098 35.0703 39.2508 34.6683L37.809 33.4054C37.35 33.0034 36.652 33.0495 36.2499 33.5085Z"
        fill="white"
      />
      <path
        d="M42.5409 26.3191L39.4682 29.8271C39.0662 30.2861 39.1123 30.9841 39.5713 31.3862L41.0131 32.6491C41.4721 33.0511 42.1701 33.0049 42.5722 32.5459L45.6449 29.0379C46.047 28.5789 46.0008 27.8809 45.5418 27.4788L44.1 26.2159C43.641 25.8139 42.943 25.8601 42.5409 26.3191Z"
        fill="white"
      />
      <path
        d="M48.8359 19.1335L45.7631 22.6416C45.3611 23.1006 45.4072 23.7986 45.8662 24.2006L47.308 25.4635C47.767 25.8655 48.4651 25.8194 48.8671 25.3604L51.9398 21.8523C52.3419 21.3933 52.2957 20.6953 51.8367 20.2933L50.3949 19.0304C49.9359 18.6283 49.2379 18.6745 48.8359 19.1335Z"
        fill="white"
      />
      <path
        d="M30.9335 28.8581L27.8608 32.3662C27.4587 32.8252 27.5049 33.5232 27.9639 33.9252L29.4057 35.1881C29.8647 35.5902 30.5627 35.544 30.9648 35.085L34.0375 31.5769C34.4395 31.1179 34.3934 30.4199 33.9344 30.0179L32.4926 28.755C32.0336 28.353 31.3356 28.3991 30.9335 28.8581Z"
        fill="white"
      />
      <path
        d="M37.2284 21.6726L34.1557 25.1806C33.7537 25.6396 33.7998 26.3376 34.2588 26.7397L35.7006 28.0026C36.1596 28.4046 36.8576 28.3584 37.2597 27.8994L40.3324 24.3914C40.7345 23.9324 40.6883 23.2344 40.2293 22.8323L38.7875 21.5695C38.3285 21.1674 37.6305 21.2136 37.2284 21.6726Z"
        fill="white"
      />
      <path
        d="M43.5234 14.487L40.4506 17.9951C40.0486 18.4541 40.0947 19.1521 40.5537 19.5541L41.9955 20.817C42.4545 21.2191 43.1526 21.1729 43.5546 20.7139L46.6273 17.2059C47.0294 16.7469 46.9832 16.0488 46.5242 15.6468L45.0824 14.3839C44.6234 13.9819 43.9254 14.028 43.5234 14.487Z"
        fill="white"
      />
      <rect
        x="6.16016"
        y="13.4531"
        width="27.2055"
        height="31.2736"
        rx="6"
        transform="rotate(-10.4057 6.16016 13.4531)"
        fill="#AAC8FA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1101 9.9732L12.0615 12.3694C8.80227 12.9679 6.64535 16.0952 7.24386 19.3544L10.725 38.3111C11.3235 41.5703 14.4508 43.7272 17.71 43.1287L32.6654 40.3823C35.9247 39.7838 38.0816 36.6565 37.4831 33.3973L34.3525 16.3499L28.5115 17.4225C27.4251 17.622 26.3827 16.903 26.1832 15.8166L25.1101 9.9732Z"
        fill="#5491F5"
      />
      <path
        d="M12.6087 28.7608C12.49 28.0611 12.9216 27.3891 13.5727 27.2597L31.1864 23.7591C31.8375 23.6297 32.4614 24.092 32.5801 24.7917C32.6987 25.4913 32.2671 26.1633 31.6161 26.2927L14.0024 29.7933C13.3513 29.9227 12.7273 29.4604 12.6087 28.7608Z"
        fill="white"
      />
      <path
        d="M13.2836 32.7405C13.1649 32.0408 13.5965 31.3688 14.2476 31.2394L31.8613 27.7388C32.5124 27.6094 33.1363 28.0717 33.255 28.7713C33.3736 29.471 32.942 30.143 32.2909 30.2724L14.6772 33.773C14.0262 33.9024 13.4022 33.4401 13.2836 32.7405Z"
        fill="white"
      />
      <path
        d="M13.9584 36.7202C13.8398 36.0205 14.2714 35.3485 14.9225 35.2191L23.6493 33.4847C24.3003 33.3553 24.9243 33.8176 25.043 34.5172C25.1616 35.2169 24.73 35.8889 24.0789 36.0183L15.3521 37.7527C14.7011 37.8821 14.0771 37.4198 13.9584 36.7202Z"
        fill="white"
      />
      <path
        d="M52.5512 33.9833L29.5263 42.4701L27.3798 36.6467C26.6864 34.7652 27.6481 32.6808 29.5295 31.9873L45.7455 26.0103C47.6269 25.3168 49.7113 26.2785 50.4048 28.1599L52.5512 33.9833Z"
        fill="#32C9C9"
      />
      <path
        d="M60.1671 33.1104C59.2095 30.5122 56.2668 29.1546 53.6686 30.1122L26.0745 40.2831C25.2682 40.5803 24.2873 40.1278 23.9901 39.3214L22.9004 36.3649C21.9427 33.7668 19 32.4091 16.4019 33.3668L2.96318 38.3201C0.365032 39.2778 -0.992621 42.2205 -0.0349732 44.8186L13.7353 82.1782C14.693 84.7763 17.6357 86.134 20.2339 85.1763L67.538 67.7405C70.1361 66.7829 71.4938 63.8402 70.5361 61.242L60.1671 33.1104Z"
        fill="#FFE181"
      />
      <circle
        cx="36.4394"
        cy="58.4558"
        r="14.1192"
        transform="rotate(-19.7413 36.4394 58.4558)"
        fill="white"
      />
      <path
        d="M27.9561 56.647C32.1196 55.0113 34.2419 51.1415 34.2419 51.1415C34.2419 51.1415 38.7998 53.002 42.5219 52.2659C44.0478 57.3388 44.2194 65.5576 39.046 67.1137C33.8726 68.6697 28.8474 59.6104 27.9561 56.647Z"
        fill="#36B7BD"
      />
      <path
        d="M37.497 62.3217L33.3809 60.1091C33.1196 59.9687 33.0289 59.6673 33.1694 59.406C33.3099 59.1446 33.6112 59.054 33.8726 59.1945L37.074 60.9153L39.6728 56.0806C39.8133 55.8192 40.1147 55.7286 40.376 55.8691C40.6373 56.0096 40.728 56.3109 40.5875 56.5723L37.497 62.3217Z"
        fill="white"
      />
      <circle cx="75.4998" cy="74.9998" r="23.6815" fill="#F9716C" />
      <path
        d="M84.8973 63.6876L83.2047 66.2266L70.6094 85.5426L69.2155 87.6335L66.1289 85.5924L67.5228 83.5015L80.1181 64.2352L81.8107 61.6465L84.8973 63.6876Z"
        fill="white"
      />
      <path
        d="M83.2053 66.2277L70.61 85.5437C69.465 85.0459 68.4195 84.3489 67.5234 83.5026L80.1187 64.2363C81.2637 64.7342 82.3092 65.4311 83.2053 66.2277Z"
        fill="white"
      />
      <path
        d="M75.3895 58.4609C66.3289 58.4609 58.9609 65.8289 58.9609 74.8895C58.9609 75.686 59.0107 76.4826 59.1103 77.2293C59.2596 78.4241 59.6081 79.6189 60.0064 80.7142C62.396 86.8873 68.37 91.3181 75.3895 91.3181C84.4501 91.3181 91.8181 83.9501 91.8181 74.8895C91.8181 65.8289 84.4501 58.4609 75.3895 58.4609ZM75.3895 87.5843C73.4977 87.5843 71.6557 87.1363 70.0129 86.3895C68.8679 85.8419 67.8224 85.1947 66.9263 84.3484C65.5821 83.1536 64.5367 81.7099 63.7899 80.067C63.3419 79.0713 62.9934 78.0259 62.8441 76.8809C62.7445 76.2337 62.6947 75.5367 62.6947 74.8397C62.6947 67.8202 68.4198 62.1449 75.3895 62.1449C77.2813 62.1449 79.0735 62.5432 80.7164 63.3397C81.8614 63.8376 82.9068 64.5345 83.8029 65.3809C86.4415 67.7207 88.0843 71.106 88.0843 74.8895C88.0843 81.909 82.409 87.5843 75.3895 87.5843Z"
        fill="white"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_104064_371145"
        x1="73.974"
        y1="121.114"
        x2="84.206"
        y2="122.748"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#38B8BD" />
        <stop offset="1" stopColor="#A3DF73" />
      </linearGradient>
      <clipPath id="clip0_104064_371145">
        <rect
          width="150"
          height="150"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleDriveUnsyncIcon;
