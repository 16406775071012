import React from 'react';

export const GoogleFile = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_104334_48735)">
      <mask
        id="mask0_104334_48735"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_104334_48735)">
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L22.5086 5.91317L18.8932 0.822266Z"
          fill="#4285F4"
        />
      </g>
      <mask
        id="mask1_104334_48735"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1_104334_48735)">
        <path
          d="M19.5273 8.91211L27.5698 16.9994V9.55029L19.5273 8.91211Z"
          fill="url(#paint0_linear_104334_48735)"
        />
      </g>
      <mask
        id="mask2_104334_48735"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask2_104334_48735)">
        <path
          d="M10.2168 24.095H21.786V22.6404H10.2168V24.095ZM10.2168 27.0041H18.8937V25.5495H10.2168V27.0041ZM10.2168 16.8223V18.2768H21.786V16.8223H10.2168ZM10.2168 21.1859H21.786V19.7314H10.2168V21.1859Z"
          fill="#F1F1F1"
        />
      </g>
      <mask
        id="mask3_104334_48735"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask3_104334_48735)">
        <path
          d="M18.8926 0.822266V7.36772C18.8926 8.57317 19.8633 9.54954 21.0618 9.54954H27.5695L18.8926 0.822266Z"
          fill="#A1C2FA"
        />
      </g>
      <mask
        id="mask4_104334_48735"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask4_104334_48735)">
        <path
          d="M6.60087 0.822266C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V3.1859C4.43164 1.9859 5.40779 1.00408 6.60087 1.00408H18.8932V0.822266H6.60087Z"
          fill="white"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask5_104334_48735"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask5_104334_48735)">
        <path
          d="M25.4009 32.6408H6.60087C5.40779 32.6408 4.43164 31.659 4.43164 30.459V30.6408C4.43164 31.8408 5.40779 32.8226 6.60087 32.8226H25.4009C26.5939 32.8226 27.5701 31.8408 27.5701 30.6408V30.459C27.5701 31.659 26.5939 32.6408 25.4009 32.6408Z"
          fill="#1A237E"
          fillOpacity="0.2"
        />
      </g>
      <mask
        id="mask6_104334_48735"
        style={{ maskType: 'luminance' }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="0"
        width="24"
        height="33"
      >
        <path
          d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask6_104334_48735)">
        <path
          d="M21.0618 9.54901C19.8633 9.54901 18.8926 8.57264 18.8926 7.36719V7.54901C18.8926 8.75446 19.8633 9.73082 21.0618 9.73082H27.5695V9.54901H21.0618Z"
          fill="#1A237E"
          fillOpacity="0.1"
        />
      </g>
      <path
        d="M18.8932 0.822266H6.60087C5.40779 0.822266 4.43164 1.80408 4.43164 3.00408V30.6404C4.43164 31.8404 5.40779 32.8223 6.60087 32.8223H25.4009C26.5939 32.8223 27.5701 31.8404 27.5701 30.6404V9.54954L18.8932 0.822266Z"
        fill="url(#paint1_radial_104334_48735)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_104334_48735"
        x1="421.692"
        y1="78.3503"
        x2="421.692"
        y2="817.752"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1A237E" stopOpacity="0.2" />
        <stop offset="1" stopColor="#1A237E" stopOpacity="0.02" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_104334_48735"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(77.7354 63.7034) scale(3731.04 3731.04)"
      >
        <stop stopColor="white" stopOpacity="0.1" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <clipPath id="clip0_104334_48735">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0 0.822266)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleFile;
