export const EMPLOYEE = 'employee';
export const OWNER = 'owner';
export const CREATOR = 'creator';
export const ADMIN = 'admin';
export const MANAGER = 'manager';
export const BENCH = 'bench';
export const MAIN = 'main';
export const JOINS = 'joins';
export const CUSTOM = 'custom';
export const CONTROLLER = 'controller';
export const INDIVIDUAL = 'individual';
export const MEMBER = 'member';
export const WORKSPACE = 'workspace';
export const WORKSPACES = 'workspaces';
export const ASSET_MANAGER = 'asset_manager';
export const ACCOUNT_MANAGER = 'account_manager';

export const AUTHOR = 'author';
export const RESPONSIBLE = 'responsible';
export const MAIN_MANAGER = 'main_manager';

export const APPROVING_MANAGER = 'approving_manager';
export const PROJECT_SUPERVISOR = 'project_supervisor';

export const ALL_OPTION = {
  value: null,
  ns: 'Filters',
  label: 'AllSelected'
};

export const WORKSPACE_POSITIONS = [
  {
    value: EMPLOYEE,
    ns: 'Filters',
    label: 'EmployeeRole'
  },
  {
    value: MANAGER,
    ns: 'Filters',
    label: 'HeadOfDepartmentRole'
  },
  {
    value: OWNER,
    ns: 'Filters',
    label: 'HeadOfCompanyRole'
  },
  {
    value: ADMIN,
    ns: 'Filters',
    label: 'AdminRole'
  },
  {
    value: ASSET_MANAGER,
    ns: 'Filters',
    label: 'AssetManagerRole'
  },
  {
    value: ACCOUNT_MANAGER,
    ns: 'Filters',
    label: 'AccountManagerRole'
  }
];

export const TASK_POSITIONS = [
  { value: AUTHOR, label: 'TaskAuthor', ns: 'Task' },
  { value: RESPONSIBLE, label: 'TaskResponsible', ns: 'Task' }
];

export const TASK_AMPLITUDE_POSITIONS = {
  TASK_AUTHOR: 'task_author',
  TASK_RESPONSIBLE: 'task_responsible',
  TASK_CONTROLLER: 'task_controller',
  TASK_EXECUTOR_DIRECT: 'task_co-executor_direct',
  TASK_EXECUTOR_NOT_DIRECT: 'task_co-executor_not_direct',
  TASK_APPROVING_PERSON: 'task_approving_person'
};

export const ALL_WORKSPACE_POSITIONS = [ALL_OPTION, ...WORKSPACE_POSITIONS];

export const ALL_POSITIONS = [
  ALL_OPTION,
  ...WORKSPACE_POSITIONS,
  ...TASK_POSITIONS
];

export const SUPERIOR = 'superior';
export const SUBORDINATE = 'subordinate';
