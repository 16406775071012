import React from 'react';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import Modal from 'components/common/modal';
import Icon from 'components/common/icon';
import { ChannelsNotFoundIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import VideoBtn from 'components/common/video-btn';
import Button from 'components/common/button';

import CodeCopy from '../../../components/code-copy';

import styles from '../creation-successed.module.scss';

const CommonModal = ({
  visible,
  onClose,
  description,
  link,
  videoSlug,
  codeCopyMessage,
  buttons,
  title
}) => (
  <Modal
    open={visible}
    centered
    destroyOnClose
    width={440}
    contentStyle={{ padding: '48px 24px 32px', textAlign: 'center' }}
    onClose={onClose}
    className={styles.root}
  >
    <Icon component={ChannelsNotFoundIcon} style={{ marginBottom: 20 }} />

    <Typography.Title level={1} wieght="bold" style={{ marginBottom: 20 }}>
      {title}
    </Typography.Title>

    <Typography.Paragraph color="black-45" style={{ marginBottom: 20 }}>
      {description}
    </Typography.Paragraph>

    <CodeCopy
      link={link}
      message={codeCopyMessage}
      className={styles.modalCodeCopy}
    />

    {videoSlug && (
      <VideoBtn slug={videoSlug} style={{ margin: '0 auto 20px' }} />
    )}

    <div className={styles.buttons}>
      {(buttons || []).map(({ text, onClick, ...props }, index) => (
        <Button
          key={`${text}-${index}`}
          {...props}
          onClick={typeof onClick === 'function' ? onClick : onClose}
        >
          {text}
        </Button>
      ))}
    </div>
  </Modal>
);

CommonModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  description: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  videoSlug: PropTypes.string,
  codeCopyMessage: PropTypes.string,
  buttons: PropTypes.array,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

CommonModal.defaultProps = {
  videoSlug: null,
  codeCopyMessage: undefined,
  title: (
    <Translation ns="ChannelCreated">
      {t => t('ChannelCreatedHeading')}
    </Translation>
  ),
  buttons: []
};

export default CommonModal;
