import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NOTIFICATIONS } from 'constants/index';

import Drawer from 'components/common/drawer';
import Icon from 'components/common/icon';
import Button from 'components/common/button';
import NotificationsWithActions from 'components/dashboard-view/views/notifications/with-actions';
import NotificationList from 'components/dashboard-view/views/notifications/list';
import FilterPane from 'components/common/filter-pane';

import {
  getActiveWorkspace,
  getAllWorkspaces,
  getPersonalWorkspace,
  getUserEmployee,
  setActiveId
} from 'store/workspace';
import {
  getActiveNotificationsTotalItems,
  getDrawerVisible,
  setDrawerVisible
} from 'store/notifications';
import { getIsOnlyUserProfile, getUser } from 'store/user';
import { initialFilter } from 'store/operator/reducer';
import { clearFilter, getFilterNotifications } from 'store/operator';

import { useRoutesService } from 'services/routes';
import useFilterIndicator from 'hooks/common/use-filter-indicator';

import useCollapsedNotificationsInBellFilterPanel from '../dashboard-view/views/notifications/panel/use-collapsed-notifications-in-bell-filter-panel';
import Filters from '../dashboard-view/views/notifications/filters';

import styles from './notifications-view.module.scss';

export const NotificationsView = () => {
  const dispatch = useDispatch();

  const visible = useSelector(getDrawerVisible);
  const user = useSelector(getUser);
  const workspaces = useSelector(getAllWorkspaces);
  const workspace = useSelector(getActiveWorkspace);
  const personalWorkspace = useSelector(getPersonalWorkspace);
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);
  const employee = useSelector(getUserEmployee);

  const activeWorkspace = workspace || personalWorkspace;

  const activeNotificationsTotal = useSelector(state =>
    getActiveNotificationsTotalItems(state)(activeWorkspace.id)
  );

  const location = useLocation();

  const [afterVisibleChange, setAfterVisibleChange] = useState(false);

  const routes = useRoutesService();

  const { t } = useTranslation('NotificationsBell');

  const onClose = useCallback(
    () => dispatch(setDrawerVisible(false)),
    [dispatch]
  );

  const goToSettings = useCallback(() => {
    dispatch(setActiveId({ workspaceId: activeWorkspace.id }));

    routes.toUserWorkspace({
      employeeId: workspaces[activeWorkspace.id].user.id,
      view: NOTIFICATIONS
    });

    onClose();
  }, [dispatch, onClose, routes, activeWorkspace.id, workspaces]);

  useEffect(() => {
    if (visible) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const filters = useSelector(getFilterNotifications);

  const [collapsedFilter, setCollapsedFilter] =
    useCollapsedNotificationsInBellFilterPanel();

  const filterIndicator = useFilterIndicator(initialFilter, filters);

  const hasFilter = !!filterIndicator;

  const onResetFilter = () =>
    hasFilter && dispatch(clearFilter({ entityId: employee.id }));

  return (
    <Drawer
      open={visible}
      onClose={onClose}
      title={
        <>
          <Drawer.Title>{t('FeedHeading')}</Drawer.Title>

          {user.personalWorkspaceId !== activeWorkspace.id && (
            <Button
              type="link"
              style={{ padding: '0 10px', height: 'auto' }}
              onClick={goToSettings}
            >
              <Icon type="setting" size={20} />
            </Button>
          )}
        </>
      }
      destroyOnClose
      width={collapsedFilter.opened ? 1180 : 840}
      bodyStyle={{ padding: '0px 10px 24px 0px', gap: 20 }}
      afterOpenChange={setAfterVisibleChange}
    >
      <FilterPane
        name={NOTIFICATIONS}
        opened={collapsedFilter.opened}
        onOpenedChange={opened => setCollapsedFilter(!opened)}
        indicator={filterIndicator}
        sizes={{ width: 380, maxWidth: 380 }}
        onReset={onResetFilter}
        bodyStyle={{ padding: 0 }}
        secondPaneClassName={
          activeNotificationsTotal > 0
            ? styles.secondPaneWithActions
            : styles.secondPane
        }
        firstPaneClassName={styles.firstPane}
        initialOpenedState={false}
      >
        <Filters isNotificationInBellVisible={visible} />

        <>
          <NotificationsWithActions afterVisibleChange={afterVisibleChange} />

          {!isOnlyUserProfile && (
            <NotificationList
              afterVisibleChange={afterVisibleChange}
              view={NOTIFICATIONS}
              listClassName={styles.notificationList}
            />
          )}
        </>
      </FilterPane>
    </Drawer>
  );
};

export default NotificationsView;
