import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';

import { FormDatePicker } from 'components/common/hook-form';

import styles from './validity-date-multi-attachment.module.scss';

const ValidityDateMultiAttachment = ({
  index,
  data: { parentName = 'validityDates' }
}) => {
  const { t } = useTranslation(['AddFile', 'Errors']);

  return (
    <FormDatePicker
      name={`${parentName}.${index}.validityDate`}
      label={t('ValidityExpiration', { ns: 'AddFile' })}
      placeholderText={t('Choose theExpirationDate', { ns: 'AddFile' })}
      showTimeSelect
      minDate={new Date()}
      wrapperClassname={styles.datePickerWrapper}
      itemProps={{ className: styles.formItem }}
      className={styles.datePicker}
      rules={{
        validate: value =>
          !value || moment(value).isAfter(moment(), 'day')
            ? true
            : t('PeriodGreaterThan24Hours', {
                ns: 'Errors'
              })
      }}
    />
  );
};

ValidityDateMultiAttachment.propTypes = {
  index: PropTypes.number.isRequired,
  data: PropTypes.shape({
    parentName: PropTypes.string
  }).isRequired
};

export default ValidityDateMultiAttachment;
