import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ORDER_STATUS,
  MESSAGE_DURATION
} from 'constants/index';

import Typography from 'components/common/typography';
import Modal from 'components/common/modal';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { DeleteEventIcon } from 'components/common/icons';

import {
  createAssetRecord,
  deleteAssetRecord,
  fetchAssetOrderStatusBookingData,
  fetchAssetTaskBookingData,
  updateAssetRecord
} from 'store/calendar';
import { fetchRelations as fetchTaskRelations } from 'store/tasks';
import { fetchOrderStatusRelations } from 'store/order-statuses';

import Form from './form';

const AssetBookingModal = ({
  visible,
  values,
  title,
  description,
  allowEditDates,
  bookingData,
  onClose,
  kind,
  objectId
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation([
    'AddCalendarAsset',
    'DeleteCalendarAsset',
    'Common'
  ]);

  const [isLoading, setIsLoading] = useState(false);
  const [visibleAccepCancel, setVisibleAcceptCancel] = useState(false);

  const fetchBookingData = entityKind => {
    if (entityKind === TYPE_TASK) {
      return dispatch(fetchAssetTaskBookingData({ id: objectId }));
    }

    if (entityKind === TYPE_ORDER_STATUS) {
      return dispatch(fetchAssetOrderStatusBookingData({ id: objectId }));
    }

    return Promise.resolve();
  };

  const onCancel = async () => {
    try {
      setIsLoading(true);

      await dispatch(deleteAssetRecord({ id: bookingData.id }));

      await fetchBookingData(kind);

      onClose();
      setVisibleAcceptCancel(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async ({ asset, dateStart, dateEnd }) => {
    try {
      setIsLoading(true);

      const record = {
        assetId: asset.value,
        kind,
        objectId,
        dateStart,
        dateEnd
      };

      if (bookingData) {
        await dispatch(updateAssetRecord({ recordId: bookingData.id, record }));
      } else {
        await dispatch(createAssetRecord({ record }));
      }

      if (kind === TYPE_TASK) {
        await dispatch(fetchTaskRelations({ id: objectId }));
      }

      if (kind === TYPE_ORDER_STATUS) {
        await dispatch(fetchOrderStatusRelations({ id: objectId }));
      }

      await fetchBookingData(kind);

      onClose();

      message.success(t('BookingSaved', { ns: 'Common' }), MESSAGE_DURATION);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        width={508}
        title={title}
        contentStyle={{ padding: 24 }}
        open={visible}
        destroyOnClose
        onClose={onClose}
      >
        <Typography.Paragraph style={{ marginBottom: 16 }}>
          {description}
        </Typography.Paragraph>

        <Form
          defaultValues={values}
          allowEditDates={allowEditDates}
          isLoading={isLoading}
          bookingData={bookingData}
          onCancel={() => setVisibleAcceptCancel(true)}
          onSubmit={onSubmit}
        />
      </Modal>

      <ModalDeleteConfirm
        iconModal={DeleteEventIcon}
        title={t('DeleteCalendarAssetHeading', { ns: 'DeleteCalendarAsset' })}
        open={visibleAccepCancel}
        isLoading={isLoading}
        deleteBtnText={t('TakeReservationOffBtn')}
        onClose={() => setVisibleAcceptCancel(false)}
        onConfirm={onCancel}
      />
    </>
  );
};

AssetBookingModal.propTypes = {
  values: PropTypes.shape({
    asset: PropTypes.object,
    dateStart: PropTypes.instanceOf(Date),
    dateEnd: PropTypes.instanceOf(Date)
  }),
  visible: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  allowEditDates: PropTypes.bool,
  bookingData: PropTypes.shape({
    id: PropTypes.number
  }),
  kind: PropTypes.oneOf([TYPE_TASK, TYPE_ORDER_STATUS]),
  objectId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClose: PropTypes.func
};

AssetBookingModal.defaultProps = {
  values: {},
  visible: false,
  allowEditDates: true,
  bookingData: null,
  title: null,
  description: null,
  kind: null,
  objectId: null,
  onClose: () => {}
};

export default AssetBookingModal;
