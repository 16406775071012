import 'moment/locale/ru';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/pl';

const localeMap = {
  'ru-RU': 'ru',
  'en-US': 'en',
  'de-DE': 'de',
  'fr-FR': 'fr',
  'es-ES': 'es',
  'pt-PT': 'pt',
  'pl-PL': 'pl'
};

export const getCalendarLocale = language => localeMap[language] || 'en';
