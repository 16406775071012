import React, { useState, useEffect } from 'react';
import { Divider, Spin, Alert } from 'antd';
import moment from 'moment';
import classnames from 'classnames';
import { isEmpty, union, uniq } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import {
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TYPE_TIME,
  TITLE,
  DESCRIPTION,
  TYPE_ADDRESS,
  TYPE_CONTACT_TO,
  TYPE_CONTACT,
  TYPE_PROJECT,
  TYPE_CO_RESPONSIBLES,
  TYPE_RELATIONS,
  TYPE_MEETING_CONTACTS,
  STATUS_DONE,
  STATUS_CANCELLED,
  TYPE_REQUEST,
  TYPE_CHANNEL,
  DATE_TIME_FORMAT_FM,
  DATE_PICKER_TIME_FORMAT
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import { SkeletonEntityCreation } from 'components/common/skeletons';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import Typography from 'components/common/typography';
import { CloseIcon } from 'components/common/icon/icons';
import {
  FormChanneleSelect,
  FormContactSelect,
  FormDatePicker,
  FormInput,
  FormInputNumber,
  FormLocationInput,
  FormProjectSelect,
  FormSprintSelect,
  FormSwitch,
  withoutBubbling
} from 'components/common/hook-form';
import FormNewEditor from 'components/common/hook-form/markdown';
import { convertMessageToString } from 'components/common/comments/converters';

import { validateMinLength } from 'utils/validators';
import { mapUserToSelectValue } from 'utils/map-user-to-select';
import useMinMaxTime from 'hooks/common/use-min-max-time';
import alphabeticallySort from 'utils/alphabetically-sort';
import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import getTimeByEstimate from 'utils/get-time-by-estimate';

import { getFullFilledFields } from '../creator/utils';
import ActionTypeController from '../components/actions/type-controller';
import ActionTypeResponsible from '../components/actions/type-responsible';
import useTaskInfo from '../creator/main-form/use-task-info';

import styles from './editor.module.scss';

const mapValueForNewFields = value => ({
  value: value.id,
  label: value
});

const getDateEndRules = ({
  startDate,
  checkSameDates,
  checkSameFormat,
  t
}) => ({
  required: t('RequiredField', { ns: 'Errors' }),
  validate: value => {
    if (checkSameDates && moment(startDate).isSame(value, checkSameFormat)) {
      return t('DateStartCannotBeEqualDateEnd', { ns: 'Errors' });
    }

    if (moment(startDate).isAfter(value)) {
      return t('DateStartCannotBeAfterDateEnd', { ns: 'Errors' });
    }

    return true;
  }
});

const BaseForm = ({
  onSubmit,
  defaultValues = {},
  isLoading,
  isLoadingAll
}) => {
  const [activeFields, setActiveFields] = useState([]);
  const [contactToVisibility, setContactToVisibility] = useState(false);
  const [visibleDateStart, setVisibleDateStart] = useState(false);
  const [visibleEstimate, setVisibleEstimate] = useState(false);

  const { t } = useTranslation([
    'AddTask',
    'Requests',
    'Errors',
    'TaskLinks',
    'EditTaskTemplate'
  ]);

  const isTopTask = !(defaultValues || {}).parent;

  const isStatusCancelled =
    defaultValues && defaultValues.status === STATUS_CANCELLED;
  const isStatusDone = defaultValues && defaultValues.status === STATUS_DONE;

  const allowEditProject = isTopTask;
  const allowEditSprint =
    allowEditProject && !isStatusCancelled && !isStatusDone;

  // const hasInputEstimate = defaultValues && defaultValues.estimation > 0;

  const { getTaskInfo } = useTaskInfo();

  const hasInputEstimate = defaultValues && defaultValues.estimation > 0;
  const apartValues =
    defaultValues && getTimeByEstimate(defaultValues.estimation);

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      dateStart:
        defaultValues &&
        defaultValues.dateStart &&
        moment(defaultValues.dateStart, DATE_TIME_FORMAT_FM).toDate(),
      dateEnd:
        defaultValues &&
        defaultValues.dateEnd &&
        moment(defaultValues.dateEnd, DATE_TIME_FORMAT_FM).toDate(),
      description: {
        description: convertMessageToString(defaultValues.description),
        fileList: defaultValues.fileList || []
      },
      hours: hasInputEstimate ? apartValues.hours.toString() : undefined,
      minutes: hasInputEstimate ? apartValues.minutes.toString() : undefined,
      tags: defaultValues.tags || [],
      relations: defaultValues.relations || [],
      responsible: mapUserToSelectValue(defaultValues.responsible || []),
      controller: defaultValues.controller
        ? mapValueForNewFields(defaultValues.controller)
        : null,
      project: defaultValues.project
        ? { value: defaultValues.project.id, label: defaultValues.project }
        : undefined,
      sprint: defaultValues.sprint
        ? { value: defaultValues.sprint.id, label: defaultValues.sprint }
        : undefined,
      storyPoint: defaultValues.storyPoint || undefined,
      isStoryPointEstimation: defaultValues.isStoryPointEstimation || false,
      channel: defaultValues.channel
        ? mapValueForNewFields(defaultValues.channel)
        : null,
      contact: defaultValues.contact
        ? mapValueForNewFields(defaultValues.contact)
        : null
    }
  });

  const startDate = methods.watch(TYPE_DATE_START);

  const isFilesLoading = useUploadingFiles(
    (methods.watch(DESCRIPTION) || {}).fileList
  );

  const isStoryPointEstimation =
    methods.watch('isStoryPointEstimation') === undefined
      ? defaultValues.isStoryPointEstimation
      : methods.watch('isStoryPointEstimation');

  const { validateValidityDateStateValues, changeValidityDateStateValue } =
    useValidityDateFileList({
      fileList: (methods.watch(DESCRIPTION) || {}).fileList,
      onChange: updatedFileList =>
        methods.setValue({
          [DESCRIPTION]: {
            ...methods.watch(DESCRIPTION),
            fileList: updatedFileList
          }
        })
    });

  const sortByCoResponsiblesAndController = (a, b) => {
    if (a === TYPE_CO_RESPONSIBLES || a === TYPE_CONTROLLER) {
      return -1;
    }
    if (
      b === TYPE_CO_RESPONSIBLES ||
      b === TYPE_CONTROLLER ||
      a === TYPE_MEETING_CONTACTS
    ) {
      return 1;
    }
    return 0;
  };

  const addFields = (...types) =>
    setActiveFields(
      uniq([...activeFields, ...types]).sort(sortByCoResponsiblesAndController)
    );
  const removeField = type =>
    setActiveFields(activeFields.filter(field => field !== type));

  useEffect(() => {
    setVisibleDateStart(false);
    setContactToVisibility(false);

    const fullFilledFields = getFullFilledFields({
      defaultValues,
      activeFields
    });

    if (fullFilledFields.includes(TYPE_DATE_START)) setVisibleDateStart(true);
    if (fullFilledFields.includes(TYPE_TIME)) setVisibleEstimate(true);

    addFields(...union(fullFilledFields));

    if (defaultValues.isScheduler) {
      addFields(TYPE_DATE_START);
      setVisibleDateStart(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  const transformSubmitedValues = async value => {
    const isValidValidityDates = validateValidityDateStateValues();

    if (!isValidValidityDates) {
      return;
    }

    const allValues = methods.getValues();
    const dirtyValues = Object.keys(allValues).reduce((acc, key) => {
      if (allValues[key] !== defaultValues[key]) {
        acc[key] = allValues[key];
      }
      return acc;
    }, {});

    const getProjectValues = () => {
      if (dirtyValues[TYPE_PROJECT] && allowEditProject) {
        return {
          project: (dirtyValues.project || {}).value || null,
          sprint: (dirtyValues.sprint || {}).value || null
        };
      }

      return {
        project: allowEditProject ? null : undefined,
        sprint: allowEditSprint ? null : undefined
      };
    };

    const getRelations = () => {
      let results = [];

      if (dirtyValues[TYPE_RELATIONS]) {
        results = [...(dirtyValues.relations || [])];
      }

      if (dirtyValues[TYPE_MEETING_CONTACTS]) {
        results = [
          ...results,
          ...transformValueToRelation(
            dirtyValues.meetingContacts || [],
            TYPE_CONTACT
          )
        ];
      }

      return results;
    };

    const taskInfoData = await getTaskInfo({
      channelUuid: dirtyValues.channel?.label?.uuid || null,
      kind: dirtyValues.channel?.label?.kind || null,
      contactId: dirtyValues.contact?.value || null,
      channelId: dirtyValues.channel?.value || null
    });

    // Убираем лишние поля и оставляем только необходимые
    const submitData = {
      ...dirtyValues,
      ...getProjectValues(),
      kind: TYPE_REQUEST,
      storyPoint: +dirtyValues.storyPoint || 0,
      relations: getRelations(),
      location: dirtyValues.location || '',
      locationExact: dirtyValues.locationExact || '',
      description: [
        { text: dirtyValues.description.description },
        ...(dirtyValues.description.links || [])
      ],
      schedulerConfig: {
        isActive: dirtyValues.isActiveScheduler,
        freqInterval: dirtyValues.freqInterval,
        weekdays: dirtyValues.weekdays,
        freqType: dirtyValues.freqType
      },
      taskInfo: taskInfoData.taskInfo
    };

    // Убираем лишние поля, которые не нужны для заявок
    delete submitData.additionalData;
    delete submitData.author;
    delete submitData.chats;
    delete submitData.permissions;
    delete submitData.dateEnd;

    await onSubmit({
      values: submitData
    });
  };

  const getDateFieldValue = key => {
    const value = methods.watch(key);

    return moment(value).isValid() ? value : undefined;
  };

  const endDate = getDateFieldValue(TYPE_DATE_END);
  const isOutdated = moment(new Date()).isAfter(endDate);

  const permissions = defaultValues && defaultValues.permissions;
  const allowChange = permissions && permissions.updateTask;

  const allowChangeDateStart = allowChange;
  const allowChangeEstimate = !!permissions && permissions.updateTaskEstimate;
  const allowChangeResponsible =
    permissions && permissions.changeTaskResponsible;
  const allowChangeController = permissions && permissions.changeTaskController;

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate,
    endDate
  });

  const project = methods.watch('project');
  const projectId = (project || {}).value;

  const actions = [
    {
      type: TYPE_DATE_START,
      title: t('StartDate'),
      onClick: () => {
        addFields(TYPE_DATE_START);
        setVisibleDateStart(true);
      },
      icon: 'calendar',
      allow: allowChangeDateStart,
      visibility: true
    },
    {
      type: TYPE_ADDRESS,
      title: t('AddressHeading'),
      onClick: () => addFields(TYPE_ADDRESS),
      icon: 'environment',
      allow: true,
      visibility: true,
      component: (
        <div className={styles.flexWrap} data-qa="qa-pmdbroh78r37ims">
          <FormLocationInput
            label={t('MainAddress')}
            name="location"
            placeholder={t('EnterAddress')}
            itemProps={{
              className: styles.location
            }}
          />

          <FormInput
            allowClear={false}
            autoComplete="off"
            className={styles.locationExact}
            label={t('AdditionalAddress')}
            name="locationExact"
            maxLength={25}
          />
        </div>
      )
    },
    {
      type: TYPE_CONTROLLER,
      title: t('Controller'),
      onClick: () => addFields(TYPE_CONTROLLER),
      icon: 'user-add',
      allow: allowChangeController,
      visibility: true,
      component: (
        <ActionTypeController
          projectId={projectId}
          defaultValues={defaultValues.controller}
        />
      )
    },
    {
      type: TYPE_TIME,
      title: t('EstimatedTime'),
      onClick: () => {
        addFields(TYPE_TIME);
        setVisibleEstimate(true);
      },
      icon: 'timer',
      allow: allowChangeEstimate,
      visibility: true
    },
    {
      type: TYPE_PROJECT,
      title: t('Project'),
      onClick: () => addFields(TYPE_PROJECT),
      icon: 'project',
      hideDeleteBtn: !allowEditProject,
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: 2
      },
      component: (
        <>
          <Typography.Text className={styles.subTitle}>
            {t('Project')}
          </Typography.Text>

          <div className={styles.projectFields}>
            <FormProjectSelect
              name="project"
              label={t('Project')}
              params={{
                isActive: true
              }}
              isClearable
              isDisabled={!allowEditProject}
              callback={() => methods.setValue({ sprint: null })}
            />

            {project && !!project.label.sprintsCount && allowEditSprint && (
              <FormSprintSelect
                name="sprint"
                label={t('Sprint')}
                params={{
                  project: projectId,
                  status: ['active', 'planned'],
                  isLag: false
                }}
              />
            )}
          </div>
        </>
      )
    }
  ].sort((a, b) => alphabeticallySort(a, b, 'title'));

  const renderTitle = () => (
    <div className={styles.field} data-qa="qa-d8fw0lok518ow1u">
      <FormInput
        label={t('TaskName')}
        className={styles.title}
        name={TITLE}
        rules={{
          required: t('RequiredField', { ns: 'Errors' }),
          minLength: validateMinLength(2)
        }}
      />
    </div>
  );

  const renderDescription = () => (
    <div className={styles.field} data-qa="qa-yvmbmxrfe6s9ats">
      <FormNewEditor
        name={DESCRIPTION}
        label={t('TaskDescriptionMobile')}
        placeholder={t('RequestDescriptionPlchldr', { ns: 'Requests' })}
        actionsDeps={{
          entityType: TYPE_REQUEST
        }}
        toolbarHidden
        allowAttach
        destination={{
          entityId: defaultValues.id,
          entityType: TYPE_REQUEST
        }}
        hideValidityDateAction
        validityDateDeps={{ changeValidityDateStateValue }}
        highlightAttachmentsBackground
      />
    </div>
  );

  const renderResponsible = () => (
    <ActionTypeResponsible
      defaultValues={defaultValues}
      projectId={projectId}
      isDisabled={!allowChangeResponsible}
    />
  );

  const renderDates = () => (
    <div className={styles.dates} data-qa="qa-v5k4pfxja3nbndv">
      {visibleDateStart && (
        <div className={styles.dateStart} data-qa="qa-pihxbjrb94sdm36">
          <FormDatePicker
            wrapperClassname={styles.datePicker}
            name={TYPE_DATE_START}
            label={t('StartDate')}
            dateFormat={DATE_PICKER_TIME_FORMAT}
            minDate={new Date()}
            maxDate={isOutdated ? undefined : endDate}
            minTime={minTimeStart}
            maxTime={maxTimeStart}
            openToDate={
              moment(startDate).isAfter(new Date()) ? startDate : new Date()
            }
          />

          <Button
            type="text"
            icon={<CloseIcon />}
            className={styles.btnDelete}
            data-qa="qa-1auv4jgdhfcz0tn"
            onClick={() => {
              removeField(TYPE_DATE_START);
              setVisibleDateStart(false);
            }}
          />
        </div>
      )}

      <FormDatePicker
        name={TYPE_DATE_END}
        label={t('TaskDueDate')}
        style={{ maxWidth: 180 }}
        rules={getDateEndRules({
          startDate,
          checkSameDates: true,
          checkSameFormat: 'minute',
          t
        })}
        dateFormat={DATE_PICKER_TIME_FORMAT}
        wrapperClassname={styles.datePicker}
        minDate={startDate || new Date()}
        maxDate={
          defaultValues.maxDateEnd && moment(defaultValues.maxDateEnd).toDate()
        }
        disabled
        minTime={minTimeEnd}
        maxTime={maxTimeEnd}
        openToDate={isOutdated ? new Date() : undefined}
      />
    </div>
  );

  const renderContact = () => (
    <FormContactSelect
      name={TYPE_CONTACT}
      addEntityButtonData={null}
      label={`${t('Contact', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
      valueText={t('EnterYourNameEmail', { ns: 'Common' })}
      isDisabled
    />
  );

  const renderChannel = () => (
    <FormChanneleSelect
      isClearable
      name={TYPE_CHANNEL}
      label={`${t('Channel', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
      addEntityButtonData={null}
      valueText={t('ChooseChannel', { ns: 'ConnectWidget' })}
      isDisabled
    />
  );

  const renderEstimate = () => (
    <div
      className={classnames(styles.field, styles.timesetField)}
      data-qa="qa-ed7qfsmt0j50gbn"
    >
      <Typography.Text className={styles.subTitle} data-qa="qa-on5j6eyngjeerw6">
        {t('EstimatedTime')}
      </Typography.Text>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start'
        }}
      >
        {isStoryPointEstimation ? (
          <FormInputNumber
            label={t('Storypoints')}
            name="storyPoint"
            placeholder="000"
            maxLength={3}
          />
        ) : (
          <div className={styles.timeset} data-qa="qa-fdatgk2o8m6vhmf">
            <FormInputNumber
              name="hours"
              label={t('Hours')}
              placeholder="000"
              maxLength={3}
            />

            <FormInputNumber
              name="minutes"
              label={t('Minutes')}
              placeholder="000"
              maxLength={3}
              autoFocus
            />
          </div>
        )}

        {!defaultValues.parent && (
          <FormSwitch
            label={t('ChangeToStorypointsToggle')}
            name="isStoryPointEstimation"
            placeholder="000"
            itemProps={{ style: { margin: '27px 0 0 0', width: 'auto' } }}
          />
        )}
      </div>

      <Button
        type="text"
        icon={<CloseIcon />}
        className={styles.btnDelete}
        style={{
          top: 2
        }}
        data-qa="qa-suyy8dz4f7mlr3k"
        onClick={() => {
          removeField('time');
          setVisibleEstimate(false);
        }}
      />
    </div>
  );

  const filteredActions =
    defaultValues &&
    actions
      .filter(action => !activeFields.includes(action.type))
      .filter(action => action.visibility)
      .filter(action => action.title);

  if (isEmpty(defaultValues) && isLoadingAll) {
    return <SkeletonEntityCreation />;
  }

  if (!isEmpty(defaultValues)) {
    return (
      <Spin spinning={isLoadingAll}>
        <FormProvider {...methods}>
          <form
            onSubmit={event =>
              withoutBubbling(event, () =>
                methods.handleSubmit(transformSubmitedValues)
              )
            }
            className={styles.content}
          >
            <div className={styles.fieldsWrap} data-qa="qa-nptmk7n1yn8ufnu">
              <Alert
                type="warning"
                message={t('RequestEditSLAWarning', { ns: 'Requests' })}
                className={styles.slaAlert}
              />

              {renderTitle()}

              {renderDescription()}

              {renderDates()}

              {visibleEstimate && renderEstimate()}

              {renderResponsible()}

              {renderContact()}

              {renderChannel()}

              {(activeFields || []).map(field => {
                const action = actions
                  .filter(item => item.visibility)
                  .find(a => a.type === field);

                if (action && field !== 'dateStart' && field !== 'time') {
                  return (
                    <div
                      key={field}
                      className={styles.field}
                      data-qa="qa-ub8mqlbdzsakvye"
                    >
                      {action.component}

                      {!action.hideDeleteBtn && (
                        <Button
                          type="text"
                          icon={<CloseIcon />}
                          className={styles.btnDelete}
                          style={action.deleteBtnStyle}
                          data-qa="qa-wzdz0dd8bd9c6bk"
                          onClick={() => removeField(field)}
                        />
                      )}
                    </div>
                  );
                }
                return undefined;
              })}

              {contactToVisibility && (
                <div className={styles.withHint} data-qa="qa-j7jl86e0dzje7n2">
                  <FormContactSelect
                    label={t('WhoToSend')}
                    valueText={t('ChooseContact')}
                    name={TYPE_CONTACT_TO}
                    isMulti
                    closeMenuOnSelect={false}
                    rules={{
                      required: contactToVisibility,
                      message: t('RequiredField', { ns: 'Errors' })
                    }}
                  />

                  <Alert
                    type="warning"
                    message={t('WhoToSendWarning')}
                    style={{ fontSize: 12 }}
                  />
                </div>
              )}
            </div>

            <Divider className={styles.divider} data-qa="qa-a7wjybuw5tx7cpb" />

            <ActionsDropdown
              actions={filteredActions}
              placement="bottomLeft"
              btnClassName={styles.btnDropdown}
              overlayClassName={styles.dropdownOverlay}
              data-qa="qa-bthkgksizx45as2"
            >
              <Button
                type="link"
                style={{
                  fontSize: 16,
                  display: 'flex',
                  alignItems: 'center',
                  padding: 0
                }}
                data-qa="qa-n1dxcbul5efsb0x"
              >
                <Icon type="plus" /> {t('ShowAllFieldsBtn')}
              </Button>
            </ActionsDropdown>

            <Button
              className={styles.btnSteps}
              data-qa="qa-nlsx1e9ljbre2yd"
              disabled={isFilesLoading}
              loading={isLoading}
              htmlType="submit"
              type="primary"
              size="large"
            >
              {t('SaveBtn')}
            </Button>
          </form>
        </FormProvider>
      </Spin>
    );
  }

  return undefined;
};

export default BaseForm;
