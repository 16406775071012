export const getUrlFileByArrayBinary = ({ data, mimetype }) => {
  const file = new Blob([new Uint8Array(data)], { type: mimetype });
  const url = URL.createObjectURL(file);

  return url;
};

export const getUrlFileByBinary = ({ data, mimetype }) => {
  const file = new Blob([data], { type: mimetype });
  const url = URL.createObjectURL(file);

  return url;
};
