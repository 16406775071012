import { PERIOD_MONTH, PRICE_MAP } from 'constants/billing';

export const getAccountAddonData = ({
  availableAddon,
  checkout,
  selectedTariff
}) => {
  let { period, currency } = selectedTariff;
  const price = checkout
    ? checkout.price
    : availableAddon[PRICE_MAP[period][currency]];

  const priceMultiplier = period === PERIOD_MONTH || checkout ? 1 : 12;
  const resultPrice = Number(price) * priceMultiplier;

  let amount = resultPrice;

  if (checkout) {
    ({ period, currency } = checkout);

    if (checkout.payNow) {
      ({ amount } = checkout);
    }
  }

  return {
    title: availableAddon.title,
    price: resultPrice,
    amount,
    period,
    currency
  };
};
