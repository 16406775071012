import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { FormEmployeeSelect } from 'components/common/hook-form';

import mapValue from 'utils/map-value';
import { showNoticeMessage } from 'services/notice';

const Form = ({ isLoading, onSubmit }) => {
  const methods = useForm();

  const { t } = useTranslation(['ShareTemplate', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography.Paragraph style={{ marginBottom: 16 }}>
          {t('ShareTemplateDesc')}
        </Typography.Paragraph>

        <FormEmployeeSelect
          isMulti
          name="employees"
          label={t('Collegue')}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
          itemProps={{
            style: {
              marginBottom: 24
            }
          }}
          allowSetYourself={false}
          params={{
            excludeSelf: true
          }}
        />

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          loading={isLoading}
          style={{ fontSize: 16 }}
        >
          {t('ShareBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func
};

Form.defaultProps = {
  isLoading: false,
  onSubmit: () => {}
};

export const ShareTemplateModal = ({ id, visible, share, onClose }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['ShareTemplate', 'Toast']);

  const onSubmit = async ({ employees }) => {
    try {
      setIsLoading(true);

      const employeesIds = employees.map(mapValue);

      await dispatch(share({ id, employeesIds }));

      showNoticeMessage({
        customContent: t('TemplateShared', { ns: 'Toast' })
      });

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      open={visible}
      title={t('ShareTemplateHeading')}
      width={508}
      contentStyle={{ padding: 24 }}
      destroyOnClose
      onClose={onClose}
    >
      <Form isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
};

ShareTemplateModal.propTypes = {
  id: PropTypes.number,
  visible: PropTypes.bool,
  share: PropTypes.func,
  onClose: PropTypes.func
};

ShareTemplateModal.defaultProps = {
  id: null,
  visible: false,
  share: () => {},
  onClose: () => {}
};

export default ShareTemplateModal;
