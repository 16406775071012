import moment from 'moment/moment';

import { DIALOG_TYPE_LOG } from 'constants/index';

export const shouldDisplayForChat = ({
  currentItem,
  previousItem,
  currentDate,
  format,
  groupedItems
}) => {
  const currItem = currentItem;
  const prevItem = previousItem;

  const isLogCurr = currItem?.logs?.length > 0;
  const isMessageCurr = !isLogCurr;

  const currCreatedAt = currItem?.createdAt;
  const prevCreatedAt = prevItem?.createdAt;
  const isPrevLogs = prevItem?.logs?.length > 0;

  const prevLastLogCreatedAt = isPrevLogs
    ? prevItem.logs[prevItem.logs.length - 1].createdAt
    : undefined;

  const currDate = currentDate;

  const prevDate =
    prevItem && prevCreatedAt
      ? moment(prevCreatedAt).format(format)
      : undefined;

  const prevLogDate =
    prevItem && prevLastLogCreatedAt
      ? moment(prevLastLogCreatedAt).format(format)
      : undefined;

  if (isMessageCurr && prevItem?.logs?.length > 0) {
    const hasLogWithSameDate = prevItem.logs.some(log =>
      moment(log.createdAt).isSame(currCreatedAt, 'day')
    );

    if (hasLogWithSameDate) {
      if (prevItem.isCollapsed) {
        return false;
      }

      const messagesInCurrentGroup = groupedItems[currDate] || [];
      const currentMessageIndex = messagesInCurrentGroup.findIndex(
        message => message.uuid === currItem.messages.curr.uuid
      );

      if (currentMessageIndex !== -1) {
        const earlierMessages = messagesInCurrentGroup.slice(
          0,
          currentMessageIndex
        );

        const allAreLogEntries = earlierMessages.every(
          message => message.kind === DIALOG_TYPE_LOG
        );

        return allAreLogEntries;
      }
    }
  }

  const previousDateToCompare = isPrevLogs ? prevLogDate : prevDate;

  return (
    currDate && previousDateToCompare && currDate !== previousDateToCompare
  );
};
