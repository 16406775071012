import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { VIDEOS } from 'constants/videos';

import EstimateModal from 'components/common/estimate';
import VideoBtn from 'components/common/video-btn';

import { checkWorklogIsFull, createWorklog } from 'store/requests';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';

export const EditorWorklogModal = ({ handleSubmit, task, ...props }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isSkipAllowed, setIsSkipAllowed] = useState(false);

  const amplitude = useAmplitude();
  const { t } = useTranslation('Requests');

  const onSubmit = async ({ value }) => {
    try {
      setIsLoading(true);
      await dispatch(createWorklog({ id: task.id, value }));

      amplitude.changeWorklog({ value, task });

      handleSubmit();
    } finally {
      setIsLoading(false);
    }
  };

  const onSkip = () => handleSubmit();

  useEffect(() => {
    if (props.visible) {
      const checkAllowSkip = async () => {
        const isFull = await dispatch(checkWorklogIsFull({ taskId: task.id }));

        setIsSkipAllowed(isFull);
      };

      checkAllowSkip();
    }

    return () => setIsSkipAllowed(false);
  }, [dispatch, props.visible, task.id]);

  const { visible, ...restProps } = props;

  return (
    <EstimateModal
      title={t('RequestTimeTrackActionsDesc')}
      estimateFormProps={{
        onlySave: true,
        isLoading,
        skip: {
          allow: isSkipAllowed,
          click: onSkip
        },
        onSubmit
      }}
      destroyOnClose
      open={visible}
      {...restProps}
    >
      <VideoBtn slug={VIDEOS.workWithTimeLog} style={{ marginTop: 20 }} />
    </EstimateModal>
  );
};

EditorWorklogModal.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number
  })
};

EditorWorklogModal.defaultProps = {
  task: {}
};

export default EditorWorklogModal;
