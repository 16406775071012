import { useState, useRef } from 'react';

export const useHistoryActions = ({ messagesRef, sortedMessages }) => {
  const [historyActionsState, setHistoryActionsState] = useState({});

  const historyActionRefs = useRef({});
  const messageToHistoryActionMap = useRef({});

  const toggleHistoryAction = ({ key, lastLog }) => {
    const groupId = key;
    setHistoryActionsState(prevState => {
      const newState = {
        ...prevState,
        [groupId]: !prevState[groupId]
      };

      const isLastMessageLogInChat =
        sortedMessages[sortedMessages.length - 1].uuid === lastLog.uuid;

      if (newState[groupId] && messagesRef.current && isLastMessageLogInChat) {
        setTimeout(() => {
          messagesRef.current.scrollTo({
            top: messagesRef.current.scrollHeight,
            behavior: 'smooth'
          });
        }, 300);
      }

      return newState;
    });
  };

  return {
    historyActionsState,
    toggleHistoryAction,
    historyActionRefs,
    messageToHistoryActionMap
  };
};
