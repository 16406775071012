import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Typography, Progress, Popover } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import { EstimateForm, getHours, getPercent } from 'components/common/estimate';
import Switch from 'components/common/controls/switch';

import {
  createWorklog,
  editTaskFields,
  setEstimate,
  setPointEstimate
} from 'store/requests';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import themeVariables from 'theme';
import { showNoticeMessage } from 'services/notice';

import StoryPointForm from './story-point-form';

import styles from './progress-bar.module.scss';

const { Text } = Typography;

const progressStyles = {
  type: 'circle',
  width: 23,
  strokeWidth: 8,
  strokeColor: themeVariables.color['brand-40']
};

const ProgressBar = ({ task, allowUpdateEstimate, allowAddWorklog }) => {
  const amplitude = useAmplitude();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [visiblePopoverWorklog, setVisiblePopoverWorklog] = useState(false);
  const [visiblePopoverEstimate, setVisiblePopoverEstimate] = useState(false);
  const [isStoryPointLocal, setIsStoryPointLocal] = useState(
    task.isStoryPointEstimation
  );

  const { t } = useTranslation(['Task', 'Toast', 'Common']);

  const allowSwitchEstimateType =
    !task.parent &&
    (task.project || {}).id &&
    (task.permissions || {}).updateTask;

  const addWorklog = useCallback(
    async ({ value, description }) => {
      if (!value) {
        return;
      }

      await dispatch(
        createWorklog({
          id: task.id,
          value,
          description: description || undefined
        })
      );
      setVisiblePopoverWorklog(false);

      amplitude.changeWorklog({ value, task });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, task.id, visiblePopoverEstimate, visiblePopoverWorklog]
  );

  const onChangeEstimate = useCallback(
    async ({ value, diff }) => {
      if (allowSwitchEstimateType) {
        await dispatch(
          editTaskFields({
            value: {
              id: task.id,
              data: { isStoryPointEstimation: isStoryPointLocal }
            }
          })
        );
      }

      await dispatch(setEstimate({ id: task.id, estimation: value, diff }));

      amplitude.updateTaskEvent({ task, value: { estimation: value } });

      setVisiblePopoverEstimate(false);
    },
    [allowSwitchEstimateType, amplitude, dispatch, isStoryPointLocal, task]
  );

  const onChangeStoryPoint = async ({ storyPoint }) => {
    try {
      setIsLoading(true);

      if (allowSwitchEstimateType) {
        await dispatch(
          editTaskFields({
            value: {
              id: task.id,
              data: { isStoryPointEstimation: isStoryPointLocal }
            }
          })
        );
      }

      await dispatch(
        setPointEstimate({ id: task.id, storyPoint: +storyPoint })
      );

      setVisiblePopoverEstimate(false);

      showNoticeMessage({
        customContent: t('EstimatedTimeSaved', { ns: 'Toast' })
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onVisibleChangeEstimate = vis =>
    setVisiblePopoverEstimate(allowUpdateEstimate ? vis : false);

  const onVisibleChangeWorklog = vis =>
    setVisiblePopoverWorklog(allowAddWorklog ? vis : false);

  const hasSubtasks = !!(task.children || []).length;

  useEffect(() => {
    setIsStoryPointLocal(task.isStoryPointEstimation);
  }, [task.isStoryPointEstimation, visiblePopoverEstimate]);

  return (
    <div className={styles.root} data-qa="qa-nepicfq29d4eojd">
      <div
        className={classnames(styles.time, {
          [styles.hovered]: allowAddWorklog
        })}
        data-qa="qa-0wypbqc05ktv8gu"
      >
        <Text className={styles.text} data-qa="qa-h7cnfqz7nkzw58f">
          {t('EstimatedTime')}
        </Text>

        <div
          className={classnames(styles.block, {
            [styles.disabled]: !allowUpdateEstimate
          })}
          data-qa="qa-zi8kb0iaflqkgd5"
        >
          <Popover
            destroyTooltipOnHide
            content={
              <>
                {allowSwitchEstimateType && (
                  <Switch
                    value={isStoryPointLocal}
                    onChange={() => setIsStoryPointLocal(!isStoryPointLocal)}
                    rootClassName={styles.switch}
                    label={t('ChangeToStorypointsToggle')}
                  />
                )}

                {isStoryPointLocal ? (
                  <StoryPointForm
                    defaultValues={{ storyPoint: task.storyPoint }}
                    isLoading={isLoading}
                    onSubmit={onChangeStoryPoint}
                    onCancel={() => setVisiblePopoverEstimate(false)}
                  />
                ) : (
                  <EstimateForm
                    defaultValues={{ estimation: task.estimation }}
                    onSubmit={({ value, diff }) =>
                      onChangeEstimate({ value, diff })
                    }
                    onCancel={() => setVisiblePopoverEstimate(false)}
                    saveMessage={t('EstimatedTimeSaved', { ns: 'Toast' })}
                  />
                )}
              </>
            }
            trigger="click"
            placement="topLeft"
            open={visiblePopoverEstimate}
            onOpenChange={onVisibleChangeEstimate}
            overlayClassName={styles.estimate}
            data-qa="qa-wwuf4awc00ih3kv"
          >
            <Progress
              percent={0}
              format={() => (
                <Icon className={styles.icon} type="timer" size={12} />
              )}
              {...progressStyles}
            />
            {task.isStoryPointEstimation
              ? `${task.storyPoint} ${t('StoryPointShort', { ns: 'Common' })}`
              : `${getHours((task || {}).estimation)} ${t('HourShort')}`}
          </Popover>

          {hasSubtasks && !task.isStoryPointEstimation && (
            <div className={styles.label} data-qa="qa-9j4rlzeepijusf2">
              {t('InSubtasks')}:{' '}
              {`${getHours(task.estimationTotal)} ${t('HourShort')}`}
            </div>
          )}
        </div>
      </div>

      <div
        className={classnames(styles.time, {
          [styles.hovered]: allowAddWorklog
        })}
        data-qa="qa-f9u4ewzkpy0kjt3"
      >
        <Text className={styles.text} data-qa="qa-lmj9mkm54a0bzq8">
          {t('TrackTime')}
        </Text>
        <div
          className={classnames(styles.block, {
            [styles.disabled]: !allowAddWorklog
          })}
          data-qa="qa-2nipvh08fu9i9wo"
        >
          <Popover
            content={
              <EstimateForm
                onSubmit={addWorklog}
                onCancel={() => setVisiblePopoverWorklog(false)}
                showComments
                saveMessage={t('TrackTimeSaved', { ns: 'Toast' })}
              />
            }
            trigger="click"
            placement="top"
            overlayClassName={styles.estimate}
            data-qa="qa-72h2yi9ght7uwt5"
            open={visiblePopoverWorklog}
            onOpenChange={onVisibleChangeWorklog}
            destroyTooltipOnHide
          >
            <Progress
              percent={task && getPercent(task.workLog, task.estimation)}
              format={() => (
                <Icon className={styles.icon} type="timer" size={12} />
              )}
              {...progressStyles}
            />
            {`${getHours((task || {}).workLog)} ${t('HourShort')}`}
          </Popover>

          {hasSubtasks && (
            <div className={styles.label} data-qa="qa-j0gr2e2tur9j69o">
              {t('InSubtasks')}:{' '}
              {`${getHours(task.workLogTotal)} ${t('HourShort')}`}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ProgressBar.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    estimate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    workLog: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  allowUpdateEstimate: PropTypes.bool,
  allowAddWorklog: PropTypes.bool
};

ProgressBar.defaultProps = {
  task: undefined,
  allowUpdateEstimate: false,
  allowAddWorklog: false
};

export default ProgressBar;
