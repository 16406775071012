import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Spin } from 'antd';
import difference from 'lodash/difference';
import PropTypes from 'prop-types';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import { useTranslation } from 'react-i18next';

import { BENCH } from 'constants/index';

import Modal from 'components/common/modal';
import UserCardSmall from 'components/common/user-card-small';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { fetchDepartmentsLocal } from 'store/team/departments';
import { fetchEmployeesLocal } from 'store/team/employees';

import {
  transformToTreeData,
  getEmployeesFromTreeData,
  getEmployeesByIds
} from './utils';
import PositionSelect from '../position-select';

import styles from './employee-select.module.scss';

/*
  Если isMulti - используем checkedKeys, иначе selectedKeys
  allKeys - используется для чекбокса "Выбрать всех". На данный момент это дубликат функционала,
  так как всегда только один корневой элемент
  callbackAfterClose - после закрытия формы, снова открываем выпадающий список. После выбора, не нужно показывать
*/

const transformTreeData = (data, isHideManagers, renderNodeByRole) =>
  data.map(item => {
    const marginLeft = item.isDirector || item.isManager ? -18 : 0;

    const node = {
      key: item.isDepartment ? `${item.title}-${item.id}` : item.id,
      title: renderNodeByRole(item),
      selectable: !item.isDepartment,
      disabled: isHideManagers && item.isManager,
      style: { marginLeft }
    };

    if (item.children) {
      return {
        ...node,
        children: transformTreeData(
          item.children,
          isHideManagers,
          renderNodeByRole
        )
      };
    }

    return node;
  });

export const OrgstructureModal = ({
  onSelect,
  onClose,
  visible,
  isMulti,
  defaultSelectedKeys,
  callbackAfterClose,
  isHideManagers,
  departmentsParams,
  employeesParams,
  ...props
}) => {
  const { t } = useTranslation('Team');

  const [treeData, setTreeData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedKeys, setCheckedKeys] = useState(defaultSelectedKeys);
  const [selectedKeys, setSelectedKeys] = useState(defaultSelectedKeys);
  const [expandedKeys, setExpandedKeys] = useState(defaultSelectedKeys);
  const [employees, setEmployees] = useState({});
  const dispatch = useDispatch();
  const [positions, setPositions] = useState([]);

  const fetchEmployees = useCallback(
    async list => {
      let deparments = [];

      await Promise.all(
        list.map(d =>
          dispatch(
            fetchEmployeesLocal({
              ...employeesParams,
              department: d.id,
              excludeBench: false,
              limit: 10000
            })
          ).then(async data => {
            const { entries } = await data;

            deparments = [
              ...deparments,
              {
                ...d,
                manager: entries.find(({ id }) => id === d.managerId),
                employees: entries.filter(({ id }) => id !== d.managerId)
              }
            ];

            return data;
          })
        )
      );

      return deparments;
    },
    [dispatch, employeesParams]
  );

  const fetchDepartments = useCallback(async () => {
    try {
      setIsLoading(true);

      const { entries } = await dispatch(
        fetchDepartmentsLocal({
          excludeType: [BENCH],
          ...departmentsParams
        })
      );

      const departments = await fetchEmployees(entries);

      const transformData = transformToTreeData({ list: departments });
      setTreeData(transformData);
      setEmployees(getEmployeesFromTreeData(transformData));
    } finally {
      setIsLoading(false);
    }
  }, [departmentsParams, dispatch, fetchEmployees]);

  const renderNodeByRole = useCallback(
    nodeProps =>
      nodeProps.isDepartment ? (
        <Typography.Text style={{ fontWeight: 600, width: 'auto' }} ellipsis>
          {nodeProps.title}
        </Typography.Text>
      ) : (
        <>
          <UserCardSmall {...nodeProps} style={{ marginRight: 10 }} />
          <span className={styles.jobTitle} onClick={e => e.stopPropagation()}>
            {nodeProps.isManager && !nodeProps.isDirector && (
              <Typography.Text color="black-55">
                {t('HeadOfDepartment')}
              </Typography.Text>
            )}
            {nodeProps.isDirector && (
              <Typography.Text color="black-55">
                {t('HeadOfCompany')}
              </Typography.Text>
            )}
          </span>
        </>
      ),
    [t]
  );

  const transformedTreeData = useCallback(
    () => transformTreeData(treeData, isHideManagers, renderNodeByRole),
    [treeData, isHideManagers, renderNodeByRole]
  );

  const handleSelect = useCallback(() => {
    const ids = isMulti ? checkedKeys : selectedKeys;
    const selectedEmployees = getEmployeesByIds({ ids, employees });

    onSelect(isMulti ? selectedEmployees : selectedEmployees[0]);
    onClose();
    setPositions([]);
  }, [checkedKeys, employees, isMulti, onClose, onSelect, selectedKeys]);

  const onChangePositions = value => {
    setPositions(value);
    const newPositions = value.map(item => item.value);

    const arr = Object.values(employees)
      .filter(({ label }) => newPositions.indexOf(label.position) !== -1)
      .map(item => item.value);

    setCheckedKeys(arr);
    setExpandedKeys(arr);
  };

  const handleCheckAll = useCallback(() => {
    const targetKeys = Object.keys(employees).filter(
      key => !isHideManagers || !employees[key].label.isManager
    );

    setCheckedKeys(checkedKeys.length ? [] : targetKeys);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedKeys.length, employees]);

  const getIsCheckedAll = useCallback(
    () => !difference(Object.keys(employees), checkedKeys).length,
    [checkedKeys, employees]
  );

  const handleClose = () => {
    onClose();
    setPositions([]);
    callbackAfterClose();
  };

  useEffect(() => {
    if (visible && isLoading) {
      fetchDepartments();
    }
  }, [visible, isLoading, fetchDepartments]);

  useEffect(() => {
    if (visible) {
      setCheckedKeys(defaultSelectedKeys);
    }
  }, [visible, defaultSelectedKeys]);

  return (
    <Modal
      title={t('TeamStructureTab')}
      contentStyle={{ display: 'flex', flexDirection: 'column', padding: 24 }}
      width={508}
      onClose={handleClose}
      contentClassName={styles.modal}
      open={visible}
      {...props}
    >
      <Spin spinning={isLoading} />

      {!isLoading && (
        <>
          {isMulti && (
            <div style={{ display: 'flex' }}>
              <Checkbox
                style={{ marginRight: 'auto' }}
                indeterminate={checkedKeys.length && !getIsCheckedAll()}
                checked={getIsCheckedAll()}
                onChange={handleCheckAll}
              >
                {t('SelectAllChckbx')}
              </Checkbox>

              <PositionSelect
                onChange={onChangePositions}
                value={positions}
                valueText={t('ChoosePosition')}
              />
            </div>
          )}

          <DirectoryTree
            defaultExpandedKeys={
              treeData.length ? [treeData[0].id.toString()] : []
            }
            multiple={isMulti}
            checkable={isMulti}
            selectable={!isMulti}
            onCheck={setCheckedKeys}
            checkedKeys={checkedKeys}
            selectedKeys={selectedKeys}
            showIcon={false}
            onSelect={setSelectedKeys}
            onExpand={setExpandedKeys}
            expandedKeys={expandedKeys}
            switcherIcon={<Icon type="arrow" size={16} />}
            treeData={transformedTreeData()}
          />

          <Button
            type="primary"
            style={{ marginLeft: 'auto', marginTop: 20 }}
            onClick={handleSelect}
          >
            {t('ChooseField')}
          </Button>
        </>
      )}
    </Modal>
  );
};

OrgstructureModal.propTypes = {
  defaultSelectedKeys: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  callbackAfterClose: PropTypes.func,
  isHideManagers: PropTypes.bool,
  departmentsParams: PropTypes.object,
  employeesParams: PropTypes.object
};

OrgstructureModal.defaultProps = {
  defaultSelectedKeys: [],
  callbackAfterClose: () => {},
  isHideManagers: false,
  departmentsParams: {},
  employeesParams: {}
};

export default OrgstructureModal;
