import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Spin, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import EmployeeAvatar from 'components/common/avatar/employee';
import Typography from 'components/common/typography';

import { fetchDiscussionMembers } from 'store/discussions';

import { getFullName } from 'utils/get-fio';

import styles from './discussion-members.module.scss';

const DiscussionMembers = ({ discussionId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('Dashboard');

  const [members, setMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);

  const fetchMembers = useCallback(
    async offset => {
      setIsLoading(true);

      try {
        const { results, count } = await dispatch(
          fetchDiscussionMembers({
            id: discussionId,
            offset
          })
        );

        setMembers(prevMembers =>
          offset ? [...prevMembers, ...results] : results
        );
        setTotal(count);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, discussionId, members]
  );

  useEffect(() => {
    if (visible && !isLoading) {
      fetchMembers(0);
    }
  }, [visible]);

  useEffect(() => {
    if (!visible) {
      setMembers([]);
      setTotal(0);
    }
  }, [visible]);

  const hasMore = visible && members.length < total;

  const content = (
    <>
      <div className={styles.content}>
        <div className={styles.contentHeader}>
          <Typography.Text color="black-55">
            {t('ConversationParticipants')}
          </Typography.Text>
        </div>

        <div className={styles.contentList}>
          <InfiniteScroll
            loadMore={() => fetchMembers(members.length)}
            hasMore={!isLoading && hasMore}
            useWindow={false}
            initialLoad={false}
          >
            {members.map(member => (
              <div key={member.id} className={styles.contentItem}>
                <div className={styles.itemHeader}>
                  <EmployeeAvatar
                    isLink={false}
                    tooltip={{ isHide: true }}
                    employee={member}
                  />

                  <Typography.Text ellipsis>
                    {getFullName(member)}
                  </Typography.Text>
                </div>

                {member.position && (
                  <Typography.Text ellipsis size="small">
                    {member.position}
                  </Typography.Text>
                )}
              </div>
            ))}

            {isLoading && <Spin key="spin-chat" />}
          </InfiniteScroll>
        </div>
      </div>
    </>
  );

  return (
    <div className={styles.root} onClick={e => e.stopPropagation()}>
      <Dropdown
        overlay={content}
        trigger={['click']}
        placement="bottomRight"
        onOpenChange={setVisible}
        open={visible}
      >
        <Tooltip title={t('ConversationParticipants')} mouseEnterDelay={0.5}>
          <Button size="small" type="text" className={styles.iconBtn}>
            <Icon type="team" size={20} color="black-55" />
          </Button>
        </Tooltip>
      </Dropdown>
    </div>
  );
};

DiscussionMembers.propTypes = {
  discussionId: PropTypes.number.isRequired
};

export default DiscussionMembers;
