import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import {
  AppstoreOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  BarsOutlined,
  BellOutlined,
  BookOutlined,
  BoxPlotOutlined,
  CalculatorOutlined,
  CalendarOutlined,
  CameraOutlined,
  ClockCircleFilled,
  ClockCircleOutlined,
  CloseCircleOutlined,
  CloudOutlined,
  ContactsOutlined,
  DeleteOutlined,
  DollarOutlined,
  DotChartOutlined,
  EditOutlined,
  EnvironmentOutlined,
  EyeInvisibleOutlined,
  FieldBinaryOutlined,
  FileOutlined,
  FileTextOutlined,
  FolderOpenOutlined,
  FontColorsOutlined,
  FunnelPlotOutlined,
  HeatMapOutlined,
  LeftOutlined,
  LineChartOutlined,
  LinkOutlined,
  MailOutlined,
  MessageOutlined,
  MoreOutlined,
  PaperClipOutlined,
  PhoneOutlined,
  PieChartOutlined,
  QuestionCircleOutlined,
  ReloadOutlined,
  RetweetOutlined,
  RightOutlined,
  SearchOutlined,
  SettingOutlined,
  ShoppingOutlined,
  SlidersOutlined,
  TableOutlined,
  TabletOutlined,
  TagsOutlined,
  UserAddOutlined,
  WarningFilled,
  WarningOutlined,
  ControlOutlined,
  DownloadOutlined,
  ReadOutlined,
  ShareAltOutlined,
  MinusCircleOutlined,
  CarryOutOutlined,
  MinusOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  StarFilled,
  QuestionCircleFilled,
  PlayCircleFilled,
  EyeInvisibleFilled,
  SmileOutlined,
  FileAddOutlined,
  ThunderboltOutlined,
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  UnorderedListOutlined,
  CheckCircleOutlined,
  OrderedListOutlined,
  PlayCircleOutlined,
  StarOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  SwapOutlined,
  ScheduleOutlined,
  MenuOutlined,
  SolutionOutlined,
  InfoCircleOutlined,
  UserOutlined
} from '@ant-design/icons';

import IconCustom from './icon-custom';
import {
  ArrowIcon,
  LogoutIcon,
  PlusCircleIcon,
  PlusIcon,
  CloseIcon,
  CheckIcon,
  TimerIcon,
  CopyIcon,
  SnippetsIcon,
  DiffIcon,
  SortAscendingIcon,
  SortDescendingIcon,
  AuditIcon,
  AtIcon,
  BigTeamIcon,
  StoryPointsIcon,
  SafetyIcon,
  PushpinIcon,
  PointerIcon,
  ListIcon,
  CanbanIcon,
  FileIcon,
  MoneyCollectIcon,
  VerifiedIcon,
  PresentationIcon,
  CommentIcon,
  SprintIcon,
  PhoneAndMessageIcon,
  OpenInNewWindowIcon,
  HistogramIcon,
  AppstoreAddIcon,
  BubbleChartIcon,
  FieldBinaryIcon,
  MapIcon,
  SankeyChartIcon,
  SunburstIcon,
  HistoryUndoIcon,
  HistoryRedoIcon,
  NodeIndexIcon,
  ReplyIcon,
  ReplyToAllIcon,
  SwitcherPlusIcon,
  AnimatedClockCircleIcon,
  SubtractIcon,
  VisaIcon,
  MastercardIcon,
  BelcartIcon,
  GptWidgetIcon,
  SingleCheckboxIcon,
  DoubleCheckboxIcon,
  ProjectIcon,
  ChannelIcon,
  TeamIcon,
  PenIcon,
  AiAssistantIcon,
  SelectTagIcon,
  SubscribeIcon,
  SubscribeFilledIcon,
  SubscribersIcon,
  JobTitleIcon,
  ProfileLinkIcon,
  OutdatedIcon,
  GoogleFile,
  GoogleSheets,
  GooglePresentation
} from './icons';
import ShoppingCardIcon from './icons/shopping-card';
import BoxIcon from './icons/box';
import DescriptionIcon from './icons/description';
import RequestsIcon from './icons/requests';

const CUSTOM_ICON = {
  'sort-ascending': SortAscendingIcon,
  'sort-descending': SortDescendingIcon,
  'plus-circle': PlusCircleIcon,
  'big-team': BigTeamIcon,
  'story-points': StoryPointsIcon,
  arrow: ArrowIcon,
  logout: LogoutIcon,
  plus: PlusIcon,
  close: CloseIcon,
  check: CheckIcon,
  timer: TimerIcon,
  copy: CopyIcon,
  snippets: SnippetsIcon,
  diff: DiffIcon,
  audit: AuditIcon,
  at: AtIcon,
  safety: SafetyIcon,
  pushpin: PushpinIcon,
  pointer: PointerIcon,
  list: ListIcon,
  canban: CanbanIcon,
  attachment: FileIcon,
  'monney-collect': MoneyCollectIcon,
  verified: VerifiedIcon,
  presentation: PresentationIcon,
  comment: CommentIcon,
  sprint: SprintIcon,
  'phone-and-message': PhoneAndMessageIcon,
  'open-in-new-widnow': OpenInNewWindowIcon,
  histogram: HistogramIcon,
  'appstore-add': AppstoreAddIcon,
  'bubble-chart': BubbleChartIcon,
  'field-binary': FieldBinaryIcon,
  'choropleth-map': FieldBinaryIcon,
  map: MapIcon,
  'snakey-chart': SankeyChartIcon,
  sunburst: SunburstIcon,
  'history-redo': HistoryRedoIcon,
  'history-undo': HistoryUndoIcon,
  'node-index': NodeIndexIcon,
  reply: ReplyIcon,
  'reply-to-all': ReplyToAllIcon,
  'switcher-plus': SwitcherPlusIcon,
  'animated-clock-circle': AnimatedClockCircleIcon,
  subtract: SubtractIcon,
  visa: VisaIcon,
  mastercard: MastercardIcon,
  belcart: BelcartIcon,
  'gpt-widget': GptWidgetIcon,
  'single-checkbox': SingleCheckboxIcon,
  'double-checkbox': DoubleCheckboxIcon,
  project: ProjectIcon,
  channel: ChannelIcon,
  team: TeamIcon,
  pen: PenIcon,
  'ai-assistant': AiAssistantIcon,
  'shopping-card': ShoppingCardIcon,
  box: BoxIcon,
  'select-tag': SelectTagIcon,
  subscribe: SubscribeIcon,
  'subscribe-filled': SubscribeFilledIcon,
  subscribers: SubscribersIcon,
  'job-title': JobTitleIcon,
  'profile-link': ProfileLinkIcon,
  outdated: OutdatedIcon,
  description: DescriptionIcon,
  requests: RequestsIcon,
  'google-file': GoogleFile,
  'google-sheets': GoogleSheets,
  'google-presentation': GooglePresentation
};

const ANTD_ICON = {
  setting: SettingOutlined,
  more: MoreOutlined,
  'clock-circle': ClockCircleOutlined,
  'font-colors': FontColorsOutlined,
  warning: WarningOutlined,
  search: SearchOutlined,
  environment: EnvironmentOutlined,
  calculator: CalculatorOutlined,
  calendar: CalendarOutlined,
  left: LeftOutlined,
  right: RightOutlined,
  camera: CameraOutlined,
  delete: DeleteOutlined,
  'paper-clip': PaperClipOutlined,
  bell: BellOutlined,
  'question-circle': QuestionCircleOutlined,
  dollar: DollarOutlined,
  tablet: TabletOutlined,
  appstore: AppstoreOutlined,
  'funnel-plot': FunnelPlotOutlined,
  book: BookOutlined,
  'line-chart': LineChartOutlined,
  'folder-open': FolderOpenOutlined,
  edit: EditOutlined,
  shopping: ShoppingOutlined,
  phone: PhoneOutlined,
  retweet: RetweetOutlined,
  'file-text': FileTextOutlined,
  tags: TagsOutlined,
  contacts: ContactsOutlined,
  'user-add': UserAddOutlined,
  link: LinkOutlined,
  mail: MailOutlined,
  file: FileOutlined,
  'eye-invisible': EyeInvisibleOutlined,
  message: MessageOutlined,
  reload: ReloadOutlined,
  'close-circle': CloseCircleOutlined,
  'bar-chart': BarChartOutlined,
  'area-chart': AreaChartOutlined,
  'pie-chart': PieChartOutlined,
  'dot-chart': DotChartOutlined,
  'heat-map': HeatMapOutlined,
  'box-plot': BoxPlotOutlined,
  sliders: SlidersOutlined,
  'field-binary': FieldBinaryOutlined,
  bars: BarsOutlined,
  table: TableOutlined,
  cloud: CloudOutlined,
  control: ControlOutlined,
  download: DownloadOutlined,
  read: ReadOutlined,
  'share-alt': ShareAltOutlined,
  'minus-circle': MinusCircleOutlined,
  'carry-out': CarryOutOutlined,
  minus: MinusOutlined,
  'minus-square': MinusSquareOutlined,
  'plus-square': PlusSquareOutlined,
  smile: SmileOutlined,
  'file-add': FileAddOutlined,
  thunderbolt: ThunderboltOutlined,
  bold: BoldOutlined,
  italic: ItalicOutlined,
  strikethrough: StrikethroughOutlined,
  'unordered-list': UnorderedListOutlined,
  'ordered-list': OrderedListOutlined,
  'check-circle': CheckCircleOutlined,
  'play-circle': PlayCircleOutlined,
  star: StarOutlined,
  'exclamation-circle': ExclamationCircleOutlined,
  eye: EyeOutlined,
  swap: SwapOutlined,
  schedule: ScheduleOutlined,
  menu: MenuOutlined,
  solution: SolutionOutlined,
  'info-circle': InfoCircleOutlined,
  'user-outlined': UserOutlined
};

const ANTD_FILLED_ICON = {
  'clock-circle': ClockCircleFilled,
  warning: WarningFilled,
  star: StarFilled,
  'question-circle': QuestionCircleFilled,
  'play-circle': PlayCircleFilled,
  'eye-invisible': EyeInvisibleFilled
};

const ROTATE = {
  default: 'rotate(0deg)',
  left: 'rotate(90deg)',
  right: 'rotate(-90deg)',
  up: 'rotate(180deg)',
  down: 'rotate(-180deg)'
};

export const Icon = ({
  type,
  component,
  side,
  color,
  size,
  style,
  className,
  theme,
  onClick,
  ...props
}) => {
  const customIcon = CUSTOM_ICON[type];
  const antdIcon = ANTD_ICON[type];
  const antdFilledIcon = ANTD_FILLED_ICON[type];

  const getIconComponent = () => {
    if (customIcon || component) {
      return IconCustom;
    }

    if (antdIcon && !theme) {
      return antdIcon;
    }

    if (antdFilledIcon && theme === 'filled') {
      return antdFilledIcon;
    }

    return null;
  };
  const IconComponent = getIconComponent();

  if (!IconComponent) return null;

  return (
    <IconComponent
      component={customIcon || component}
      className={classnames(`color-${color}`, className)}
      style={{
        display: 'inline-flex',
        transform: ROTATE[side],
        transition: ' 0.2s ease-in-out',
        transformOrigin: 'center center',
        fontSize: size,
        ...style
      }}
      onClick={onClick}
      {...props}
    />
  );
};

Icon.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.oneOf(Object.keys(CUSTOM_ICON)),
    PropTypes.oneOf(Object.keys(ANTD_ICON))
  ]),
  component: PropTypes.elementType,
  side: PropTypes.oneOf(Object.keys(ROTATE)),
  color: PropTypes.string,
  size: PropTypes.number,
  style: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.string
};

Icon.defaultProps = {
  type: null,
  component: null,
  side: 'default',
  color: 'inherit',
  size: 24,
  style: {},
  className: undefined,
  theme: undefined
};

export default Icon;
