import React, { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Alert, Checkbox, Divider } from 'antd';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Trans, Translation, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  DATE_FORMAT,
  SCHEDULER_OPTIONS,
  SPEC_KIND_SYSTEM,
  SPECIFICATION,
  DOCUMENT_TYPES,
  FUNNELS_SETTINGS
} from 'constants/index';

import {
  FormAttachDocuments,
  FormCurrencySelect,
  FormCustomSelect,
  FormDatePicker,
  FormEmployeeSelect,
  FormInput,
  FormInputNumber,
  FormLocationInput,
  FormRadio,
  LabelWithTooltip
} from 'components/common/hook-form';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import FormNewEditor from 'components/common/hook-form/markdown';
import Tooltip from 'components/common/tooltip';

import { getActiveWorkspace } from 'store/workspace';

import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';
import useMinMaxTime from 'hooks/common/use-min-max-time';
import scrollToFirstError from 'utils/scroll-to-first-error';
import useRoutesService from 'services/routes';

import Table from './table';
import SchedulerForm from './scheduler';

import styles from './system-specification.module.scss';

const { Title, Text, Paragraph } = Typography;

const LANGUAGE_OPTIONS = [
  {
    label: 'RU',
    value: 'ru'
  },
  {
    label: 'ENG',
    value: 'eng'
  },
  {
    label: 'DE',
    value: 'de'
  },
  {
    label: 'FR',
    value: 'fr'
  },
  {
    label: 'ES',
    value: 'es'
  },
  {
    label: 'PT',
    value: 'pt'
  },
  {
    label: 'PL',
    value: 'pl'
  }
];

const WITH_VAT_OPTIONS = [
  {
    label: (
      <Tooltip
        title={
          <Translation ns="OrderConditions">{t => t('WithVATTip')}</Translation>
        }
      >
        <span>
          <Translation ns="OrderConditions">
            {t => t('WithoutVATBtn')}
          </Translation>
        </span>
      </Tooltip>
    ),
    value: false
  },
  {
    label: (
      <Translation ns="OrderConditions">{t => t('WithVATBtn')}</Translation>
    ),
    value: true
  }
];

const scrollToPeriodicActForm = (ref, shouldValidate = false) => {
  ref.current.scrollIntoView({ behavior: 'smooth', block: 'center' });

  ref.current.classList.remove(styles.scrollToPeriodicActForm);
  return setTimeout(
    () =>
      !shouldValidate &&
      ref.current.classList.add(styles.scrollToPeriodicActForm),
    100
  );
};

const SystemSpecificationForm = ({
  defaultValues,
  isLoading,
  isLoadingPreview,
  onSubmit,
  editRequisites
}) => {
  const myRef = useRef(null);
  const [dataSource, setDataSource] = useState(defaultValues.items);
  const [total, setTotal] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [scheduler, setScheduler] = useState(!!defaultValues.scheduler);

  const { bankDetails, address, isIe, isIndividual } =
    useSelector(getActiveWorkspace);

  const { t } = useTranslation([
    'OrderConditions',
    'Errors',
    'Common',
    'Order',
    'AddOrder'
  ]);

  const methods = useForm({
    defaultValues: {
      language: defaultValues.language,
      contractDate: moment(defaultValues.contractDate).toDate(),
      contractNumber: defaultValues.contractNumber,
      specificationCreatedAt: moment(
        defaultValues.specificationCreatedAt
      ).toDate(),
      documentList: defaultValues.documentList,
      withTax: defaultValues.withTax,
      tax: defaultValues.tax,
      currency: defaultValues.currency,
      location: defaultValues.location,
      locationExact: defaultValues.locationExact,
      startAt: defaultValues.startAt
        ? moment(defaultValues.startAt).toDate()
        : undefined,
      deadlineAt: moment(defaultValues.deadlineAt).toDate(),
      shipmentAt: defaultValues.shipmentAt
        ? moment(defaultValues.shipmentAt).toDate()
        : undefined,
      daysToPayment: defaultValues.daysToPayment,
      comment: {
        description: defaultValues.comment,
        fileList: []
      },
      title: defaultValues.title,
      content: {
        description: defaultValues.content,
        fileList: defaultValues.fileList || []
      },
      scheduler: {
        ...defaultValues.scheduler,
        startAt: defaultValues.scheduler
          ? moment(defaultValues.scheduler.startAt).toDate()
          : undefined,
        interval:
          (defaultValues.scheduler && defaultValues.scheduler.interval) || 1,
        frequency:
          (defaultValues.scheduler && defaultValues.scheduler.frequency) ||
          SCHEDULER_OPTIONS[1]
      },
      paymentFromDocumentType: defaultValues.paymentFromDocumentType,
      signatory: defaultValues.signatory
    }
  });

  const [
    withTaxField,
    deadlineAtField,
    contentField,
    documentListField,
    daysToPaymentField,
    specificationCreatedAtField,
    currencyField,
    taxField,
    languageField,
    startAtField,
    paymentFromDocumentTypeField
  ] = useWatch({
    name: [
      'withTax',
      'deadlineAt',
      'content',
      'documentList',
      'daysToPayment',
      'specificationCreatedAt',
      'currency',
      'tax',
      'language',
      'startAt',
      'paymentFromDocumentType'
    ],
    control: methods.control
  });

  const routes = useRoutesService({ returnUrl: true });
  const isDocumentListLoading = useUploadingFiles(documentListField);
  const isFileListLoading = useUploadingFiles(contentField.fileList);
  const isUploadingFiles = isFileListLoading || isDocumentListLoading;

  const [minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate: startAtField,
    endDate: deadlineAtField
  });

  const needRequisites =
    !isIndividual && (!(bankDetails || []).length || (isIe && !address));

  const transformSubmitedValues = values => ({
    ...values,
    daysToPayment:
      (values.daysToPayment !== '' && values.daysToPayment) || undefined,
    tax: values.withTax ? values.tax : null,
    content: values.content.description,
    fileList: getFileIdsV2(values.content.fileList),
    documentList: getFileIdsV2(values.documentList),
    comment: values.comment.description,
    currency: values.currency.value,
    isPreview,
    kind: SPEC_KIND_SYSTEM,
    items: dataSource.map(({ tax: _, ...item }) => item),
    price: total.totalPrice,
    scheduler: scheduler
      ? {
          ...values.scheduler,
          frequency: values.scheduler.frequency.value.toLowerCase()
        }
      : null,
    paymentFromDocumentType: values.paymentFromDocumentType.value.toLowerCase(),
    signatoryId: values.signatory
      ? values.signatory.value
      : defaultValues.signatoryId,
    attachmentFileList: values.documentList || []
  });

  const handleSchedulingCheck = () => {
    setScheduler(value => !value);
  };

  useEffect(() => {
    scrollToFirstError(methods);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods.formState]);

  const renderSignerField = () => (
    <FormEmployeeSelect
      name="signatory"
      label={
        <LabelWithTooltip
          label={t('Signer', { ns: 'Order' })}
          tooltip={
            <Trans
              i18nKey="SignerFieldTip"
              ns="AddOrder"
              components={{
                lnk: <Link to={routes.toContacts({ view: FUNNELS_SETTINGS })} />
              }}
            />
          }
          style={{ maxWidth: 255 }}
        />
      }
      rules={{
        required: t('RequiredField', { ns: 'Errors' })
      }}
      allowSetYourself={false}
      params={{
        workspaceControllers: true
      }}
      optionProps={{ isFilters: true }}
      renderContentTop={() => {}}
    />
  );

  return (
    <FormProvider {...methods}>
      <form
        className={styles.root}
        onSubmit={methods.handleSubmit(values =>
          onSubmit(transformSubmitedValues(values))
        )}
      >
        <div className={styles.languageWrap}>
          <Title level={3} className={styles.title}>
            {t('DocLanguage')}
          </Title>

          <FormRadio
            name="language"
            options={LANGUAGE_OPTIONS}
            withButton
            itemProps={{
              className: styles.radio
            }}
          />
        </div>

        {needRequisites && (
          <Alert
            style={{ marginTop: 24 }}
            type="error"
            message={
              <>
                <Text>
                  {t('BankDetailsWarning')}{' '}
                  <Button
                    type="link"
                    className={styles.requisitesBtn}
                    onClick={editRequisites}
                  >
                    {t('BankDetailsBtn')}
                  </Button>
                </Text>
              </>
            }
          />
        )}

        <div
          className={styles.blockWrapper}
          style={{
            marginTop: 24
          }}
        >
          <Title level={3} className={styles.title}>
            {t('BasisHeading')}
          </Title>

          <div className={styles.foundationWrap}>
            <FormDatePicker
              label={t('ContractDate')}
              name="contractDate"
              showTimeSelect={false}
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />

            <FormInput
              label={t('ContractNumber')}
              name="contractNumber"
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />

            <FormDatePicker
              label={t('SpecificationDate')}
              name="specificationCreatedAt"
              showTimeSelect={false}
              rules={{
                required: t('RequiredField', { ns: 'Errors' })
              }}
            />

            {renderSignerField()}
          </div>

          <FormAttachDocuments
            label={t('AttachContractBtn')}
            style={{ margin: 0 }}
            name="documentList"
            buttonProps={{
              type: 'link',
              className: styles.documentsButton
            }}
          />
        </div>

        <div className={styles.blockWrapperGray}>
          <Title level={3}>{t('CommonConditionsHeading')}</Title>

          <Paragraph style={{ marginBottom: 24 }}>
            {t('CommonConditionsDesc')} «
            <Button
              type="link"
              onClick={() => scrollToPeriodicActForm(myRef)}
              style={{ fontWeight: 400, padding: 0 }}
            >
              {t('ScheduledActsBtn')}
            </Button>
            ».
          </Paragraph>

          <div className={styles.blockWrapper}>
            <Title level={3} className={styles.title}>
              {t('CostHeading')}
            </Title>

            <div className={styles.vatWrap}>
              <FormRadio
                name="withTax"
                options={WITH_VAT_OPTIONS}
                withButton
                itemProps={{
                  className: styles.withVat
                }}
              />

              {withTaxField && (
                <FormInputNumber
                  label={t('VATPercent')}
                  name="tax"
                  rules={{
                    required: t('RequiredField', { ns: 'Errors' })
                  }}
                  itemProps={{
                    className: styles.vat
                  }}
                />
              )}

              <FormCurrencySelect
                label={t('Currency')}
                name="currency"
                rules={{
                  required: t('RequiredField', { ns: 'Errors' })
                }}
                itemProps={{
                  className: styles.currency
                }}
              />
            </div>

            <Table
              data={{
                ...defaultValues,
                language: languageField,
                deadlineAt: deadlineAtField,
                withTax: withTaxField,
                tax: taxField,
                currency: currencyField.value,
                paymentFromDocumentType:
                  paymentFromDocumentTypeField.value.toLowerCase()
              }}
              dataSource={dataSource}
              setDataSource={setDataSource}
              total={total}
              setTotal={setTotal}
            />

            <Divider className={styles.divider} />

            <Title level={3} className={styles.title}>
              {t('AddressHeading')}
            </Title>

            <div className={styles.locationWrap}>
              <FormLocationInput
                label={t('MainAddress')}
                name="location"
                placeholder=""
                itemProps={{
                  className: styles.withoutMargin
                }}
              />

              <FormInput
                label={t('AdditionalAddress')}
                name="locationExact"
                itemProps={{
                  className: styles.withoutMargin
                }}
              />
            </div>

            <Divider className={styles.divider} />

            <Title level={3} className={styles.title}>
              {t('DeadlinesHeading')}
            </Title>

            <div className={styles.datesWrap}>
              <FormDatePicker
                label={t('OrderStartDate')}
                maxDate={deadlineAtField}
                name="startAt"
                rules={{
                  required: t('RequiredField', { ns: 'Errors' })
                }}
                wrapperClassname={styles.datePickerWrapper}
              />

              <FormDatePicker
                label={t('OrderDueDate')}
                name="deadlineAt"
                minDate={startAtField || new Date()}
                minTime={minTimeEnd}
                maxTime={maxTimeEnd}
                rules={{
                  required: t('RequiredField', { ns: 'Errors' }),
                  validate: value =>
                    !moment(startAtField).isAfter(value) ||
                    t('DateStartCannotBeAfterDateEnd', { ns: 'Errors' })
                }}
                wrapperClassname={styles.datePickerWrapper}
              />

              <FormDatePicker
                label={t('ShipmentDate')}
                name="shipmentAt"
                wrapperClassname={styles.datePickerWrapper}
              />
            </div>

            <div className={styles.daysToPaymentWrap}>
              <Text size="large">{t('PaymentIsDueWithin')}</Text>

              <FormInputNumber
                name="daysToPayment"
                isOnlyWhole
                disableZeroEntry
                suffix={t('days')}
                itemProps={{
                  className: styles.withoutMargin
                }}
              />

              <Text size="large">{t('FromTheMomentIssue')}</Text>

              <FormCustomSelect
                name="paymentFromDocumentType"
                options={DOCUMENT_TYPES}
                className={styles.documentType}
              />
            </div>

            {specificationCreatedAtField &&
              paymentFromDocumentTypeField.value === SPECIFICATION &&
              daysToPaymentField &&
              daysToPaymentField !== '' && (
                <div style={{ marginTop: 16 }}>
                  <Text size="large">{t('PaymentDueDate')} </Text>

                  <Text size="large" weight="semibold">
                    {moment(specificationCreatedAtField)
                      .add(daysToPaymentField || 0, 'days')
                      .format(DATE_FORMAT)}
                  </Text>
                </div>
              )}
          </div>
          <div ref={myRef}>
            <Checkbox
              style={{ marginBottom: 24 }}
              onChange={handleSchedulingCheck}
              checked={scheduler}
            >
              {t('ScheduledActsBtnChckbx')}
            </Checkbox>
          </div>

          {scheduler && (
            <div className={styles.blockWrapper}>
              <Title level={3} className={styles.title}>
                {t('ActScheduleHeading')}
              </Title>

              <SchedulerForm />
            </div>
          )}
        </div>

        <div className={styles.blockWrapper}>
          <Title level={3} className={styles.title}>
            {t('AdditionalConditionsHeading')}
          </Title>

          <FormNewEditor
            label={t('AdditionalConditionsDesc')}
            name="comment"
            allowAttach={false}
            placeholder=""
            itemProps={{
              className: styles.comment
            }}
            showItems={{
              upload: false,
              mention: false,
              emoji: false,
              topToolbar: true
            }}
            isHtml
            resizeInput
          />
        </div>

        {/* <div className={styles.blockWrapper}>
          <Title level={3} className={styles.title}>
            {t('OrderDescHeading')}
          </Title>

          <FormInput
            label={t('OrderName')}
            name="title"
            placeholder={t('EnterServiceName')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
          />

          <FormNewEditor
            label={t('OrderDesc')}
            name="content"
            placeholder={t('OrderDescPlchldr')}
            resizeInput
          />
        </div> */}

        <div className={styles.buttonsWrap}>
          <Button
            type="link"
            size="large"
            className={styles.previewBtn}
            htmlType="submit"
            disabled={needRequisites || isLoading}
            loading={isLoadingPreview}
            onClick={() => setIsPreview(true)}
          >
            {t('PreviewBtn')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            width="expanded"
            disabled={needRequisites || isLoadingPreview}
            loading={isUploadingFiles || isLoading}
            onClick={() => setIsPreview(false)}
          >
            {`${t('SendBtn')} ${
              isUploadingFiles ? t('FileLoading', { ns: 'Common' }) : ''
            }`}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

SystemSpecificationForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isLoadingPreview: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  editRequisites: PropTypes.func.isRequired
};

SystemSpecificationForm.defaultProps = {
  defaultValues: {},
  isLoading: false,
  isLoadingPreview: false
};

export default SystemSpecificationForm;
