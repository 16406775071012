// COMMON
export const AI_ASSISTANT_MENTION_FEATURE_FLAG = 'ai-assistant-mention';
export const FILTER_PANE_OPENED_STATE_FEATURE_FLAG = 'filter-pane-opened-state';
export const SHOW_NEWYEAR_CONTENT_FEATURE_FLAG = 'show-newyear-content';

// TASKS
export const SET_REMINDER_FEATURE_FLAG = 'set-reminder';
export const GANTT_CHART_FEATURE_FLAG = 'gantt-chart';

// MENU
export const MENU_ANDROID_AND_IOS_FEATURE_FLAG = 'menu-android-and-ios';
export const MENU_REPORTS_FEATURE_FLAG = 'menu-reports';

// REPORTS
export const AD_SOURCES_REPORT_FEATURE_FLAG = 'ad-sources-report';
export const QUALITY_OF_SERVICE_PURCHASES_REPORT_FEATURE_FLAG =
  'quality-of-service-purchases-report';
export const QUALITY_OF_SERVICE_ORDERS_REPORT_FEATURE_FLAG =
  'quality-of-service-orders-report';
export const QUALITY_OF_SERVICE_ISSUES_REPORT_FEATURE_FLAG =
  'quality-of-service-issues-report';
export const REJECTED_ORDERS_REPORT_FEATURE_FLAG = 'rejected-orders-report';
export const APPLICATION_REPORT_FEATURE_FLAG = 'application-report';
export const CUSTOMER_REQUEST_REACTION_SPEED_FEATURE_FLAG =
  'customer-request-reaction-speed';
export const PLAN_ESTIMATION_REPORT_FEATURE_FLAG = 'plan-estimation-report';
export const EXECUTIVE_DISCIPLINE_REPORT_FEATURE_FLAG =
  'executive-discipline-report';
export const TIME_TRACK_REPORT_FEATURE_FLAG = 'time-track-report';
export const TIME_TRACK_EXTEND_REPORT_FEATURE_FLAG = 'time-track-extend-report';
export const CONTACT_AND_CHATS_REPORT_FEATURE_FLAG = 'contact-and-chats-report';

// CHANNELS
export const CHANNELS_WHATSAPP_FEATURE_FLAG = 'channels-whatsapp';

// WORKSPACE
export const WORKSPACE_OWN_GOOGLE_DRIVE_INTEGRATION_FLAG =
  'workspace-own-google-drive-integration';
export const WORKSPACE_COMMON_GOOGLE_DRIVE_INTEGRATION_LEGACY_FLAG =
  'workspace-common-google-drive-integration-legacy';

// CHAT
export const CHAT_EXPANDING_LOGS_HISTORY_FEATURE_FLAG =
  'chat-expanding-logs-history';
export const DISCUSSION_CREATING_CONVERSATION_FEATURE_FLAG =
  'discussion-creating-conversation';
