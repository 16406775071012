import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Modal from 'components/common/modal';
import TypeForm from 'components/common/tags/type-form';

import { editTag } from 'store/tags';

import styles from './edit-tag-modal.module.scss';

const EditTagModal = ({ visible, onClose, searchValue, editableTag }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('TagsEditReplaceDelete');

  const onSubmit = data => dispatch(editTag(data));

  return (
    <Modal
      width={298}
      title={t('EditTagPageTitle')}
      destroyOnClose
      open={visible}
      onClose={onClose}
      contentClassName={styles.modalContent}
    >
      <TypeForm
        searchValue={searchValue}
        handleClose={onClose}
        isOpenModal={visible}
        editableTag={editableTag}
        onSubmit={onSubmit}
        warningMessage={t('AttentionText')}
      />
    </Modal>
  );
};

EditTagModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  searchValue: PropTypes.string,
  editableTag: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    type: PropTypes.string,
    entityType: PropTypes.string,
    entityId: PropTypes.string,
    link: PropTypes.string
  })
};

EditTagModal.defaultProps = {
  visible: false,
  onClose: () => {},
  searchValue: '',
  editableTag: null
};

export default EditTagModal;
