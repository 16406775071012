import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { LINK_TYPE } from 'constants/router';

import Tooltip from 'components/common/tooltip';

import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

import Option from '../option';

import styles from './common-option.module.scss';

const { Paragraph, Text } = Typography;

export const CommonOption = ({
  option,
  className,
  showId,
  showIcon,
  isLinkToElement,
  selectType,
  ...props
}) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const urlBySelectOption = {
    [LINK_TYPE.PROJECT]: routes.toProject(option.id),
    [LINK_TYPE.TASK]: `${routes.toTasks()}${modals.tasks.showDetails({
      id: option.id
    })}`,
    [LINK_TYPE.REQUEST]: `${routes.toRequests()}${modals.requests.showDetails({
      id: option.id
    })}`,
    [LINK_TYPE.ASSETS]: `${routes.toAssets()}${modals.assets.showDetails({
      id: option.id
    })}`
  };

  if (typeof option === 'string') {
    return option;
  }

  const title = option.title || option.name;

  const CommonOptionContent = () => (
    <>
      {option.icon && showIcon && (
        <img src={option.icon} className={styles.icon} alt={title} />
      )}

      <Paragraph
        ellipsis={{ rows: 1 }}
        style={{ marginBottom: 0, flexGrow: 1 }}
      >
        {option.id && showId && (
          <Text className={styles.textPrimary}>ID {option.id}</Text>
        )}
        <span className={isLinkToElement ? styles.textPrimary : ''}>
          <Tooltip title={title}>
            <span>{title}</span>
          </Tooltip>
        </span>
      </Paragraph>
    </>
  );

  return (
    <Option
      className={classnames(className, styles.root)}
      title={title}
      {...props}
    >
      {isLinkToElement ? (
        <span>
          <Link to={urlBySelectOption[selectType]}>
            <CommonOptionContent />
          </Link>
        </span>
      ) : (
        <CommonOptionContent />
      )}
    </Option>
  );
};

CommonOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }).isRequired,
  showId: PropTypes.bool,
  showIcon: PropTypes.bool,
  isLinkToElement: PropTypes.bool,
  selectType: PropTypes.string
};

CommonOption.defaultProps = {
  showId: true,
  showIcon: true,
  isLinkToElement: false,
  selectType: ''
};

export default CommonOption;
