import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import api from 'api';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import { FormPhoneInput } from 'components/common/hook-form';
import Typography from 'components/common/typography';
import { MobileAppQrIcon } from 'components/common/icons';

import { getUserPhone } from 'store/user';

import styles from './mobile-app.module.scss';

const { Title, Text } = Typography;

const Form = ({ isLoading, defaultValues, onSubmit }) => {
  const { t } = useTranslation(['DownloadMobApp', 'DownloadBlankScreen']);
  const methods = useForm({ defaultValues });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormPhoneInput
          name="phone"
          label={t('PhoneNumber')}
          itemProps={{ style: { textAlign: 'left', marginBottom: 32 } }}
        />

        <Button
          type="primary"
          size="large"
          width="expanded"
          htmlType="submit"
          loading={isLoading}
          className={styles.submit}
        >
          {t('DownloadAppBtn', { ns: 'DownloadBlankScreen' })}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  defaultValues: PropTypes.shape({
    phone: PropTypes.string
  }),
  onSubmit: PropTypes.func.isRequired
};

Form.defaultProps = {
  isLoading: false,
  defaultValues: {}
};

export const MobileAppModal = ({ visible, onClose }) => {
  const phone = useSelector(getUserPhone);

  const { t } = useTranslation('DownloadMobApp');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async data => {
    try {
      setIsLoading(true);
      await api.support.sendMobileAppLink(data.phone);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={800}
      destroyOnClose
      open={visible}
      contentClassName={styles.root}
      onClose={onClose}
    >
      <div className={styles.content}>
        <Title level={1} weight="bold" className={styles.title}>
          {t('DownloadMobAppHeading')}
        </Title>

        <Text color="black-45" className={styles.description}>
          {t('DownloadMobAppDesc')}
        </Text>

        <Form
          onSubmit={onSubmit}
          isLoading={isLoading}
          defaultValues={{ phone }}
        />
      </div>

      <div className={styles.qr}>
        <Icon component={MobileAppQrIcon} />
      </div>
    </Modal>
  );
};

MobileAppModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

MobileAppModal.defaultProps = {
  visible: false
};

export default MobileAppModal;
