import React, { useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  ACCEPTED_STATE_ACTUAL,
  ACCEPTED_STATE_AGREEMENT,
  SPEC_KIND_SYSTEM
} from 'constants/index';

import Attachment from 'components/common/attachments/attachment';
import { FormDatePicker } from 'components/common/hook-form';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { AttachmentSkeleton } from 'components/common/skeletons';
import FormNewEditor from 'components/common/hook-form/markdown';

import {
  fetchOrderStatusAllSpecifications,
  fetchOrderStatusAttachments
} from 'store/order-statuses';

import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';
import useMinMaxTime from 'hooks/common/use-min-max-time';

import Table from '../system-specification/table';

import styles from './change-act.module.scss';

const { Title } = Typography;

const actDescription = {
  ru: 'Настоящий акт является актом сдачи-приемки выполненных работ (оказанных услуг). Стороны подтверждают , что исполнитель сдал, а заказчик принял вышеперечисленные работы (услуги), выполненные (оказанные) в полном объеме и в установленный договором срок. Заказчик претензий по объему, качеству, срокам выполнения работ (оказания услуг) и иным условиям договора не имеет.',
  eng:
    'The invoice is a confirmation of the performed works (rendered services). ' +
    ' Parties therefore confirm that above mentioned services are accomplished to the fullest extent and due date. ' +
    'Customer has no claims as of the volume, quality and terms of services provided.'
};

const ActChangeSystemForm = ({
  defaultValues,
  isLoading,
  isLoadingPreview,
  onSubmit,
  orderStatus
}) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState(defaultValues.items);
  const [total, setTotal] = useState({});
  const [isPreview, setIsPreview] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);

  const [isFillLoading, setIsFillLoading] = useState(false);
  const [submittedValues, setSubmittedValues] = useState(null);

  const { t } = useTranslation(['ChangeAct', 'Errors']);

  const methods = useForm({
    defaultValues: {
      language: defaultValues.language,
      contractDate: moment(defaultValues.contractDate).toDate(),
      contractNumber: defaultValues.contractNumber,
      specificationCreatedAt: moment(
        defaultValues.specificationCreatedAt
      ).toDate(),
      documentList: defaultValues.documentList,
      withTax: defaultValues.withTax,
      tax: defaultValues.tax,
      currency: defaultValues.currency,
      location: defaultValues.location,
      locationExact: defaultValues.locationExact,
      startAt: defaultValues.startAt
        ? moment(defaultValues.startAt).toDate()
        : undefined,
      deadlineAt: moment(defaultValues.deadlineAt).toDate(),
      shipmentAt: defaultValues.shipmentAt
        ? moment(defaultValues.shipmentAt).toDate()
        : undefined,
      daysToPayment: defaultValues.daysToPayment,
      comment: {
        description: defaultValues.comment,
        fileList: []
      },
      title: defaultValues.title,
      actDescription: {
        description: actDescription[defaultValues.language],
        fileList: defaultValues.fileList || []
      },
      paymentFromDocumentType: defaultValues.paymentFromDocumentType,
      actCreatedAt: new Date()
    }
  });

  const [
    withTaxField,
    deadlineAtField,
    actDescriptionField,
    documentListField,
    currencyField,
    taxField,
    languageField,
    paymentFromDocumentTypeField,
    actCreatedAtField
  ] = useWatch({
    name: [
      'withTax',
      'deadlineAt',
      'actDescription',
      'documentList',
      'currency',
      'tax',
      'language',
      'paymentFromDocumentType',
      'actCreatedAt'
    ],
    control: methods.control
  });

  const isDocumentListLoading = useUploadingFiles(documentListField);
  const isFileListLoading = useUploadingFiles(actDescriptionField.fileList);
  const isUploadingFiles = isFileListLoading || isDocumentListLoading;

  const actAgreement =
    orderStatus.acts &&
    orderStatus.acts.find(({ state }) => state === ACCEPTED_STATE_AGREEMENT);

  const [minTimeStart, maxTimeStart] = useMinMaxTime({
    startDate: actCreatedAtField,
    endDate: defaultValues.scheduler
      ? defaultValues.scheduler.nextDate
      : deadlineAtField
  });

  const transformSubmitedValues = values => ({
    ...values,
    daysToPayment:
      (values.daysToPayment !== '' && values.daysToPayment) || undefined,
    tax: values.withTax ? values.tax : null,
    fileList: getFileIdsV2(values.actDescription.fileList),
    documentList: getFileIdsV2(values.documentList),
    comment: values.comment.description,
    currency: values.currency,
    isPreview,
    kind: SPEC_KIND_SYSTEM,
    items: dataSource.map(({ tax: _, ...item }) => item),
    price: total.totalPrice,
    paymentFromDocumentType: values.paymentFromDocumentType.toLowerCase(),
    actDescription: values.actDescription.description
  });

  const fetchDocuments = async () => {
    try {
      setIsLoadingDocuments(true);
      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: {
            relatedOrderStatuses: orderStatus.id,
            ignoreCache: true
          }
        })
      );

      const results = documentList;

      if (orderStatus.contract) {
        const { _embedded: specificationsEmbedded = {} } = await dispatch(
          fetchOrderStatusAllSpecifications({
            id: orderStatus.id,
            contractId: orderStatus.contract
          })
        );

        const resultEmbedded = specificationsEmbedded.specifications || [];

        resultEmbedded.forEach(specOrAct => {
          const docsWithState = results.filter(result =>
            specOrAct.documentList.includes(result.fileId)
          );
          docsWithState.forEach(doc => {
            const index = results.findIndex(
              result => result.fileId === doc.fileId
            );

            if (index !== -1) {
              results[index] = {
                ...results[index],
                acceptedState: specOrAct.state
              };
            }
          });
        });
      }

      setDocuments(
        results.filter(
          ({ acceptedState }) => acceptedState === ACCEPTED_STATE_ACTUAL
        )
      );
    } finally {
      setIsLoadingDocuments(false);
    }
  };

  useEffect(() => {
    fetchDocuments();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const WarningMessage = () => (
    <>
      {t('ActCostChangedWarning', {
        totalPrice: `${total.totalPrice} ${currencyField}`,
        withVat: t(total.tax ? 'WithVATBtn' : 'WithoutVATBtn'),
        defaultTotalPrice: `${defaultValues.price.toFixed(2)} ${currencyField}`
      })}
    </>
  );

  const handleSubmit = values => {
    if (isFillLoading) {
      return setTimeout(() => setSubmittedValues(values), 500);
    }

    return onSubmit(transformSubmitedValues(values));
  };

  useEffect(() => {
    if (submittedValues && !isFillLoading) {
      onSubmit(transformSubmitedValues(submittedValues));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submittedValues, isFillLoading]);

  return (
    <>
      <FormProvider {...methods}>
        <form
          className={styles.root}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          {isLoadingDocuments && (
            <div className={styles.skeletonWrap}>
              {[...Array(2).keys()].map(key => (
                <AttachmentSkeleton
                  key={`act-documents-skeleton-${key}`}
                  className={styles.skeleton}
                />
              ))}
            </div>
          )}

          {!isLoadingDocuments && (
            <div className={styles.documents}>
              {documents.map(a => (
                <Attachment
                  key={a.id || a.uid || a.uuid}
                  file={a}
                  actionsDeps={{
                    statusId: orderStatus.id,
                    sendCopyToComment: a.acceptedState !== undefined,
                    allowDeleteFrom: false
                  }}
                  fileLinkTarget="_blank"
                />
              ))}
            </div>
          )}

          <FormDatePicker
            label={t('ActDate')}
            minDate={moment(
              actAgreement && defaultValues.scheduler
                ? actAgreement.createdAt
                : defaultValues.startAt
            ).toDate()}
            maxDate={moment(
              defaultValues.scheduler
                ? defaultValues.scheduler.nextDate
                : defaultValues.deadlineAt
            ).toDate()}
            minTime={minTimeStart}
            maxTime={maxTimeStart}
            name="actCreatedAt"
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
          />

          <Title level={3} className={styles.title}>
            {t('CostHeading')}
          </Title>

          {Number(total.totalPrice) > defaultValues.price && (
            <Alert
              type="warning"
              message={<WarningMessage />}
              className={styles.warningMessage}
            />
          )}

          <Table
            data={{
              ...defaultValues,
              language: languageField,
              deadlineAt: deadlineAtField,
              withTax: withTaxField,
              tax: taxField,
              currency: currencyField,
              paymentFromDocumentType:
                paymentFromDocumentTypeField.toLowerCase()
            }}
            dataSource={dataSource}
            setDataSource={setDataSource}
            setIsFillLoading={setIsFillLoading}
            total={total}
            setTotal={setTotal}
            isEditorField={false}
          />

          <div className={styles.comment}>
            <FormNewEditor
              label={t('TextInAct')}
              name="actDescription"
              isHtml
              showItems={{
                upload: false,
                emoji: false,
                mention: false,
                topToolbar: true
              }}
            />
          </div>

          <div className={styles.buttonsWrap}>
            <Button
              type="link"
              size="large"
              className={styles.previewBtn}
              htmlType="submit"
              disabled={isLoading}
              loading={isLoadingPreview}
              onClick={() => setIsPreview(true)}
            >
              {t('PreviewBtn')}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              size="large"
              width="expanded"
              disabled={isLoadingPreview}
              loading={isUploadingFiles || isLoading}
              onClick={() => setIsPreview(false)}
            >
              {`${t('SaveAndSendBtn')} ${
                isUploadingFiles ? t('FileLoading', { ns: 'Common' }) : ''
              }`}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

ActChangeSystemForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  isLoadingPreview: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

ActChangeSystemForm.defaultProps = {
  defaultValues: {},
  isLoading: false,
  isLoadingPreview: false
};

export default ActChangeSystemForm;
