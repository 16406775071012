import { CONTACT, ORDER, TASK, TYPE_ASSET, TYPE_DISCUSSION } from './status';

export const NOTIFICATIONS = 'notifications';

export const FILTER_ALL = 'all';
export const FILTER_COMMAND = 'action';
export const FILTER_TASK = 'task';
export const FILTER_ORDER = 'order';
export const FILTER_CONTACT = 'contact';

export const EMPLOYEE_DISMISS = 'employee-dismiss';

const OWNER_CHANGE = 'owner-change';
const EMPLOYEE_BENCH = 'employee-bench';
const ADMIN_CHANGE = 'admin-change';
const OWNER_BECOME = 'owner-become';
const EMPLOYEE_JOIN = 'employee-join';
export const WORKSPACE_JOIN = 'workspace_join';

export const CHANGING_PROCESSES = [
  OWNER_CHANGE,
  EMPLOYEE_BENCH,
  ADMIN_CHANGE,
  OWNER_BECOME,
  EMPLOYEE_JOIN
];

export const COLLAPSED_WIDGET_PANEL = 'collapsed-widget-panel';
export const COLLAPSED_NOTIFICATIONS_FILTER_PANEL =
  'filter-pane/collapsed-notifications-filter-panel';

export const COLLAPSED_NOTIFICATIONS_IN_BELL_FILTER_PANEL =
  'filter-pane/collapsed-notifications-in-bell-filter-panel';

export const ENTITY_TYPE_FILTER_OPTIONS = {
  [TYPE_DISCUSSION]: {
    value: TYPE_DISCUSSION,
    label: 'Conversations',
    ns: 'Dashboard'
  },
  [TASK]: {
    value: TASK,
    label: 'Tasks',
    ns: 'Dashboard'
  },
  [ORDER]: {
    value: ORDER,
    label: 'MenuOrders',
    ns: 'Dashboard'
  },
  [CONTACT]: {
    value: CONTACT,
    label: 'Contacts',
    ns: 'Dashboard'
  },
  [TYPE_ASSET]: {
    value: TYPE_ASSET,
    label: 'DirectoriesFilter',
    ns: 'AIAssistant'
  }
};
