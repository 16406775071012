import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTabKey, setTabKey, setIsTabVisible } from 'store/router';

import { useLocalStorage } from 'hooks';

export const BrowserTabsProvider = ({ children }) => {
  const dispatch = useDispatch();

  const tabKey = useSelector(getTabKey);

  const [openedTabs, setOpenedTabs] = useLocalStorage('openedTabs', []);
  const [, setLastActiveTab] = useLocalStorage('lasetActiveTab', null);

  useEffect(() => {
    dispatch(setTabKey(Date.now().toString(36)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tabKey) {
      setOpenedTabs([tabKey, ...openedTabs]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      const currentOpenedTabs = JSON.parse(localStorage.getItem('openedTabs'));

      setOpenedTabs(currentOpenedTabs.filter(t => t !== tabKey));
    });

    document.addEventListener('click', () => {
      setLastActiveTab(tabKey);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabKey]);

  useEffect(() => {
    document.addEventListener('visibilitychange', () => {
      dispatch(setIsTabVisible(document.visibilityState === 'visible'));
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <>{children}</>;
};

export default BrowserTabsProvider;
