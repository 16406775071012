import React, { useEffect, useState } from 'react';
import { Checkbox, Spin, Tree } from 'antd';
import { useDispatch } from 'react-redux';
import DirectoryTree from 'antd/lib/tree/DirectoryTree';
import difference from 'lodash/difference';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import Modal from 'components/common/modal';
import Icon from 'components/common/icon';
import Button from 'components/common/button';

import { fetchCategoriesLocal, fetchAssetsLocal } from 'store/assets';

import DebounceInput from '../../debounce-input';
import {
  getAssetIdByKey,
  getAssetIdsByKey,
  getAssetKey,
  getAssetsByKeys,
  getCategoryIdByKey,
  getCategoryKeyById,
  transformToTreeData
} from './utils';
import AssetOption from '../custom-select/asset-option';

import styles from './assets-select.module.scss';

const AssetsSelectModal = ({ visible, value, isMulti, onClose, onChange }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['Filters', 'Common']);

  const [search, setSearch] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);

  const [currentCategories, setCurrentCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [categoriesWithLoadedAssets, setCategoriesWithLoadedAssets] = useState(
    {}
  );
  const [assets, setAssets] = useState([]);
  const [treeData, setTreeData] = useState([]);

  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedValueKeys, setCheckedValueKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [allKeys, setAllKeys] = useState([]);
  const [selectedKey, setSelectedKey] = useState(null);

  const isAllChecked =
    !difference(allKeys, checkedKeys).length &&
    (!!currentCategories.length || search);

  const onCheckKeys = keys => {
    const added = keys.filter(k => !checkedKeys.includes(k));
    const deleted = checkedKeys.filter(k => !keys.includes(k));

    const values = [...checkedValueKeys, ...added].filter(
      k => !deleted.includes(k)
    );
    setCheckedValueKeys(values);

    setCheckedKeys(keys);
  };

  const createTreeData = (categoryList, loadedCategoryIds = []) => {
    setCurrentCategories(categoryList);
    setCategoriesWithLoadedAssets(
      loadedCategoryIds.reduce((acc, curr) => {
        acc[curr] = true;
        return acc;
      }, {})
    );

    setAllKeys(categoryList.map(item => getCategoryKeyById(item.id)));

    const data = transformToTreeData(categoryList);
    setTreeData(data);
  };

  const fetchCategories = async ({ isInitial, categories, values } = {}) => {
    try {
      setIsLoading(true);

      let resultCategories = categories;

      if (!resultCategories) {
        resultCategories = await dispatch(fetchCategoriesLocal());
        setAllCategories(resultCategories);
      }

      let resultValue = (() => {
        if (isMulti) {
          return values || value || [];
        }

        return Array.isArray(value) ? value : [value];
      })();

      if (isInitial && isMulti) {
        const assetWithoutCategory = resultValue.filter(
          a => a.label && !a.label.category
        );

        if (assetWithoutCategory.length) {
          const { results } = await dispatch(
            fetchAssetsLocal({
              params: {
                limit: 10000,
                ids: assetWithoutCategory.map(a => a.label.id)
              }
            })
          );

          resultValue = resultValue.map(a => {
            const fetchedAsset = results.find(r => r.id === a.label.id);

            return { ...a, label: { ...a.label, ...(fetchedAsset || {}) } };
          });
        }

        setCheckedValueKeys(resultValue.map(a => getAssetKey(a.label)));
      }

      let assetCategoryIds = [];

      if (resultValue.filter(Boolean).length) {
        assetCategoryIds = Array.from(
          new Set(
            resultValue
              .filter(a => a.label && a.label.category)
              .map(a => a.label.category.id)
          )
        );

        const { results } = await dispatch(
          fetchAssetsLocal({
            params: {
              limit: 10000,
              category: assetCategoryIds.map(id => ({ value: id }))
            }
          })
        );

        setAssets(results);
        setExpandedKeys(assetCategoryIds.map(getCategoryKeyById));
        setCheckedKeys(resultValue.map(a => getAssetKey(a.label)));
      }

      createTreeData(resultCategories, assetCategoryIds);
    } finally {
      setIsLoading(false);
    }
  };

  const onExpandKeys = async (keys, { expanded }) => {
    const [newExpandedKey] = difference(keys, expandedKeys);
    const categoryId = getCategoryIdByKey(newExpandedKey);

    if (
      expanded &&
      categoryId &&
      !categoriesWithLoadedAssets[categoryId] &&
      !search
    ) {
      try {
        setIsLoading(true);

        const { results } = await dispatch(
          fetchAssetsLocal({ params: { limit: 10000, category: categoryId } })
        );

        setAllKeys(prev => [...prev, ...results.map(getAssetKey)]);

        if (isAllChecked) {
          setCheckedKeys(prev => [...prev, ...results.map(getAssetKey)]);
        }

        setAssets(prev => [...prev, ...results]);
        setCategoriesWithLoadedAssets(prev => ({
          ...prev,
          [categoryId]: true
        }));
      } finally {
        setIsLoading(false);
      }
    }

    setExpandedKeys(keys);
  };

  const onCheckAll = () => onCheckKeys(isAllChecked ? [] : allKeys);

  const onSubmit = async () => {
    let resultAssets = assets;
    let resultKeys = isMulti ? checkedValueKeys : [selectedKey];

    if (isAllChecked && !search && isMulti) {
      try {
        setIsLoadingSubmit(true);

        const { results } = await dispatch(
          fetchAssetsLocal({ params: { limit: 10000 } })
        );

        resultAssets = results;
        resultKeys = results.map(getAssetKey);
      } finally {
        setIsLoadingSubmit(false);
      }
    }

    const categoryIds = resultKeys
      .map(getCategoryIdByKey)
      .filter(Boolean)
      .filter(k => !categoriesWithLoadedAssets[k]);

    const assetIds = resultKeys.map(getAssetIdByKey).filter(Boolean);

    if (!isAllChecked && !search && isMulti) {
      try {
        setIsLoadingSubmit(true);

        if (categoryIds.length) {
          const { results } = await dispatch(
            fetchAssetsLocal({
              params: {
                limit: 10000,
                category: categoryIds.map(id => ({ value: id }))
              }
            })
          );

          resultAssets = [...resultAssets, ...results];
          resultKeys = [...resultKeys, ...results.map(getAssetKey)];
        }
      } finally {
        setIsLoadingSubmit(false);
      }
    }

    if (search && resultKeys.length && isMulti) {
      try {
        setIsLoadingSubmit(true);

        const promises = [];

        if (categoryIds.length) {
          promises.push(
            dispatch(
              fetchAssetsLocal({
                withoutCancelling: true,
                params: {
                  limit: 10000,
                  category: categoryIds.map(id => ({ value: id }))
                }
              })
            )
          );
        } else {
          promises.push(Promise.resolve({ results: [] }));
        }

        if (assetIds.length) {
          promises.push(
            dispatch(
              fetchAssetsLocal({
                withoutCancelling: true,
                params: {
                  limit: 10000,
                  ids: assetIds
                }
              })
            )
          );
        } else {
          promises.push(Promise.resolve({ results: [] }));
        }

        const assetResults = await Promise.all(promises);

        resultAssets = [...assetResults[0].results, ...assetResults[1].results];
        resultKeys = resultAssets.map(getAssetKey);
      } finally {
        setIsLoadingSubmit(false);
      }
    }

    const assetValues = getAssetsByKeys({
      keys: [...new Set(resultKeys)],
      assets: resultAssets
    });

    onChange(isMulti ? assetValues : assetValues[0]);

    onClose();
  };

  const handleSearch = async () => {
    if (!search) {
      if (isMulti) {
        return fetchCategories({
          categories: allCategories,
          values: checkedValueKeys
            .map(getAssetIdsByKey)
            .filter(ids => !!ids.id)
            .map(({ id, categoryId }) => ({
              label: { id, category: { id: categoryId } }
            }))
        });
      }

      setSelectedKey(null);
      return createTreeData(allCategories);
    }

    try {
      setIsLoading(true);

      const { results } = await dispatch(
        fetchAssetsLocal({ params: { limit: 10000, search } })
      );

      setAllKeys(results.map(getAssetKey));
      setCheckedKeys(
        checkedValueKeys.filter(key =>
          results.find(a => a.id === getAssetIdByKey(key))
        )
      );

      return setAssets(results);
    } finally {
      setIsLoading(false);
    }
  };

  const renderNodeByRole = nodeProps =>
    nodeProps.isCategory ? nodeProps.title : <AssetOption option={nodeProps} />;

  const renderTreeNodes = data =>
    data.map(item => {
      const children = [
        ...assets
          .filter(a => a.category.id === item.id)
          .map(a => ({ ...a, key: getAssetKey(a) })),
        ...(item.children || [])
      ];

      // need to allow to expand category and load assets, if it is not have subcategories
      if (item.assetsCount && !children.length && !search) {
        children.push({
          title: 'fake-node',
          key: `fake-node-${item.key}`
        });
      }

      if (children.length) {
        return (
          <Tree.TreeNode
            title={renderNodeByRole(item)}
            key={item.key}
            dataRef={item}
            selectable={!item.isCategory}
          >
            {renderTreeNodes(children)}
          </Tree.TreeNode>
        );
      }

      return (
        <Tree.TreeNode
          key={item.key}
          title={renderNodeByRole(item)}
          dataRef={item}
          selectable={!item.isCategory}
        />
      );
    });

  useEffect(() => {
    if (visible) {
      fetchCategories({ isInitial: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (allCategories.length) {
      handleSearch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  return (
    <Modal
      width={800}
      title={t('AllAssetsHeading')}
      open={visible}
      contentClassName={classnames(styles.modal, {
        [styles.withSearch]: search
      })}
      destroyOnClose
      centered
      onClose={onClose}
    >
      <DebounceInput
        placeholder={t('SearchWithEnter', { ns: 'Common' })}
        isSetValueByPressEnter
        value={search}
        setValue={setSearch}
      />

      <Spin spinning={isLoading} wrapperClassName={styles.content}>
        {isMulti && (
          <Checkbox
            indeterminate={checkedKeys.length && !isAllChecked}
            checked={isAllChecked}
            onChange={onCheckAll}
          >
            {t('ChooseAllChckbx')}
          </Checkbox>
        )}

        <DirectoryTree
          checkable={isMulti}
          multiple={isMulti}
          selectable={!isMulti}
          showIcon={false}
          switcherIcon={<Icon type="arrow" size={16} />}
          expandedKeys={expandedKeys}
          checkedKeys={checkedKeys}
          onExpand={onExpandKeys}
          onCheck={onCheckKeys}
          onSelect={([key]) => setSelectedKey(key)}
        >
          {search
            ? assets.map(a => (
                <Tree.TreeNode
                  key={getAssetKey(a)}
                  title={renderNodeByRole(a)}
                  dataRef={a}
                  selectable
                />
              ))
            : renderTreeNodes(treeData)}
        </DirectoryTree>
      </Spin>

      <Button
        type="primary"
        width="expanded"
        className={styles.submit}
        disabled={isLoading}
        loading={isLoadingSubmit}
        onClick={onSubmit}
      >
        {t('ChooseBtn')}
      </Button>
    </Modal>
  );
};

export default AssetsSelectModal;
