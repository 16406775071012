import React, { useState } from 'react';
// import { Link } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  // COMPLETE_CLOSE_ORDER,
  COMPLETE_COMMENT,
  COMPLETE_DOCUMENT,
  COMPLETE_LINK,
  COMPLETE_MEDIA,
  TASK
} from 'constants/index';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import Typography from 'components/common/typography';
import FormInput from 'components/common/hook-form/input';
import FormAttachments from 'components/common/hook-form/attachments';
import { FormMarkdownEditor } from 'components/common/hook-form';
import { adoptMessageObject } from 'components/common/comments/converters';
import useManageSubscribers from 'components/common/subscriptions/use-manage-subscribers';
import useValidityDate from 'components/common/validity-date/use-validity-date';

import { sendComment } from 'store/comments';

// import useRoutesService from 'services/routes';
// import useModalsService from 'services/modals';
import {
  getIsAudio,
  getIsEditableFile,
  getIsImage,
  getIsPDF,
  getIsVideo,
  getIsXLS
} from 'hooks/common/use-file-upload/types';
import { getFileIds } from 'hooks/common/use-file-upload/get-file-ids';

const Form = ({ isLoading, taskId, info, currentComplete, onSubmit }) => {
  const methods = useForm({
    defaultValues: {
      link: '',
      comment: '',
      attachments: [],
      markdown: {
        description: '',
        fileList: []
      }
    }
  });

  const { t } = useTranslation(['TaskResultModal', 'Errors']);

  const showCommentField = currentComplete !== COMPLETE_COMMENT;
  const showAttachmentsField =
    currentComplete === COMPLETE_MEDIA || currentComplete === COMPLETE_DOCUMENT;
  const showLinkField = currentComplete === COMPLETE_LINK;
  const showMarkdownField = currentComplete === COMPLETE_COMMENT;

  const linkPattern = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );

  const attachmentsValidator = {
    [COMPLETE_MEDIA]: value => {
      if (!value.length) {
        return t('RequiredField', { ns: 'Errors' });
      }

      const mediaFiles = value.filter(
        a => getIsImage(a) || getIsVideo(a) || getIsAudio(a)
      );

      if (!mediaFiles.length) {
        return t('UploadMedia', { ns: 'Errors' });
      }

      return true;
    },
    [COMPLETE_DOCUMENT]: value => {
      if (!value.length) {
        return t('RequiredField', { ns: 'Errors' });
      }

      const docFiles = value.filter(
        a => getIsEditableFile(a) || getIsPDF(a) || getIsXLS(a)
      );

      if (!docFiles.length) {
        return t('UploadDoc', { ns: 'Errors' });
      }

      return true;
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Typography.Paragraph style={{ marginBotton: 16 }}>
          {info}
        </Typography.Paragraph>

        {showLinkField && (
          <FormInput
            name="link"
            placeholder={t('EnterLink')}
            data-qa="qa-vx7espuc82zl3x1"
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              pattern: {
                value: linkPattern,
                message: t('EnterCorrectLink', { ns: 'Errors' })
              }
            }}
          />
        )}

        {showCommentField && (
          <FormInput
            name="comment"
            placeholder={t('Comment')}
            data-qa="qa-3vxltv1e3lkpumn"
          />
        )}

        {showAttachmentsField && (
          <FormAttachments
            taskId={taskId}
            name="attachments"
            rules={{
              validate: attachmentsValidator[currentComplete]
            }}
          />
        )}

        {showMarkdownField && (
          <FormMarkdownEditor
            name="markdown"
            placeholder=""
            data-qa="qa-xwau0gob7r1mzct"
            toolbarHidden
            rules={{
              validate: value =>
                !!value.description || t('RequiredField', { ns: 'Errors' })
            }}
            taskId={taskId}
          />
        )}

        <Button
          htmlType="submit"
          type="primary"
          loading={isLoading}
          style={{ fontWeight: 600, display: 'block', margin: '24px 0 0 auto' }}
          data-qa="qa-p0veg8mwkisqupy"
        >
          {t('SendBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  isLoading: PropTypes.bool,
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  taskId: PropTypes.number,
  currentComplete: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

Form.defaultProps = {
  isLoading: false,
  info: undefined,
  taskId: undefined,
  currentComplete: undefined
};

export const CompleteRequireModal = ({
  visible,
  data,
  onUpdateStatus,
  onClose,
  resetReorderingTasks
}) => {
  const dispatch = useDispatch();

  // const routes = useRoutesService({ returnUrl: true });
  // const modals = useModalsService({ returnUrl: true });

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('TaskResultModal');

  const { subscribeToNewAttachments } = useManageSubscribers();
  const { handleChangeValidityDateForNewAttachments } = useValidityDate();

  const { task, status } = data;

  // const { icon, objectId, relation = {} } =
  //   (task.relations || []).find(({ type }) => type === ORDER) || {};

  // const orderLink = `${routes.toOrderStatuses()}${modals.orders.showDetails({
  //   orderStatusId: objectId
  // })}`;

  const infoByValue = {
    [COMPLETE_DOCUMENT]: t('TaskResultDocumentDesc'),
    // [COMPLETE_CLOSE_ORDER]: (
    //   <span>
    //     После нажатия на кнопку "Отправить" заказчику будет направлен запрос на
    //     приемку выполненных работ по заказу
    //     <Link
    //       to={orderLink}
    //       style={{ display: 'inline-flex', alignItems: 'center' }}
    //       data-qa="qa-b2mvrr9zg8q43v4"
    //     >
    //       <img
    //         src={icon}
    //         alt={objectId}
    //         style={{ margin: '0 4px 3px 8px', width: 23 }}
    //       />
    //       {relation.id}
    //     </Link>
    //   </span>
    // ),
    [COMPLETE_COMMENT]: t('TaskResultCommentDesc'),
    [COMPLETE_MEDIA]: t('TaskResultMediafileDesc'),
    [COMPLETE_LINK]: t('TaskResultLinkDesc')
  };

  const onSubmit = async ({ attachments, markdown, link, comment }) => {
    const getContent = () => {
      let result = [];

      if (link) {
        result = [{ type: 'link', url: link }];
      }

      if (comment) {
        result = [...result, { text: comment }];
      }

      if (markdown) {
        result = [...result, { text: markdown.description }];
      }

      return result;
    };

    const attachmentsToSend = markdown
      ? (markdown || {}).fileList
      : attachments;

    try {
      setIsLoading(true);

      await dispatch(
        sendComment({
          id: task.id,
          comment: {
            entity: 'task',
            is_private: false,
            fileList: getFileIds(attachmentsToSend || []),
            content: adoptMessageObject([{ children: getContent() }])
          }
        })
      );

      await onUpdateStatus({ id: task.id, status });

      subscribeToNewAttachments(attachmentsToSend);
      handleChangeValidityDateForNewAttachments({
        attachments: attachmentsToSend,
        actionDeps: {
          entityType: TASK,
          entityId: task.id
        }
      });

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={t('TaskResultModalHeading')}
      data-qa="qa-vejp91opwgcz9ze"
      width={1170}
      centered
      open={visible}
      contentStyle={{ padding: 24 }}
      onClose={() => {
        onClose();
        resetReorderingTasks();
      }}
    >
      <Form
        taskId={task.id}
        info={infoByValue[task.completeRequire || COMPLETE_COMMENT]}
        currentComplete={task.completeRequire || COMPLETE_COMMENT}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

CompleteRequireModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({
    task: PropTypes.object,
    status: PropTypes.string
  }),
  onUpdateStatus: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  resetReorderingTasks: PropTypes.func
};

CompleteRequireModal.defaultProps = {
  visible: false,
  data: {
    task: {},
    status: null
  },
  resetReorderingTasks: () => {}
};

export default CompleteRequireModal;
