import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { TYPE_ASSET } from 'constants/index';

import Modal from 'components/common/modal';
import FormAssetSelect from 'components/common/hook-form/select/asset';
import Button from 'components/common/button';

import mapValue from 'utils/map-value';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const AssetRelationModal = ({ visible, assets, onClose, onSubmit }) => {
  const methods = useForm();

  const { t } = useTranslation(['ChooseAsset', 'Errors']);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    methods.reset();

    return onClose();
  };

  const handleSubmit = async ({ assets: values }) => {
    try {
      setIsLoading(true);

      const result = {
        isDelete: false,
        relType: TYPE_ASSET,
        relationIds: values.map(mapValue)
      };

      await onSubmit([result]);

      showNoticeMessage({ number: NOTICE_NUMBER.relationsSaved });
      handleClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={508}
      title={t('ChooseAssetHeading')}
      destroyOnClose
      contentStyle={{ padding: 24 }}
      open={visible}
      onClose={handleClose}
    >
      <FormProvider {...methods}>
        <form
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <FormAssetSelect
            label={t('Asset')}
            name="assets"
            isMulti
            isLinkToElement
            showSelectedOptionSeparately
            params={{
              exclude: assets.map(a => a.id)
            }}
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
          />

          <Button
            type="primary"
            width="expanded"
            htmlType="submit"
            style={{ alignSelf: 'flex-end' }}
            loading={isLoading}
          >
            {t('ConfirmBtn')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

AssetRelationModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default AssetRelationModal;
