import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ORDER,
  TYPE_CONTACT,
  TYPE_ASSET
} from 'constants/index';

import Typography from 'components/common/typography';

import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';

import { Create as CreateWithWorkspaceGoogleDriveSupport } from './create-with-workspace-google-drive-support';
import { Create as CreateLegacy } from './create-legacy';
import { SELECT_TYPE_STEP, SETTINGS_STEP } from './utils/constants';

import styles from './create.module.scss';

const { Title } = Typography;

export const Create = props => {
  const { t } = useTranslation('Common');

  const {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    isOwnAndCommonGoogleDriveIntegrationEnabled
  } = useWorkspaceGoogleDriveSupport();

  // Такой кейс пока будет только для Busel Capital, потом удалится
  if (isOwnAndCommonGoogleDriveIntegrationEnabled) {
    return (
      <>
        <div className={styles.panel}>
          <Title level={3} className={styles.title}>
            {t('CompanyName')}
          </Title>
          <CreateLegacy {...props} />
        </div>

        <div className={styles.panel}>
          <Title level={3} className={styles.title}>
            {t('GoogleDrive')}
          </Title>
          <CreateWithWorkspaceGoogleDriveSupport {...props} />
        </div>
      </>
    );
  }

  return isWorkspaceOwnGoogleDriveIntegrationEnabled ? (
    <CreateWithWorkspaceGoogleDriveSupport {...props} />
  ) : (
    <CreateLegacy {...props} />
  );
};

Create.propTypes = {
  defaultValues: PropTypes.shape({
    step: PropTypes.oneOf([SELECT_TYPE_STEP, SETTINGS_STEP]),
    relation: PropTypes.shape({
      relationId: PropTypes.number,
      relationType: PropTypes.oneOf([
        TYPE_TASK,
        TYPE_ORDER,
        TYPE_CONTACT,
        TYPE_ASSET
      ])
    })
  }),
  onSubmit: PropTypes.func
};

Create.defaultProps = {
  defaultValues: {},
  onSubmit: () => {}
};
