import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';

import RequestSelect from 'components/common/controls/custom-select/request-select';

import FormItem from '../form-item';

export const FormRequestSelect = ({
  rules,
  name,
  label,
  itemProps,
  ...props
}) => {
  const { control, formState } = useFormContext();

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={rules}
        render={({ field }) => <RequestSelect {...field} {...props} />}
      />
    </FormItem>
  );
};
