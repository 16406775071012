import React, { useState, useCallback, useEffect } from 'react';
import { Divider, Alert } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { isEqual } from 'lodash';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';

import {
  TYPE_MEMBERS,
  TYPE_CONTROLLER,
  TYPE_DATE_START,
  TYPE_DATE_END,
  TYPE_TIME,
  TYPE_ADDRESS,
  TITLE,
  DESCRIPTION,
  TYPE_RELATIONS,
  TYPE_CONTACT_TO,
  TYPE_CONTACT,
  DATE_TIME_FORMAT_FM,
  TYPE_PROJECT,
  RESPONSIBLE,
  SCHEDULER,
  TYPE_TAGS,
  TYPE_CO_RESPONSIBLES,
  TYPE_MEETING_CONTACTS,
  TYPE_REQUEST,
  TYPE_CHANNEL,
  DATE_PICKER_TIME_FORMAT
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import { convertMessageToString } from 'components/common/comments/converters';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import useValidityDateFileList from 'components/common/validity-date/use-validity-date-file-list';
import Typography from 'components/common/typography';
import {
  FormChanneleSelect,
  FormContactSelect,
  FormDatePicker,
  FormInput,
  FormInputNumber,
  FormLocationInput,
  FormProjectSelect,
  FormRelationsSelect,
  FormSprintSelect,
  FormSwitch,
  FormTagsSelect,
  LabelWithTooltip,
  validateMinLength,
  withoutBubbling
} from 'components/common/hook-form';
import { CloseIcon } from 'components/common/icon/icons';
import FormNewEditor from 'components/common/hook-form/markdown';
import { addValidityDateState } from 'components/common/validity-date/utils/add-validity-date-state';

import useMinMaxTime from 'hooks/common/use-min-max-time';
import getTimeByEstimate from 'utils/get-time-by-estimate';
import { useUploadingFiles } from 'hooks/common/use-file-upload/use-uploading-files';
import { usePrevious } from 'hooks';
import getMembers from 'utils/get-members';

import ActionTypeMembers from '../../components/actions/type-members';
import ActionTypeResponsible from '../../components/actions/type-responsible';
import ActionTypeController from '../../components/actions/type-controller';
import useTaskInfo from './use-task-info';
import { getFullFilledFields } from '../utils';

import styles from './styles.module.scss';

const mapValue = value => {
  if (value) {
    return {
      value: value.id,
      label: {
        ...value
      }
    };
  }
  return null;
};

const getDateEndRules = ({
  startDate,
  checkSameDates,
  checkSameFormat,
  t
}) => ({
  required: t('RequiredField', { ns: 'Errors' }),
  validate: value => {
    if (checkSameDates && moment(startDate).isSame(value, checkSameFormat)) {
      return t('DateStartCannotBeEqualDateEnd', { ns: 'Errors' });
    }

    if (moment(startDate).isAfter(value)) {
      return t('DateStartCannotBeAfterDateEnd', { ns: 'Errors' });
    }

    return true;
  }
});

const MainForm = ({
  onSubmit,
  current,
  currentTaskType,
  defaultValues = {},
  isLoading,
  isBacklog
}) => {
  const prevValues = usePrevious(defaultValues);

  const [activeFields, setActiveFields] = useState([]);
  const [visibleDateStart, setVisibleDateStart] = useState(false);
  const [contactToVisibility, setContactToVisibility] = useState(false);

  const { t } = useTranslation([
    'AddTask',
    'Requests',
    'AddTaskType',
    'Errors',
    'TaskLinks',
    'Common'
  ]);

  const getInitialDateStartValue = () =>
    defaultValues.dateStart
      ? moment(defaultValues.dateStart, DATE_TIME_FORMAT_FM).toDate().getTime()
      : new Date(moment().toDate().getTime());

  const description =
    typeof defaultValues.description === 'string'
      ? defaultValues.description
      : (defaultValues.description || []).filter(el => !el.link);

  const apartValues =
    defaultValues && getTimeByEstimate(defaultValues.estimation);

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      title: defaultValues.title || '',
      responsible: defaultValues.responsible
        ? mapValue(defaultValues.responsible[0])
        : null,
      controller: defaultValues.controller
        ? mapValue(defaultValues.controller)
        : null,
      dateStart: getInitialDateStartValue(),
      dateEnd: moment(defaultValues.dateEnd).isValid()
        ? moment(defaultValues.dateEnd).toDate()
        : defaultValues.maxDateEnd && moment(defaultValues.maxDateEnd).toDate(),
      tags: defaultValues.tags ? defaultValues.tags.map(mapValue) : [],
      relations: defaultValues?.relations || [],
      description: {
        description: convertMessageToString(description),
        fileList: (defaultValues?.fileList || []).map(addValidityDateState)
      },
      project: defaultValues?.project
        ? {
            value: defaultValues.project.id,
            label: defaultValues.project
          }
        : undefined,
      sprint: defaultValues?.sprint
        ? {
            value: defaultValues.sprint.id,
            label: defaultValues.sprint
          }
        : undefined,
      members: defaultValues ? getMembers(defaultValues) : [],
      hours: apartValues?.hours ? apartValues.hours.toString() : null,
      minutes: apartValues?.minutes ? apartValues.minutes.toString() : null,
      storyPoint: defaultValues?.storyPoint || undefined,
      isStoryPointEstimation: defaultValues?.isStoryPointEstimation || false,
      contactTo: (defaultValues?.relations || [])
        .filter(item => item.type === TYPE_CONTACT)
        .map(item => ({
          label: {
            ...item.label,
            firstName: item.label?.name || ''
          },
          value: item.objectId
        })),
      contact: defaultValues?.contact || [],
      channel: defaultValues?.channel || null,
      taskInfo: defaultValues?.taskInfo || {}
    }
  });

  const isTopTask = !(defaultValues || {}).parent;
  const isFromChat = !!(defaultValues.taskInfo || {}).messageUuid;
  const isSlaRequestFormChat =
    isFromChat && !!(defaultValues.taskInfo || {}).executorSla;

  const project = methods.watch(TYPE_PROJECT);
  const projectId = (project || {}).value;

  useEffect(() => {
    setVisibleDateStart(false);
    setContactToVisibility(false);

    const fullFilledFields = getFullFilledFields({
      defaultValues,
      activeFields
    });

    if (fullFilledFields.includes(TYPE_DATE_START)) setVisibleDateStart(true);

    setActiveFields(fullFilledFields);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTaskType]);

  // When creating a task from outside, we update the form if there have been changes in initialData
  useEffect(() => {
    if (
      prevValues &&
      Object.keys(defaultValues || {}).length &&
      !isEqual(defaultValues, prevValues)
    ) {
      methods.reset();
    }
  }, [prevValues, defaultValues]);

  const addField = type =>
    setActiveFields(
      [...activeFields, type].sort(sortByCoResponsiblesAndController)
    );

  const removeField = type => {
    setActiveFields(activeFields.filter(field => field !== type));
    methods.resetField(type);
  };

  const startDate = methods.watch(TYPE_DATE_START);
  const endDate = methods.watch(TYPE_DATE_END);
  const contact = methods.watch(TYPE_CONTACT);

  const { getTaskInfo } = useTaskInfo();

  const [previousEndDate, setPreviousEndDate] = useState(null);
  const [executorSla, setExecutorSla] = useState(null);

  const selectedChannel = methods.watch(TYPE_CHANNEL);

  useEffect(() => {
    const fetchChannelSla = async () => {
      if (selectedChannel && selectedChannel.label?.uuid) {
        const taskInfo = await getTaskInfo({
          channelUuid: selectedChannel.label.uuid,
          kind: selectedChannel.label.kind,
          contactId: null
        });

        setExecutorSla(taskInfo.taskInfo.executorSla || null);
      } else {
        setExecutorSla(null);
      }
    };

    fetchChannelSla();
  }, [selectedChannel]);

  useEffect(() => {
    if (executorSla !== null) {
      const calculatedDateEnd = new Date(Date.now() + executorSla * 60 * 1000);

      setPreviousEndDate(methods.getValues(TYPE_DATE_END));

      methods.setValue(TYPE_DATE_END, calculatedDateEnd);
    } else if (!selectedChannel) {
      methods.setValue(TYPE_DATE_END, previousEndDate || null);
    }
  }, [executorSla, selectedChannel]);

  const transformSubmitedValues = async value => {
    const isValidValidityDates = validateValidityDateStateValues();

    if (!isValidValidityDates) {
      return;
    }

    if (
      projectId &&
      !value.dateStart &&
      !value.dateEnd &&
      !methods.watch(SCHEDULER)
    ) {
      // to reset field validation
      methods.setValue({ [TYPE_DATE_END]: undefined });
    }

    const isErrSubTask = !!((value[DESCRIPTION] || {}).subTasks || []).find(
      subTask => !subTask.title
    );

    if (!isErrSubTask) {
      const getRelations = () => {
        let results = [];

        if (activeFields.includes(TYPE_RELATIONS)) {
          results = [...(value.relations || [])];
        }

        if (activeFields.includes(TYPE_MEETING_CONTACTS)) {
          results = [
            ...results,
            ...transformValueToRelation(
              value.meetingContacts || [],
              TYPE_CONTACT
            )
          ];
        }

        return results;
      };

      const taskInfoData = await getTaskInfo({
        channelUuid: value.channel?.label?.uuid || null,
        kind: value.channel?.label?.kind || null,
        contactId: value.contact?.value || null,
        channelId: value.channel?.value || null
      });

      onSubmit({
        ...value,
        kind: TYPE_REQUEST,
        hours: activeFields.includes(TYPE_TIME) ? value.hours : null,
        minutes: activeFields.includes(TYPE_TIME) ? value.minutes : null,
        approvingManager: null,
        relations: getRelations(),
        dateStart: activeFields.includes(TYPE_DATE_START)
          ? value.dateStart
          : null,
        project: isTopTask ? (value.project || {}).value || null : null,
        sprint: isTopTask ? (value.sprint || {}).value || null : null,
        storyPoint: +value.storyPoint || undefined,
        ...value[DESCRIPTION],
        [DESCRIPTION]: (value[DESCRIPTION] || {})[DESCRIPTION] && [
          {
            text: value[DESCRIPTION].description
          },
          ...(value[DESCRIPTION].links || [])
        ],
        ...(value[SCHEDULER]
          ? {
              schedulerConfig: {
                freqInterval: value.freqInterval,
                weekdays: value.weekdays,
                freqType: value.freqType
              }
            }
          : {}),
        taskInfo: taskInfoData.taskInfo
      });
    }
  };

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate,
    endDate
  });

  const isUploadingFiles = useUploadingFiles(
    (methods.watch(DESCRIPTION) || {}).fileList
  );

  const isStoryPointEstimation = methods.watch('isStoryPointEstimation');

  const descriptionValues = methods.watch(DESCRIPTION);

  const { changeValidityDateStateValue, validateValidityDateStateValues } =
    useValidityDateFileList({
      fileList: descriptionValues.fileList,
      onChange: updatedFileList =>
        methods.setValue(DESCRIPTION, {
          ...descriptionValues,
          fileList: updatedFileList
        })
    });

  const actions = [
    {
      type: TYPE_ADDRESS,
      title: t('AddressHeading'),
      onClick: () => addField(TYPE_ADDRESS),
      icon: 'environment',
      allow: true,
      visibility: true,
      component: (
        <div className={styles.flexWrap}>
          <FormLocationInput
            label={t('MainAddress')}
            name="location"
            placeholder={t('EnterAddress')}
            itemProps={{
              className: styles.location
            }}
          />

          <FormInput label={t('AdditionalAddress')} name="locationExact" />
        </div>
      )
    },
    {
      type: TYPE_CONTROLLER,
      title: t('Controller'),
      allow: true,
      onClick: () => addField(TYPE_CONTROLLER),
      icon: 'user-add',
      visibility: true,
      component: (
        <ActionTypeController
          projectId={projectId}
          defaultValues={defaultValues.controller}
        />
      )
    },
    {
      type: TYPE_TAGS,
      title: t('Tags'),
      onClick: () => addField(TYPE_TAGS),
      icon: 'tags',
      allow: true,
      visibility: true,
      component: (
        <FormTagsSelect
          name={TYPE_TAGS}
          label={t('Tags')}
          itemProps={{ style: { marginBottom: 24 } }}
        />
      )
    },
    {
      type: TYPE_DATE_START,
      title: t('StartDate'),
      onClick: () => {
        addField(TYPE_DATE_START);
        setVisibleDateStart(true);
      },
      icon: 'calendar',
      disabled: false,
      allow: true,
      visibility: true
    },
    {
      type: TYPE_PROJECT,
      title: t('Project'),
      onClick: () => addField(TYPE_PROJECT),
      icon: 'project',
      allow: true,
      visibility: isTopTask,
      deleteBtnStyle: {
        top: -12
      },
      component: (
        <>
          <Typography.Text
            className={classnames(styles.subTitle, styles.block)}
          >
            {t('Project')}
          </Typography.Text>

          <div className={styles.projectFields}>
            <FormProjectSelect
              name="project"
              label={t('Project')}
              params={{
                isActive: true
              }}
              closeMenuOnSelect
              callback={() => methods.setValue({ sprint: null })}
            />

            {project && !!project.label.sprintsCount && true && (
              <FormSprintSelect
                name="sprint"
                label={t('Sprint')}
                params={{
                  project: projectId,
                  status: ['active', 'planned'],
                  isLag: false
                }}
              />
            )}
          </div>
        </>
      )
    },
    {
      type: TYPE_RELATIONS,
      title: t('LinksHeading'),
      onClick: () => addField(TYPE_RELATIONS),
      icon: 'link',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -8
      },
      component: (
        <>
          <Typography.Text
            className={classnames(styles.subTitle, styles.block)}
            style={{ marginBottom: 16 }}
            data-qa="qa-tolj1gemcntd5ei"
          >
            {t('LinksHeading')}
          </Typography.Text>

          <FormRelationsSelect
            name={TYPE_RELATIONS}
            label={
              <LabelWithTooltip
                label={t('LinkType', { ns: 'TaskLinks' })}
                tooltip={t('RequestLinksDesc', { ns: 'Requests' })}
              />
            }
          />
        </>
      )
    },
    {
      type: TYPE_MEMBERS,
      title: t('Coexecutors'),
      onClick: () => addField(TYPE_MEMBERS),
      icon: 'big-team',
      allow: true,
      visibility: true,
      component: (
        <div className={styles.members} data-qa="qa-ssr3ysl0qog09qb">
          <ActionTypeMembers
            projectId={projectId}
            defaultValues={defaultValues}
          />
        </div>
      )
    },
    {
      type: TYPE_TIME,
      title: t('EstimatedTime'),
      onClick: () => addField(TYPE_TIME),
      icon: 'timer',
      allow: true,
      visibility: true,
      deleteBtnStyle: {
        top: -10
      },
      component: (
        <>
          <Typography.Text
            className={classnames(styles.subTitle, styles.block)}
            data-qa="qa-5d3rzy2j6qplqgy"
          >
            {t('EstimatedTime')}
          </Typography.Text>

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start'
            }}
          >
            {isStoryPointEstimation ? (
              <FormInputNumber
                label={t('Storypoints')}
                name="storyPoint"
                placeholder="000"
                maxLength={3}
              />
            ) : (
              <div className={styles.timeset} data-qa="qa-pz7tabse7kerbw0">
                <FormInputNumber
                  name="hours"
                  label={t('Hours')}
                  placeholder="000"
                  maxLength={3}
                />

                <FormInputNumber
                  name="minutes"
                  label={t('Minutes')}
                  placeholder="000"
                  maxLength={3}
                  autoFocus
                />
              </div>
            )}

            {!defaultValues.parent && (
              <FormSwitch
                label={t('ChangeToStorypointsToggle')}
                name="isStoryPointEstimation"
                placeholder="000"
                itemProps={{ style: { margin: '27px 0 0 0', width: 'auto' } }}
              />
            )}
          </div>
        </>
      )
    }
  ];

  const renderTitle = () => (
    <div className={styles.field} data-qa="qa-bjc7ya7fwudhyna">
      <FormInput
        label={t('TaskName')}
        className={styles.title}
        name={TITLE}
        rules={{
          required: t('RequiredField', { ns: 'Errors' }),
          minLength: validateMinLength(2)
        }}
        autoFocus
        autoComplete="off"
      />
    </div>
  );

  const renderDescription = () => (
    <div
      className={classnames(styles.field, styles.description)}
      data-qa="qa-kpga5cdf9m2eks8"
    >
      <FormNewEditor
        name={DESCRIPTION}
        label={t('TaskDescriptionMobile')}
        placeholder={t('RequestDescriptionPlchldr', { ns: 'Requests' })}
        actionsDeps={{
          entityType: TYPE_REQUEST
        }}
        mentionClassName={styles.mentionWrap}
        toolbarHidden
        allowAttach
        destination={{
          entityId: defaultValues.id,
          entityType: TYPE_REQUEST
        }}
        resizeInput
        allowManageSubscribers
        hideSubscribersAction
        hideValidityDateAction
        validityDateDeps={{ changeValidityDateStateValue }}
        highlightAttachmentsBackground
      />
    </div>
  );

  const renderResponsible = useCallback(() => {
    const responsibles = methods.watch(RESPONSIBLE);

    return (
      <>
        <ActionTypeResponsible
          projectId={projectId}
          isDisabled={false}
          defaultValues={defaultValues}
        />

        {(responsibles || []).length > 1 && (
          <Alert
            type="warning"
            message={t('SeveralResponsiblesWarning')}
            style={{ fontSize: 12, marginBottom: 15 }}
          />
        )}
      </>
    );
  }, [projectId]);

  const renderDates = () => (
    <div className={classnames(styles.dates)} data-qa="qa-i2p5c3lsddlcdgk">
      <div className={styles.dateContainer} data-qa="qa-n862olj2yefwsuv">
        {visibleDateStart && (
          <div className={styles.dateStart} data-qa="qa-el2pguhp7736pmj">
            {visibleDateStart && (
              <FormDatePicker
                name={TYPE_DATE_START}
                label={t('StartDate')}
                rules={{
                  required: projectId,
                  message: t('RequiredField', { ns: 'Errors' })
                }}
                dateFormat={DATE_PICKER_TIME_FORMAT}
                wrapperClassname={styles.datePicker}
                allowSetBeforeCurrentDate={false}
                minDate={new Date()}
                maxDate={endDate}
                minTime={minTimeStart}
                maxTime={maxTimeStart}
              />
            )}
            <Button
              icon={<CloseIcon />}
              type="text"
              className={styles.btnDelete}
              data-qa="qa-je9cb5tlkwj268k"
              onClick={() => {
                removeField(TYPE_DATE_START);
                setVisibleDateStart(false);
              }}
            />
          </div>
        )}
        <FormDatePicker
          name={TYPE_DATE_END}
          label={t('TaskDueDate')}
          style={{ maxWidth: 180 }}
          rules={getDateEndRules({
            startDate,
            checkSameDates: true,
            checkSameFormat: 'minute',
            t
          })}
          dateFormat={DATE_PICKER_TIME_FORMAT}
          wrapperClassname={styles.datePicker}
          minDate={startDate || new Date()}
          maxDate={
            defaultValues.maxDateEnd &&
            moment(defaultValues.maxDateEnd).toDate()
          }
          disabled={!!executorSla || isSlaRequestFormChat}
          minTime={minTimeEnd}
          maxTime={maxTimeEnd}
        />
      </div>
    </div>
  );

  const renderContact = () => (
    <FormContactSelect
      name={TYPE_CONTACT}
      addEntityButtonData={null}
      label={`${t('Contact', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
      valueText={t('EnterYourNameEmail', { ns: 'Common' })}
      reloadAfterOpen
      params={{
        channel: selectedChannel ? selectedChannel.value : undefined
      }}
      isDisabled={isFromChat}
    />
  );

  const renderChannel = () => (
    <FormChanneleSelect
      isClearable
      name={TYPE_CHANNEL}
      label={`${t('Channel', { ns: 'Common' })} ${t('Optional', { ns: 'Common' })}`}
      addEntityButtonData={null}
      params={{
        contact: contact ? contact.value : undefined,
        ordering: 'name'
      }}
      reloadAfterOpen
      valueText={t('ChooseChannel', { ns: 'ConnectWidget' })}
      isDisabled={isSlaRequestFormChat}
    />
  );

  const filteredActions = actions
    .filter(a => !activeFields.includes(a.type))
    .filter(action => action.visibility);

  const sortByCoResponsiblesAndController = (a, b) => {
    if (a === TYPE_CONTROLLER || a === TYPE_CO_RESPONSIBLES) {
      return -1;
    }
    if (b === TYPE_CONTROLLER || b === TYPE_CO_RESPONSIBLES) {
      return 1;
    }
    return 0;
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={event =>
          withoutBubbling(event, () =>
            methods.handleSubmit(transformSubmitedValues)
          )
        }
        className={styles.root}
      >
        <div className={styles.fieldsWrap} data-qa="qa-qekax3a8aav153d">
          {executorSla && !isSlaRequestFormChat && (
            <Alert
              type="warning"
              message={t('RequestSLAWarning', { ns: 'Requests' })}
              className={styles.slaAlert}
            />
          )}

          {isSlaRequestFormChat && (
            <Alert
              type="warning"
              message={t('RequestCreateSLAWarning', { ns: 'Requests' })}
              className={styles.slaAlert}
            />
          )}

          {renderTitle()}

          {renderDescription()}

          {renderDates()}

          {renderResponsible()}

          {renderContact()}

          {renderChannel()}

          {(activeFields || []).map(field => {
            const action = actions
              .filter(item => item.visibility)
              .find(item => item.type === field);

            if (field !== TYPE_DATE_START) {
              return (
                <div
                  key={field}
                  className={styles.field}
                  data-qa="qa-h5ztwdjpszrlnx0"
                >
                  {action && action.component}

                  <Button
                    type="text"
                    icon={<CloseIcon />}
                    className={styles.btnDelete}
                    style={(action || {}).deleteBtnStyle}
                    data-qa="qa-yau3zi7shuxwodz"
                    onClick={() => removeField(field)}
                  />
                </div>
              );
            }

            return undefined;
          })}

          {contactToVisibility && (
            <div className={styles.withHint} data-qa="qa-zfwkursejfcgrsi">
              <FormContactSelect
                label={t('WhoToSend')}
                valueText={t('ChooseContact')}
                name={TYPE_CONTACT_TO}
                isMulti
                closeMenuOnSelect={false}
                rules={{
                  required: contactToVisibility,
                  message: t('RequiredField', { ns: 'Errors' })
                }}
              />

              <Alert
                type="warning"
                message={t('WhoToSendWarning')}
                style={{ fontSize: 12 }}
              />
            </div>
          )}
        </div>

        <Divider className={styles.divider} data-qa="qa-sb8qczoewi8j9t7" />

        <ActionsDropdown
          actions={filteredActions}
          placement="bottomLeft"
          btnClassName={styles.actionsWrap}
          data-qa="qa-ul9pdpfvyr1qaf2"
          overlayClassName={styles.dropdownOverlay}
        >
          <Button
            type="link"
            weight="bold"
            className={styles.btnDropdown}
            data-qa="qa-s6038pwmz8o0h26"
          >
            <Icon type="plus" /> {t('ShowAllFieldsBtn')}
          </Button>
        </ActionsDropdown>

        <div className={styles.footerActions} data-qa="qa-ia26ypbtpanka41">
          <Button
            style={{ marginLeft: 'auto', minWidth: 130 }}
            htmlType="submit"
            type="primary"
            data-qa="qa-1ffnvx0iym4t05o"
            size="large"
            disabled={isLoading || isUploadingFiles}
            loading={isLoading || isUploadingFiles}
          >
            {t('CreateBtn')}{' '}
            {isUploadingFiles && ` ${t('FileLoading', { ns: 'Common' })}`}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default MainForm;
