import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { WIDTH_DRAWER } from 'constants/styles';

import AppointmentDrawer from 'components/team-view/employees-view/drawers/appointment';
import EmployeeEditorDrawer from 'components/team-view/employees-view/drawers/editor';
import useVisibleWriteProfile from 'components/team-view/employees-view/drawers/editor/use-visible-write-profile';
import useVisibleAppointment from 'components/team-view/employees-view/drawers/appointment/use-visible-appointment';

import { getIsOnlyUserProfile } from 'store/user';
import { getAllWorkspaces } from 'store/workspace';

import getActiveWorkspaces from 'utils/get-active-workspaces';

export const BenchProvider = ({ children }) => {
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);
  const workspaces = useSelector(getAllWorkspaces);

  const visibleAppointment = useVisibleAppointment();
  const visibleWriteProfile = useVisibleWriteProfile();

  const { t } = useTranslation('FiilEmployeeProfile');

  if (isOnlyUserProfile || !getActiveWorkspaces(workspaces).length) {
    return children;
  }

  return (
    <>
      {children}

      <EmployeeEditorDrawer
        visible={visibleWriteProfile}
        title={t('FiilEmployeeProfileHeading')}
        closable={false}
        data-qa="qa-sm9twfpxge5qkh2"
      />

      <AppointmentDrawer
        open={visibleAppointment && !visibleWriteProfile}
        width={WIDTH_DRAWER}
      />
    </>
  );
};

BenchProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

BenchProvider.defaultProps = {
  children: undefined
};

export default BenchProvider;
