import React, { useState } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { Button, Popover } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'components/common/theme';

import FormItem from '../form-item';
import ColorPicker from './color-picker';

import styles from './color-input.module.scss';

export const FormColorInput = ({
  rules,
  name,
  label,
  itemProps,
  disabled,
  ...props
}) => {
  const { variables } = useTheme();
  const { control, formState } = useFormContext();
  const [visible, setVisible] = useState(false);

  const { t } = useTranslation('Errors');

  return (
    <FormItem
      label={label}
      name={name}
      {...itemProps}
      errors={formState.errors}
    >
      <Controller
        control={control}
        rules={{
          ...rules,
          pattern: {
            value: /^#[0-9a-fA-F]{6}$/,
            message: t('ValidateCorrectValue')
          }
        }}
        defaultValue={variables.color['brand-40']}
        render={({ field }) => {
          const { value, onChange, ...renderProps } = field;

          return (
            <Popover
              placement="rightTop"
              content={<ColorPicker value={value} onChange={onChange} />}
              trigger="click"
              open={visible}
              overlayClassName={styles.popover}
              className={styles.wrapPopover}
              onOpenChange={v => setVisible(disabled ? false : v)}
              getPopupContainer={trigger => trigger.parentNode}
            >
              <Button
                className={classnames(styles.root, {
                  [styles.disabled]: disabled
                })}
                {...renderProps}
                {...props}
              >
                <span>{value}</span>
                <div
                  className={styles.colorBlock}
                  style={{ backgroundColor: value }}
                />
              </Button>
            </Popover>
          );
        }}
      />
    </FormItem>
  );
};

FormColorInput.defaultProps = {
  rules: PropTypes.any,
  itemProps: PropTypes.object
};

FormColorInput.defaultProps = {
  rules: {},
  itemProps: {}
};

export default FormColorInput;
