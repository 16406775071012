import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Icon } from 'components/common/icon';
import { GoogleDriveNotConnectedIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { getUserEmployee } from 'store/workspace';

import getHasOwnerRole from 'utils/get-has-owner-role';
import useRoutesService from 'services/routes';

import styles from './workspace-google-drive-not-integrated.module.scss';

const { Paragraph } = Typography;

export const WorkspaceGoogleDriveNotIntegrated = () => {
  const { t } = useTranslation('Common');

  const routes = useRoutesService();

  const employee = useSelector(getUserEmployee);

  const isOwner = getHasOwnerRole(employee.roles);

  const noConnectionMessage = isOwner
    ? t('GoogleDriveNotConnectedMessageForOwner')
    : t('GoogleDriveNotConnectedMessage');

  const handleClick = () => routes.toWorkspaceIntegrations();

  return (
    <div className={styles.root}>
      <div className={styles.wrapper}>
        <div className={styles.image}>
          <Icon component={GoogleDriveNotConnectedIcon} />
        </div>

        <Paragraph className={styles.message}>{noConnectionMessage}</Paragraph>

        {isOwner && (
          <Button
            type="primary"
            width="expanded"
            size="large"
            onClick={handleClick}
          >
            {t('Connect')}
          </Button>
        )}
      </div>
    </div>
  );
};
