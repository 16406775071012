import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { FormEmployeeSelect, FormSwitch } from 'components/common/hook-form';

import { getIsEditableFile } from 'hooks/common/use-file-upload/types';

import styles from './manage-subscribers-multi-attachment.module.scss';

const ManageSubscribersMultiAttachment = ({ data, index }) => {
  const form = useFormContext();
  const { t } = useTranslation(['FileSubscribers', 'AddFile']);

  const { mimeType, parentName = 'subscribersForAttachments' } = data;

  const watchAllowSubscriberSelection = form.watch(
    `${parentName}.${index}.allowSubscriberSelection`
  );

  return (
    <>
      {getIsEditableFile({ mimeType }) && (
        <div className={styles.root}>
          <FormSwitch
            name={`${parentName}.${index}.allowSubscriberSelection`}
            label={t('SubscribeEmployeesToggle', { ns: 'AddFile' })}
            itemProps={{ className: styles.formItem }}
          />

          {watchAllowSubscriberSelection && (
            <FormEmployeeSelect
              name={`${parentName}.${index}.subscribers`}
              label={t('Subscribers')}
              isMulti
              params={{
                isPostMethod: true
              }}
              itemProps={{ className: styles.formItem }}
            />
          )}
        </div>
      )}
    </>
  );
};

ManageSubscribersMultiAttachment.propTypes = {
  data: PropTypes.shape({
    mimeType: PropTypes.string.isRequired,
    allowSubscriberSelection: PropTypes.bool,
    fileId: PropTypes.string,
    parentName: PropTypes.string,
    subscribers: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired
      })
    )
  }).isRequired,
  index: PropTypes.number.isRequired
};

export default ManageSubscribersMultiAttachment;
