import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { message } from 'antd';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MESSAGE_DURATION } from 'constants/index';

import Modal from 'components/common/modal';

import { addCategory } from 'store/assets';

import CategoryForm from '../../forms/category';

const CategoryCreatorModal = ({ visible, onClose, values }) => {
  const match = useRouteMatch('/:id/assets/categories/:categoryId');
  const { categoryId } = (match || {}).params || {};

  const { t } = useTranslation(['AddJournal', 'Toast']);

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async category => {
    try {
      setIsLoading(true);

      await dispatch(addCategory({ category, categoryId: +categoryId }));

      onClose();

      message.success(t('SettingsSaved', { ns: 'Toast' }), MESSAGE_DURATION);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={t('AddJournalHeading')}
      contentStyle={{ padding: 24 }}
      centered
      open={visible}
      width={508}
      onClose={onClose}
      destroyOnClose
    >
      <CategoryForm
        isCreator
        values={values}
        btnText={t('SaveBtn')}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

export default CategoryCreatorModal;
