import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { TASK } from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import styles from './ai-assistant-prompts.module.scss';

const ControlsAiAssistantPrompts = ({
  hasAiAssistantMention,
  entityType,
  onClick
}) => {
  const { t } = useTranslation('AIAssistant');

  const amplitude = useAmplitude();

  const prompts =
    entityType === TASK
      ? [
          {
            id: 1,
            text: t('SummarizeFromComments'),
            type: entityType,
            eventType: 'summarize'
          },

          {
            id: 2,
            text: t('EnhanceText'),
            type: entityType,
            eventType: 'enhance_text'
          },
          {
            id: 3,
            text: t('CheckGrammar'),
            type: entityType,
            eventType: 'check_grammar'
          }
        ]
      : [];

  const handleClick = prompt => {
    amplitude.aiChatPromptClickEvent({ type: prompt.eventType });
    onClick(prompt);
  };

  return (
    <>
      {prompts.length > 0 && (
        <div
          className={classnames(styles.root, {
            [styles.visible]: hasAiAssistantMention
          })}
        >
          <Typography.Paragraph weight="semibold" className={styles.title}>
            {t('HowCanIHelpHeading')}
          </Typography.Paragraph>

          <div className={styles.prompts}>
            {prompts.map(prompt => (
              <Button
                key={`ai-assistant-prompt-${prompt.id}`}
                type="tertiary"
                size="small"
                onClick={() => handleClick(prompt)}
              >
                {prompt.text}
              </Button>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

ControlsAiAssistantPrompts.propTypes = {
  hasAiAssistantMention: PropTypes.bool,
  entityType: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

ControlsAiAssistantPrompts.defaultProps = {
  hasAiAssistantMention: false
};

export default ControlsAiAssistantPrompts;
