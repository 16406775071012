import i18n from 'i18n-config';

const EMPLOYEE_PROFILE_NAMESPACE = 'EmployeeProfile';

const transformFields = (fields, parentKey) =>
  Object.keys(fields).map(key => ({
    key,
    parentKey,
    ...fields[key]
  }));

export const transformDataSource = dataSource => {
  const orderFields = transformFields(dataSource.order, 'order');
  const taskFields = transformFields(dataSource.task, 'task');
  const requestFields = transformFields(dataSource.request, 'request');
  const channelFields = transformFields(dataSource.channel, 'channel');
  const contactFields = transformFields(dataSource.contact, 'contact');
  const recordFields = transformFields(dataSource.record, 'record');
  const assetFields = transformFields(dataSource.asset, 'asset');
  const teamFields = transformFields(dataSource.team, 'team');

  return [
    {
      name: i18n.t('Tasks', { ns: EMPLOYEE_PROFILE_NAMESPACE }),
      email: {
        value: null,
        readOnly: false,
        field: 'task-email'
      },
      notification: {
        value: null,
        readOnly: false,
        field: 'task-feed'
      },
      pushNotification: {
        value: null,
        readOnly: false,
        field: 'task-popup'
      },
      isRow: true
    },
    ...taskFields,
    {
      name: i18n.t('RequestsTitle', { ns: 'Requests' }),
      email: {
        value: null,
        readOnly: false,
        field: 'request-email'
      },
      notification: {
        value: null,
        readOnly: false,
        field: 'request-feed'
      },
      pushNotification: {
        value: null,
        readOnly: false,
        field: 'request-popup'
      },
      isRow: true
    },
    ...requestFields,
    {
      name: i18n.t('Orders', { ns: EMPLOYEE_PROFILE_NAMESPACE }),
      email: {
        value: null,
        readOnly: false,
        field: 'order-email'
      },
      notification: {
        value: null,
        readOnly: false,
        field: 'order-feed'
      },
      pushNotification: {
        value: null,
        readOnly: false,
        field: 'order-popup'
      },
      isRow: true
    },
    ...orderFields,
    {
      name: i18n.t('Contacts', { ns: EMPLOYEE_PROFILE_NAMESPACE }),
      email: {
        value: null,
        readOnly: false,
        field: 'channel-email'
      },
      notification: {
        value: null,
        readOnly: false,
        field: 'channel-feed'
      },
      pushNotification: {
        value: null,
        readOnly: false,
        field: 'channel-popup'
      },
      isRow: true
    },
    ...channelFields,
    ...contactFields,
    {
      name: i18n.t('Calendar', { ns: EMPLOYEE_PROFILE_NAMESPACE }),
      email: {
        value: null,
        readOnly: false,
        field: 'order-email'
      },
      notification: {
        value: null,
        readOnly: false,
        field: 'order-feed'
      },
      pushNotification: {
        value: null,
        readOnly: false,
        field: 'order-popup'
      },
      isRow: true
    },
    ...recordFields,
    {
      name: i18n.t('Journals', { ns: EMPLOYEE_PROFILE_NAMESPACE }),
      email: {
        value: null,
        readOnly: false,
        field: 'asset-email'
      },
      notification: {
        value: null,
        readOnly: false,
        field: 'asset-feed'
      },
      pushNotification: {
        value: null,
        readOnly: false,
        field: 'asset-popup'
      },
      isRow: true
    },
    ...assetFields,
    {
      name: i18n.t('Team', { ns: EMPLOYEE_PROFILE_NAMESPACE }),
      email: {
        value: null,
        readOnly: false,
        field: 'asset-email'
      },
      notification: {
        value: null,
        readOnly: false,
        field: 'asset-feed'
      },
      pushNotification: {
        value: null,
        readOnly: false,
        field: 'asset-popup'
      },
      isRow: true
    },
    ...teamFields
  ];
};

export default transformDataSource;
