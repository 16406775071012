export const downloadFile = ({ url, fileName }) => {
  const a = document.createElement('a');
  a.style.display = 'none';
  a.target = '_blank';

  document.body.appendChild(a);

  a.href = url;
  a.setAttribute('download', fileName);

  a.click();

  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
};
