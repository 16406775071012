import { useCallback, useEffect } from 'react';
import get from 'lodash/get';

import { config } from 'config';
import { initGA, initYM, initFB, initVK } from './utils';

const {
  REACT_APP_GOOGLE_ANALYTICS_ID,
  REACT_APP_YANDEX_METRIKA_ID,
  REACT_APP_VK_PIXEL_ID,
  REACT_APP_FB_PIXEL_ID,
  REACT_APP_ENVIRONMENT
} = config;

const ENVIRONMENT_TRACKERS = {
  development: ['ym', 'gtag'],
  staging: ['ym', 'gtag', 'fbq', 'VK'],
  production: ['ym', 'gtag', 'fbq', 'VK']
}[REACT_APP_ENVIRONMENT];

window.analyticsState = {
  canTrack: false
};

const trackSafely =
  key =>
  (...args) => {
    try {
      get(window, key, () => {})(...args);
    } catch (error) {
      // eslint-disable-next-line no-empty
    }
  };

let callAttempt = 0;
const handleAnalyticsAction = (callback, delay = 500, max = 10) => {
  const { canTrack } = window.analyticsState;

  callAttempt++;

  if (callAttempt >= max || !canTrack || !ENVIRONMENT_TRACKERS) {
    return;
  }

  if (!ENVIRONMENT_TRACKERS.every(key => !!window[key])) {
    setTimeout(() => handleAnalyticsAction(callback, delay, max), delay);
    return;
  }

  callback();
};

export const useAnalytics = init => {
  const reachGoal = useCallback(
    (name, category) =>
      handleAnalyticsAction(() => {
        trackSafely('ym')(REACT_APP_YANDEX_METRIKA_ID, 'reachGoal', name);
        trackSafely('gtag')('event', name, { event_category: category });
      }),
    []
  );

  const trackPageView = useCallback(
    (url = window.location.pathname) =>
      handleAnalyticsAction(() => {
        trackSafely('ym')(REACT_APP_YANDEX_METRIKA_ID, 'hit', url, {
          title: document.title
        });
        trackSafely('gtag')('config', REACT_APP_GOOGLE_ANALYTICS_ID, {
          page_path: url
        });
        trackSafely('fbq')('track', 'PageView');
        trackSafely('VK.Goal')('page_view');
      }),
    []
  );

  useEffect(() => {
    if (init) {
      window.analyticsState.canTrack = true;

      initGA(REACT_APP_GOOGLE_ANALYTICS_ID);
      initYM(REACT_APP_YANDEX_METRIKA_ID);
      initFB(REACT_APP_FB_PIXEL_ID);
      initVK(REACT_APP_VK_PIXEL_ID);
    }
  }, [init]);

  return {
    reachGoal,
    trackPageView
  };
};

export default useAnalytics;
