// https://docs.google.com/document/d/1iLur4PGP8bryPq8nXq-ILM6rkjDvrCnbj5jLNOU5nAM/edit#

import React from 'react';
import { message } from 'antd';
import { Translation } from 'react-i18next';

const DURATION = 2;

const SUCCESS_NOTICE_TYPE = 'success';
export const ERROR_NOTICE_TYPE = 'error';
export const WARNING_NOTICE_TYPE = 'warning';

export const NOTICE_NUMBER = {
  taskFavoriteAdded: 1,
  taskFavoriteDeleted: 2,

  requestFavoriteAdded: 4,
  requestFavoriteDeleted: 6,
  requestStatusChanged: 10,

  taskStatusChanged: 7,
  memberListSaved: 8, // co-responsible
  relationsSaved: 9,
  orderFavoriteAdded: 22,
  orderStatusUpdated: 31,
  orderFavoriteDeleted: 90,
  orderStatusStateChanged: 78,
  orderStatusRequestToContractorSended: 80,
  orderStatusRequestSended: 0, // delete
  orderCancelled: 0, // delete

  linkCopied: 79,
  templateSaved: 3,
  templateDeleted: 5,

  multipleTaskCreated: 88,

  channelConnected: 81,

  companyDataUpdated: 50,
  companyRequistiesUpdated: 51,

  joinRequestSended: 89,

  fileReplacedToTrash: 92,
  fileRestored: 93,
  fileDeleted: 94,
  fileSavedToDisk: 95,
  fileRenamed: 96,

  membersSaved: 102,
  accessSettingsSaved: 103,
  changeOfResponsible: 110,
  requestCancelled: 111,
  contactsMerged: 112,
  orderStatusCancelAct: 113,
  orderStatusChangedAct: 114,
  orderStatusActAgreed: 115,

  contactStatusChanged: 116,

  syncWithGoogleCalendarEnded: 120,

  becomeOwnerRequestSended: 125,
  joinDepartmentRequestSended: 126
};

const getNoticeByNumber = ({ number, data }) => {
  switch (number) {
    case 1:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('TaskAddedToFavorite')}
        </Translation>
      );
    case 2:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('TaskDeletedFromFavorite')}
        </Translation>
      );
    case 3:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('TemplateSaved')}
        </Translation>
      );
    case 4:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('RequestAddedToFavorite')}
        </Translation>
      );
    case 5:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('TemplateDeleted')}
        </Translation>
      );
    case 6:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('RequestDeletedFromFavorite')}
        </Translation>
      );
    case 7:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('TaskStatusChange')}
        </Translation>
      );
    case 8:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('MemberListSaved')}
        </Translation>
      );
    case 9:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('LinksSaved')}
        </Translation>
      );
    case 10:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('RequestStatusChanged')}
        </Translation>
      );
    case 22:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderAddedToFavorite')}
        </Translation>
      );
    case 31:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderStatusUpdated')}
        </Translation>
      );
    case 50:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('CompanyDataSuccessfullyUpdated')}
        </Translation>
      );
    case 51:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('CompanyDetailsSuccessfullyUpdated')}
        </Translation>
      );
    case 78:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderStatusStateChanged')}
        </Translation>
      );
    case 79:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('LinkCopied')}
        </Translation>
      );
    case 80:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderStatusRequestToContractorSended')}
        </Translation>
      );
    case 81:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('ChannelConnected')}
        </Translation>
      );
    case 88:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('TaskCreationProcess')}
        </Translation>
      );
    case 89:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('AssignmentRequestSent')}
        </Translation>
      );
    case 90:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderDeletedFromFavorite')}
        </Translation>
      );
    case 92:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderStatusRequestToContractorSended')}
        </Translation>
      );
    case 93:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('FileRestored')}
        </Translation>
      );
    case 94:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('FileRemoved')}
        </Translation>
      );
    case 95:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('FileSavedToDisk')}
        </Translation>
      );
    case 96:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('FileRenamed')}
        </Translation>
      );
    case 102:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('СontactsMembersUpdated')}
        </Translation>
      );
    case 103:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('SettingsSaved')}
        </Translation>
      );
    case 110:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('ResponsibleChanged')}
        </Translation>
      );
    case 111:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderRequestCancelled')}
        </Translation>
      );
    case 112:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('ContactsMerged')}
        </Translation>
      );
    case 113:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderStatusCancelAct')}
        </Translation>
      );
    case 114:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderStatusChangedAct')}
        </Translation>
      );
    case 115:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('OrderStatusActAgreed')}
        </Translation>
      );
    case 116:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('ContactStatusChanged')}
        </Translation>
      );
    case 120:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('CalendarSyncSubmitToast')}
        </Translation>
      );
    case 125:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('BecomeOwnerRequestSended')}
        </Translation>
      );
    case 126:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('JoinDepartmentRequestSended')}
        </Translation>
      );
    default:
      return (
        <Translation ns="Toast" useSuspense={false}>
          {t => t('DataUpdatedSuccessfully')}
        </Translation>
      );
  }
};

export const showNoticeMessage = ({
  number,
  type = SUCCESS_NOTICE_TYPE,
  data,
  customContent,
  duration = DURATION
} = {}) => {
  const content = customContent || getNoticeByNumber({ number, data });

  return message[type](content, duration);
};
