import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { useWebsocketOperatorContext } from 'providers';

import { EMAIL, WHATSAPP } from 'constants/index';

import Modal from 'components/common/modal';

import { createEmailChat, createWhatsAppChat } from 'store/contacts';

import MainForm from '../form';

const MessageSendModal = ({
  contact,
  employeeId,
  visible,
  channels,
  onChange,
  onClose
}) => {
  const socket = useWebsocketOperatorContext();

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('ComposeEmail', 'ComposeWhatsApp');

  const handleSubmit = async values => {
    const contactChat = (values.chats || []).find(
      ({ email, source, channelUuid, channelKind }) =>
        (email === values.email || source === values.phone) &&
        channelKind === (values.email ? EMAIL : WHATSAPP) &&
        channelUuid === values.channelUuid
    );

    try {
      setIsLoading(true);

      let responseChats = [];

      if (isEmpty(contactChat)) {
        let response;

        if (values.email) {
          response = await dispatch(
            createEmailChat({
              id: contact.id,
              values: { channel: values.channel, email: values.email }
            })
          );
        }

        if (values.phone) {
          response = await dispatch(
            createWhatsAppChat({
              id: contact.id,
              values: {
                channel: values.channel,
                phone: values.phone
              }
            })
          );
        }

        responseChats = response.chats;

        socket.joinRooms([response]);
      }

      const roomUuid = (
        responseChats.length ? responseChats : values.chats
      ).find(
        c =>
          (values.email
            ? c.email === values.email
            : values.phone === c.source) && c.channelUuid === values.channelUuid
      ).uuid;

      onChange({ label: values.label, value: roomUuid });
    } finally {
      setIsLoading(false);
    }

    return onClose();
  };

  return (
    <Modal
      width={508}
      title={
        visible && visible.label?.channelKind === EMAIL
          ? t('ComposeEmailHeading')
          : t('ComposeWhatsAppHeading', { ns: 'ComposeWhatsApp' })
      }
      destroyOnClose
      open={!!visible}
      onClose={onClose}
      contentStyle={{ padding: 24 }}
      centered
    >
      <MainForm
        handleSubmit={handleSubmit}
        onClose={onClose}
        defaultValues={{ channel: channels[0], employeeId, contact }}
        isLoading={isLoading}
        kind={visible && visible.label?.channelKind}
      />
    </Modal>
  );
};

MessageSendModal.propTypes = {
  contact: PropTypes.shape({}),
  employeeId: PropTypes.number.isRequired,
  visible: PropTypes.object,
  channels: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

MessageSendModal.defaultProps = {
  contact: {},
  visible: undefined
};

export default MessageSendModal;
