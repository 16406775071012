import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { CHANNELS_CREATOR_DRAWER, WHATSAPP } from 'constants/index';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import ChannelsNotFoundIcon from 'components/common/icons/multicolor/channels-not-found';
import Button from 'components/common/button';

import { setVisibleDrawer } from 'store/drawers';

import styles from './whatsapp-successfully-added.module.scss';

const { Title, Text } = Typography;

const WhatsAppSuccessfullyAddedModal = ({ visible, onClose }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('ConnectWhatsApp');

  const setVisibleWhatsAppCreator = () => {
    onClose();

    dispatch(
      setVisibleDrawer({
        drawer: CHANNELS_CREATOR_DRAWER,
        data: {
          type: WHATSAPP
        }
      })
    );
  };

  return (
    <Modal
      width={440}
      open={visible}
      centered
      destroyOnClose
      contentClassName={styles.root}
      onClose={onClose}
    >
      <Icon component={ChannelsNotFoundIcon} />

      <Title className={styles.title}>{t('WhatsAppConnectedHeading')}</Title>

      <Text color="black-45" className={styles.description}>
        {t('WhatsAppConnectedDesc')}
      </Text>

      <div className={styles.buttons}>
        <Button
          onClick={onClose}
          type="text"
          size="large"
          className={styles.btnCancel}
        >
          {t('SetLaterBtn')}
        </Button>

        <Button onClick={setVisibleWhatsAppCreator} type="primary" size="large">
          {t('ConnectChannelBtn')}
        </Button>
      </div>
    </Modal>
  );
};

WhatsAppSuccessfullyAddedModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default WhatsAppSuccessfullyAddedModal;
