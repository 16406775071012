import React, { useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { BENCH, STATUS_COMPLETED } from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import RequestCreatorDrawer from 'components/requests-view/view/drawers/creator';

import { getProjectFactory } from 'store/projects';
import { getUserEmployee, getWorkspacePermissions } from 'store/workspace';

import styles from './view-info.module.scss';

const Requests = () => {
  const employee = useSelector(getUserEmployee);

  const [requestCreatorVisible, setRequestCreatorVisible] = useState(false);

  const permissions = useSelector(getWorkspacePermissions);
  const isCanCreateTask = (permissions || {}).createTask;

  const match = useRouteMatch('/:id/tasks/projects/:projectId/:status');
  const { projectId } = (match || {}).params || {};

  const project = useSelector(state => getProjectFactory(state)(+projectId));

  const { t } = useTranslation(['Requests', 'Backlog', 'Sprints']);

  const isProjectStatusCompleted = (project || {}).status === STATUS_COMPLETED;

  const isBench = !!employee.roles.find(({ type }) => type === BENCH);

  return (
    <>
      <div className={styles.tasks}>
        <Typography.Title level={2} className={styles.title}>
          {t('RequestsTitle')}
        </Typography.Title>

        <Button
          type="primary"
          className={styles.button}
          disabled={!isCanCreateTask || isProjectStatusCompleted || isBench}
          onClick={() => setRequestCreatorVisible(true)}
        >
          <Icon type="plus" />
          {t('AddRequest')}
        </Button>
      </div>

      <RequestCreatorDrawer
        visible={requestCreatorVisible}
        data={{ project, withShowDetails: true }}
        onClose={() => setRequestCreatorVisible(false)}
      />
    </>
  );
};

export default Requests;
