import React, { useMemo, memo } from 'react';
import classnames from 'classnames';
import { Avatar as AvatarAntd } from 'antd';
import PropTypes from 'prop-types';
import { API_ROOT } from 'api/v1';

import themeVariables from 'theme';

import Icon from '../icon';
import { DissmissIcon } from '../icons';

import styles from './avatar.module.scss';

export const COLORS = [
  themeVariables.color['red-70'],
  themeVariables.color['red-80'],
  themeVariables.color['green-20'],
  themeVariables.color['black-65'],
  themeVariables.color['black-35'],
  themeVariables.color['yellow-20'],
  themeVariables.color['azure-semi'],
  themeVariables.color['brand-50'],
  themeVariables.color['brand-25'],
  themeVariables.color.brand,
  themeVariables.color['pink-light'],
  themeVariables.color['magneta-semi']
];

const getColorIndexFromName = name => {
  const charCodeSum =
    typeof name === 'string'
      ? name.split('').reduce((curr, acc) => curr + acc.charCodeAt(0), 0)
      : Math.floor(Math.random() * COLORS.length);

  return (charCodeSum * charCodeSum) % COLORS.length;
};

const Avatar = ({
  src,
  style,
  title,
  children,
  className,
  isActive,
  size,
  ...props
}) => {
  const isTypeOfString = typeof children === 'string';

  const srcWithDomain = useMemo(
    () =>
      src && !src.startsWith('http') && !src.startsWith('/static/')
        ? `${API_ROOT}${src}`
        : src,
    [src]
  );

  const avatarColor = useMemo(
    () => COLORS[getColorIndexFromName(isTypeOfString ? children : title)],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [srcWithDomain, children, title]
  );

  return (
    <AvatarAntd
      className={classnames(className, styles.root, {
        [styles.isDismissed]: isActive === false
      })}
      style={{
        backgroundColor: avatarColor,
        ...style
      }}
      src={srcWithDomain}
      title={title}
      size={size}
      {...props}
    >
      {children}

      {!isActive && (
        <Icon
          component={DissmissIcon}
          color="red"
          size={size + 2}
          className={styles.dismiss}
        />
      )}
    </AvatarAntd>
  );
};

Avatar.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isActive: PropTypes.bool
};

Avatar.defaultProps = {
  src: undefined,
  title: undefined,
  style: {},
  children: undefined,
  isActive: true
};

export default memo(Avatar);
