import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { TYPE_MESSAGE } from 'constants/index';

import Modal from 'components/common/modal';

import { createReminder } from 'store/reminders';

import { showNoticeMessage } from 'services/notice';

import Form from './form';

import styles from './remind-message-modal.module.scss';

const RemindMessageModal = ({
  visible,
  onClose,
  sourceMessage,
  sourceEntityId,
  sourceEntityType,
  sourceEntityTitle
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('Toast');

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ remindDate, comment }) => {
    try {
      setIsLoading(true);

      await dispatch(
        createReminder({
          entityType: TYPE_MESSAGE,
          entityId: sourceMessage.uuid,
          values: {
            remindDate,
            comment,
            sourceMessage,
            sourceEntityId,
            sourceEntityType,
            sourceEntityTitle
          }
        })
      );

      showNoticeMessage({ customContent: t('MessageReminderCreated') });
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={440}
      destroyOnClose
      centered
      contentClassName={styles.modal}
      open={visible}
      onClose={onClose}
    >
      <Form onSubmit={onSubmit} isLoading={isLoading} />
    </Modal>
  );
};

RemindMessageModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  sourceMessage: PropTypes.shape({
    uuid: PropTypes.string.isRequired
  }),
  sourceEntityId: PropTypes.string,
  sourceEntityType: PropTypes.string,
  sourceEntityTitle: PropTypes.string
};

RemindMessageModal.defaultProps = {
  sourceMessage: null,
  sourceEntityId: null,
  sourceEntityType: null,
  sourceEntityTitle: null
};

export default RemindMessageModal;
