import React from 'react';
import { Button } from 'antd';
import { isEqual } from 'lodash';
import { Translation } from 'react-i18next';

import { PERIODS } from 'constants/index';

import { DateRangeSelect } from 'components/common/controls/custom-select';
import { useTheme } from 'components/common/theme';

export const Dates = ({
  onChange,
  value,
  disabled,
  datePickerProps,
  ...props
}) => {
  const { variables } = useTheme();

  return (
    <div>
      <DateRangeSelect
        value={value}
        onChange={onChange}
        datePickerProps={undefined}
        disabled={disabled}
        {...props}
      />

      <div style={{ margin: '0 20px' }}>
        {PERIODS.map(period => (
          <Button
            key={period.label}
            type="link"
            style={{
              marginRight: 10,
              marginBottom: 10,
              fontWeight: 'normal',
              backgroundColor:
                value && isEqual(value.value, period.value)
                  ? variables.color['brand-90']
                  : 'transparent',
              padding: 10,
              height: 'auto'
            }}
            onClick={() =>
              onChange({
                label: [],
                value: period.value
              })
            }
          >
            <Translation ns={period.ns}>{t => t(period.label)}</Translation>
          </Button>
        ))}
      </div>
    </div>
  );
};

export default Dates;
