import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import FormInput from 'components/common/hook-form/input';

export const AddFieldModal = ({ visible, onClose, onSubmit }) => {
  const methods = useForm();

  const { t } = useTranslation(['EditLegalInfo', 'Errors']);

  const handleSubmit = ({ name }) => {
    onSubmit({ name, value: undefined });
    onClose();
  };

  return (
    <Modal
      open={visible}
      title={t('AddOwnFieldHeading')}
      destroyOnClose
      width={508}
      contentStyle={{ padding: 24 }}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(handleSubmit)}>
          <FormInput
            label={t('FieldName')}
            name="name"
            itemProps={{
              style: { marginBottom: 24 }
            }}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
          />

          <Button
            type="primary"
            htmlType="submit"
            style={{ display: 'block', marginLeft: 'auto' }}
          >
            {t('SaveBtn')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

export default AddFieldModal;
