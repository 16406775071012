import React from 'react';

export const GoogleSheets = () => (
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_104334_31908)">
      <path
        d="M19.6355 0.822266L27.6355 8.82227L23.6355 9.54954L19.6355 8.82227L18.9082 4.82227L19.6355 0.822266Z"
        fill="#188038"
      />
      <path
        d="M19.636 8.82227V0.822266H6.5451C5.33964 0.822266 4.36328 1.79863 4.36328 3.00408V30.6404C4.36328 31.8459 5.33964 32.8223 6.5451 32.8223H25.4542C26.6596 32.8223 27.636 31.8459 27.636 30.6404V8.82227H19.636Z"
        fill="#34A853"
      />
      <path
        d="M8.72656 13.1855V23.731H23.272V13.1855H8.72656ZM15.0902 21.9128H10.5447V19.3674H15.0902V21.9128ZM15.0902 17.5492H10.5447V15.0037H15.0902V17.5492ZM21.4538 21.9128H16.9084V19.3674H21.4538V21.9128ZM21.4538 17.5492H16.9084V15.0037H21.4538V17.5492Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_104334_31908">
        <rect
          width="32"
          height="32"
          fill="white"
          transform="translate(0 0.822266)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default GoogleSheets;
