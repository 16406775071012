import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  ASSET_SUBSCRIBERS_DRAWER,
  ASSIGNMENTS_CHANGE_DRAWER,
  CREATE_ORDER_STATUS_MODAL,
  INCOMING,
  TYPE_ASSET
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import { useTheme } from 'components/common/theme';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { AssetDeletedIcon } from 'components/common/icons';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import ProjectCreatorDrawer from 'components/tasks-view/projects-view/drawers/creator';
// import PhoneConfirmationModal from 'components/common/phone-confirmation-modal';
import ContactCreatorDrawer from 'components/contacts-view/drawers/creator';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import { useModalContext } from 'components/app/modals-provider';

import { changeMembers, fetchMembers, remove } from 'store/assets';
// import { getActiveWorkspace, getUserEmployee } from 'store/workspace';
import { setVisibleDrawer } from 'store/drawers';

import useRoutesService from 'services/routes';
import { NOTICE_NUMBER } from 'services/notice';

import AssetEditorDrawer from '../drawers/editor';

const Actions = ({ asset, isFromDetails, style }) => {
  const { variables } = useTheme();
  const dispatch = useDispatch();
  const routes = useRoutesService();

  const { t } = useTranslation([
    'Asset',
    'AssetAccessSettings',
    'DeleteAsset',
    'Toast',
    'Common',
    'Contacts'
  ]);

  // const employee = useSelector(getUserEmployee);
  // const workspace = useSelector(getActiveWorkspace);

  const [editorVisible, setEditorVisible] = useState(false);

  const [deleteVisible, setDeleteVisible] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const [taskVisible, setTaskVisible] = useState(false);

  // const [visiblePhoneConfirmation, setVisiblePhoneConfirmation] = useState(
  //   false
  // );

  const { showModal } = useModalContext();

  const onCreateOrderStatus = () =>
    showModal(CREATE_ORDER_STATUS_MODAL, {
      data: {
        defaultKind: INCOMING,
        defaultValues: {
          relations: transformValueToRelation(
            [
              {
                value: id,
                label: asset
              }
            ],
            TYPE_ASSET
          )
        },
        withContractor: true,
        withShowDetails: true
      }
    });

  const [contactVisible, setContactVisible] = useState(false);

  const [projectVisible, setProjectVisible] = useState(false);

  const { permissions = {}, responsible = {}, title, id } = asset;

  const editAccess = async ({ added, deleted }) => {
    if (deleted.length) {
      await dispatch(
        changeMembers({
          id,
          employees: deleted,
          isDelete: true
        })
      );
    }

    if (added.length) {
      await dispatch(
        changeMembers({
          id,
          employees: added,
          isDelete: false
        })
      );
    }
  };

  const showAssignmentsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: ASSIGNMENTS_CHANGE_DRAWER,
        data: {
          title: t('AccessSettingsAction'),
          description: t('AccessSettingsDesc', { ns: 'AssetAccessSettings' }),
          employeeListTitle: t('AssetCoexecutorsListText', {
            ns: 'AssetAccessSettings'
          }),
          submitText: t('SaveSettingsBtn', { ns: 'AssetAccessSettings' }),
          noticeNumber: NOTICE_NUMBER.accessSettingsSaved,
          fetch: () => dispatch(fetchMembers({ id })),
          submit: editAccess
        }
      })
    );

  const onDelete = async () => {
    try {
      setDeleteIsLoading(true);

      await dispatch(remove({ id }));

      setDeleteVisible(false);

      message.success(t('AssetDeleted', { ns: 'Toast' }));

      if (isFromDetails) {
        routes.toAssets();
      }
    } finally {
      setDeleteIsLoading(false);
    }
  };

  const showSubscribersDrawer = () => {
    dispatch(
      setVisibleDrawer({
        drawer: ASSET_SUBSCRIBERS_DRAWER,
        data: {
          asset
        }
      })
    );
  };

  const actions = [
    {
      title: t('EditAction'),
      allow: permissions.editAsset,
      onClick: () => setEditorVisible(true),
      icon: 'edit'
    },
    {
      title: t('AddContactToFunnel', { ns: 'Contacts' }),
      allow: true,
      onClick: onCreateOrderStatus,
      icon: 'shopping'
    },
    {
      title: t('AddTaskAction'),
      allow: true,
      onClick: () => setTaskVisible(true),
      icon: 'snippets'
    },
    {
      title: t('AddContactAction'),
      allow: true,
      onClick: () => setContactVisible(true),
      icon: 'phone'
    },
    {
      title: t('AddProject', { ns: 'Common' }),
      allow: true,
      onClick: () => setProjectVisible(true),
      icon: 'project'
    },
    {
      title: t('AccessSettingsAction'),
      allow: permissions.editAsset,
      onClick: showAssignmentsDrawer,
      icon: 'user-add'
    },
    {
      key: 'subscribers',
      title: t('SubscribersBtn', { ns: 'Asset' }),
      onClick: showSubscribersDrawer,
      allow: !isFromDetails,
      icon: 'subscribers',
      iconSize: 20
    },
    {
      title: t('DeleteAction'),
      allow: permissions.deleteAsset,
      style: { color: variables.color.red },
      onClick: () => setDeleteVisible(true),
      icon: 'delete'
    }
  ];

  return (
    <>
      <ActionsDropdown actions={actions} btnStyle={style} />

      <AssetEditorDrawer
        visible={editorVisible}
        onClose={() => setEditorVisible(false)}
        id={id}
      />

      <ModalDeleteConfirm
        iconModal={AssetDeletedIcon}
        title={t('DeleteAssetHeading', { ns: 'DeleteAsset', title })}
        description={t('DeleteAssetDesc', { ns: 'DeleteAsset' })}
        open={deleteVisible}
        isLoading={deleteIsLoading}
        onConfirm={onDelete}
        onClose={() => setDeleteVisible(false)}
      />

      <TaskCreatorDrawer
        value={{
          relations: transformValueToRelation(
            [
              {
                value: id,
                label: asset
              }
            ],
            TYPE_ASSET
          ),
          responsible: [responsible]
        }}
        visible={taskVisible}
        onClose={() => setTaskVisible(false)}
      />

      {/* <PhoneConfirmationModal
        visible={visiblePhoneConfirmation}
        employee={employee}
        description={
          <>
            Подтверждение номера телефона необходимо <br /> для обеспечения
            безопасной работы наших <br /> пользователей
          </>
        }
        onClose={() => setVisiblePhoneConfirmation(false)}
        callback={() => showOrderDrawer()}
      /> */}

      <ContactCreatorDrawer
        values={{ assetId: id }}
        visible={contactVisible}
        isDetailsAfterSubmit
        onClose={() => setContactVisible(false)}
      />

      <ProjectCreatorDrawer
        defaultValues={{
          assetId: id,
          manager: responsible
        }}
        isRedirectAfterSubmit
        visible={projectVisible}
        onClose={() => setProjectVisible(false)}
      />
    </>
  );
};

Actions.propTypes = {
  asset: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string
  }),
  style: PropTypes.object,
  isFromDetails: PropTypes.bool
};

Actions.defaultProps = {
  asset: {},
  style: {},
  isFromDetails: true
};

export default Actions;
