import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Spin } from 'antd';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';
import classnames from 'classnames';

import {
  STATUS_COMPLETED,
  STATUS_IN_WORK,
  STATUS_CHECK,
  STATUS_CANCELLED,
  TYPE_AGREEMENT,
  STATUS_FOR_EXECUTION,
  GET_IN_WORK_ACTION,
  TYPE_MEETING,
  SLA_REASON_MODAL_PROPS,
  SLA_REASON_MODAL_EDITOR_PROPS
} from 'constants/index';

import Button from 'components/common/button';
import {
  FeedbackModal,
  TASK_COMPLETED_CONTROLLER,
  TASK_COMPLETED_RESPONSIBLE,
  useFeedbackModal
} from 'components/common/feedback-modal';

import { updateStatus } from 'store/requests';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import AgreementActions from './agreement-actions';
import ReasonModal from './reason-modal';
import CompleteRequireModal from './complete-require-modal';

import styles from './agreement-actions/agreement-actions.module.scss';

export const Statuses = ({
  task,
  allowSwitchComplete,
  isResponsible,
  isController,
  allowChange,
  actions,
  checkIsWorklog
}) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const [
    visibleFeedbackModal,
    onCloseFeedbackModal,
    checkCanShowFeedbackModal
  ] = useFeedbackModal();

  const [isLoading, setIsLoading] = useState(false);
  const [visibleReasonModal, setVisibleReasonModal] = useState(undefined);
  const [visibleCompleteModal, setVisibleCompleteModal] = useState(undefined);

  if (!actions.length) {
    return null;
  }

  const isTypeMeeting = task.kind === TYPE_MEETING;
  const isTypeAgreement = task.kind === TYPE_AGREEMENT;

  const getStatus = newStatus => {
    const changeStatusOnReview =
      task.status === STATUS_IN_WORK &&
      newStatus === STATUS_COMPLETED &&
      allowSwitchComplete;

    return changeStatusOnReview ? STATUS_CHECK : newStatus;
  };

  const onUpdateStatus = async ({
    id,
    status,
    reason,
    reasonFileList,
    dateEnd
  }) => {
    await dispatch(
      updateStatus({
        id,
        status,
        reason,
        reasonFileList,
        dateEnd,
        oldStatus: task.status
      })
    );

    if (status === STATUS_COMPLETED && (isResponsible || isController)) {
      checkCanShowFeedbackModal();
    }

    if (status === STATUS_CANCELLED || status === STATUS_COMPLETED) {
      amplitude.finishTaskEvent({
        ...task,
        status
      });
    }

    showNoticeMessage({ number: NOTICE_NUMBER.requestStatusChanged });
  };

  // eslint-disable-next-line
  const handleUpdateStatus = async ({ status: newStatus }) => {
    const isNewStatusToDo = newStatus === STATUS_FOR_EXECUTION;
    const isNewStatusCancelled = newStatus === STATUS_CANCELLED;
    const isNewStatusCompleted = newStatus === STATUS_COMPLETED;
    const isSlaCompleted =
      task.taskInfo &&
      isNewStatusCompleted &&
      ((!task.controller && isResponsible) ||
        (isController && task.status === STATUS_CHECK));

    try {
      setIsLoading(true);

      if (
        (!isTypeMeeting &&
          (((isStatusCompleted || isStatusReview) && isNewStatusToDo) ||
            isNewStatusCancelled)) ||
        (isTypeMeeting && (isNewStatusToDo || isNewStatusCancelled)) ||
        isSlaCompleted
      ) {
        return setVisibleReasonModal({
          task,
          status: getStatus(newStatus),
          ...(isSlaCompleted ? { ...SLA_REASON_MODAL_PROPS } : {}),
          editorProps: isSlaCompleted
            ? SLA_REASON_MODAL_EDITOR_PROPS
            : undefined
        });
      }

      if (task.completeRequire && isStatusInWork && isNewStatusCompleted) {
        return setVisibleCompleteModal({ task, status: getStatus(newStatus) });
      }

      await onUpdateStatus({ id: task.id, status: getStatus(newStatus) });
    } finally {
      setIsLoading(false);
    }
  };

  const handleStatus = async newStatus => {
    try {
      setIsLoading(true);

      if (newStatus === STATUS_CANCELLED) {
        await handleUpdateStatus({ status: newStatus });
      } else {
        await checkIsWorklog(handleUpdateStatus)({ status: newStatus });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const isStatusForExecution = task.status === STATUS_FOR_EXECUTION;
  const isStatusInWork = task.status === STATUS_IN_WORK;
  const isStatusCompleted = task.status === STATUS_COMPLETED;
  const isStatusReview = task.status === STATUS_CHECK;

  if (isTypeAgreement) {
    return (
      <AgreementActions
        checkIsWorklog={checkIsWorklog}
        task={task}
        allowChange={allowChange}
        onUpdateStatus={onUpdateStatus}
      />
    );
  }

  const currentActions =
    isTypeAgreement && isStatusForExecution ? [GET_IN_WORK_ACTION] : actions;

  return (
    <>
      <div className={styles.root} data-qa="qa-ijhpgw1cy75djcl">
        <Divider />

        <Spin spinning={isLoading}>
          <div
            className={classnames(styles.statuses, {
              [styles.odd]: currentActions.length % 2 !== 0
            })}
            data-qa="qa-6z8pmphn12f7cyq"
          >
            {currentActions.map(action => (
              <Button
                key={action.label}
                mood={action.mood}
                type="secondary"
                size="large"
                className={styles.btnAction}
                data-qa="qa-1dy5uhjs39tc5a5"
                onClick={() => handleStatus(action.status)}
                disabled={action.status !== STATUS_CANCELLED && !allowChange}
                ghost
              >
                <Translation ns={action.ns}>{t => t(action.label)}</Translation>
              </Button>
            ))}
          </div>
        </Spin>
      </div>

      <ReasonModal
        visible={visibleReasonModal !== undefined}
        data={visibleReasonModal}
        onUpdateStatus={onUpdateStatus}
        onClose={() => setVisibleReasonModal(undefined)}
      />

      <CompleteRequireModal
        data={visibleCompleteModal}
        visible={visibleCompleteModal !== undefined}
        onUpdateStatus={onUpdateStatus}
        onClose={() => setVisibleCompleteModal(undefined)}
      />

      <FeedbackModal
        visible={visibleFeedbackModal}
        eventName={
          isController ? TASK_COMPLETED_CONTROLLER : TASK_COMPLETED_RESPONSIBLE
        }
        onClose={onCloseFeedbackModal}
      />
    </>
  );
};

Statuses.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    status: PropTypes.string,
    kind: PropTypes.string,
    parent: PropTypes.number
  }).isRequired,
  allowSwitchComplete: PropTypes.bool,
  allowChange: PropTypes.bool,
  actions: PropTypes.array,
  checkIsWorklog: PropTypes.func
};

Statuses.defaultProps = {
  allowSwitchComplete: false,
  allowChange: false,
  actions: [],
  checkIsWorklog: () => () => {}
};
export default Statuses;
