import { FormProvider, useForm } from 'react-hook-form';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FormEmployeeSelect, FormInput } from 'components/common/hook-form';
import Button from 'components/common/button';
import Typography from 'components/common/typography';
import FormBooksSelect from 'components/common/hook-form/select/books';

export const CategoryForm = ({
  values,
  isLoading,
  selectProps,
  btnText,
  onSubmit,
  isDeleted,
  ...props
}) => {
  const { t } = useTranslation([
    'AddJournal',
    'Journal',
    'Journals',
    'AddAsset',
    'DeleteJournal',
    'Errors'
  ]);

  const methods = useForm({
    defaultValues: {
      title: values.title,
      category: values.category
        ? {
            value: values.category.id,
            label: values.category.title
          }
        : undefined
    }
  });
  const handleSubmit = data => ({
    title: data.title,
    parentId: data.category ? data.category.value : null,
    managerId: data.managerId && data.managerId.label.id
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(v => onSubmit(handleSubmit(v)))}>
        {isDeleted ? (
          <FormBooksSelect
            name="category"
            valueText={t('ChooseJournal', { ns: 'DeleteJournal' })}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            placeholder={t('Search', { ns: 'Journal' })}
            itemProps={{ style: { marginBottom: 24 } }}
            {...selectProps}
          />
        ) : (
          <>
            <FormInput
              label={t('JournalTitle')}
              placeholder={t('EnterTitle')}
              name="title"
              rules={{
                required: t('RequiredField', { ns: 'Errors' }),
                minLength: {
                  value: 2,
                  message: t('MinCheckingTextInput', {
                    ns: 'Errors',
                    field: t('Title', { ns: 'AddAsset' })
                  })
                },
                maxLength: {
                  value: 50,
                  message: t('MaxCheckingTextInput', {
                    ns: 'Errors',
                    field: t('Title', { ns: 'AddAsset' })
                  })
                }
              }}
            />

            <div style={{ marginBottom: 16 }}>
              <FormBooksSelect
                name="category"
                label={t('ParentJournal')}
                valueText={t('ChooseParentJournal')}
                placeholder={t('Search', { ns: 'Journal' })}
                itemProps={{ style: { marginBottom: 0 } }}
                isMulti={false}
                {...selectProps}
              />
              <Typography.Text size="small" color="black-55">
                {t('ParentJournalTip')}
              </Typography.Text>
            </div>

            {props.isCreator && (
              <FormEmployeeSelect
                name="managerId"
                label={t('Manager')}
                valueText={t('AllSelected', { ns: 'Journals' })}
                isMulti={false}
                rules={{
                  required: t('RequiredField', { ns: 'Errors' })
                }}
                itemProps={{ style: { marginBottom: 24 } }}
                {...selectProps}
              />
            )}
          </>
        )}

        <Button
          htmlType="submit"
          type="primary"
          width="expanded"
          style={{ marginLeft: 'auto', display: 'block' }}
          loading={isLoading}
        >
          {btnText}
        </Button>
      </form>
    </FormProvider>
  );
};

CategoryForm.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
    category: PropTypes.object
  })
};

CategoryForm.defaultProps = {
  values: {}
};

export default CategoryForm;
