import { useSelector } from 'react-redux';

import { TOKEN_FILES } from 'constants/api';

import { getActiveWorkspace } from '../../../store/workspace';
import useLocalStorage from '../use-local-storage';
import { getFileIds } from './get-file-ids';
import { downloadFile } from './download-file';
import { useUploadingFiles } from './use-uploading-files';
import api from '../../../api';
import { useApi as useApiLegacy } from './use-api';
import { useApi as useApiWithWorkspaceGoogleDriveSupport } from './use-api-with-workspace-google-drive-support';

export const useFileUpload = (
  { hasWorkspaceGoogleDriveWorkflow, storage } = {
    hasWorkspaceGoogleDriveWorkflow: false
  }
) => {
  const workspace = useSelector(getActiveWorkspace);

  const [, setToken] = useLocalStorage(TOKEN_FILES);

  const useApi = hasWorkspaceGoogleDriveWorkflow
    ? useApiWithWorkspaceGoogleDriveSupport
    : useApiLegacy;

  const { auth, uploadFile, splitFile, uploadPart, cancelUpload } = useApi();

  // Стандартная загрузка файлов в систему
  const onUpload = ({
    file,
    onSuccess = () => {},
    onError = () => {},
    onProgress = () => {}
  }) =>
    api.files
      .upload(file, workspace.id, e =>
        onProgress({ percent: (e.loaded / e.total) * 100 }, file)
      )
      .then(data => {
        const uplodedFile = data[0];
        onSuccess(uplodedFile, file);

        return uplodedFile;
      })
      .catch(onError);

  // Загрузка файлов через микросервис
  const onUploadAttachment = async ({
    file,
    onSuccess,
    onError = err => console.log('onUploadAttachment error', err),
    onProgress = () => {}
  }) => {
    try {
      if (!hasWorkspaceGoogleDriveWorkflow) {
        await auth(workspace.id, workspace.user.id).then(setToken);
      }

      // eslint-disable-next-line camelcase
      const { file_id, offset } = await uploadFile({ file, storage });

      splitFile({
        file,
        initialOffset: offset,
        upload: async params => uploadPart({ file_id, ...params }),
        onSuccess,
        onProgress
      });
    } catch (err) {
      onError(err);
    }
  };

  return {
    onUpload,
    onUploadAttachment,
    getFileIds,
    downloadFile,
    useUploadingFiles,
    cancelUpload
  };
};
