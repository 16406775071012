import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import api from 'api';

import { SUBORDINATE, BENCH } from 'constants/index';

import { completeRegistration, updateUser } from 'store/user';
import {
  joinWorkspace,
  createWorkspace,
  fetchWorkspaces,
  updateWorkspaceUser,
  setActiveId
} from 'store/workspace';
import { createDepartmentWithInvite } from 'store/team/departments';
import { getRouterLocation } from 'store/router';

import { useRoutesService } from 'services/routes';
import { capitalize } from 'utils/capitalize';

import CompleteUserForm from './forms/complete-user';
import CreateWorkspaceForm from './forms/create-workspace';
import CreateTeamForm from './forms/create-team';

const getSteps = isCreateWorkspace => {
  const steps = [
    { id: 'CompleteUserForm', component: CompleteUserForm },
    { id: 'CreateWorkspaceForm', component: CreateWorkspaceForm },
    // Если Создаем компанию (!vatNumber.id). Создание подразделения
    { id: 'CreateTeamForm', component: CreateTeamForm, lastStep: true }
  ];

  return isCreateWorkspace
    ? steps.filter(({ id }) => id !== 'CompleteUserForm')
    : steps;
};

const COMPLETE_USER_STEP = 0;
const CREATE_WORKSPACE_STEP = 1;
const CREATE_TEAM_STEP = 2;

const REGISTER_FLOW = 1;
const WORKSPACES_COUNT_TO_CHECK_FLOW = 2;

// Обычная регистрация. Включает в себя создание компании или просоединение
export const WorkflowOneNine = ({
  isCreateWorkspace,
  flow,
  className,
  callback,
  defaultData
}) => {
  const dispatch = useDispatch();
  const routes = useRoutesService();

  const routerLocation = useSelector(getRouterLocation) || {};

  const { state } = routerLocation;
  const { isRegistrationCompleted, isRegistrationCompanyCompleted } =
    state || {};

  const steps = getSteps(isCreateWorkspace);

  const getCurrentStep = () => {
    if (flow || (isRegistrationCompleted && isRegistrationCompanyCompleted)) {
      return CREATE_TEAM_STEP;
    }

    return isRegistrationCompleted ? CREATE_WORKSPACE_STEP : COMPLETE_USER_STEP;
  };

  const [data, setData] = useState(defaultData);
  const [active, setActive] = useState(steps[getCurrentStep()]);
  const [isLoading, setIsLoading] = useState(false);

  const joinToWorkspace = workspaceId =>
    dispatch(joinWorkspace({ workspaceId }));
  const onCreateWorkspace = workspace =>
    dispatch(createWorkspace({ ...workspace, initial: true }));

  const nextIndex = steps.findIndex(step => step.id === active.id) + 1;
  const hasNextStep = nextIndex < steps.length && active.lastStep !== true;

  const allowInviteOwner = data.position !== SUBORDINATE;

  const completeUser = async ({ firstName, lastName, phone, promoCode }) => {
    try {
      setIsLoading(true);
      await dispatch(
        completeRegistration({
          firstName: capitalize(firstName),
          lastName: capitalize(lastName),
          phone,
          promoCode: promoCode || undefined
        })
      );

      // Если была оборвана регистрация по 2 или 3 флоу во время заполнения профиля
      // будет открываться первый флоу, потому что этого заранее нельзя определить
      // и при заполнении профиля грузим воркспейсы, в которых смотрим:
      // 1. Если вокрспейсов больше двух - просто кидаем на выбор воркспейсов
      // и там при выборе по полю workpsace.user.flow будет понятно, закончил ли юзер регистрацию
      // 2. Если меньше двух - смотрим в них, если юзер на bench - завершаем регистрацию,
      // если есть в workpsace.user поле flow === 2 || 3, то кидаем на регистрацию (будет форма создания команды)
      const workspaces = await dispatch(fetchWorkspaces());

      if (workspaces.length <= WORKSPACES_COUNT_TO_CHECK_FLOW) {
        // eslint-disable-next-line consistent-return
        workspaces.forEach(async workspace => {
          if (workspace.user.roles.find(({ type }) => type === BENCH)) {
            setActiveId({ workspaceid: workspace.id });
            return finishRegister({
              isCreateTeam: false,
              isNotUpdateFlow: true
            });
          }

          if (workspace.user.flow) {
            await dispatch(
              updateUser({ user: { isRegistrationCompanyCompleted: true } })
            );
            setActiveId({ workspaceid: workspace.id });
            return routes.toRegisterFinish(state);
          }
        });
      } else {
        routes.toSelectWorksapce({}, true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onJoinWorkspace = async ({ id }) => {
    try {
      setIsLoading(true);
      await joinToWorkspace(id);
    } finally {
      setIsLoading(false);
    }
  };

  const createDepartment = ({ name, managerEmail, employeeEmails }) => {
    setIsLoading(true);

    return dispatch(
      createDepartmentWithInvite({ name, managerEmail, employeeEmails })
    ).finally(() => setIsLoading(false));
  };

  const finishRegister = async ({
    isCreateTeam,
    isNotUpdateFlow,
    workspaceId
  }) => {
    await callback(isCreateTeam, isNotUpdateFlow, workspaceId);
  };

  const onNextStep = async values => {
    const newData = { ...data, ...values };
    setData(newData);

    const hasVatNumber = !!Object.keys(newData.vatNumber || {}).length;
    const isCreatingWorkspace = hasVatNumber && !newData.vatNumber.id;

    if (active.id === 'CompleteUserForm') {
      await completeUser(newData);
    }

    if (active.id === 'CreateWorkspaceForm') {
      // Регистрация частного лица или создание компании
      if (!hasVatNumber || isCreatingWorkspace) {
        if (isCreatingWorkspace) {
          // Создание компании
          const { id, user } = await onCreateWorkspace(newData.vatNumber);
          await dispatch(
            updateWorkspaceUser({ user: { id: user.id, flow: REGISTER_FLOW } })
          );
          await dispatch(
            updateUser({ user: { isRegistrationCompanyCompleted: true } })
          );
          dispatch(setActiveId({ workspaceId: id }));
        }
        return setActive(steps[nextIndex]);
      }

      try {
        // Пробуем принять приглашение.
        // Нужно в случае когда человек регистрируется НЕ по приглашению с почты.
        const { data: userData = {} } = await api.invitation.confirm({
          workspaceId: newData.vatNumber.id,
          errorHandle: false
        });

        if ('isAccepted' in userData && !userData.isAccepted) {
          await onJoinWorkspace(newData.vatNumber);
          await finishRegister({ isCreateTeam: true, isNotUpdateFlow: true });
        } else {
          if (userData.position === BENCH) {
            if (isCreateWorkspace) {
              const workspaces = await dispatch(fetchWorkspaces());
              const workspaceId = workspaces.find(
                w => w.vatNumber === userData.vatNumber
              ).id;
              await finishRegister({ workspaceId });
            } else {
              await finishRegister({});
            }
          } else {
            setData({ ...newData, position: userData.position });
            setActive(steps[nextIndex]);
          }

          if (!isCreateWorkspace) {
            await dispatch(fetchWorkspaces());
          }
        }
      } catch (err) {
        // Присоединение к компании
        await onJoinWorkspace(newData.vatNumber);
        await finishRegister({ isCreateTeam: true });
      }
      return true; // Завершение регистрации
    }

    if (active.id === 'CreateTeamForm') {
      await createDepartment(newData);
      await finishRegister({ isCreateTeam: true });
      return true; // Завершение регистрации
    }

    return setActive(steps[nextIndex]);
  };

  const onSubmit = values => {
    setIsLoading(true);
    onNextStep(values).finally(() => setIsLoading(false));
  };

  const onSkip = () => {
    if (!hasNextStep) {
      finishRegister({
        isCreateTeam: active.id === 'CreateTeamForm',
        isNotUpdateFlow: !(active.id === 'CreateTeamForm')
      });
    } else if (hasNextStep) {
      setActive(steps[nextIndex]);
    }
  };

  const ActiveForm = active.component;

  useEffect(() => {
    setActive(steps[getCurrentStep()]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flow]);

  return (
    <ActiveForm
      className={className}
      data-qa="qa-f5wk3ozxk1wkcjd"
      data={data}
      isLoading={isLoading}
      isCreateWorkspace={isCreateWorkspace}
      allowInviteOwner={allowInviteOwner}
      onSubmit={onSubmit}
      onSkip={onSkip}
      isOAuth={(state || {}).isOAuth}
    />
  );
};

WorkflowOneNine.propTypes = {
  isCreateWorkspace: PropTypes.bool, // Форма создания компании
  className: PropTypes.string,
  callback: PropTypes.func,
  defaultData: PropTypes.shape({
    countryCode: PropTypes.string
  })
};

WorkflowOneNine.defaultProps = {
  isCreateWorkspace: false,
  className: undefined,
  callback: () => {},
  defaultData: {}
};

export default WorkflowOneNine;
