import React from 'react';
import { Empty, Button } from 'antd';
import { useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import { Error403Icon } from 'components/common/icons';

import getSupportLink from 'utils/get-support-link';

const { Text, Title, Paragraph } = Typography;

export const ErrorModal = ({ visible, props }) => {
  const { t } = useTranslation('ScreenErrors');

  const supportLink = getSupportLink();

  return (
    <Modal width={440} open={visible} closable={false} {...props}>
      <Empty
        style={{ padding: '60px 24px 32px', margin: 0 }}
        image={<Icon component={Error403Icon} />}
        imageStyle={{ height: 'auto' }}
        description={
          <>
            <Title level={1}>{t('SmthWentWrongHeading')}</Title>
            <Text
              style={{ display: 'block', marginBottom: 20 }}
              color="black-45"
            >
              {t('SmthWentWrongDesc')}
            </Text>

            <Button
              onClick={() => window.location.reload()}
              size="large"
              type="primary"
            >
              {t('TryAgainBtn')}
            </Button>

            <Paragraph color="black-45" style={{ margin: '20px 0 0' }}>
              {t('SupportDesc')} <br />
              <a href={supportLink.href} target={supportLink.target}>
                {t('SupportBtn')}
              </a>
            </Paragraph>
          </>
        }
      />
    </Modal>
  );
};

export default ErrorModal;
