import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { TYPE_MEMBERS } from 'constants/index';

import { FormEmployeeSelect } from 'components/common/hook-form';

import { fetchAssignments } from 'store/requests/actions';

import { usePrevious } from 'hooks/common';

import styles from './members-field.module.scss';

const MembersField = ({ defaultValues, label }) => {
  const dispatch = useDispatch();

  const [isLoadingAssignments, setIsLoadingAssignments] = useState(false);

  const prevTaskId = usePrevious((defaultValues || {}).id);

  const fetchData = async () => {
    setIsLoadingAssignments(true);
    try {
      await dispatch(
        fetchAssignments({ id: defaultValues.id, directMembersOnly: true })
      );
    } finally {
      setIsLoadingAssignments(false);
    }
  };

  useEffect(() => {
    if (
      !Object.keys(defaultValues).length ||
      (defaultValues || {}).id === prevTaskId ||
      defaultValues.assignments
    )
      return;

    fetchData();
    //  eslint-disable-next-line
  }, [defaultValues]);

  return (
    <div className={styles.withHint} data-qa="qa-i5xbtzxahlokbi8">
      <FormEmployeeSelect
        label={label}
        name={TYPE_MEMBERS}
        params={{
          excludeSelf: true
        }}
        isMulti
        reloadAfterOpen
        allowSelectAll
        showSelectedOptionsOnList={false}
      />

      {isLoadingAssignments && <Spin className={styles.spin} />}
    </div>
  );
};

MembersField.propTypes = {
  defaultValues: PropTypes.shape({
    id: PropTypes.number,
    assignments: PropTypes.arrayOf(
      PropTypes.shape({
        employee: PropTypes.object,
        roles: PropTypes.array,
        id: PropTypes.number
      })
    )
  }),
  label: PropTypes.node
};

MembersField.defaultProps = {
  defaultValues: {},
  label: undefined
};

export default MembersField;
