import React, {
  createRef,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import useEmployeeNotificationsChat from 'components/common/chat/entity-hooks/use-employee-notifications-chat';
import Chat from 'components/common/chat';
import TimerAndCalendarIcon from 'components/common/icons/multicolor/timer-and-calendar';
import NoNotificationsIcon from 'components/common/icons/multicolor/no-notifications';
import DateList from 'components/common/date-list';

import { initialFilter } from 'store/operator/reducer';
import { getFilterNotifications } from 'store/operator';

import useFilterIndicator from 'hooks/common/use-filter-indicator';

import NotificationMessage from './message';
import ReadAllNotifications from '../read-all';

import styles from './list.module.scss';

const NotificationList = ({ afterVisibleChange, view, listClassName }) => {
  const { t } = useTranslation('Dashboard');
  const { state = {} } = useLocation();
  const history = useHistory();
  const notificationRefs = useRef({});

  const filters = useSelector(getFilterNotifications);

  const hasFilter = !!useFilterIndicator(initialFilter, filters);

  const { isLoading, hasMessages, messages, hasMore, messagesRef, loadMore } =
    useEmployeeNotificationsChat({
      afterVisibleChange,
      view
    });

  const clearState = useCallback(() => {
    history.replace({ ...history.location, state: {} });
  }, [history]);

  const components = useMemo(
    () => ({
      List: forwardRef((props, ref) => {
        const { children, ...restProps } = props;

        return (
          <div {...restProps} ref={ref} className={classnames(listClassName)}>
            <DateList
              selector={item => item?.children?.props?.message?.createdAt}
              className={styles.date}
              firstDateAdditionalComponent={<ReadAllNotifications />}
            >
              {children}
            </DateList>
          </div>
        );
      }),
      Item: ({ item, ...props }) => (
        <div
          {...props}
          className={styles.messageWrap}
          ref={notificationRefs.current[item.uuid]}
        />
      )
    }),
    [listClassName]
  );

  const scrollToNotification = notificationId => {
    if ((notificationRefs.current[notificationId] || {}).current) {
      notificationRefs.current[notificationId].current.scrollIntoView({
        block: 'center',
        behavior: 'auto'
      });
    }
  };

  useEffect(() => {
    if (state && state.notificationId) {
      scrollToNotification(state.notificationId);
      clearState();
    }
  }, [clearState, state]);

  useEffect(() => {
    if (messages.length > 0) {
      messages.forEach(message => {
        if (!notificationRefs.current[message.uuid]) {
          notificationRefs.current[message.uuid] = createRef();
        }
      });
    }
  }, [messages]);

  const renderedMessages = useMemo(() => {
    if (!messages.length) {
      return null;
    }

    return (
      <Virtuoso
        style={{ height: '100%' }}
        data={messages}
        components={components}
        itemContent={(_, message) => (
          <NotificationMessage message={message} view={view} />
        )}
        customScrollParent={messagesRef.current}
        overscan={{
          reverse: 1500,
          main: 1500
        }}
      />
    );
  }, [components, messages, messagesRef, view]);

  return (
    <Chat
      isLoading={isLoading}
      hasMessages={hasMessages}
      messages={renderedMessages}
      messagesRef={messagesRef}
      className={styles.root}
      messagesClassName={styles.messages}
      empty={{
        icon: hasFilter ? NoNotificationsIcon : TimerAndCalendarIcon,
        description: t(
          hasFilter ? 'NoNotificationsFound' : 'NoNotificationsYet'
        )
      }}
      skeletonProps={{
        className: styles.skeleton,
        isIncoming: true
      }}
      isReverse={false}
      hasMore={hasMore}
      isJoined
      threshold={300}
      loadMore={loadMore}
    />
  );
};

NotificationList.propTypes = {
  afterVisibleChange: PropTypes.bool,
  view: PropTypes.string,
  listClassName: PropTypes.string
};

NotificationList.defaultProps = {
  afterVisibleChange: false,
  listClassName: undefined,
  view: undefined
};

export default NotificationList;
