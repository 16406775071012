import { useDispatch } from 'react-redux';

import { getUrlAttachmentByType, getUrlAttachment } from 'store/attachments';

import useGoogleAccount from 'hooks/common/use-google-account';
import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';
import { useFileUpload } from 'hooks';

import { getEntityData } from '../utils';

export const useViewFile = ({
  statusId,
  contactId,
  taskId,
  assetId,
  entityId,
  entityType
} = {}) => {
  const dispatch = useDispatch();

  const {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    hasWorkspaceGoogleDriveIntegration
  } = useWorkspaceGoogleDriveSupport();

  const { downloadFile } = useFileUpload({
    hasWorkspaceGoogleDriveWorkflow:
      isWorkspaceOwnGoogleDriveIntegrationEnabled &&
      hasWorkspaceGoogleDriveIntegration
  });

  const { check } = useGoogleAccount();

  const entityData = getEntityData({
    statusId,
    contactId,
    taskId,
    assetId,
    entityId,
    entityType
  });

  const onDownload = ({ fileId, title }) =>
    dispatch(getUrlAttachment({ ...entityData, fileId })).then(({ url }) =>
      downloadFile({ url, fileName: title })
    );

  const onView = async file => {
    const { url } = await dispatch(
      getUrlAttachmentByType({ ...file, ...entityData })
    );

    return { ...file, url };
  };

  const viewFile = async file => {
    if (!file.fileId || file.isTrash) {
      return;
    }

    const onClickDownload = () => onDownload(file);
    const onClickView = () => onView(file);

    await check(file, onClickDownload, onClickView);
  };

  return { entityData, viewFile };
};

export default useViewFile;
