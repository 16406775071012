export default api => ({
  fetch: id =>
    api
      .get(`/v1/workspaces/${id}/`, { show400: false })
      .then(({ data }) => data),

  // TODO: is_individual - delete
  fetchAll: ({ limit = 1000 } = {}) =>
    api
      .get(`/v1/workspaces/`, {
        errorHandle: false,
        params: { limit, is_individual: false }
      })
      .then(({ data }) => data.results),

  // TODO: is_individual - delete
  search: ({ workspaceId, search, country, limit, offset }) =>
    api
      .get(`/v1/${workspaceId}/search/workspaces/`, {
        params: { search, country, limit, offset, is_individual: false }
      })
      .then(({ data }) => data),

  searchExternal: ({ query, country }) =>
    api
      .post('/v1/workspaces/find-external/', { query, country })
      .then(({ data }) => data.results),

  fetchJoinWorkspaces: workspaceId =>
    api.get(`/v1/${workspaceId}/workspace/join-workspaces-list/`),

  create: workspace => api.post('/v1/workspaces/', workspace),

  patch: workspace => api.patch(`/v1/workspaces/${workspace.id}/`, workspace),

  createCategory: (workspaceId, categories) =>
    api.post(`/v1/${workspaceId}/workspace-categories/`, categories),

  deleteCategory: (categoryId, workspaceId) =>
    api.delete(
      `/v1/${workspaceId}/workspace-categories/by-category/${categoryId}/`
    ),

  createCity: (workspaceId, city) =>
    api.post(`/v1/${workspaceId}/workspace-cities/`, {
      city
    }),

  deleteCity: (cityId, workspaceId) =>
    api.delete(`/v1/${workspaceId}/workspace-cities/by-city/${cityId}/`),

  vatNumber: ({ vatNumber, country }) =>
    api
      .post(
        '/v1/workspaces/vat-number/',
        {
          vatNumber,
          country
        },
        { errorHandle: false }
      )
      .then(({ data }) => data),

  join: workspaceId => api.post(`/v1/workspaces/${workspaceId}/join/`),

  fetchPriceList: ({ workspaceId, ...params }) =>
    api
      .get(`/v1/${workspaceId}/workspace-price-list/`, { params })
      .then(({ data }) => data),

  fetchPriceListHistory: ({ workspaceId, ...params }) =>
    api
      .get(`/v1/${workspaceId}/workspace-price-list/history/`, { params })
      .then(({ data }) => data),

  createPriceList: ({ workspaceId, priceList }) =>
    api
      .post(`/v1/${workspaceId}/workspace-price-list/`, priceList)
      .then(({ data }) => data),

  fetchPublicContract: ({ workspaceId, ...params }) =>
    api
      .get(`/v1/workspaces/${workspaceId}/public-contract-preview/`, { params })
      .then(({ data }) => data),

  createPublicContract: ({ workspaceId }) =>
    api
      .post(`/v1/workspaces/${workspaceId}/create-public-contract/`)
      .then(({ data }) => data),

  deleteWorkspace: ({ workspaceId }) =>
    api.delete(`/v1/workspaces/${workspaceId}/`),

  checkCanDeleteWorkspace: ({ workspaceId }) =>
    api.get(`/v1/workspaces/${workspaceId}/can-delete/`),

  restoreWorkspace: ({ workspaceId }) =>
    api.post(`/v1/workspaces/${workspaceId}/restore/`),

  updateWorkspaceLegatInfo: ({ workspaceId }) =>
    api.post(`/v1/workspaces/${workspaceId}/update-info/`),

  addGoogleDriveIntegration: ({ workspaceId, code, redirectUri }) =>
    api
      .post(`/v1/${workspaceId}/integrations/google-drive`, {
        code,
        redirectUri
      })
      .then(({ data }) => data),

  deleteGoogleDriveIntegration: ({ workspaceId }) =>
    api.delete(`/v1/${workspaceId}/integrations/google-drive`)
});
