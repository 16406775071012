import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Spin } from 'antd';
import PropTypes from 'prop-types';
import { Translation, useTranslation } from 'react-i18next';
import classnames from 'classnames';

import {
  AGREEMENT_TASK_ACTIONS,
  AGREEMENT_TASK_APPROVED,
  AGREEMENT_TASK_PROGRESS,
  AGREEMENT_TASK_REJECTED,
  STATUS_CANCELLED,
  STATUS_COMPLETED,
  STATUS_IN_WORK
} from 'constants/index';

import Button from 'components/common/button';

import { agreementAction } from 'store/tasks';
import { getUserEmployee } from 'store/workspace';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import getHasOwnerRole from 'utils/get-has-owner-role';

import RejectedModal from './rejected-modal';

import styles from './agreement-actions.module.scss';

export const AgreementActions = ({
  task,
  checkIsWorklog,
  allowChange,
  onUpdateStatus
}) => {
  const { t } = useTranslation('ReasonModal');

  const dispatch = useDispatch();
  const amplitude = useAmplitude();
  const userEmployee = useSelector(getUserEmployee);

  const isResponsible = (task.responsible || {}).id === userEmployee.id;
  const isAuthor = (task.author || {}).id === userEmployee.id;
  const isProjectManager = ((task.project || {}).managers || []).find(
    m => m.id === userEmployee.id
  );
  const isOwner = getHasOwnerRole(userEmployee.roles);

  const isNotResponsible = isOwner || isProjectManager || isAuthor;

  const isStatusInWork = task.status === STATUS_IN_WORK;
  const isFinalStatus =
    task.status === STATUS_CANCELLED || task.status === STATUS_COMPLETED;

  const [isLoading, setIsLoading] = useState(false);
  const [visibleRejectedModal, setVisibleRejectedModal] = useState(false);

  const onCheckStatus = ({ value }) => {
    if (
      value === AGREEMENT_TASK_REJECTED ||
      value === STATUS_CANCELLED ||
      value === AGREEMENT_TASK_PROGRESS
    ) {
      setVisibleRejectedModal(true);
    } else {
      setAgreement({ value });
    }
  };

  const setAgreement = async ({ value: action, reason: rejectionReason }) => {
    try {
      setIsLoading(true);

      if (
        isStatusInWork &&
        isNotResponsible &&
        action === STATUS_CANCELLED &&
        !isResponsible
      ) {
        await onUpdateStatus({
          id: task.id,
          status: STATUS_CANCELLED,
          reason: rejectionReason,
          oldStatus: task.status
        });
      } else {
        await dispatch(
          agreementAction({
            id: task.id,
            action: isFinalStatus ? AGREEMENT_TASK_PROGRESS : action,
            rejectionReason
          })
        );
      }

      amplitude.finishTaskEvent(task);
      setVisibleRejectedModal(false);
    } finally {
      setIsLoading(false);
    }
  };

  const onClickAction = async value => {
    try {
      setIsLoading(true);

      if (value === AGREEMENT_TASK_REJECTED || value === STATUS_CANCELLED) {
        await onCheckStatus({ status: task.status, value });
      } else {
        await checkIsWorklog(onCheckStatus)({ status: task.status, value });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const actions = AGREEMENT_TASK_ACTIONS.map(action => {
    if (action.value === AGREEMENT_TASK_APPROVED) {
      return {
        ...action,
        disabled: !allowChange
      };
    }

    if (action.value === AGREEMENT_TASK_REJECTED) {
      return {
        ...action,
        value: isNotResponsible ? STATUS_CANCELLED : AGREEMENT_TASK_REJECTED,
        label: isResponsible ? 'DeclineTaskBtn' : 'CancelBtn',
        disabled: !isResponsible && !isOwner && !isProjectManager && !isAuthor
      };
    }

    if (action.value === AGREEMENT_TASK_PROGRESS) {
      return {
        ...action,
        disabled: !task.permissions.canReturnTodoAgreement
      };
    }

    return action;
  });

  const filteredActions = actions.filter(action => {
    if (isFinalStatus) {
      return action.value === AGREEMENT_TASK_PROGRESS;
    }

    if (task.parent && isStatusInWork) {
      return action.value !== AGREEMENT_TASK_PROGRESS;
    }

    if (!task.parent && isStatusInWork) {
      return (
        action.value !== AGREEMENT_TASK_APPROVED &&
        action.value !== AGREEMENT_TASK_PROGRESS
      );
    }

    return true;
  });

  return (
    <div className={styles.root}>
      <Divider />

      <Spin spinning={isLoading}>
        <div
          className={classnames(styles.statuses, {
            [styles.odd]: filteredActions.length % 2 !== 0
          })}
          data-qa="qa-v13zxz2tjsk9gzx"
        >
          {filteredActions.map(action => (
            <Button
              key={action.label}
              mood={action.mood}
              className={styles.btnAction}
              data-qa="qa-0b9pj0mysldyhi7"
              onClick={() => onClickAction(action.value)}
              disabled={action.disabled}
              ghost
              type="secondary"
              size="large"
            >
              {/* eslint-disable-next-line no-shadow */}
              <Translation ns={action.ns}>{t => t(action.label)}</Translation>
            </Button>
          ))}
        </div>
      </Spin>

      <RejectedModal
        visible={visibleRejectedModal}
        title={!isStatusInWork ? t('DeclineTaskReasonHeading') : undefined}
        onSubmit={reason =>
          setAgreement({
            reason: reason.description,
            value:
              isNotResponsible && !isResponsible
                ? STATUS_CANCELLED
                : AGREEMENT_TASK_REJECTED
          })
        }
        onClose={() => setVisibleRejectedModal(false)}
      />
    </div>
  );
};

AgreementActions.propTypes = {
  task: PropTypes.object,
  allowChange: PropTypes.bool,
  checkIsWorklog: PropTypes.func,
  onUpdateStatus: PropTypes.func
};

AgreementActions.defaultProps = {
  allowChange: false,
  checkIsWorklog: () => () => {},
  task: null,
  onUpdateStatus: () => {}
};

export default AgreementActions;
