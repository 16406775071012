import { useState, useEffect } from 'react';

import { MODAL, SELECTED_REQUEST, EDIT_REQUEST } from '../../constants';
import { useQueryParam } from '../common';

export const useRequestEditor = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const modal = useQueryParam(MODAL);
  const task = Number(useQueryParam(SELECTED_REQUEST));

  useEffect(() => {
    setVisible(!!(modal === EDIT_REQUEST && task));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  return [visible, setVisible];
};

export default useRequestEditor;
