import React from 'react';
import PropTypes from 'prop-types';

import Modal from 'components/common/modal';
import { PaymentJournalIcon } from 'components/common/icons';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import useRoutesService from 'services/routes';

import styles from './service-unavailable.module.scss';

const { Title, Text } = Typography;

const ServiceUnavailableModal = ({
  visible,
  onClose,
  title,
  description,
  buttonText,
  ...props
}) => {
  const routes = useRoutesService();

  const handleToBilling = () => {
    routes.toBilling();
    onClose();
  };

  return (
    <Modal
      width={440}
      open={visible}
      centered
      destroyOnClose
      contentClassName={styles.root}
      onClose={onClose}
      {...props}
    >
      <Icon component={PaymentJournalIcon} />

      <Title className={styles.title}>{title}</Title>

      <Text className={styles.description}>{description}</Text>

      {buttonText && (
        <Button onClick={handleToBilling} type="primary" size="large">
          {buttonText}
        </Button>
      )}
    </Modal>
  );
};

ServiceUnavailableModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  buttonText: PropTypes.string
};

ServiceUnavailableModal.defaultProps = {
  buttonText: undefined,
  description: '',
  title: ''
};

export default ServiceUnavailableModal;
