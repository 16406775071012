import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import uniq from 'lodash/uniq';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_ORDER_STATUS,
  TYPE_CONTACT,
  TYPE_ASSET,
  TYPE_REQUEST
} from 'constants/index';

import Button from 'components/common/button';
import {
  FormContactSelect,
  FormOrderSelect,
  FormRequestSelect,
  FormTaskSelect
} from 'components/common/hook-form';
import FormAssetSelect from 'components/common/hook-form/select/asset';
import { SkeletonInput } from 'components/common/skeletons';

import { fetchOrderStatusesByIds } from 'store/order-statuses';

import styles from './relations.module.scss';

const Form = ({ defaultValues, isLoading, allowEdit, onSubmit }) => {
  const dispatch = useDispatch();

  const methods = useForm({ defaultValues });

  const [isLoadingOrderStatusRelations, setIsLoadingOrderStatusRelations] =
    useState(false);

  const { t } = useTranslation(['Common', 'Requests']);

  const commonSelectProps = {
    isMulti: true,
    closeMenuOnSelect: false,
    showSelectedOptionSeparately: true,
    isLinkToElement: true,
    itemProps: {
      className: styles.select
    },
    isDisabled: !allowEdit
  };

  const fetchOrderStatuses = useCallback(async () => {
    try {
      setIsLoadingOrderStatusRelations(true);

      const { _embedded } = await dispatch(
        fetchOrderStatusesByIds({
          ids: uniq(defaultValues[TYPE_ORDER_STATUS].map(os => os.value))
        })
      );

      if (_embedded) {
        const { orderStatuses: fetchedOrderStatuses } = _embedded;
        const result = defaultValues[TYPE_ORDER_STATUS].map(os => {
          const fetchderOrderStatus = fetchedOrderStatuses.find(
            fos => fos.id === os.value
          );

          return {
            ...os,
            label: { ...os.label, ...(fetchderOrderStatus || {}) }
          };
        });

        methods.setValue(TYPE_ORDER_STATUS, result);
      }
    } finally {
      setIsLoadingOrderStatusRelations(false);
    }
  }, [defaultValues, dispatch, methods]);

  useEffect(() => {
    if (defaultValues[TYPE_ORDER_STATUS].length) {
      fetchOrderStatuses();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormTaskSelect
          name={TYPE_TASK}
          label={t('Tasks')}
          valueText={t('ChooseTask')}
          {...commonSelectProps}
        />

        <FormRequestSelect
          name={TYPE_REQUEST}
          label={t('RequestsTitle', { ns: 'Requests' })}
          valueText={t('ChooseRequest', { ns: 'Requests' })}
          {...commonSelectProps}
        />

        <FormContactSelect
          name={TYPE_CONTACT}
          label={t('Contacts')}
          valueText={t('ChooseContact')}
          {...commonSelectProps}
        />

        {isLoadingOrderStatusRelations ? (
          <SkeletonInput className={styles.select} />
        ) : (
          <FormOrderSelect
            name={TYPE_ORDER_STATUS}
            label={t('Orders')}
            valueText={t('ChooseOrder')}
            {...commonSelectProps}
          />
        )}

        <FormAssetSelect
          name={TYPE_ASSET}
          label={t('Assets')}
          {...commonSelectProps}
        />

        <Button
          type="primary"
          htmlType="submit"
          width="expanded"
          className={styles.submit}
          disabled={!allowEdit}
          loading={isLoading}
        >
          {t('Save')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  defaultValues: PropTypes.shape({
    // TODO
  }),
  onSubmit: PropTypes.func.isRequired
};

Form.defaultProps = {
  defaultValues: {}
};

export default Form;
