import React from 'react';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import AttachmentsControl from 'components/common/attachments-control';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

export const UploadFilesDescription = ({
  fileList,
  onChangeFiles,
  uploadFileServer,
  children,
  uploadText,
  isDisabled,
  actionsDeps,
  entityType,
  uploadFileComponent,
  attachmentProps,
  ...props
}) => {
  const { t } = useTranslation('CommonChat');

  return (
    uploadFileComponent || (
      <AttachmentsControl
        fileList={fileList}
        onChange={onChangeFiles}
        uploadFileServer={uploadFileServer}
        actionsDeps={actionsDeps}
        entityType={entityType}
        attachmentProps={attachmentProps}
      >
        {children || (
          <Tooltip title={t('AttachFilesTip')} mouseEnterDelay={0.5}>
            <Button
              type="text"
              disabled={isDisabled}
              style={{ fontWeight: 600, height: 'auto' }}
              {...props}
            >
              <Icon type="paper-clip" size={16} color="black-55" />
              {!!uploadText && <span>{uploadText}</span>}
            </Button>
          </Tooltip>
        )}
      </AttachmentsControl>
    )
  );
};

export default UploadFilesDescription;
