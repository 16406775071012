import React from 'react';
import { Empty } from 'antd';
import PropTypes from 'prop-types';
import { Translation } from 'react-i18next';

import Icon from 'components/common/icon';
import { UploadFiles } from 'components/common/controls/upload-files';
import Typography from 'components/common/typography';
import { MyDriveIcon } from 'components/common/icons';

import { useFileUpload } from 'hooks';
import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';

import styles from './upload.module.scss';

export const Upload = ({
  uploadFileServer,
  isOnlyUpload,
  icon,
  description,
  hasWorkspaceGoogleDriveSupport,
  ...props
}) => {
  const { hasWorkspaceGoogleDriveIntegration } =
    useWorkspaceGoogleDriveSupport();

  const { onUploadAttachment, onUpload } = useFileUpload({
    hasWorkspaceGoogleDriveWorkflow:
      hasWorkspaceGoogleDriveSupport && hasWorkspaceGoogleDriveIntegration,
    storage:
      hasWorkspaceGoogleDriveSupport && !hasWorkspaceGoogleDriveIntegration
        ? 's3'
        : undefined
  });

  const customRequest = () => {
    if (uploadFileServer) {
      return isOnlyUpload ? onUpload : onUploadAttachment;
    }

    return () => {};
  };

  return (
    <UploadFiles
      fullWidth
      showAttachments={false}
      className={styles.root}
      customRequest={customRequest()}
      {...props}
      hasWorkspaceGoogleDriveSupport={hasWorkspaceGoogleDriveSupport}
    >
      <Empty
        image={<Icon component={icon} />}
        imageStyle={{ height: 'auto' }}
        description={description}
      />
    </UploadFiles>
  );
};

Upload.propTypes = {
  isOnlyUpload: PropTypes.bool,
  uploadFileServer: PropTypes.bool,
  hasWorkspaceGoogleDriveSupport: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
};

Upload.defaultProps = {
  isOnlyUpload: false,
  uploadFileServer: true,
  icon: MyDriveIcon,
  hasWorkspaceGoogleDriveSupport: false,
  description: (
    <Typography.Paragraph className={styles.title}>
      <Translation ns="UploadFile">{t => t('DragnDropDesc')}</Translation>
    </Typography.Paragraph>
  )
};
