import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SCHEDULER_OPTIONS, DOCUMENT_TYPES } from 'constants/index';

import Drawer from 'components/common/drawer';
import SystemSpecificationForm from 'components/orders-view/forms/system-specification';
import EditWorkspaceRequisitesDrawer from 'components/settings-view/drawers/edit-workspace-requisites';
import useMediaViewer from 'components/common/media-viewer-provider/use-media-viewer';

import {
  fetchOrderStatusSignatory,
  previewSystemSpecification
} from 'store/order-statuses';
import { getUserEmployee } from 'store/workspace';
import { getUILanguage } from 'store/ui';

import { getUrlFileByArrayBinary } from 'hooks/common/use-file-upload/get-url-file-by-binaty';
import getIso2CodeByLanguage from 'utils/get-iso-2-code-by-language';

const DEFAULT_VAT = 20;

const OrderContractDrawer = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();

  const employee = useSelector(getUserEmployee);
  const language = useSelector(getUILanguage);

  const [visibleEditRequisites, setVisibleEditRequisites] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { onOpen } = useMediaViewer();

  const { t } = useTranslation('OrderConditions');

  const {
    orderStatus = {},
    specificationData = {}, // data from custom or previous specification
    isFromCustomSpecification,
    onSubmit,
    resetReordering
  } = data;

  const defaultValues = {
    ...specificationData,
    contractDate:
      specificationData.contractDate ||
      specificationData.startAt ||
      orderStatus.createdAt,
    specificationCreatedAt:
      specificationData.specificationCreatedAt ||
      specificationData.startAt ||
      orderStatus.createdAt,
    documentList: isFromCustomSpecification
      ? specificationData.documentList
      : [],
    language:
      specificationData.language ||
      getIso2CodeByLanguage({ language, isEnToEng: true }),
    withTax:
      specificationData.withTax === undefined
        ? true
        : specificationData.withTax,
    tax: specificationData.tax || DEFAULT_VAT,
    currency: {
      label: specificationData.currency || employee.currency,
      value: specificationData.currency || employee.currency
    },
    items: specificationData.items || [],
    location: specificationData.location || orderStatus.location,
    locationExact: specificationData.locationExact || orderStatus.locationExact,
    startAt: specificationData.startAt || orderStatus.startAt,
    deadlineAt: specificationData.deadlineAt || orderStatus.deadlineAt,
    title: specificationData.title || orderStatus.title,
    content: specificationData.content || orderStatus.content,
    fileList: isFromCustomSpecification
      ? specificationData.fileList
      : (orderStatus.documentList || []).filter(file =>
          specificationData.fileList.includes(file.fileId)
        ),
    scheduler: specificationData.scheduler
      ? {
          ...specificationData.scheduler,
          interval: specificationData.scheduler.interval,
          frequency: SCHEDULER_OPTIONS.find(
            ({ value }) => value === specificationData.scheduler.frequency
          )
        }
      : null,
    paymentFromDocumentType:
      DOCUMENT_TYPES.find(
        ({ value }) => value === specificationData.paymentFromDocumentType
      ) || DOCUMENT_TYPES[0],
    signatory: orderStatus.signatory
      ? {
          value: orderStatus.signatory.id,
          label: orderStatus.signatory
        }
      : specificationData.signatory
  };

  const preview = useCallback(
    async values => {
      try {
        setIsLoadingPreview(true);

        await dispatch(
          previewSystemSpecification({
            specification: values,
            orderStatusId: orderStatus.id
          })
        ).then(({ data: result, filename, mimetype: mimeType }) => {
          const url = getUrlFileByArrayBinary({ data: result, mimeType });

          onOpen([{ url, name: filename, mimeType }]);
        });
      } finally {
        setIsLoadingPreview(false);
      }
    },
    [dispatch, onOpen, orderStatus.id]
  );

  const onFetchSignatory = useCallback(
    async signatoryId => dispatch(fetchOrderStatusSignatory({ signatoryId })),
    [dispatch]
  );

  const handleSubmit = useCallback(
    async ({ isPreview, ...values }) => {
      if (isPreview) {
        return preview(values);
      }

      try {
        setIsLoading(true);

        await onSubmit(values);
        await onFetchSignatory(values.signatoryId);
        return onClose();
      } finally {
        setIsLoading(false);
      }
    },
    [onClose, onFetchSignatory, onSubmit, preview]
  );

  const handleCloseDrawer = useCallback(() => {
    resetReordering();
    onClose();
  }, [onClose, resetReordering]);

  return (
    <>
      <Drawer
        open={visible}
        width={1100}
        title={<Drawer.Title>{t('OrderConditionsHeading')}</Drawer.Title>}
        destroyOnClose
        onClose={handleCloseDrawer}
      >
        <SystemSpecificationForm
          defaultValues={defaultValues}
          isLoading={isLoading}
          isLoadingPreview={isLoadingPreview}
          onSubmit={handleSubmit}
          editRequisites={() => setVisibleEditRequisites(true)}
        />
      </Drawer>

      <EditWorkspaceRequisitesDrawer
        visible={visibleEditRequisites}
        onClose={() => setVisibleEditRequisites(false)}
      />
    </>
  );
};

OrderContractDrawer.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired
};

OrderContractDrawer.defaultProps = {
  visible: false,
  data: {}
};

export default OrderContractDrawer;
