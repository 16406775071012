import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import { FormEmployeeSelect } from 'components/common/hook-form';

import { editTaskFields } from 'store/requests';

const Form = ({ defaultValues, isLoading, onSubmit }) => {
  const methods = useForm({
    defaultValues
  });

  const { t } = useTranslation(['TaskRoleActions', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <FormEmployeeSelect
          name="approvingManager"
          label={t('Approving')}
          data-qa="qa-9vnibaksj2a1dpx"
          closeMenuOnSelect
          rules={{ required: t('RequierdField', { ns: 'Errors' }) }}
          itemProps={{ style: { marginBottom: 24 } }}
          style={{ width: '100%' }}
        />

        <Button
          type="primary"
          data-qa="qa-ljmnbhllnmgq6sz"
          width="expanded"
          htmlType="submit"
          loading={isLoading}
          style={{
            display: 'block',
            fontSize: 16,
            fontWeidht: 600,
            marginLeft: 'auto'
          }}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  defaultValues: PropTypes.shape({
    approvingManager: PropTypes.object
  }),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

Form.defaultProps = {
  defaultValues: {},
  isLoading: false
};

export const EditorApprovingManagerModal = ({ task, onClose, ...props }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('TaskRoleActions');

  const onSubmit = async ({ approvingManager }) => {
    try {
      setIsLoading(true);

      await dispatch(
        editTaskFields({
          value: {
            id: task.id,
            data: { approvingManager: approvingManager.value }
          }
        })
      );

      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={508}
      title={t('ChangeApprovingHeading')}
      data-qa="qa-5h1um96bge6kqo6"
      contentStyle={{ padding: 24 }}
      onClose={onClose}
      {...props}
    >
      <Form
        defaultValues={{
          approvingManager: {
            label: task.approvingManager,
            value: (task.approvingManager || {}).id
          }
        }}
        isLoading={isLoading}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

EditorApprovingManagerModal.propTypes = {
  task: PropTypes.shape({
    id: PropTypes.number,
    approvingManager: PropTypes.object
  }).isRequired,
  onClose: PropTypes.func.isRequired
};

export default EditorApprovingManagerModal;
