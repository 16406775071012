import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { COMMENTS, TIME_LOG } from 'constants/index';

import Icon from 'components/common/icon';
import Chat from 'components/common/chat';
import useRequestChat from 'components/common/chat/entity-hooks/use-request-chat';

import { setReplyCommentState } from 'store/comments';

import TimeLog from '../../../time-log';
import PermissionsError from './permissions-error';

import styles from './tabs.module.scss';

const { TabPane } = Tabs;

const TABS = [
  { id: COMMENTS, icon: 'comment', tKey: 'TaskMessagesTab' },
  { id: TIME_LOG, icon: 'timer', tKey: 'TimeTrackingJournalTab' }
];

export const TabsProvider = ({ className, task }) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(COMMENTS);

  const history = useHistory();

  const {
    renderedMessages,
    messagesRef,
    hasMessages,
    isLoading: isLoadingMessages,
    toBottom,
    renderedControls,
    isJoined,
    hasMore,
    loadMore
  } = useRequestChat({ request: task });

  const { t } = useTranslation('Task');

  const { location } = history;
  const { showCommentsTab } = location.state || {};

  const allowCreateComment = task.permissions.createCommentTask;

  const renderTabPane = useCallback(() => {
    switch (activeTab) {
      case COMMENTS:
        return (
          <div className={styles.chat}>
            {allowCreateComment ? (
              <Chat
                messagesRef={messagesRef}
                isLoading={isLoadingMessages}
                hasMessages={hasMessages}
                messages={renderedMessages}
                toBottom={toBottom}
                controls={renderedControls}
                isJoined={isJoined}
                hasMore={hasMore}
                loadMore={loadMore}
              />
            ) : (
              <PermissionsError />
            )}
          </div>
        );
      case TIME_LOG:
      default:
        return <TimeLog task={task} />;
    }
  }, [
    activeTab,
    allowCreateComment,
    hasMessages,
    hasMore,
    isJoined,
    isLoadingMessages,
    loadMore,
    messagesRef,
    renderedControls,
    renderedMessages,
    task,
    toBottom
  ]);

  useEffect(() => {
    dispatch(setReplyCommentState(null));
  }, [dispatch, task.id]);

  useEffect(() => {
    if (showCommentsTab) {
      setActiveTab(COMMENTS);

      history.replace({
        ...location,
        state: { ...(location.state || {}), showCommentsTab: false }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCommentsTab]);

  useEffect(() => {
    setActiveTab(COMMENTS);
  }, [task.id]);

  return (
    <div
      className={classnames(className, styles.root)}
      data-qa="qa-7iyxeab2o3d2z7x"
    >
      <Tabs
        activeKey={activeTab}
        onChange={setActiveTab}
        className={styles.tabs}
        data-qa="qa-1m6evd2vu644uic"
      >
        {TABS.map(({ id, tKey, icon }) => (
          <TabPane
            key={id}
            tab={
              <>
                <Icon type={icon} size={16} />
                <span> {t(tKey)}</span>
              </>
            }
            className={styles.flexCenter}
            data-qa="qa-c5l2lti1h1c0h44"
          />
        ))}
      </Tabs>

      <div className={styles.content} data-qa="qa-ig12qbrgp1wkj3s">
        {renderTabPane()}
      </div>
    </div>
  );
};

TabsProvider.propTypes = {
  task: PropTypes.object.isRequired,
  className: PropTypes.string
};

TabsProvider.defaultProps = {
  className: undefined
};

export default TabsProvider;
