import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import ShortForm from 'components/orders-view/forms/short';
import { SkeletonEntityCreation } from 'components/common/skeletons';

import useEdit from './use-edit';

import styles from './change-record.module.scss';

const OrderChangeModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation('Contacts');

  const { isLoading, values, hasValues, onSubmit } = useEdit({
    orderStatusId: data.id,
    onClose,
    visible
  });

  return (
    <Modal
      title={t('ChangeRecordHeading')}
      open={visible}
      onClose={onClose}
      destroyOnClose
      centered
      contentClassName={styles.root}
      width={508}
    >
      {!hasValues ? (
        <SkeletonEntityCreation />
      ) : (
        <ShortForm
          isEdit
          isLoading={isLoading}
          defaultValues={values}
          onSubmit={onSubmit}
        />
      )}
    </Modal>
  );
};

OrderChangeModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

OrderChangeModal.defaultProps = {
  visible: false,
  data: {}
};

export default OrderChangeModal;
