import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line no-restricted-syntax
import { Button as AntButton } from 'antd';
import classnames from 'classnames';

import styles from './button.module.scss';

const ANT_COMPATIBILITY = {
  TYPE: {
    primary: 'primary',
    secondary: 'default',
    tertiary: 'text',
    link: 'link',
    text: 'text',
    default: ''
  }
};

const MOOD = {
  negative: 'moodNegative',
  positive: 'moodPositive',
  none: ''
};

const WIDTH = {
  expanded: 'widthExpanded',
  default: 'widthDefault',
  full: 'widthFull'
};

const TYPE = {
  primary: 'typePrimary',
  secondary: 'typeSecondary',
  tertiary: 'typeTertiary',
  link: 'typeLink',
  text: 'typeText',
  google: 'typeGoogle',
  googleDrive: 'typeGoogleDrive',
  default: ''
};

const SIZE = {
  large: 'sizeLarge',
  default: 'sizeDefault',
  small: 'sizeSmall'
};

/**
 * **Button**
 *
 * @param  props
 * @param  props.type { primary | secondary | tertiary | link | text | google | googleDrive | default } default
 * @param  props.width { expanded | default | full } default
 * @param  props.mood { negative | positive | none } positive
 * @param  props.size { large | default | small } default
 */
export const Button = ({
  width = WIDTH.default,
  mood = MOOD.positive,
  type = TYPE.default,
  size = SIZE.default,
  className,
  ...props
}) => (
  <AntButton
    type={ANT_COMPATIBILITY.TYPE[type] || type}
    size={size}
    className={classnames(
      styles.root,
      styles[WIDTH[width]],
      styles[MOOD[mood]],
      styles[TYPE[type]],
      className
    )}
    data-qa="qa-cz9omlwxj3j8l1z"
    {...props}
  />
);

Button.propTypes = {
  ...AntButton.propTypes,
  width: PropTypes.oneOf(Object.keys(WIDTH)),
  mood: PropTypes.oneOf(Object.keys(MOOD)),
  type: PropTypes.oneOf(Object.keys(TYPE)),
  size: PropTypes.oneOf(Object.keys(SIZE))
};

Button.defaultProps = {
  width: 'default',
  mood: 'positive',
  type: 'default',
  size: 'default'
};

export default Button;
