import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useTheme } from 'components/common/theme';
import { addValidityDateState } from 'components/common/validity-date/utils/add-validity-date-state';

import {
  getUrlVersionAttachment,
  restoreVersionAttachment,
  deleteVersionAttachment,
  viewVersionAttachmentByType
} from 'store/attachments';

import { useGoogleAccount, useFileUpload } from 'hooks';
import { useWorkspaceGoogleDriveSupport } from 'hooks/workspace/useWorkspaceGoogleDriveSupport';
import useModalsService from 'services/modals';
import { showNoticeMessage } from 'services/notice';

export const useActions = ({ onClose, setVersions }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(['MyDriveVersionHistory', 'Toast']);

  const {
    isWorkspaceOwnGoogleDriveIntegrationEnabled,
    hasWorkspaceGoogleDriveIntegration
  } = useWorkspaceGoogleDriveSupport();

  const { variables } = useTheme();
  const { downloadFile } = useFileUpload({
    hasWorkspaceGoogleDriveWorkflow:
      isWorkspaceOwnGoogleDriveIntegrationEnabled &&
      hasWorkspaceGoogleDriveIntegration
  });
  const { check } = useGoogleAccount();
  const modals = useModalsService();

  const onDownload = ({ fileId, versionId, title }) =>
    dispatch(getUrlVersionAttachment({ fileId, versionId })).then(({ url }) =>
      downloadFile({ url, fileName: title })
    );

  const restoreFile = async ({ fileId, versionId }) => {
    const restoredVersion = await dispatch(
      restoreVersionAttachment({ fileId, versionId })
    );

    setVersions(prev => [restoredVersion, ...prev]);

    showNoticeMessage({ customContent: t('VersionRestored', { ns: 'Toast' }) });
  };

  const deleteFile = async ({ fileId, versionId }) => {
    await dispatch(deleteVersionAttachment({ fileId, versionId }));

    setVersions(prev => prev.filter(v => v.id !== versionId));

    showNoticeMessage({ customContent: t('VersionRemoved', { ns: 'Toast' }) });
  };

  const onCreateTask = file => {
    onClose();

    modals.tasks.showCreator({
      initialData: { fileList: [addValidityDateState(file)] }
    });
  };

  const viewFile = file => {
    const onClickDownload = () => onDownload(file);

    const onClickView = async () => {
      const { url } = await dispatch(viewVersionAttachmentByType(file));

      return { url, ...file };
    };

    check(file, onClickDownload, onClickView);
  };

  const getActions = file => {
    const { allowRestore = false, allowDelete = false } = file;

    const actions = [
      {
        key: 'download',
        title: t('DownloadFileAction'),
        onClick: () => onDownload(file),
        allow: true
      },
      {
        key: 'createTask',
        title: t('CreateTaskFileAction'),
        onClick: () => onCreateTask(file),
        allow: true
      },
      {
        key: 'restore',
        title: t('RestoreFileAction'),
        onClick: () => restoreFile(file),
        allow: allowRestore
      },
      {
        key: 'delete',
        title: t('DeleteFileAction'),
        style: { color: variables.color['red-60'] },
        onClick: () => deleteFile(file),
        allow: allowDelete
      }
    ];

    return actions.filter(({ allow }) => allow !== false);
  };

  return {
    getActions,
    viewFile
  };
};

export default useActions;
