import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CREATOR_DRAWER_WIDTH } from 'constants/drawers';

import Drawer from 'components/common/drawer';

import {
  fetchMessageTemplates,
  createMessageTemplate,
  updateMessageTemplate,
  getMessageTemplatesList
} from 'store/message-templates';

import {
  MESSAGE_TEMPLATES_LIST,
  MESSAGE_TEMPLATES_EDIT,
  MESSAGE_TEMPLATES_CREATE
} from './constants';
import MessageTemplatesList from './components/list';
import MessageTemplatesForm from './components/form';

const MessageTemplatesDrawer = ({ visible, onClose, data }) => {
  const dispatch = useDispatch();
  const templates = useSelector(getMessageTemplatesList);

  const { initialComponent, addTextToEditor } = data;

  const [template, setTemplate] = useState({});
  const [selectedComponent, setSelectedComponent] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState('');

  const { t } = useTranslation('AnswerTemplates');

  const fetchTemplates = async () => {
    setIsLoading(true);

    try {
      await dispatch(fetchMessageTemplates({ search }));
    } finally {
      setIsLoading(false);
    }
  };

  const createTemplate = useCallback(
    async ({ title, text }) => {
      setIsLoading(true);

      try {
        await dispatch(
          createMessageTemplate({
            template: {
              title,
              text: text.description
            }
          })
        );

        if (initialComponent === MESSAGE_TEMPLATES_CREATE) {
          onClose();
        }

        setSelectedComponent(MESSAGE_TEMPLATES_LIST);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, initialComponent, onClose]
  );

  const updateTemplate = useCallback(
    async ({ title, text }) => {
      setIsLoading(true);

      try {
        await dispatch(
          updateMessageTemplate({
            template: { id: template.id, text: text.description, title }
          })
        );

        setSelectedComponent(MESSAGE_TEMPLATES_LIST);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, template.id]
  );

  const componentData = useMemo(() => {
    if (selectedComponent === MESSAGE_TEMPLATES_CREATE) {
      return {
        title: (
          <>
            {initialComponent !== MESSAGE_TEMPLATES_CREATE && (
              <Drawer.Back
                onClick={() => setSelectedComponent(MESSAGE_TEMPLATES_LIST)}
              />
            )}

            <Drawer.Title>{t('AddNewAnswerTemplateHeading')}</Drawer.Title>
          </>
        ),
        component: (
          <MessageTemplatesForm
            onSubmit={createTemplate}
            isLoading={isLoading}
            template={data.value}
            submitBtnText={t('CreateBtn')}
          />
        )
      };
    }

    if (selectedComponent === MESSAGE_TEMPLATES_EDIT) {
      return {
        title: (
          <>
            <Drawer.Back
              onClick={() => setSelectedComponent(MESSAGE_TEMPLATES_LIST)}
            />

            <Drawer.Title>{t('EditAnswerTemplateHeading')}</Drawer.Title>
          </>
        ),
        component: (
          <MessageTemplatesForm
            template={template}
            onSubmit={updateTemplate}
            submitBtnText={t('SaveBtn')}
            isLoading={isLoading}
          />
        )
      };
    }

    return {
      title: <Drawer.Title>{t('AnswerTemplatesHeading')}</Drawer.Title>,
      component: (
        <MessageTemplatesList
          setSelectedComponent={setSelectedComponent}
          templates={templates}
          setTemplate={setTemplate}
          isLoading={isLoading}
          addTextToEditor={addTextToEditor}
          onClose={onClose}
          searchProps={{
            search,
            setSearch
          }}
        />
      )
    };
  }, [
    selectedComponent,
    t,
    templates,
    isLoading,
    addTextToEditor,
    onClose,
    search,
    initialComponent,
    createTemplate,
    data.value,
    template,
    updateTemplate
  ]);

  const handleDrawerClose = () => {
    onClose();
    setSelectedComponent(MESSAGE_TEMPLATES_LIST);
  };

  useEffect(() => {
    if (visible) {
      fetchTemplates();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, search]);

  useEffect(() => {
    if (visible) {
      setSelectedComponent(initialComponent);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Drawer
      title={componentData.title}
      open={visible}
      width={CREATOR_DRAWER_WIDTH}
      onClose={handleDrawerClose}
      destroyOnClose
    >
      {componentData.component}
    </Drawer>
  );
};

MessageTemplatesDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  data: PropTypes.shape({
    addTextToEditor: PropTypes.func,
    initialComponent: PropTypes.string,
    value: PropTypes.string
  })
};

MessageTemplatesDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
  data: {}
};

export default MessageTemplatesDrawer;
