import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { TimeIsOverIcon } from 'components/common/icons';
import Icon from 'components/common/icon';

import { updateSubtasksDates } from 'store/requests';

const { Title } = Typography;

export const EditorDatesModal = ({ params, onClose, ...props }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('SubtaskDueDates');

  const onAccept = async () => {
    setIsLoading(true);

    try {
      await dispatch(updateSubtasksDates(params));
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      width={440}
      contentStyle={{ padding: '60px 24px 32px', textAlign: 'center' }}
      onClose={onClose}
      {...props}
    >
      <Icon component={TimeIsOverIcon} style={{ marginBottom: 32 }} />

      <Title level={1} style={{ marginBottom: 32 }}>
        {t('SubtaskDueDatesHeading')}
      </Title>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Button
          size="large"
          type="link"
          mood="negative"
          style={{ marginRight: 20, fontWeight: 'normal' }}
          onClick={onClose}
        >
          {t('NoBtn')}
        </Button>

        <Button
          size="large"
          type="primary"
          loading={isLoading}
          onClick={onAccept}
        >
          {t('YesBtn')}
        </Button>
      </div>
    </Modal>
  );
};

EditorDatesModal.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.number
  })
};

EditorDatesModal.defaultProps = {
  params: {}
};

export default EditorDatesModal;
