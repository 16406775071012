import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Spin } from 'antd';

import { REQUEST_FIELD_CONTACT, REQUEST_FIELD_CHANNEL } from 'constants/index';

import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';
import Tooltip from 'components/common/tooltip';

import { fetchChannelsLocal } from 'store/channels';
import { fetchOneContactLocal } from 'store/contacts';

import { FieldEditorItem } from 'providers/field-editor';
import { getFullName } from 'utils/get-fio';
import useRoutesService from 'services/routes';

import styles from './ContactChannelInfo.module.scss';

export const ContactChannelInfo = ({ request }) => {
  const { t } = useTranslation('Requests');

  const dispatch = useDispatch();

  const [contact, setContact] = useState(null);
  const [channel, setChannel] = useState(null);
  const [loading, setLoading] = useState(false);

  const routes = useRoutesService({ returnUrl: true });

  useEffect(() => {
    const loadChannelAndContact = async taskInfo => {
      setLoading(true);

      try {
        const promises = [];

        if (taskInfo?.channelUuid) {
          promises.push(
            dispatch(fetchChannelsLocal({ channelUuid: taskInfo.channelUuid }))
              .then(({ results }) => results?.[0] || null)
              .then(setChannel)
          );
        }

        if (taskInfo?.contactId) {
          promises.push(
            dispatch(fetchOneContactLocal({ id: taskInfo.contactId })).then(
              setContact
            )
          );
        }

        await Promise.all(promises);
      } finally {
        setLoading(false);
      }
    };

    if (request.taskInfo) {
      loadChannelAndContact(request.taskInfo);
    }
  }, [request.taskInfo]);

  if (loading) {
    return (
      <div className={styles.loadingWrapper}>
        <Spin size="small" />
      </div>
    );
  }

  return (
    (!!contact || !!channel) && (
      <div className={styles.root}>
        {contact && (
          <FieldEditorItem
            field={REQUEST_FIELD_CONTACT}
            defaultValues={{ REQUEST_FIELD_CONTACT: contact.id }}
            className={styles.item}
          >
            <DetailsField
              iconType="user-outlined"
              title={t('ContactField')}
              className={styles.detailsField}
            >
              <a
                href={routes.toContact({ id: contact.id })}
                rel="noopener noreferrer"
              >
                <Typography.Text
                  color="brand"
                  style={{
                    marginRight: 8,
                    whiteSpace: 'nowrap',
                    display: 'block'
                  }}
                  ellipsis
                >
                  <Tooltip title={getFullName(contact)}>
                    {getFullName(contact)}
                  </Tooltip>
                </Typography.Text>
              </a>
            </DetailsField>
          </FieldEditorItem>
        )}

        {channel && (
          <FieldEditorItem
            field={REQUEST_FIELD_CHANNEL}
            defaultValues={{ REQUEST_FIELD_CHANNEL: channel.uuid }}
            className={styles.item}
          >
            <DetailsField
              iconType="phone-and-message"
              title={t('ChannelField')}
              className={styles.detailsField}
            >
              <Tooltip title={channel.name}>
                <Typography.Text ellipsis>
                  {`ID ${channel.id} ${channel.name}`}
                </Typography.Text>
              </Tooltip>
            </DetailsField>
          </FieldEditorItem>
        )}
      </div>
    )
  );
};
