import React from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Translation, useTranslation } from 'react-i18next';

import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Tooltip from 'components/common/tooltip';

import { TASK_TYPES, TYPE_REQUEST } from '../../../../constants';
import CopyLink from '../../../common/copy-link';
import useModalsService from '../../../../services/modals';

import styles from './icon-id.module.scss';

const IconId = ({
  className,
  object,
  style,
  type,
  copyable,
  onClick,
  isSchedulerActive,
  link
}) => {
  const { t } = useTranslation('Tasks');

  const modals = useModalsService({ returnUrl: true });

  const hasOnClick = typeof onClick === 'function';

  const onClickId = () => hasOnClick && onClick();
  const onClickTaskParent = requestId =>
    modals.requests.showDetails({ id: requestId });

  const currentIcon = TASK_TYPES.find(x => x.id === TYPE_REQUEST);

  return (
    <Typography.Paragraph
      className={classnames(styles.root, className, styles[type], {
        [styles.link]: hasOnClick,
        [styles.rootWithScheduler]: isSchedulerActive
      })}
      data-qa="qa-gt68yg6urqtjvs8"
      style={{
        marginBottom: 0,
        ...style
      }}
      onClick={onClickId}
    >
      {object.parent && (
        <>
          <Tooltip title={t('ParentTaskTip')}>
            <Link
              to={onClickTaskParent(object.parent)}
              style={{ padding: 0, height: 'auto', whiteSpace: 'nowrap' }}
              data-qa="qa-u7qspa8f63hvoss"
            >
              ID {object.parent}
            </Link>
          </Tooltip>

          <Divider
            type="vertical"
            style={{ marginTop: 4 }}
            data-qa="qa-w8lces8m1zqktr0"
          />
        </>
      )}

      <Tooltip
        title={
          <Translation ns={currentIcon.ns}>
            {translate => translate(currentIcon.label)}
          </Translation>
        }
      >
        <Icon className={styles.icon} component={currentIcon.icon} />
      </Tooltip>

      <div className={styles.id} data-qa="qa-c6nyhalmfiakjrk">
        ID {object.id}
      </div>

      {copyable && (
        <CopyLink link={link || window.location.href} label={copyable.label} />
      )}
    </Typography.Paragraph>
  );
};

IconId.propTypes = {
  className: PropTypes.string,
  object: PropTypes.shape({
    id: PropTypes.number,
    kind: PropTypes.string,
    parent: PropTypes.number
  }),
  style: PropTypes.object,
  type: PropTypes.string,
  copyable: PropTypes.object,
  onClick: PropTypes.func,
  isSchedulerActive: PropTypes.bool,
  link: PropTypes.string
};

IconId.defaultProps = {
  className: undefined,
  object: {},
  style: {},
  type: undefined, // link
  copyable: undefined,
  onClick: undefined,
  isSchedulerActive: false,
  link: null
};

export default IconId;
