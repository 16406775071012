import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { LINK_TYPE } from 'constants/index';

import RequestCreatorDrawer from 'components/requests-view/view/drawers/creator';

import { fetchRequestsLocal } from 'store/requests';

import CustomSelect from '../custom-select';
import CommonOption from '../custom-select/common-option';

const mapValue = value => ({
  value: value.id,
  label: value
});

export const RequestSelect = ({ isMulti, value, onChange, ...props }) => {
  const dispatch = useDispatch();

  const [visibleCreator, setVisibleCreator] = useState(false);

  const { t } = useTranslation('Requests');

  const addEntityButtonData = {
    title: t('AddRequest'),
    onClick: () => setVisibleCreator(true)
  };

  const creatableCallback = values => {
    if (!values) {
      return null;
    }

    const transfromed = values.map(mapValue)[0];
    return onChange(isMulti ? [...(value || []), transfromed] : transfromed);
  };

  const fetchData = params =>
    dispatch(fetchRequestsLocal({ ...params })).then(({ results, ...res }) => ({
      ...res,
      entries: results.map(mapValue)
    }));

  return (
    <>
      <CustomSelect
        isAsync
        isSearchable
        isClearable
        placeholder={t('SearchForRequests')}
        Option={CommonOption}
        fetchData={fetchData}
        selectType={LINK_TYPE.REQUEST}
        addEntityButtonData={addEntityButtonData}
        value={value}
        isMulti={isMulti}
        onChange={onChange}
        {...props}
      />

      <RequestCreatorDrawer
        visible={visibleCreator}
        data={{ withShowDetails: false }}
        onClose={() => setVisibleCreator(false)}
        onTasksCreated={creatableCallback}
      />
    </>
  );
};

export default RequestSelect;
