import moment from 'moment/moment';

export const shouldDisplayForHistory = ({
  currentItem,
  previousItem,
  currentDate,
  format,
  sortedMessages
}) => {
  const currItem = currentItem;
  const prevItem = previousItem;
  const currDate = currentDate;

  const currentMessageIndex = sortedMessages.findIndex(
    ({ uuid }) => uuid === currItem.messages.curr.uuid
  );

  const previousMessage = sortedMessages[currentMessageIndex - 1];
  const previousMessageDate = previousMessage?.createdAt;

  if (!prevItem && !previousMessageDate) {
    return true;
  }

  if (
    !prevItem &&
    previousMessageDate &&
    moment(previousMessageDate).isSame(currDate, 'day')
  ) {
    return false;
  }

  const prevDateInMainArray = previousMessageDate
    ? moment(previousMessageDate).format(format)
    : undefined;

  return currDate && prevDateInMainArray && currDate !== prevDateInMainArray;
};
