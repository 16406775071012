import React, { forwardRef } from 'react';
import { Collapse } from 'antd';
import { useTranslation } from 'react-i18next';

import Message from 'components/contacts-view/views/chats/contact-chat/message';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import {
  GroupedDateList,
  HISTORY_ACTION
} from 'components/common/chat/groupedDateList';

import useAmplitude from 'hooks/amplitude/use-amplitude';

import styles from './historyAction.module.scss';

export const HistoryAction = forwardRef(
  (
    {
      logs = [],
      messageRefs,
      entity,
      entityType,
      destination,
      sortedMessages,
      isCollapsed,
      toggleGroup
    },
    ref
  ) => {
    const { t } = useTranslation('CommonChat');

    const amplitude = useAmplitude();

    const logMessages = logs.map((log, index) => ({
      prev: logs[index - 1] || null,
      curr: log,
      next: logs[index + 1] || null
    }));

    const toggleCollapse = event => {
      if (event.length > 0) {
        amplitude.chatHistoryActionClickEvent();
      }
      toggleGroup({ lastLog: logs[logs.length - 1] });
    };

    return (
      <div ref={ref}>
        <Collapse
          ghost
          activeKey={isCollapsed ? [1] : []}
          bordered={false}
          expandIconPosition="end"
          className={styles.collapse}
          onChange={toggleCollapse}
          destroyInactivePanel
          expandIcon={({ isActive }) =>
            isActive ? (
              <Icon
                type="arrow"
                size={16}
                color="black-35"
                className={styles.arrowUp}
                style={{ transform: 'translateY(-50%)' }}
              />
            ) : (
              <Icon
                type="arrow"
                size={16}
                color="black-35"
                style={{ transform: 'translateY(-50%)' }}
              />
            )
          }
        >
          <Collapse.Panel
            key={1}
            header={
              <div className={styles.panelHeader}>
                <Typography.Paragraph
                  color="black-35"
                  className={styles.collapseTitle}
                  weight="semibold"
                  size="small"
                >
                  {t('ActionHistory')}
                </Typography.Paragraph>
              </div>
            }
          >
            <div>
              <GroupedDateList
                source={HISTORY_ACTION}
                className={styles.date}
                sortedMessages={sortedMessages}
              >
                {logMessages.map(({ prev, curr, next }) => (
                  <Message
                    key={curr.uuid}
                    entity={entity}
                    entityType={entityType}
                    destination={destination}
                    createdAt={curr.createdAt}
                    messages={{
                      prev,
                      curr,
                      next
                    }}
                  />
                ))}
              </GroupedDateList>
            </div>
          </Collapse.Panel>
        </Collapse>
      </div>
    );
  }
);
