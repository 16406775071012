import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ONLY_THIS, CHANGING_SCHEDULER_OPTIONS } from 'constants/index';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import { FormRadio } from 'components/common/hook-form';

import styles from './editor.module.scss';

export const ChangingSchedulerTaskModal = ({
  isLoading,
  visible,
  onClose,
  onSubmit
}) => {
  const methods = useForm({
    changingScheduler: ONLY_THIS
  });

  const { t } = useTranslation('ChangeRepetitiveTask');

  return (
    <Modal
      width={508}
      open={visible}
      title={t('ChangeRepetitiveTaskHeading')}
      onClose={onClose}
    >
      <FormProvider {...methods}>
        <form
          className={styles.changingSchedulerFrom}
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <FormRadio
            name="changingScheduler"
            options={CHANGING_SCHEDULER_OPTIONS}
          />

          <Button
            htmlType="submit"
            type="primary"
            width="expanded"
            loading={isLoading}
          >
            {t('SaveBtn')}
          </Button>
        </form>
      </FormProvider>
    </Modal>
  );
};

ChangingSchedulerTaskModal.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.number
  })
};

ChangingSchedulerTaskModal.defaultProps = {
  params: {}
};

export default ChangingSchedulerTaskModal;
